.flag-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.aligned-flag {
    vertical-align: text-bottom;
    width: 1em !important;
    margin-left: 5px;
}

.flag {
  .flag-background;
  position: relative;
  display: inline-block;
  width: 21px;
  &:before {
    content: "\00a0";
  }
}

.ajax-flag {
    vertical-align: top;
    margin-left: 5px;
}

.flag-icon(@country) {
  .flag-@{country} {
    background-image: ~"url(@{flag-icon-css-path}@{flag-icon-rect-path}/@{country}.svg)";
  }
}
