.page-title {
    position: relative;
    text-align:center;
    &:extend(.rubik);

    .title {
        margin: 20px 0 0;
        font-size: 55px; //2.5em;
        line-height: 75px;
        word-break: break-word;
    }

    .title-description {
        font-size: @font-size-big;
        line-height:2.5rem;
        margin: 20px 0 50px;
    }
}

.big-title {
    margin-top: 60px;
    text-align: center;
    display: block;
}
