/* company-upsell - domains/ssl */

.company-upsell {
    margin-top: 40px;
    position: relative;
    text-align: center;

    .icon {
        background: #fff;
        padding: 38px 20px 10px;
    }

    .grdcell {
        background: @color-lighter-grey;
    }

    .description {
        background-color: @color-lighter-grey;
        padding: 10px 13px;
    }

    .upsell-items {
        & > .grdcell {
            border: solid @color-grey;
            border-width: 1px 0;
            min-width: 120px;

            &:first-of-type {
                border-left-width: 1px;
            }

            &:last-of-type {
                border-right: 1px solid @color-grey;
            }
        }

        .icon {
            height: 95px;
            .clearfix();

            [class*='spr-'] {
                vertical-align: middle;
            }
        }
    }

    &.rounded {
        .upsell-items {
            background-color: @color-white;
            border-radius: @box-border-radius;
            border: 1px solid @color-white;
            .grdcell {
                border: none;
                background:none;
                .icon {
                    background: none;
                }
            }
        }
    }

    .slogan {
        background-color: @color-secondary-alt;
        display: inline-block;
        left: 50%;
        margin-left: -211px;
        padding: 0 15px;
        position: absolute;
        top: -20px;
        height:42px;
        z-index: 1;

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            border: 21px solid @color-secondary-alt;
            z-index: -1;
        }

        &::before {
            left: -2em;
            border-right-width: 21px;
            border-left-color: transparent;
        }

        &::after {
            right: -2em;
            border-left-width: 21px;
            border-right-color: transparent;
        }

        &.wide {
            margin-left: -255px;
            width: 500px;
        }

        &.andy {
            .andy-holder {
                display: inline-block;
                margin-top: 8px;
            }

            img {
            }
        }
    }

    .svg-holder {
        svg {
            margin-top: -7px;
            width: 50px;
            height: 50px;
        }

        &.browsers {
            svg {
                margin-top: 3px;
                width: 30px;
                height: 30px;
            }
        }
    }
}

@media (max-width: @break-lg-value) {
    .company-upsell {
        .slogan {
            left: auto;
            max-width: none;
            margin-left: 0;
            position: relative;
            top: 0;
            width: 100%;

            &.wide {
                margin-left: 0;
                width: 100%;
            }

            &::after,
            &::before {
                display: none;
            }
        }
        &.rounded  {
            .slogan {
                border-top-left-radius: @box-border-radius;
                border-top-right-radius: @box-border-radius;
            }
            .upsell-items {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        .grdcell {
            &:nth-of-type(-n+3) {
                border-bottom: 0;
            }

            &:nth-of-type(3) {
                border-right: 1px solid @color-light-grey;
            }

            &:nth-of-type(4) {
                border-left: 1px solid @color-light-grey;
            }
        }
    }
}

@media @break-sm {
    .company-upsell {
        .slogan {
            /*padding: 7px 15px 10px;*/
            max-height: none;
            img {
                display:none;
            }
        }

        .upsell-items {
            margin-top: -1px;

            & > .grdcell {
                border-left: 1px solid @color-grey;
                border-right: 1px solid @color-grey;

                &:nth-of-type(-n + 4) {
                    border-bottom: 0;
                }
            }
        }
    }

}
@media @break-xs {
    .company-upsell {
        .slogan {
            font-size:.8rem;
            height:auto;

        }
    }
}
