@media @break-sm {
    .domain-pricelist,
    .item-pricelist {
        .sorting-select,
        .duration-select,
        .category-select {
            flex-wrap: wrap;

            .row-title {
                flex: 1 0 100%;
                text-align: right;
            }
        }

        .td {
            &.prices {
                flex: 0 0 100%;
            }
        }
    }
}
