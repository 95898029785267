@media @break-sm {
    .quote-cont {
        min-height: 55px;
    }

    .hosting-features {
        .written {
            bottom: 30px;
        }

        .monitor-dell {
            display: none;
        }
    }
}
@media @break-md {

}

@media @break-max {
    .hosting-features {
        .right-img {
            display:none;
        }
        .fast-servers {
            img {
                left: 0;
            }
            .written {
                display:none;
            }
        }
    }
}
