/*=================================
=            Job pages            =
=================================*/


.jobs-page {
    .brd-v.bg-ltgrey img {
        margin: 0 auto;
        display: block;
    }
    .check-list {
        .list-item {
        }
    }
}
.dark-titles {
    .brd-bx {
        .bx-ttl {
            background: @color-lightest-grey;
            padding: 10px 20px;
        }
    }
}
label .no-upper {
    text-transform: none;
}

.inline-list {
    &.brands {
        text-align: center;
        .list-item {
            margin: 0 45px;
        }
        .f-small {
            color: @color-darker-grey;
            margin: 5px 0px 8px;
        }
    }
}

#job-form {
    margin-bottom: 0;
    padding: 0;

    .job-message {
        height: 397px;
    }

    textarea {
        min-height: 383px;
    }
}

/*==========  Jobs list  ==========*/
.text-w-appended-img {
    .clearfix();

    position: relative;

    img {
        position: absolute;
        left: 0;
        max-width: 100px;
        padding: 25px;
    }

    p {
        padding-left: 100px;
    }
}

.job-list {
    &:extend(.std-list all);

    .job-title {
        font-size: 16px;
        font-weight: bold;
        margin: 12px 0 9px;
    }

    li {
        padding: 10px 0;
        border-bottom: 1px solid @color-grey;
        overflow: auto;

        &:first-of-type {
            border-top: 1px solid @color-grey;
        }

        & > a {
            display: inline-block;

            &.c-grey:visited {
                color: @color-grey;
            }
        }
    }

    .disabled {
        color: @color-grey;
        background: none;

        a {
            color: @color-grey;
        }
    }
}

.available-jobs {
    margin-top: 40px;
}

.other-applicants {
    &:extend(.text-w-appended-img all);
}

.job-taken {
    position: absolute;
    top: 28%;
    width: 470px;
    border: 10px solid #ff0000;
    background: white;
    z-index: 2;
    left: 50%;
    margin-left: -235px;
    text-align: center;
    color: #ff0000;
    padding: 5px 10px;
    transform: rotate(-30deg);
    box-shadow: 3px 3px 20px rgba(0,0,0,0.15);

    h2 {
        font-size: 44px;
        line-height: 1.2em;
        margin: 10px;
    }
}

.job-perks {
    .grdcell {
        min-width: 185px;
    }

    .bx-cntnt {
        min-width: 150px;
    }
}

.ttl-w-skill {
    h6 {
        float: left;
    }

    .skill-level {
        float: right;
    }
}

.skill-level {
    height: 8px;

    i {
        width: 50px;
        height: 10px;
        background: @color-grey;
        display: inline-block;
        margin-right: 1px;

        &.on {
            background: @color-secondary;
        }
    }

    &.green {}

    &.orange {
        i.on {
            background: @color-secondary-alt;
        }
    }

    &.red {
        i.on {
            background: @color-red-package;
        }
    }
}

/*=====  End of Job pages  ======*/
