@media @break-max-lg {
    .bg-e-postno-2, .bg-email-streznik {
        background: none;
        min-height: unset;
        width: unset;
    }
}

@media @break-sm {
    .kv-item {
        height: 420px;
    }
}

@media (max-width: 836px) {
    .kv-ssd {
        .labeled {
            padding-top: 0;
            text-align: left;
            font-weight: bold;

            .ruibk {
                display: none;
            }
        }

        .grd {
            max-width: 100%;
        }
    }

    .loading-compare {
        flex: 100%;
        margin-top: 5px;
    }

    .kv-options {
        margin: 20px auto 15px
    }
}

@media (max-width: 760px) {
    .kv-domene h2.heading {
        font-size:28px;
    }
}

@media (max-width: 650px) {
    .kv-domene {

        h2.heading {
            font-size:25px;
        }

        .kv-options {
            p {
                font-size: 13px;
            }
            .option {
                min-width:130px;
            }
        }
    }
}

@media (max-width: 575px) {
    .mobile-keyvisual {
        padding:0;
        margin:0;
        display:block;

        .kv-item {
            height:300px;
        }
    }
}

@media (max-width: 276px) {
    .mobile-keyvisual {
        display:none;
    }
}

@media (max-width: 930px) {
    .bg-gostovanje {
        background:none;
        min-height:284px;
        &:after {
            display:none;
        }
    }

}
@media (max-width: 850px) {
    .bg-gostovanje {
        .benefits {
            li {
                margin: 0 10px;
            }
        }
    }
}
@media (max-width: 800px) {
    .bg-gostovanje {
        h1.title {
            font-size:36px ! important;
        }
        h4 {
            font-size:18px;
        }
        .benefits {
            li {
                margin: 0 0px;
            }
        }
    }
}
@media (max-width: 700px) {
    .bg-gostovanje {
        padding-bottom:30px;
        h1.title {
            font-size:30px ! important;
        }
        h4 {
            font-size:16px;
            line-height:31px;
        }
       .benefits {
           margin-top:0;
           li {
               width:130px;
               margin-top:30px;
               font-size:12px;
           }
       }
    }
}

@media (max-width: 580px) {
    .kv-casestudies {

        #case-studies-animation {
            margin:0 auto 0 auto;
        }
    }
}
@media (max-width: 1240px) {
    .bg-spletne-strani {
        .page-title {
            text-align:center;
            width: 100%;
        }
        background: none;
        min-height: unset;
    }
}
@media (max-width: 580px) {
    .bg-spletne-strani {
        .buttons {
            a {
                margin: 10px 0;
            }
        }
    }
}
@media (max-width: 1240px) {
    .bg-predloge {
        .page-title {
            text-align:center;
            width: 100%;
        }
        background: none;
        min-height: unset;
    }
}
