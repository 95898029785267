/* Tabs - external js */

.tabs-holder {
    padding: 20px 20px 0;
    background-color: @color-grey;

    &.bg-primary {

        a {
            color: #FFF;
        }

        .active a {
            color: @color-primary;
        }

    }
}
