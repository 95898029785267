/* Slick theme*/
@slick-arrow-color:@color-fake-black;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-opacity-default: 0.75;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 0.25;
@slick-dot-color: black;
@slick-dot-color-active: @slick-dot-color;

/* Slider */

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;

    &:hover,
    &:focus {
        outline: none;
        background: transparent;
        color: transparent;

        &::before {
            opacity: @slick-opacity-on-hover;
        }
    }

    &.slick-disabled::before {
        opacity: @slick-opacity-not-active;
    }
}

.slick-prev::before,
.slick-next::before {
    font-family: 'neoserv';
    font-size: 28px;
    line-height: 1;
    color: @slick-arrow-color;
    opacity: @slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -15px;

    &::before {
        content: @slick-prev-character;

        [dir="rtl"] & {
            content: @slick-next-character;
        }
    }
}

.slick-next {
    right: -15px;

    &::before {
        content: @slick-next-character;

        [dir="rtl"] & {
            content: @slick-prev-character;
        }
    }
}

.inside-nav {
    .slick-next {
        right: 25px;
    }

    .slick-prev {
        left: 25px;
    }
}

/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height:380px;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;

    img {
        //display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}


/* Dots */
.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -40px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: @color-primary;
            padding: 5px;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;

                &::before {
                }
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: '\e616';
                width: 20px;
                height: 20px;
                font-family: 'neoserv';
                font-size: 1.5rem;
                line-height: 20px;
                font-weight: bold;
                text-align: center;
                color: inherit;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button::before {
            content: '\e617';
        }
    }
}

/**
 * Dodane stvari
 */

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 30px;
    margin-top: 0;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.colored-bg {
    .slick-prev,
    .slick-next {
        &::before {
            color: white;
        }
    }
}

.slick-next {
    right: -35px;
}

.slick-prev {
    left: -35px;
}

.slick-prev::before {
    .iconFamily();

    content: "\e602";
    font-weight: bold;
}

.slick-next::before {
    .iconFamily();

    content: "\e604";
    font-weight: bold;
}

.video-slides {
    .arrow-controls {
        margin-top: -50px;

        /*.flex-direction-nav {
            li {
                margin-right: 0;
                padding: 14px 17px 14px 14px;
                background-color: rgba(255, 255, 255, 0.5);
                font-size: 30px;
                border-radius: 0;
            }
        }*/
    }

    h4 {
        margin-top: -5px;
    }
}
