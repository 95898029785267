/* Hostig - software */

.hosting-software {
    padding: 30px 0 20px;
    margin-bottom: -40px;
}

.multi-slides {
    .item {
        margin: 0 20px;
        min-width: 140px;

        &:last-of-type {
            margin: 0;
        }
    }

    .optimized {
        display: inline-block;
        vertical-align: top;
        margin-left: 8px;

        strong {
            display: block;
            padding: 5px 0 3px;
            color: @color-secondary;
        }

        a {
            font-weight: bold;
        }
    }
}

.hosting-slides {
    .item {
        margin: 0 20px;
        min-width: 140px;

        &:last-of-type {
            margin: 0;
        }
    }

    .optimized {
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;

        strong {
            display: block;
            padding: 25px 0 3px;
            color: @color-secondary;
        }

        a {
            font-weight: bold;
        }
    }
}

.hosting-slides2 {

    ul {
        justify-content: center;
        align-items: center;
        li {
            display: inline-flex;        /* Ensure each <li> behaves like an inline-flex container */
            width: auto;                 /* Allow <li> elements to take only the width needed for their content */
            margin: 0 10px;              /* Add some spacing between <li> elements */
            flex: 0 0 auto;

        }
    }
    .item {
        margin: 0 20px;
        min-width: 140px;

        &:last-of-type {
            margin: 0;
        }
    }

    .optimized {
        display: inline-block;
        vertical-align: top;
       /* margin-right: 20px;*/

        strong {
            display: block;
            padding: 25px 0 3px;
            color: @color-primary;
        }

        a {
            font-weight: bold;
        }
    }
}

.software-slides {
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
    p {
        font-size:@font-size-smaller;
    }
}

.optimized .icon-check-black-long,
.icon-check-black-long.ok {
    position: relative;
    top: 3px;
    left: 5px;
    font-size: 26px;
    color: #42aa0c;
    line-height: 0;
}

.server-pros {
    padding: 40px 0;
    margin-top: 40px;

    .brd-bx {

    }

    .bx-cntnt {
        font-size: 12px;
        min-height: 200px;
    }

    .brd-bx,
    .bx-crcle {
        background-color: #fff;
        flex-direction: column;
    }

    .bx-ttl {
        max-height: 90px;
        min-width: 160px;
    }

    & > .grdcell {
        min-width: 210px;
    }
}

.no-spam {
    & > div {
        position: absolute;
        top: 30px;
        left: 10px;
        font-size: 22px;
    }

    &::after {
        position: absolute;
        top: 37px;
        content: ' ';
        height: 5px;
        width: 100%;
        background-color: #f00;
        transform: rotate(-45deg);
    }
}

.widget-title {
    margin-top: 0;
}

.hosting-hardware-articles {
    .bx-ttl {
        min-height: 55px;
        display: flex;
        align-items: center;
    }
}

.cpanel-articles {
    .brd-bx {
        min-width: 210px;
    }

    .grdcell {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: @break-md-value) {
    .server-pros {
        .bx-cntnt {
            // Ne sme biti auto - ker potem pride do flexbuga #1
            min-height: 110px;
        }

        .bx-ttl {
            min-height: 71px;
        }
    }
}
