/* ----------  Feature Boxes  ---------- */
.vps-feature-boxes {

    /* Flexbox semi-support fix */
    & > .grdcell {
        min-width: 140px;

        margin-top: 0.86rem;
        padding: 0.5rem 0 0 0.6rem;

        @media(min-width:@break-md-value){
            margin-top: 0.86rem;
            padding: 2.86rem 0 0 0.6rem;
        }

        @media(max-width: 575px) {
            max-width: 150px;
        }
    }

    .sprite-holder {
        margin-top: 5px;
        height: 50px;
        margin-bottom: 5px;
    }

    .upsell-box {
        padding: 15px 10px 20px;
        border-color: transparent;
        margin-bottom: 0;
        min-width: 105px;
        text-align: center;

        &:extend(.rubik);
    }
}
