@font-face {
    font-family: 'neoserv2';
    src:
        url('../fonts/neoserv2.ttf?o71fyy') format('truetype'),
        url('../fonts/neoserv2.woff?o71fyy') format('woff'),
        url('../fonts/neoserv2.svg?o71fyy#neoserv2') format('svg');

    font-weight: normal;
    font-style: normal;
}

/*----------  Icon definitions  ----------*/
.icon2Family() {
    &:extend(.family-icon2);
}
.family-icon2 {
    font-family: 'neoserv2';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text-decoration: none;
}
[class^="icon2-"], [class*=" icon2-"] {
    .icon2Family();
}

.icon2-chevron-left:before { content: '\f104'; } /* '' */
.icon2-chevron-right:before { content: '\203a'; } /* '' */
