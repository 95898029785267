/* Newsletter box */
.newsletter-gold-box {
    background-color: @color-secondary-alt-lightest;

    h4 {
        margin: 20px 0 0;
        color: @color-secondary-alt;
    }

    p {
        margin: 10px 0 20px;
        font-size: @font-size-bigger;
    }

    .newsletter-text,
    .newsletter-img {
        float: left;
    }

    .newsletter-img {
        width: 30%;

        img {
            margin: 0 auto;
            padding: 20px 0;
            display: block;
        }
    }

    .newsletter-text {
        padding-right: 40px;
        width: 70%;
    }

    .submit-button {
        position: relative;

        .btn-blue {
            position: absolute;
            top: 0;
            right: 0;
        }

        input {
            width: 100%;
            padding-bottom: 4px;
            font-size: @font-size-bigger;
        }
    }
}

@media (max-width: @break-sm-value) {
    .newsletter-gold-box {
        padding: 0;

        .newsletter-text {
            width: 100%;
            padding: 0 20px 20px;
        }

        .newsletter-img {
            display: none;
        }

        .submit-button .btn-blue {
            position: relative;
            width: 100%;
            margin-top: 20px;
        }
    }
}
