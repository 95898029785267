@media only screen and (max-width: 1023px) {
    table.ns-table-responsive-col {
        th, td {
            width: 18% !important;
        }

        th:first-child, td:first-child {
            width: 24% !important;
        }

        th:nth-child(5), td:nth-child(5) {
            width: 22% !important;
        }
    }
}

@media only screen and (max-width: 468px) {
    table.ns-table-responsive-col, table.ns-table-responsive-col-4 {
        tr {
            display: flex;
        }

        th, td {
            width: 20% !important;
        }
    }
}
