@media @break-max-lg {
    .page-title {
        .bg-e-postno-2, .bg-email-streznik {
            width: 100%;
        }

        h1.title .additional-title {
            display: block;
            font-size: 32px;
        }

        .h2.title {
            font-size: 40px;
        }

        h4.rubik-title {
            margin-top: 0;
        }
    }
}

@media @break-xlg {
    .page-title {

    }
}

@media @break-md {
    .page-title {
        .title {
            font-size: 40px;
            line-height: 50px;
        }
        .title-description {
            font-size: 1.3rem;
            line-height:2rem;
            margin: 20px 0 50px;
        }
    }

}

@media @break-sm {
    .page-title {
        .title {
            font-size: 40px;
            line-height: 50px;
        }
        .title-description {
            font-size: 1.2rem;
            line-height:1.8rem;
            margin: 0px 0 30px;
        }
    }
}
@media @break-xs {
    .page-title {
        .title {
            font-size: 30px;
            line-height: 40px;
        }
        .title-description {
            font-size: 1.1rem;
            line-height:1.6rem;
            margin: 10px 0 30px;
        }
    }
}
