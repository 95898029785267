/*================================
=           CHECKOUT 5           =
================================*/
.checkout-5 {
    .right-info {
        display: none;
    }

    .top-header .support {
        margin-top: 34px;
    }
}

#crypto-payment {
    align-items: center;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .crypto-icons {
        max-width: 320px;
    }
}

.crypto-icons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -3px 0;

    .img {
        margin: 4px 8px 4px 0;
    }
}

.coin-payment {
    .crypto-icons {
        //justify-content: flex-start;
    }

    .btn-big {
        margin-top: 15px;
    }

    .final-amount {
        position: relative;
    }
}

.btn-crypto {
    margin-left: auto;
}

/* Crypto payment modal */
.crypto-box-radio {
    align-items: center;
    color: @color-fake-black;
    border: 1px solid #ccc;
    display: flex;
    margin: 0;
    text-decoration: none;
    padding: 13px 7px 12px;
    text-align: center;

    label {
        margin: 0 auto 0 5px;
    }
}

.crypto-cell {
    max-width: 180px;
    min-width: 150px;
    padding-left: 0.8rem !important;
}

.final-amount {
    &.loading {
        color: transparent;
        transition: color 200ms;

        &::before {
            &:extend(.btn-loading::after all);

            left: 20px;
            color: @color-fake-black;
        }
    }
}

.coin-payment-status {
    .std-list {
        p {
            margin-bottom: 0;
        }

        .img {
            margin-top: -12px;
            margin-right: 30px;
        }
    }

    .payment-amount,
    .payment-address,
    .payment-tag {
        word-wrap: break-word;
    }
}

.checkout-success {
    flex-grow: 3;
}

.checkout-social {
    margin-left: 20px;
}

.qr-hodler {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 40px 20px;
}

.payment-amount {
    vertical-align: middle;
    opacity: 0.5;
}

.prepayment {
    img {
        width: 65px;
        height: 65px;
    }
    .prepayment-info {
        margin-top: -15px;
        margin-right: 10px;
        font-size: 12px;
    }
}

#website-payment {
    display: none;
}

@media (min-width: @break-xlg-value) {
    .additional-data {
        .crypto-icons {
            max-width: 95%;
        }
    }
}

@media (max-width: @break-md-value) {
    .additional-data {
        .crypto-icons {
            justify-content: center;
        }
    }
}

@media @break-sm {
    .coin-payment {
        .mdl-img {
            display: none;
        }
    }

    .crypto-cell {
        max-width: none;
    }
}
