/* Hosting server - prikaz komponent */

.hosting-server {
    padding: 40px 0 40px;

    .server {
        min-height: 260px;
        background: url(../img/cms/server-dell-r760_x2.jpg) no-repeat;
        background-size: 100%;
        padding-bottom: 50px;

        .grdcell {
            text-align: center;
            min-width: 100px;
        }
    }

    &.server2 {
        .server{
            min-height: 260px; //
            background: url(../img/cms/server-dell-r760-medium.png) no-repeat;
            background-size: contain;

            .grdcell {
                text-align: center;
                min-width: 100px;
            }
        }
    }

    .hint {
        position: relative;
        bottom: -20px;
        text-align: center;
        width: 100%;
    }

    & .hw-ssd {
        margin-top: 35%;
    }

    & .hw-ram {
        margin-top: 50%;
    }

    & .hw-cpu {
        margin-top: 65%;
    }

    & .hw-pci {
        margin-top: 50%;
    }

    & .hw-psu {
        margin-top: 35%;
    }

    .popover-btn.active {
        &[class*='icon-']:before {
            content: '\e619';
        }

    }
    // Small version

    &.small {
        padding: 10px 0 0;

        .server {
            min-height: 220px;
            max-width: 1280px;
            margin: 0 auto;
            background: url(../img/cms/server-dell-r760-medium.png) no-repeat 38px 0;
        }

        .holder {
            margin-top: 70%;
        }

    }
}

.holder {
    position: relative;
    display: inline-block;

    & .icon-hw-ssd {
        background-color: @color-secondary-alt;
    }

    & .icon-hw-ram {
        background-color: @color-primary;
    }

    & .icon-hw-cpu {
        background-color: @color-secondary;
    }

    & .icon-hw-pci {
        background-color: @color-darker-grey;
    }

    & .icon-hw-psu {
        background-color: @color-fake-black;
    }
}
