@import "fonts/neoserv";
@import "fonts/neoserv-domains";
@import "fonts/neoserv2";


/* ==================================
=            TYPOGRAPHY            =
================================== */
@font-face {
    font-family: 'Mathilde';
    src:
        local('Mathilde'),
        url('../fonts/mathilde-webfont.woff2') format('woff2'),
        url('../fonts/mathilde-webfont.woff') format('woff'),
        url('../fonts/mathilde-webfont.ttf') format('ttf'),
        url('../fonts/mathilde-webfont.eot') format('eot');
    font-weight: normal;
    font-style: normal;
}

/* rubik-300 - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/rubik-v21-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/rubik-v21-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/rubik-v21-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-600 - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/rubik-v21-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-600.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700 - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/rubik-v21-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-800 - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/rubik-v21-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-800.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-900 - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/rubik-v21-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-900.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-300italic - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/rubik-v21-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-300italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-italic - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/rubik-v21-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500italic - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/rubik-v21-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-500italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-600italic - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/rubik-v21-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-600italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700italic - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/rubik-v21-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-700italic.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-800italic - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/rubik-v21-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-800italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-900italic - latin-ext_latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/rubik-v21-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin-ext_latin-900italic.svg#Rubik') format('svg'); /* Legacy iOS */
}

.written {
    font-family: 'Mathilde', 'Bradley Hand', sans-serif;
    color: @color-primary;
}

.font-mono {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

.font-comic {
    font-family: "Comic Sans MS", cursive, sans-serif;
    color: @color-primary;
}

/* ----------  Icons mods  ---------- */
.icon-discount-right {
    color: @color-primary;
}

.icon-discount-right.promo {
    color: @color-secondary-alt;
}

.icon-fabo {
    color: #4e69a2;
}

.icon-yt {
    color: #e62117;
}

.icon-gp {
    color: #db4437;
}

.icon-instagram {
    color: #c8349a;
}

.icon-twt {
    color: #1b95e0;
}

.icon-in {
    color: #0369a0;
}

.icon-download-arrow {
    color: @color-secondary;
}

/* ==========  LINKS  ========== */
a {
    color: @color-primary;

    &:visited {
        color: @color-primary;
    }
}

/* ==========  HEADINGS  ========== */

// Svg logotip v tekstu
.logo-text {
    padding-left: 5px;
    height: 36px;
    margin-bottom: -9px;
    vertical-align: bottom;
}

h1 {
    font-size: 55px; // 2.5em; // 35px
    line-height: 1.43em;
    margin: 0.7em 0 0.8em; // 0.7em 0 0.8em;
    &:extend(.rubik);

    .logo-text {
        height: 60px;
        margin-bottom: -17px;
    }
    strong {
        &:extend(.rubik700);
    }
}

h2 {
    font-size: 35px; // 2.15em; // 30px
    line-height: 1.33em;
    margin: 0.6em 0 0.8em;// 0.6em 0 0.8em;
    .logo-text {
        height: 41px;
        margin-bottom: -5px;
    }
    &:extend(.rubik);
    strong {
        &:extend(.rubik700);
    }
}

h3 {
    font-size: 30px; // 1.79em; // 25px
    line-height: 1.2em;
    margin: 0.6em 0 0.8em;// 0.6em 0 0.8em;
}

h4 {
    font-size: 1.4rem; // 1.43em; // 20px
    line-height: 1.5em;
    margin: 0.6em 0 0.8em;// 0.6em 0 0.8em;
    &.studio {
        font-size: 18px;
        margin-bottom: 0;
    }
}

h5 {
    font-size: 17px; // 1.21em; // 17px
    line-height: 1.18em;
    margin: 0.6em 0 0.7em;// 0.6em 0 0.7em;
    &.studio {
        font-size: 26px;
        margin-bottom: 0;
        }
    &.gostovanje {
        line-height: 1.40em;
        }
}

h6 {
    font-size: 14px; // 1em; // 14px
    margin: 0.5em 0;// 0.5em 0 0.5em;
    .logo-text {
        height: 20px;
        margin-bottom: 0;
    }
}

.sup-title {
    & + h1,
    & + h2,
    & + h3,
    & + h4 {
        margin-top: 0;
    }
}

.sub-title {
    margin-top: -10px; //-0.75em
}

h6,
h5,
h4,
h3 {
    .rubik {
        font-size: 0.95em;
    }
}

.arial {
    font-family: Arial, Helvetica, "Liberation Sans", FreeSans, Sans-serif;
}

.rubik {
    &:extend(.arial);

    font-weight: 300;
    /* Dodano da se fonti naložijo takoj in stran ne skače */
    font-family: 'Rubik', 'Tahoma', Helvetica, sans-serif;
    .arial {
        font-size: 0.97em;
    }

}
.rubik400 {
    &:extend(.rubik);
    font-weight:400 !important;
}
.rubik500 {
    &:extend(.rubik);
    font-weight:500 !important;
}
.rubik600 {
    &:extend(.rubik);
    font-weight:600 !important;
}
.rubik700 {
    &:extend(.rubik);
    font-weight:700 !important;
}
.rubik800 {
    &:extend(.rubik);
    font-weight:800 !important;
}
.rubik900 {
    &:extend(.rubik);
    font-weight:900 !important;
}


.fonts-loaded {
    .rubik,
    .main-menu a {
        font-family: 'Rubik', 'Tahoma', Helvetica, sans-serif;
    }

    .rubik {
        .arial {
            font-size: 0.97em;
        }

        strong {
            font-family: Arial, Helvetica, "Liberation Sans", FreeSans, Sans-serif;
            font-size: 0.97em;
        }
    }
}

/* Animations */

/* icon spinner */
.spin {
    animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
    -moz-animation: spin 1s infinite linear;
    -o-animation: spin 1s infinite linear;
    display: inline-block;
    font-size: 4em;
    height: 1em;
    line-height: 1;
    margin: 0.5em;
}

.spin.test {
    animation: spin 1.5s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
    -webkit-animation: spin 1.5s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
    -moz-animation: spin 1.5s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
    -o-animation: spin 1.5s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.pulse {
    -webkit-animation: spin 1s infinite steps(8);
    animation: spin 1s infinite steps(8);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform: rotate3D(0deg);
        transform: rotate3D(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transform: rotate3D(360deg);
        transform: rotate3D(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform: rotate3D(0deg);
        transform: rotate3D(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transform: rotate3D(360deg);
        transform: rotate3D(360deg);
    }
}

.text-blue{
    color:@color-primary;
}
.text-white{
    color:@color-white;
}
