/* Hosting package page */

.hosting-priceboxes {
    border: 2px solid @color-grey;
    border-right-width: 1px;
    text-align: center;
    font-weight: bold;
    border-top-width: 1px;
    border-radius:@box-border-radius;

    .duration {
        min-height: 40px;
    }

    .price-box {
        border: solid @color-grey;
        border-width: 1px 1px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-width: 150px;
        padding-bottom: 15px;
        position: relative;
    }

    .prices {
        min-height: 50;
    }

    .old-price {
        font-size: 20px;
        margin: 5px 0;
    }

    .new-price {
        margin-top: 0;
        font-size: 25px;
    }
}

.hosting-performance {
    .package-title {
        background-color: #fff;
        background-size: 100% auto;
        color: @color-fake-black;
        left: 50%;
        margin-left: -87px;
        max-width: 175px;
        min-height: 80px;
        position: absolute;
        text-align: center;
        top: 5px;
        width: 100%;

         &.dedicated {
            margin-left: 135px;
            top: -5px;
        }

        &.white {
            background: url(../img/cms/box_white.svg) 0 0 no-repeat;
        }

        &.green {
            background: url(../img/cms/box_green.svg) 0 0 no-repeat;

            h3,h4 {
                color: @color-secondary;
            }
        }

        &.orange {
            background: url(../img/cms/box_orange.svg) 0 0 no-repeat;

            h3,h4 {
                color: @color-secondary-alt;
            }
        }

        &.blue {
            background: url(../img/cms/box_blue.svg) 0 0 no-repeat;

            h3,h4 {
                color: @color-primary;
            }
        }

        &.red {
            background: url(../img/cms/box_red.svg) 0 0 no-repeat;

            h3,h4 {
                color: @color-red-package;
            }
        }

        h3 {
            margin: 0;
            padding-top: 8px;
        }
    }
}

.hosting-table {
    position: relative;
    margin-bottom: 0;

    tr.first {
        td {
            padding-top: 53px;
            width: 50%
        }
    }

    tr.second {
        td {
            padding-top: 53px;
            /*width: 70% */
        }
    }

    td {
        &:nth-of-type(2n-1) {
            min-width: 165px;
        }
    }

    td {
        &:nth-of-type(2n) {
            text-align: center;
        }
    }

    tr {
        &:nth-of-type(2n) {
            td {
                background-color: #efefef;
            }
        }
    }

    .title {
        td {
            padding: 20px;
        }
    }
}

.vps-table{
    margin-top:-70px;
    .stevec-td{
        top: 30px;
        position: relative;
        z-index: 99;
    }
    .bg-white td{
        background-color: #fff !important;

        .package-title.dedicated{
            left:0;
            right: 0;
            margin-left: auto !important;
            margin-right: auto;
            top:auto;
            bottom: -40px;
        }
    }
    tr td{
        min-width: auto;
    }
    // tr td:first-child{
    //     min-width: 380px;
    // }
    tbody tr:last-child td{
        border-bottom:1px solid @color-grey;
        padding-bottom: 5px;
    }
    tfoot{
        td{border-bottom:1px solid @color-grey; font-size: 18px;}
        td:first-of-type{
            border-bottom: 0;
            border-left: 0;
            font-size: 14px;
        }

        td:nth-of-type(2n-1){
            min-width: 165px;
        }
    }
    tr td:nth-child(2){
        border-left:1px solid @color-grey;
        border-right:1px solid @color-grey;
    }
    tr td:last-child{
        display: none;
        @media(min-width:600px){
            display: table-cell;
        }
        @media(min-width:769px){
            display: none;
        }
        @media(min-width:870px){
            display: table-cell;
        }
    }

    tr td:first-child{
        display: none;
    }
    .responsive-table-vps{
        font-size: 80%;
        @media(min-width:@break-xlg-menu-value){
            display: none;
        }
    }
    &.mobile-only{
        margin-top: -40px;
        tr td:last-child{display: table-cell;}
        display: table;
        @media(min-width:600px){
            display: none;
        }
        @media(min-width:769px){
            display: table;
        }
        @media(min-width:870px){
            display: none;
        }

    }
    @media(min-width:@break-xlg-menu-value){
        tr td:first-child{
            display: table-cell;
        }
        .responsive-table-vps{
            display: none;
        }
    }

}


.checks-crosses {
    .icon-check-black-long,
    .icon-cross-black {
        vertical-align: sub;
        line-height: 0;
        font-weight: bold;
        font-size: 26px;
        color: @color-secondary;
    }

    .icon-cross-black {
        color: @color-primary;
    }
}

.features-small {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 195px;

    li {
        padding: 5px;
        border: 1px solid #ccc;
        border-bottom-width: 0;
        .clearfix();

        &:last-of-type {
            border-bottom-width: 1px;
        }
    }

    .sprite {
        float: left;
        margin: 5px 14px;
    }

    .title,
    .desc {
        display: block;
    }

    .text {
        float: left;
        margin-left: 5px;
    }

    .status {
        padding: 10px;
        float: right;
        display: block;
    }

    i.status {
        padding: 20px 10px;
    }
}

.extra-features {
    flex-direction: row;

    & > .grdcell {
        padding: 10px 15px 10px 20px;
    }

    img,
    .text {
        display: inline-block;
        margin-right: 15px;
        vertical-align: middle;
    }

    .status {
        float: right;
        padding: 18px 0;
    }

    .holder {
        display: block;
        margin: 0 auto;

        .c-dgry {
            vertical-align: top;
            margin: 10 0 5px;
        }
    }
}

.package-applications {
    .items {
        padding: 0 10 15px;
    }

    .weight-bold {
        display: block;
        margin-bottom: 2px;
    }

    .item {
        min-width: 120px;
        padding: 15px 15px 5px;
        text-align: center;
    }

    .sprite {
        margin-bottom: 5px;
    }

    .icon-check-black-long {
        margin-left: 1px;
    }

    small {
        white-space: nowrap;
    }
}
