
// dynamic break
.br-lg {
    display: none;
    line-height: 0;
}

@media screen and (min-width: @break-lg-value) {
    .br-lg {
        display: block;
    }
}

// Position
.p-r {
    position: relative;
}

.p-a {
    position: absolute;
}

// Top
.top-45 {
    top: -45px;
}

.top-65 {
    top: -65px;
}

// Bottom
.bottom-45 {
    bottom: -45px;
}

.bottom-65 {
    bottom: -65px;
}

.text-right {
    text-align: right !important;
}


.text-left {
    text-align: left !important;
}