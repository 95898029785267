/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.slides,
.slides > li,
.slider ul,
.selectric-items ul,
// used in cart
/* select - podoben selectricu */
.selectric-low,
.selectric-sm,
.clock-block:before,
.clock-val:before {
    outline: none;
}

.slides {
    .clearfix();
    //overflow: hidden;
    margin: 0;
    padding: 0;
}

/*======================================
  Selectric v1.9.3
======================================*/
.selectric-wrapper {
    position: relative;
    cursor: pointer;
}

.selectric-responsive {
    width: 100%;
}

.selectric {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
}

.selectric .label {
    display: block;
    overflow: hidden;
    height: 38px;
    margin: 0 38px 0 10px;
    font-size: 14px;
    color: @color-fake-black;
    line-height: 38px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.selectric .button {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    height: 38px;
    width: 38px;
    font: 0/0 a;
    color: #BBBBBB;
    text-align: center;
    *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0;
    width: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: @color-primary;
    border-bottom: none;
}

.selectric-hover .selectric {
    border-color: #C4C4C4;
}

.selectric-hover .selectric .button {
    color: #A2A2A2;
}

.selectric-hover .selectric .button:after {
    border-top-color: #A2A2A2;
}

.selectric-open {
    z-index: 9999;
}

.selectric-open .selectric {
    .box-shadow(0 0 0 1px @color-fake-black inset);
    z-index: 11;
    border-color: @color-fake-black;
    //border-bottom: transparent;
}

.selection .selectric-above .selectric-items {
    margin-bottom: -3px;
}

.checkout-cart .title + .selection .selectric-above .selectric-items {
    margin-bottom: -8px;
}

.selectric-open .selectric-items {
    display: block;
}

.selectric-disabled {
    cursor: default;
    opacity: 0.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    filter: alpha(opacity=50);
}

.selectric-hide-select {
    position: relative;
    overflow: hidden;
    height: 0;
    width: 0;
}

.selectric-hide-select select {
    position: absolute;
    display: none;
    left: -100%;
}

.selectric-input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0) !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    background: none !important;
    border: none !important;
    outline: none !important;
    *font: 0/0 a !important;
}

.selectric-temp-show {
    position: absolute !important;
    display: block !important;
    visibility: hidden !important;
}

/* Items box */
.selectric-items {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: -1;
    background: #FFFFFF;
    border: 2px solid @color-fake-black;
    border-top: 0;
    box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
    overflow: auto;
    height: 100%;
}

.selectric {
    //border: 2px solid @color-fake-black;
    //border-bottom: 0px;
}

.selectric-above {
    &.selectric-open {
        .selectric-items {
            top: auto !important;
            bottom: 100%;
            border-top: 1px solid @color-fake-black;
            border-bottom: 0;

            li {
                border-top: 0;
                border-bottom: 1px solid @color-grey;

                &.selected {
                    & + li {
                        border-color: @color-grey;
                    }
                }
            }
        }
        .selectric {
            border: 2px solid @color-fake-black;
            border-top: 0;
        }
    }
}

.selectric-items ul {
    padding-left: 0;
    margin: 0;
}
.selectric-items li {
    min-height: 22px;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    list-style: none;
}

.selectric-items li {
    display: block;
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    color: @color-fake-black;
    border-top: 1px solid @color-grey;

    &:first-of-type {
        border-top: 0;
    }
}

.selectric-items li.selected {
    font-weight: bold;

    & + li {
    }
    &:hover {
    }
}

.selectric-items li:hover {
    background: #FAFAFA;
    color: @color-fake-black;
}

.selectric-items .disabled {
    cursor: default !important;
    opacity: 0.5;
    background: none !important;
    color: @color-fake-black !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    filter: alpha(opacity=50);
}

.selectric-items .selectric-group .selectric-group-label {
    padding-left: 10px;
    cursor: default;
    background: #EFEFEF;
    font-weight: bold;
    color: @color-fake-black;
    border-top: 1px solid @color-grey;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectric-items .selectric-group.disabled li {
    opacity: 1;
    filter: alpha(opacity=100);
}

.selectric-items .selectric-group li {
    //padding-left: 11px;
}

.selectric-items {
    .additional {
        background: @color-lightest-grey;
        border-radius: 20px;
        font-size: 0.8em;
        padding: 0 5px;
        margin-left: 5px;
        align-items: center;
        justify-content: space-evenly;
    }

    .additional-block {
        display: block;
    }
}

// domene - cenik - premium
.selectric-full-width-select {
    position: inherit;

    .selectric-items {
        position: absolute;
        top: 47px;
        left: 0;
        z-index: 10;
        width: 100% !important;

        i[class^="icon-"] {
            position: relative;
            top: 5px;
            font-size: 26px;

            &.before {
                padding-right: 15px;
            }
            &.before {
                padding-left: 15px;
            }
        }
        .text {
            display: inline-block;
            padding: 5px 0 4px;
        }
    }

    li {
        border: 0 !important;
        display: inline-block;
        min-height: 42px;
        width: 25%;
        padding: 2px 10px 3px;
        color: @color-primary;

        &.last {
            background-color: @color-lighter-grey;
            min-height: 33px;
            padding-left: 25px;
            text-align: center;
            width: 100%;

            span {
                text-decoration: underline;
            }
        }
    }

    &.selectric-open {
        .selectric {
            .box-shadow(~'0 4px 0 -1px #fff, 1px 0px 0 0px #432b2b, -0px 0px 0 1px #432b2b');
            border-bottom: 0;
        }

        .selectric-items {
            border-top: 2px solid @color-fake-black;
        }

        li {
            border: 0;
            &.selected {
                & + li {
                    //border-color: @color-grey;
                    color: @color-fake-black;
                }
                &.last {
                    color: @color-fake-black;
                    border-color: @color-grey;
                }
            }
        }
    }

    &.selectric-above.selectric-open {
        .selectric {
            .box-shadow(~'0 -6px 0 -2px #fff, 2px -4px 0 -2px #432b2b, -2px -4px 0 -2px #432b2b');
            border-top: 0;
            border-bottom: 2px solid #432B2B;
        }
        .selectric-items {
            margin-bottom: -5px;
            border: 2px solid #432B2B;

            li {
            }
        }
    }
}

/* Pri odprtem selectu se prikaže drugačen text */
.selectric .label .hovered,
.selectric-open .label .normal {
    display: none;
}

.selectric-open .label .hovered {
    display: block;
}

/*.selectric-md {
   height: 0;
   min-width: 100px;
   padding: 0;

   opacity: 0;
   background: #fff;
}*/

.selectric-selectric-low {
    .selectric {
        background: #FFFFFF;
        border: 1px solid #888888;
    }

    .label {
        height: 20px;
        margin: 0 20px 0 10px;
        line-height: 22px;
    }

    .button {
        height: 20px;
        width: 26px;
        color: @color-darker-grey;
    }
}

// used in cart - ssl
.selectric-selectric-xs {
    width: 75px;
}

.selectric-weight-bold {
    .label {
        font-weight: bold;
    }
}

.selectric-selectric-sm {
    width: 100px;
    .selectric {
        width: 100px;
    }
}

.selectric-selectric-md {
    width: 140px;
    .selectric {
        width: 140px;
    }
}

.selectric-selectric-md2 {
    width: 130px;
    .selectric {
        width: 130px;
    }
}

.selectric-selectric-lg {
    width: 200px;
    .selectric {
        width: 200px;
    }
}
select.selectric-sm,
select.selectric-md,
select.selectric-md2,
select.selectric-lg {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
}
.selectric-selectric-xlg {
    max-width: 375px;
    width: 100%;

    .selectric {
        max-width: 375px;
    }
}

.selectric-selectric-fw {
    width: 100%;
    .selectric {
        width: 100%;
    }
}

/*----------  Flip-down clock  ----------*/
.clock {
    display: inline-block;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clock-block {
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: 50px;
    width: 30px;
    margin: 0;
    padding-top: 10px;
    background: #EBEBEB;
    background-image: -webkit-linear-gradient(top, #F4F4F4, #EBEBEB 20%, #D3D3D3 50%, #EBEBEB 80%, #E9E9E9);
    background-image: -moz-linear-gradient(top, #F4F4F4, #EBEBEB 20%, #D3D3D3 50%, #EBEBEB 80%, #E9E9E9);
    background-image: -o-linear-gradient(top, #F4F4F4, #EBEBEB 20%, #D3D3D3 50%, #EBEBEB 80%, #E9E9E9);
    background-image: linear-gradient(to bottom, #F4F4F4, #EBEBEB 20%, #D3D3D3 50%, #EBEBEB 80%, #E9E9E9);
    line-height: 1;
    text-align: center;
    text-shadow: 0 1px rgba(255, 255, 255, 0.3);
    border: 1px solid #9FA2AD;
    border-radius: 3px 3px 2px 2px;
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
}

.clock-block:after {
    content: "";
    position: absolute;
    right: -1px;
    bottom: -4px;
    left: -1px;
    z-index: -1;
    height: 4px;
    background: #CACFD9;
    border: 1px solid #9FA2AD;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.1);
}

.clock-block:before {
    bottom: -7px;
}

.clock-val {
    position: relative;
    display: block;
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
    color: #4F5258;
}

.clock-val:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
}

.clock-val:before {
    top: -16px;
    bottom: 50%;
    z-index: -1;
    border-radius: 2px 2px 0 0;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

.clock-val:after {
    top: 50%;
    bottom: -16px;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
    border-radius: 0 0 2px 2px;
    border-top: 1px solid rgba(60, 62, 68, 0.4);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

.clock-unit {
    display: block;
    margin-top: -2px;
    font-size: 13px;
    font-weight: 200;
    color: #6B707A;
    text-transform: capitalize;
}

.clock-unit:after {
    content: "";
    position: absolute;
    top: 100%;
    right: -1px;
    left: -1px;
    z-index: -2;
    height: 30px;
    margin-top: 6px;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
    border-radius: 0 0 3px 3px;
}

/* Magnigic popup - inline mod */
@media screen and (min-width: @break-lg-value) {
    .modal-container {
        position: relative;
        min-height: 500px;

        .mfp-bg {
            position: absolute;
        }
        .mfp-wrap {
            position: absolute;
        }
    }
}

@media screen and (max-width: @break-lg-value) {
    .modal-container {
        .mfp-ready {
            position: fixed !important;
        }
    }
}

.mfp-hide {
    display: none;
}

/* lightbox arrows */
.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
    border-left: 0;
}

.mfp-content {
    width: 100%;
}

.mfp-arrow {
    position: absolute;
    top: 50%;
    height: 60px;
    width: 70px;
    margin-top: -60px;
    padding: 0;
    background-color: rgba(255,255,255,0.3);
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    margin-top: 10px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
    margin-left: 30px;
    border-left: 17px solid #FFFFFF;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
    margin-left: 22px;
    border-right: 17px solid #FFFFFF;
}

/* optional zoomout effet */
.mfp-zoom-out {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(1.3);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {
        .mfp-with-anim {
            opacity: 0;
            transform: scale(1.3);
        }
        &.mfp-bg {
            opacity: 0;
        }
    }
}

/* PRISMJS*/
/* * http://prismjs.com/download.html?themes=prism&languages=css+clike+javascript+bash+css-extras+docker+git+json+php+php-extras+sql
*/
code[class*="language-"],
pre[class*="language-"] {
    color: black;
    background: none;
    text-shadow: 0 1px white;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
    text-shadow: none;
    background: #b3d4fc;
}

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #a67f59;
    background: hsla(0, 0%, 100%, .5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #07a;
}

.token.function {
    color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}
/* EOF PRISMJS */
