/* Website package page */

.websites-packages-table {
    position: relative;
    margin-bottom: 10;

    tr.first {
        td {
            padding-top: 53px;

        }
    }

    td {
        &:nth-of-type(2n-1) {
            width: 50%;
            background-color: #fff
        }
    }

    td {
        &:nth-of-type(2n) {
            text-align: center;
            width: 50%;
            padding: 17px;
           background-color: #efefef
        }
    }

    tr {
        &:nth-of-type(2n) {
            td {
                min-width: 100px;
             /*    background-color: #efefef;*/
            }
        }
    }

    .title {
        td {
            padding: 20px;
        }
    }
}

.features-small {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 195px;
    margin-top: 27px;

    li {
        padding: 5px;
        border: 1px solid #ccc;
        border-bottom-width: 0;
        .clearfix();

        &:last-of-type {
            border-bottom-width: 1px;
        }
    }

    .sprite {
        float: left;
        margin: 5px 14px;
    }

    .title,
    .desc {
        display: block;
        margin-top: 5px
    }

    .text {
        float: left;
        margin-left: 5px;
    }

    .status {
        padding: 10px;
        float: right;
        display: block;
    }

    i.status {
        padding: 20px 10px;
    }
}
