
/*============================================
=            PAGE HEADER - SEARCH            =
============================================*/

.page-header {
    .header-content {
        flex-grow: 1.5;
        margin-top: -20px;

        h1 {
            line-height: 1.5em;
        }
    }

    &.active-search {
        position: relative;
    }
}

.search-holder {
    display: inline-block;

    .icon {
        position: absolute;
        top: 7px;
        left: 8px;
        font-size: 26px;
        color: #1d9ad6;
        z-index: 1;
    }

    input {
        width: 100%;
        padding-left: 40px;
    }

    .active-only {
        display: none;
    }

    form {
        padding-bottom: 5px;
    }

    .input-group {
        margin-bottom: 0;
    }
}

.search-trigger {
    border: 2px solid #fff;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    margin: 20px 0 20px 0;

    .search-button {
    }

    .icon {
        font-size: 26px;
        z-index: 1;
    }

    .search-label {
        vertical-align: text-bottom;
        margin: 5px;
    }
}

.small-search {
    position: relative;
}

.search-box {
    &:not(.centered) {
        background: rgba(55, 55, 55, 0.9);
        display: none;
        height: 100%;
        left: 0;
        overflow: auto;
        position: fixed;
        top: 0;
        width: 100%;
        // 9999 je fixed-menu
        z-index: 10000;
    }

    /* Flexbox semi-support fix */
    .grdcell {
        min-width: auto;
    }

    .container {
        margin-top: 205px;
    }

    &.active {
        display: block;
    }

    &.centered {
        display: block;
        position: relative;
        max-width: 700px;
        margin: 10px auto 50px;


        .container {
            padding: 0;
            margin-top: 20px;
        }

        form {
            width: 100%;
            padding: 0;
            position: relative;
        }

        .search-field {
            margin: 0;
            width: 100%;
            border-radius: @box-border-radius;
            padding-right:110px;
        }

        .submit-button {
            position: absolute;
            right: 0;
            top: 0;
            margin-top: -2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .search-results {
            position: absolute;
            z-index: 10;
            width: 100%;
        }

        .search-results {
            text-align: left;
        }

        a {
            color: @color-primary;
        }
    }

    .search-form {
        align-items: baseline;
        z-index: 2;
        position: relative;
        padding: 40px 0;
        margin: 0;
        background: @color-primary;
        font-size: 30px;
        border-radius: @box-border-radius;
    }

    .search-field {
        .box-shadow(none);
        &:extend(.rubik all);

        border: 0;
        flex-grow: 8;
        font-size: 26px;
        height: 60px;

        margin: 0 0 0 40px;
        padding-top: 10px;
        width: 100%;
        border-radius: @box-border-radius;
    }

    .submit-button {
        height: 60px;
        max-width: 100px;
        margin-left:20px;
        .icon {
            font-size: 36px;
        }
    }

    .close-search-holder {
        text-align: right;
    }

    .close-search {
        color: #fff;
        display: inline-block;
        font-size: 46px;
        margin: -3px 20px 0;
        max-width: 48px;
        vertical-align: middle;
    }

    .results-title {
        padding: 10px 40px;
        background-color: @color-fake-black;
        color: #fff;

        .icon-close {
            float: right;
            margin-right: -20px;
            color: #fff;
            text-decoration: none;
            border: 0;
            font-size: 19px;
            font-weight: bold;
        }
    }

    .search-results {
        position: relative;
        z-index: 1;
        display: none;
        .box-shadow(0 0 8px rgba(0, 0, 0, 0.5));
    }

    .result {
        position: relative;
        padding-bottom: 0;
        border-bottom: 1px solid #ccc;
        background-color: #fff;
        z-index: 1;

        &:last-of-type {
            border: 0;
        }

        .post-title {
            text-decoration: underline;
        }

        &.focused {
            background-color: #e9e9e9;
        }

        &:hover {
            background-color: #f4f4f4;
        }
    }

    .result-link {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        min-height: 90px;
        padding: 0 40px;
    }

    .post-title {
        display: inline-block;
        font-size: @font-size-bigger;
    }

    .img-holder {
        &:extend(.post-img all);
    }

    &.results {
        .search-results {
            display: block;
        }
    }
}

.responsive .search-box {
    .search-field {
        font-size: @font-size-big;
        height: 40px;
        margin: 0 0 0 20px;
    }

    &.centered {
        .search-field {
            margin: 0;
        }
    }

    .submit-button {
        height: 40px;
        padding: 7px 12px;

        .icon {
            font-size: 26px;
            padding: 0;
        }
    }
}

.post-img {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border: 1px solid #1d9ad6;
}

/*=====  End of PAGE HEADER - SEARCH  ======*/

@media @break-sm {
    .responsive {
        .search-box:not(.centered) {
            & > .container {
                margin-top: 0;
                padding: 0;
            }
        }
    }

    .search-box {
        .img-holder {
            display: none;
        }
    }
}
