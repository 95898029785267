
.mypanel-visual {
    margin-bottom: 40px;

    padding: 20px 40px 30px;
    .clearfix();

    .check-list {
        min-width: 265px;
        margin-right: 30px;
    }

    img {
        margin-top: 75px;
        min-width: 200px;
    }

    .grd {
        margin-bottom: 0;
    }
}

.ccp-title {
    margin-bottom: -1.86rem;
}

.ccp-description {
    margin-top: 0;
}

.ccp-img {
    img {
        float: right;
    }
}

.ccp-description,
.ccp-img {
    min-width: 265px;
}
