
/* Checkout mobile */
@media (max-width: 1170px) {
    .checkout-content .tabs-holder {
        padding: 0;
        background: transparent;
    }

    .flat-tabs {

        a {
            width: 100%;
            height: 100%;
        }

        .mini-desc {
            display: none;
        }
    }

    .checkout-timeline {
        display: none;
    }
}

@media @break-lg {
    .checkout-content {
        .flat-tabs {
            margin-top: 20px;
            li {
            }
        }
    }
}


@media (max-width: @break-md-value) {
    .checkout-content,
    .checkout-side {
        flex: 100%;
    }

    .checkout-side {
        background: #fff;
    }

    .checkout-cart {
        .item {
            background: @color-cart-bg;
        }

        .item-error {
            background: @color-cart-error-bg;
        }

        .domain-input {
            /*button {
                position: relative;
                width: 100%;
            }*/
        }

        .cart-title {
            border-top: 1px dashed #ccc;
            font-size: 1.8em;
            margin-top: 30px;
            padding: 10px 0 14px;
            text-align: center;

            .icon-cart {
                font-size: 38px;
                bottom: -2px;
            }
        }
    }

    .cart-content {
        background: @color-cart-bg;
    }

    .checkout {
        border-width: 1px 0;
        margin-left: -20px;
        margin-right: -20px;
    }
}

@media (max-width: @break-sm-value) {
    .brand-features {
        margin-top: 45px;
        margin-bottom: 100px;

        .numbers {
            padding-top: 0;
        }

        .description {
            font-size: 1em;
        }
    }

    .checkout-content {
        padding-bottom: 20px;

        & .content {
            padding: 0 20px 5px;
        }
    }

    .payment-methods {
        .title {
            max-width: none;
        }

        .payment-holder {
            margin: 0 20px;
        }

        .title,
        .additional-data,
        .img-data,
        .data {
            flex: 100%;
            text-align: center;
        }

        .cc {
            .data {
                text-align: left;
            }
        }

        .data {
            a {
                display: block;
                float: none;
                margin: 0 auto;
            }
        }
    }

    /* Skrij kljukco */
    .final-step .indented,
    .final-step h3 {
        padding-left: 14px;
    }

    .check-mark {
        display: none;
    }

    /* Izbira podjetje / oseba */
    .tabbed-radio {
        .simple-tabs {
            width: 100%;
        }

        a {
            width: 100%;
            min-width: 190px;

            &:last-of-type {
                border: 1px solid @color-grey;
            }
        }
    }

    .lightbox {
        width: 95%;
        left: 2.5%;
        margin-left: 0%;
    }

    #upn {
        min-width: 100px;

        .client .transaction-title {
            width: auto;
        }

        .input {
            height: auto;
            min-height: 22px;
        }

        .company {
            .reference .p2 {
                width: auto;
            }

            .bic {
                width: auto;

                .input {
                    width: auto;
                }
            }

            .iban {
                min-width: 100px;

                .input {
                    width: auto;
                }
            }
        }
    }
}

@media (max-width: @break-xs-value) {
    .checkout-content {
        .collapsable .collapsable-content {
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
        }
        #ssl {

            .align-right {
                float: none;
            }
            .selectric-selectric-lg .selectric {
                width: auto;
            }
            .selectric-select-ssl {
                width: 60%;
                margin-right: 2%;
            }
            .selectric-select-duration {
                width: 33%;
                float: none;
            }
        }
    }
}

@media (max-width: 365px) {

    // Izbira durationa - certifikati 3. korak
    .collapsable-item {
        .selectric-category-duration {
            float: right;
        }
    }
}
