.package-boxes-holder {
    padding: 20px 20px 0;
}

.package-boxes {
    & > .grdcell {
        min-width: 270px;
    }

    & > .grdcell.wide {
        min-width: 320px;
    }

    .package-box {
        background: @color-lightest-grey;
        padding: 10px 20px 20px;
        position: relative;
        text-align: center;
    }

    .ribbon-diag {
        color: #fff;
        top: 0;
        right: 0;
        width: 125px;
        height: 85px;

        span {
            top: 12px;
            right: -33px;
        }
    }

    .package-title {
        margin-bottom: 10px;
    }

    .sub-title {
        display: block;
        margin-bottom: 10px;
    }

    .price-box {
        margin-top: 15px;
    }

    .selectric-wrapper {
        margin: auto;
    }

    .old-price {
        font-size: @font-size-bigger;
    }

    .new-price {
        font-size: @font-size-big;
    }

    .hosting-price {
        margin: 12px 0;
    }

    .btn-big {
        width: 100%;
    }

    .hosting-addon {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.counter {
            a {
                float: left;
                width: 50px;
                display: block;
                height: 30px;
                line-height: 30px;
                font-size: 25px;
                text-align: center;
                text-decoration: none;
            }

            input {
                float: left;
                width: 60px;
                height: 30px;
                margin: 0 12px;
                text-align: center;
            }
        }
    }
}
