
/* Domene - razlaga popustov */
.discounts-explained {

    .mdl-content {
        position: relative;
        padding-bottom: 70px;
    }

    .andy-td {
        width: 30%;
    }

    .andy-explains {
        position: absolute;
        top: 135px;
        right: 6%;
    }
}
