@media @break-sm {
    .security-notice {
        align-items: center;
        flex-direction: column;

        &::before {
            margin: 5px 0 10px;
        }
    }

    .input-fields {
        .input-box {
            max-width: 205px;
            margin: 0 auto;
        }
    }
}
