.prices {

    /* Icon simbolizing discount */
	.icon-discount-right {
		position: relative;
		bottom: -2px;
		margin: -3px 0;
		display: inline-block;
		font-size: @font-size-big;
	}
}
