/* Prikaz zadovoljnih uporabnikov - row */
.zadovoljni-u {
    margin-top: 60px;
}

.uporabniki {
    background-color: #ebebeb;
    position: relative;
    margin: 100px 0;

    .spr {
        display: inline-block;
        margin: 0px 0 -10px;
        position: relative;
    }

    .trusts-us {
        flex-grow: 0.9;
        flex-shrink: 1.5;
        min-width: 210px;
    }

    .uporabniki-left,
    .uporabniki-right {
        flex-grow: 1.1;
    }

    .uporabniki-right {
        margin-left: -11px;
        text-align: right;

        .spr {
            margin-left: 4%;
        }
    }

    .uporabniki-left {
        .spr {
            margin-right: 4%;
        }
    }

    .user-box {
        color: white;
        display: inline-block;
        padding: 5px 10px;
        position: absolute;
        white-space: nowrap;
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            clear: both;
        }

        &.right {
            right: 100%;
            margin-right: -90%;

            &::after {
                right: 5px;
            }
        }

        &.left {
            left: 100%;
            margin-left: -75%;

            &::after {
                left: 5px;
            }
        }
    }

    .top-cloud {
        top: -60px;

        &::after {
            border-top: 7px solid grey;
            bottom: -7px;
        }
    }

    .bottom-cloud {
        bottom: -45px;
        display: inline-block;
        padding: 3px 5px;
        z-index: 1;

        &::after {
            border-bottom: 7px solid grey;
            top: -7px;
        }
    }

    .orange {
        background: @color-secondary-alt;

        &::after {
            border-bottom-color: @color-secondary-alt;
            border-top-color: @color-secondary-alt;
        }
    }

    .green {
        background: @color-secondary;

        &::after {
            border-bottom-color: @color-secondary;
            border-top-color: @color-secondary;
        }
    }

    .black {
        background: @color-fake-black;

        &::after {
            border-bottom-color: @color-fake-black;
            border-top-color: @color-fake-black;
        }
    }

    .blue {
        background: @color-primary;

        &::after {
            border-bottom-color: @color-primary;
            border-top-color: @color-primary;
        }
    }

    .spr-gasper_parte {
        .user-box {
            top: -80px;
        }
    }

    .spr-andrej_kuralt {
        .user-box {
            bottom: -65px;
        }
    }



/*
    .top {
        background-color: @color-primary;
        width: 52px;
        left: 19px;

        &::after {
            left: 7px;
            top: -7px;
            border-bottom: 7px solid @color-primary;
        }
    }

    .brez {
        background-color: @color-secondary;
        width: 136px;
        left: 87px;

        &::after {
            left: 7px;
            top: -7px;
            border-bottom: 7px solid @color-secondary;
        }
    }

    .hvala {
        background-color: @color-domene-orange;
        width: 140px;
        left: 45px;

        &::after {
            right: 7px;
            bottom: -7px;
            border-top: 7px solid @color-domene-orange;
        }
    }

    .hitri {
        background-color: @color-dark;
        width: 142px;
        left: 221px;

        &::after {
            left: 7px;
            bottom: -7px;
            border-top: 7px solid @color-dark;
        }
    }

    .hitro {
        background-color: @color-domene-orange;
        width: 137px;
        left: 500px;

        span {
            margin-right: 8px;
        }

        &::after {
            right: 7px;
            top: -7px;
            border-bottom: 7px solid @color-domene-orange;
        }
    }

    .neverjetna {
        background-color: @color-primary;
        width: 155px;
        left: 545px;

        &::after {
            right: 7px;
            bottom: -7px;
            border-top: 7px solid @color-primary;
        }
    }

    .zakon {
        background-color: @color-secondary;
        width: 85px;
        left: 741px;

        &::after {
            left: 7px;
            bottom: -7px;
            border-top: 7px solid @color-secondary;
        }
    }

    .preprosto {
        background-color: @color-dark;
        width: 150px;
        left: 690px;

        &::after {
            right: 7px;
            top: -7px;
            border-bottom: 7px solid @color-dark;
        }
    }*/
}
