.grd {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.grdcell {
    height: auto;
    flex: 1 0 0%; //1;
    //min-height: 100%;
    width: 100%;

    /* Flexbox semi-support fix */
    min-width: 270px;
}

.grdcell-min {
    width: 250px;
}

.grd-msnry {
    height: 100vw;
    flex-direction: column;
    flex-wrap: wrap;
}

.grd-inl {
    display: inline-flex;
}

.grd-flex-cells > .grdcell {
    display: flex;
}

.grd-itm-tp {
    align-items: flex-start;
}

.grd-itm-bttm {
    align-items: flex-end;
}

.grd-itm-cntr {
    align-items: center;
}

.grd-itm-strch {
    align-items: stretch;
}

.grd-justify-center {
    justify-content: center;
}

.grdcell--top {
    align-self: flex-start;
}

.grdcell--bottom {
    align-self: flex-end;
}

.grdcell--center {
    align-self: center;
}

.grdcell--1of2 {
    flex: 0 0 50%;
}

.grd-fit > .grdcell {
    flex: 1 0 0%;
}

.grd-full > .grdcell {
    flex: 0 0 100%;
}

.grd-1of2 > .grdcell {
    flex: 0 0 50%;
}

.grd-1of3 > .grdcell {
    flex: 0 0 33.3333%;
}

.grd-1of4 > .grdcell {
    flex: 0 0 25%;
}

.grd-1of5 > .grdcell {
    flex: 0 0 20%;
}

.sm-grd-show {
    display: none;
}


@media @break-sm {
    .sm-grd-fit > .grdcell {
        flex: 1 0 0%;
    }

    .sm-grd-full > .grdcell,
    .sm-grdcell-full {
        flex: 0 0 100%;
    }

    .sm-grd-1of2 > .grdcell {
        flex: 0 0 50%;
    }

    .sm-grd-1of3 > .grdcell {
        flex: 0 0 33.3333%;
    }

    .sm-grd-1of4 > .grdcell {
        flex: 0 0 25%;
    }

    .sm-grd-1of5 > .grdcell {
        flex: 0 0 20%;
    }
}

@media @break-md {
    .md-grd-fit > .grdcell {
        flex: 1 0 0%;
    }

    .md-grd-full > .grdcell {
        flex: 0 0 100%;
    }

    .md-grd-1of2 > .grdcell {
        flex: 0 0 50%;
    }

    .md-grd-1of3 > .grdcell {
        flex: 0 0 33.3333%;
    }

    .md-grd-1of4 > .grdcell {
        flex: 0 0 25%;
    }

    .md-grd-1of5 > .grdcell {
        flex: 0 0 20%;
    }
}

@media @break-lg {
    .lg-grd-fit > .grdcell {
        flex: 1 0 0%;
    }

    .lg-grd-full > .grdcell {
        flex: 0 0 100%;
    }

    .lg-grd-1of2 > .grdcell {
        flex: 0 0 50%;
    }

    .lg-grd-1of3 > .grdcell {
        flex: 0 0 33.3333%;
    }

    .lg-grd-1of4 > .grdcell {
        flex: 0 0 25%;
    }

    .lg-grd-1of5 > .grdcell {
        flex: 0 0 20%;
    }
}

@media @break-xlg {
    .xlg-grd-fit > .grdcell {
        flex: 1 0 0%;
    }

    .xlg-grd-full > .grdcell {
        flex: 0 0 100%;
    }

    .xlg-grd-1of2 > .grdcell {
        flex: 0 0 50%;
    }

    .xlg-grd-1of3 > .grdcell {
        flex: 0 0 33.3333%;
    }

    .xlg-grd-1of4 > .grdcell {
        flex: 0 0 25%;
    }

    .xlg-grd-1of5 > .grdcell {
        flex: 0 0 20%;
    }
}

@media @break-min-xlg {
    .xxlg-grd-fit > .grdcell {
        flex: 1 0 0%;
    }

    .xxlg-grd-full > .grdcell {
        flex: 0 0 100%;
    }

    .xxlg-grd-1of2 > .grdcell {
        flex: 0 0 50%;
    }

    .xxlg-grd-1of3 > .grdcell {
        flex: 0 0 33.3333%;
    }

    .xxlg-grd-1of4 > .grdcell {
        flex: 0 0 25%;
    }

    .xxlg-grd-1of5 > .grdcell {
        flex: 0 0 20%;
    }
}

/* Grid gutters */

.grd-gttrs-sm {
    margin: -@gutters-small-size 0 @gutters-small-size -@gutters-small-size;
}

.grd-gttrs-sm > .grdcell {
    padding: @gutters-small-size 0 0 @gutters-small-size;
}

.grd-gttrs {
    margin: -@gutters-size 0 @gutters-size -@gutters-size;
}

.grd-gttrs > .grdcell {
    padding: @gutters-size 0 0 @gutters-size;
}

.grd-gttrs-lg {
    margin: -@gutters-large-size 0 @gutters-large-size -@gutters-large-size;
}

.grd-gttrs-lg > .grdcell {
    padding: @gutters-large-size 0 0 @gutters-large-size;
}

.grd-gttrs-xl {
    margin: -@gutters-extra-large-size 0 @gutters-extra-large-size -@gutters-extra-large-size;
}

.grd-gttrs-xl > .grdcell {
    padding: @gutters-extra-large-size 0 0 @gutters-extra-large-size;
}



@media @break-sm {
    .sm-grd-gttrs-off {
        margin: 0;
    }

    .sm-grd-gttrs-off > .grdcell {
        padding: 0;
    }

    .sm-grd-gttrs {
        margin: -@gutters-size 0 @gutters-size -@gutters-size;
    }

    .sm-grd-gttrs > .grdcell {
        padding: @gutters-size 0 0 @gutters-size;
    }

    .sm-grd-gttrs-sm {
        margin: -@gutters-small-size 0 @gutters-small-size -@gutters-small-size;
    }

    .sm-grd-gttrs-sm > .grdcell {
        padding: @gutters-small-size 0 0 @gutters-small-size;
    }

    .sm-grd-gttrs-lg {
        margin: -@gutters-large-size 0 @gutters-large-size -@gutters-large-size;
    }

    .sm-grd-gttrs-lg > .grdcell {
        padding: @gutters-large-size 0 0 @gutters-large-size;
    }

    .sm-grd-gttrs-xl {
        margin: -@gutters-extra-large-size 0 @gutters-extra-large-size -@gutters-extra-large-size;
    }

    .sm-grd-gttrs-xl > .grdcell {
        padding: @gutters-extra-large-size 0 0 @gutters-extra-large-size;
    }

}

@media @break-md {
    .md-grd-gttrs {
        margin: -@gutters-size 0 @gutters-size -@gutters-size;
    }

    .md-grd-gttrs > .grdcell {
        padding: @gutters-size 0 0 @gutters-size;
    }

    .md-grd-gttrs-lg {
        margin: -@gutters-large-size 0 @gutters-large-size -@gutters-large-size;
    }

    .md-grd-gttrs-lg > .grdcell {
        padding: @gutters-large-size 0 0 @gutters-large-size;
    }

    .md-grd-gttrs-xl {
        margin: -@gutters-extra-large-size 0 @gutters-extra-large-size -@gutters-extra-large-size;
    }

    .md-grd-gttrs-xl > .grdcell {
        padding: @gutters-extra-large-size 0 0 @gutters-extra-large-size;
    }
}

@media @break-lg {
    .lg-grd-gttrs {
        margin: -@gutters-size 0 @gutters-size -@gutters-size;
    }

    .lg-grd-gttrs > .grdcell {
        padding: @gutters-size 0 0 @gutters-size;
    }

    .lg-grd-gttrs-lg {
        margin: -@gutters-large-size 0 @gutters-large-size -@gutters-large-size;
    }

    .lg-grd-gttrs-lg > .grdcell {
        padding: @gutters-large-size 0 0 @gutters-large-size;
    }

    .lg-grd-gttrs-xl {
        margin: -@gutters-extra-large-size 0 @gutters-extra-large-size -@gutters-extra-large-size;
    }

    .lg-grd-gttrs-xl > .grdcell {
        padding: @gutters-extra-large-size 0 0 @gutters-extra-large-size;
    }
}



/* End */

.p-relative{
    position: relative;
}
.p-absolute{
    position: absolute;
}

.justify-content-space-evenly{
    justify-content: space-evenly;
}
.justify-content-space-between{
    justify-content: space-between;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.align-self-flex-end{
    align-self: flex-end;
}

.h-100{
    height: 100%;
}


@media(max-width: @break-sm-value){
    .hidden-xs{
        display: none;
    }

}

@media(min-width: @break-sm-value){
    .hidden-xs{
        display: block;
    }
    .hidden-sm{
        display: none;

    }
}

@media(min-width:@break-md-value){
    .hidden-xs, .hidden-sm{
        display: block;
    }
    .hidden-md{
        display: none;
    }
}
@media(min-width: @break-lg-value){
    .hidden-xs, .hidden-sm, .hidden-md{
        display: block;
    }
    .hidden-lg{
        display: none;
    }
}
@media(min-width:@break-xlg-value){
    .hidden-xs, .hidden-sm .hidden-lg{
        display: block;
    }
    .hidden-xlg{
        display: none;
    }
}
.grid30{
    width: 100%;
    flex: 1 0 0;
}
@media (min-width:1121px){
    .grid30{
        width: 30%;
        flex: 0 0 0;
    }
}
