.kv-list {
    display: inline-block;
    padding: 40px 0 30px 20px;
    position: relative;
    width: 40%;
    z-index: 1;

    li {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-left: 75px;
        vertical-align: top;
        padding-bottom: 10px;
        margin-bottom: 25px;
        margin-left: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        small {
            margin-bottom: -5px;
            display: inline-block;
        }
    }

    .pre-icon {
        position: absolute;
        left: 5px;
        font-size: 36px;
        margin-top: -4px;
    }
}

.kv {
    .clearfix();

    background-color: #ebebeb;

    .kv-content {
        padding: 30px 30px 45px;
        text-align: center;
    }

    .icon-check-black-long {
        .border-radius(50px);

        display: inline-block;
        padding: 5px;
        color: @color-secondary;
        background: #fff;
        font-size: 32px;
    }

    .kv-carrousel {
        margin-top: -15px;
        text-align: center;
        position: relative;
    }

    .ssl-img {
        margin-left: -30px;
    }

    .description {
        float: left;
        padding: 20px;
        font-size: @font-size-bigger;
        line-height: @font-size-biggest;
    }

    .ssl-kv-img {
        bottom: 0;
        position: absolute;
        right: 0;
    }

    .pre-icon {
        background-color: #fff;
        border-radius: 35px;
        height: 50px;
        padding: 6px 8px;
        width: 50px;
    }

    .icon-lock {
        font-size: 28px;
        padding: 11px;
    }

    .icon-speed {
        padding: 6px 7px;
    }

    .icon-euro {
        padding: 12px 10px;
        font-size: 26px;
    }

    .icon-euro {
        padding: 12px 10px;
        font-size: 26px;
    }

    .icon-organization {
        font-size: 32px;
        padding: 8px 10px;
    }

    .ssl-info {
        &:extend(.clearfix all);

        position: relative;
    }

    &.orange {
        .description {
            background-color: @color-secondary-alt;
            color: #fff;
        }

        .kv-list {
            strong {
                color: @color-secondary-alt;
            }

            .pre-icon {
                color: @color-secondary-alt;
            }
        }

        .ssl-info {
            background: url('../img/cms/kv/ssl/domenski-ssl-certifikati-oseba.png') no-repeat 62% 27%;
        }
    }

    &.blue {
        .description {
            background-color: @color-primary;
            color: #fff;
        }

        .kv-list {
            width: 70%;
            float: right;

            strong {
                color: @color-primary;
            }

            .pre-icon {
                color: @color-primary;
            }
        }

        .ssl-info {
            background: url('../img/cms/kv/ssl/organizacjiski-ssl-certifikat-people.png') no-repeat 0 100%;
        }
    }

    &.green {
        .description {
            background-color: @color-secondary;
            color: #fff;
        }

        .kv-list {
            width: 70%;
            float: right;

            strong {
                color: @color-secondary;
            }

            .pre-icon {
                color: @color-secondary;
            }
        }

        .ssl-info {
            background: url('../img/cms/kv/ssl/razsirjeni-ssl-certifikati-ceo.png') no-repeat 0 100%;
        }
    }

    &.ssl {
        .check-holder {
            position: relative;
            padding: 0 10px 0 32px;

            &:last-of-type {
                padding-right: 0;
            }
        }

        .icon-check-black-long {
            position: absolute;
            left: 0;
            top: -8px;
            padding: 0;
            color: inherit;
            background: transparent;
        }
    }
}

.carrousel-title {
    position: absolute;
    top: -33px;
    left: 50%;
    display: block;
    padding: 10px 10px 0;
    margin-left: -80px;
    width: 160px;
    font-weight: bold;
    border: 2px solid #fff;
    border-bottom: 0;
    z-index: 1;
}

.fact-slider {
    border-top: 2px solid #fff;
    min-height: 56px;
    overflow: hidden;
    padding: 0 35px;

    .slick-next,
    .slick-arrow {
        &::before {
            color: #fff;
        }
    }
}

.fact-list {
    li {
        // Postane viden, ko je slider vzpostavljen
        display: none;
        font-size: 21px;
        padding: 19px 0 16px;
    }
    .slick-track {
        height:auto;
    }
}


.ssl {
    .icon {
        i {
            font-size: 52px;
            margin-top: -10px;
            display: inline-block;
        }

        &.icons {
            i {
                margin-top: 0;
                font-size: 38px;

                &:first-of-type {
                    margin-right: -2px;
                }

                &:last-of-type {
                    margin-left: -2px;
                }
            }
        }
    }
}

.icon-download-arrow {
    font-size: 32px;
}

/*================================================
=            Posamezen SSL certifikat            =
================================================*/
.ssl-certificate {
    flex-direction: row-reverse;
    flex-wrap: wrap;

    .image-holder {
        & > li {
            min-width: 40px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        img {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            display: block;
        }
    }

    .text-content {
        min-width: 260px;
        text-align: justify;

        .align-right {
            padding: 0 0 0 15px;
            max-width: 170px;
        }
    }

    .std-list {
        margin-bottom: 10px;
    }

    .main-content {
        flex-grow: 2.4;
    }
}

@media (max-width: @break-md-value) {
    .kv.ssl .ssl-info {
        background: none;
    }

    .kv.blue .kv-list {
        width: 100%;
    }

    .kv-list {
        padding: 40px 30px 30px 20px;
    }
}

@media @break-sm {
    .ssl-certificate {
        & > .grdcell {
            flex: 0 0 100%;
        }

    }
}
