.boxes {
    border-top: 5px solid #fff;

    .box {
        position: relative;
        float: left;
        padding: 40px 25px  25px;
        //width: 33.33%;
        min-height: 150px;
        border-right: 5px solid #fff;
        text-align: center;

        img {
            margin: 5px 0 10px;
            max-height: 75px;
        }

        &::before,
        &::after {
            position: absolute;
            // left: 100%;
            top: 50%;
            z-index: 2;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            -moz-transform: scale(0.9999);
        }

        &::before {
            right: -46px;
            border-color: fadeout(#fff, 100%);
            border-left-color: #fff;
            border-width: 23px;
            margin-top: -23px;
        }

        &::after {
            right: -35px;
            border-color: fadeout(@color-primary, 100%);
            border-left-color: @color-primary;
            border-width: 19px;
            margin-top: -19px;
        }

        &.bg-secondary-alt {
            &::after {
                border-color: fadeout(@color-secondary-alt, 100%);
                border-left-color: @color-secondary-alt;
            }
        }

        &:last-of-type {
            border-right: 0;

            &::before,
            &::after {
                display: none;
            }
        }
    }

    .number {
        &:extend(.circle);
        &:extend(.circle.huge);

        position: absolute;
        left: 50%;
        margin-left: -36px;
        top: -37px;
        font-size: 32px;
        background: @color-fake-black;
        border: 5px solid #fff;
    }
}

.affiliate-box {
    .box {
        &.grdcell {
            /* Flexbox semi-support fix */
            min-width: 235px;
        }
    }

    .header {
        background: url(../img/cms/why-us/table-tile.jpg) repeat-x 0 100%;

        h2 {
            display: inline-block;
            margin: 20px 0 11% 35px;
        }

        img {
            margin: 40px 40px 0 -120px;
        }
    }
}

.whyus-affiliate {
    .icon-holder {
        bottom: 10px;
    }
}

@media @break-sm {
    .boxes {
        .box {
            border-right: 0;
            border-bottom: 5px solid #fff;

            &::before,
            &::after {
                display: none;
            }
        }
    }
}

@media @break-lg {
    .boxes {
        .box {
            border-right: 0;
            border-bottom: 5px solid #fff;

            &::before,
            &::after {
                display: none;
            }
        }
    }
}
