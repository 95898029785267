
.main-video {
    margin-bottom: 40px;

    iframe {
        height: 100%;
    }
}

.chat {
    margin-bottom: 20px;
    min-width: 265px;

    .title {
        .rubik {
            white-space: nowrap;
        }
    }

    .dialog {
        .clearfix();

        padding: 10px 0;
        border-bottom: 1px solid @color-grey;

        &:first-of-type {
            border-top: 1px solid @color-grey;
        }
    }

    .user-icon {
        position: relative;
        width: 57px;
        height: 57px;
        border: 1px solid @color-grey;
        vertical-align: bottom;
        text-align: center;

        &::after,
        &::before {
            top: 50%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &::after {
            border-color: rgba(255, 255, 255, 0);
            border-width: 9px;
            margin-top: -9px;
        }

        &::before {
            border-color: rgba(204, 204, 204, 0);
            border-width: 10px;
            margin-top: -10px;
        }

        .user-img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .right-side,
    .left-side {
        position: relative;
        width: 100%;
        float: right;
    }

    .left-side {
        .user-icon {
            float: left;

            &::after {
                right: -17px;
                border-left-color: #fff;
            }

            &::before {
                right: -20px;
                border-left-color: @color-grey;
            }
        }

        .text {
            padding-left: 80px;
        }
    }

    .right-side {
        .user-icon {
            float: right;

            .user-img {
                left: 11px;
                bottom: 11px;
            }

            &::after {
                left: -17px;
                border-right-color: #fff;
            }

            &::before {
                left: -20px;
                border-right-color: @color-grey;
            }
        }

        .text {
            text-align: right;
            padding-right: 80px;
            color: @color-primary;
        }
    }

    .text {
        margin-top: 23px;
        width: 100%;
    }
}

.on-top {
    position: absolute;
    top: -1em;
}
