@media @break-sm {
    .domain-promo-slider {
        padding: 0 15px;
    }
}

@media (max-width: 390px) {
    .domain-promo-box {
        /*min-width: 232px; */

        .prices {
            margin: 10px 16px 10px;
        }
    }

    .mini-promo-box {
        text-align: center;
    }
}

@media (max-width: 1088px)
{
    .sm-mb20
    {
        margin-bottom: 20px;
    }
}
