.item-showoff {
    border: 1px solid @color-light-grey;
    min-width: 250px;
}

.item-banner {
    padding: 20px 20px 10px;
    text-align: center;

    .item-category {
        display: block;
        text-transform: uppercase;
    }

    .item-header {
        margin-top: 0;
    }

    & > img {
        margin-bottom: 10px;
    }
}

.item-details {
    padding: 20px;
    background-color: @color-lightest-grey;

    & > .selectric-wrapper {
        margin-bottom: 20px;
    }

    .item-pricing {
        overflow: hidden;
        margin-bottom: 8px;
        background-color: inherit;

        .title {
            float: left;
            background-color: inherit;
            position: relative;
            z-index: 2;
            padding-right: 5px;

        }

        .value,
        .prices {
            float: right;
        }

        .prices {
            background-color: inherit;
        }

        .value,
        .old-price {
            background-color: inherit;
            position: relative;
            z-index: 2;
            padding-left: 5px;
        }

        .dotted {
            border-bottom: 1px dotted;
            position: relative;
            bottom: -1em;
        }
    }
}
