/* Shadows */
.inset-shadow {
    .box-shadow(~"0 5px 7px -5px #333 inset, 0 -5px 7px -5px #333 inset");
}

.basic-shadow {
    .box-shadow(~"0 0 10px 3px rgba(0,0,0,0.5)");
}

/* Element box type */
.ib,
.d-ib {
    display: inline-block;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-inline-block-important {
    display: inline-block !important;
}

.d-block-important {
    display: block !important;
}
@media(min-width: @break-sm-value){
    .d-sm-flex{
        display: flex;
    }
}

.clear-b {
    clear: both;
}

/* Clearfix */
.clearfix::before,
.clearfix::after {
    content: " ";
    display: table;
}

.clearfix::after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* Vertical align */
.va-top {
    vertical-align: top;
}

.va-middle {
    vertical-align: middle;
}

.va-bottom {
    vertical-align: bottom;
}

.va-baseline {
    vertical-align: baseline;
}

/* Alignment */
.align-center {
    .center-block();
}

.align-left {
    float: left;

    img& {
        padding-right: 20px;
        padding-bottom: 10px;
    }
}

.align-right {
    float: right;

    img& {
        padding-left: 20px;
        padding-bottom: 10px;
    }
}

/* Visibility */
.hidden,
.selectric-hidden,
.show-on-load {
    display: none !important;
    visibility: hidden;
}

.invisible {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.u-overflow-hidden {
    overflow: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.disable-click {
    pointer-events: none;

    .inside {
        border: 3px solid @color-grey;
        background-color: @color-grey !important;
        &:after, &:before {
            border-left-color: @color-grey !important;
        }
    }
}

.tag-primary-70 {
    .tag-mixin(70px, 20px, 11px, @color-primary-alt);
}

.tag-primary-standout-70 {
    .tag-mixin(70px, 20px, 11px, @color-primary-alt, @color-cart-bg);
}

.domain-tag-premium {
    top: -4px;
    margin-left: 15px;

    span {
        margin-left: 8px;
    }
}

/* Sizing - width/height */
.w200px {
    width: 200px;
}

.w250px {
    width: 250px;
}

.w100 {
    width: 100%;
}

.w70 {
    width: 70%;
}

.w60 {
    width: 60%;
}

.w50 {
    width: 50%;
}

.w40 {
    width: 40%;
}

.w30 {
    width: 30%;
}

.w29 {
    width: 29%;
}

.h30 {
    height: 30px;
}

.h40 {
    height: 40px;
}

.h50 {
    height: 50px;
}
