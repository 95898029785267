/* circles / badges */
.circle {
	display: inline-block;
	padding: 2px 5px;
	margin-top: -3px;
	min-width: 25px;
	min-height: 25px;
	text-indent: -1px;
	border-radius: 25px;
	text-align: center;
	color: #fff;
	&.big {
		padding: 8px 5px;
		height: 35px;
		width: 35px;
		text-indent: 0;
	}
	&.huge {
		padding: 23px 15px;
		height: 75px;
		width: 75px;
		margin-left: -33px;
		border-radius: 60px;
		font-weight: bold;
		text-indent: 0;
	}
}
