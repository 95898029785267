/*==================================
=         NS domain icons         =
==================================*/
@font-face {
	font-family: 'ns-domains';
	src:url('../fonts/ns-domains.eot?5mb5sy');
	src:url('../fonts/ns-domains.eot?5mb5sy#iefix') format('embedded-opentype'),
		url('../fonts/ns-domains.ttf?5mb5sy') format('truetype'),
		url('../fonts/ns-domains.woff?5mb5sy') format('woff'),
		url('../fonts/ns-domains.svg?5mb5sy#ns-domains') format('svg');
	font-weight: normal;
	font-style: normal;
}


/*----------  Icon definitions  ----------*/
.iconDomainsFamily () {
	&:extend(.domains-family-icon);
}
.domains-family-icon {
	font-family: 'ns-domains';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	text-decoration: none;
}
[class^="icon-domains-"], [class*=" icon-domains-"] {
	.iconDomainsFamily();
}


.icon-domains-kids:before {
	content: "\e800";
}
.icon-domains-auto-moto:before {
	content: "\e801";
}
.icon-domains-technology:before {
	content: "\e802";
}
.icon-domains-education:before {
	content: "\e803";
}
.icon-domains-fashion:before {
	content: "\e804";
}
.icon-domains-business-finance:before {
	content: "\e805";
}
.icon-domains-food-drink:before {
	content: "\e806";
}
.icon-domains-general:before {
	content: "\e807";
}
.icon-domains-adults:before {
	content: "\e808";
}
.icon-domains-health:before {
	content: "\e809";
}
.icon-domains-job:before {
	content: "\e80a";
}
.icon-domains-law:before {
	content: "\e80b";
}
.icon-domains-lifestyle:before {
	content: "\e80c";
}
.icon-domains-love:before {
	content: "\e80d";
}
.icon-domains-marketing:before {
	content: "\e80e";
}
.icon-domains-fun:before {
	content: "\e80f";
}
.icon-domains-real-estate:before {
	content: "\e810";
}
.icon-domains-religion:before {
	content: "\e811";
}
.icon-domains-sport:before {
	content: "\e812";
}
.icon-domains-travelling:before {
	content: "\e813";
}

