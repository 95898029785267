
.guarantee-visual {
    position: relative;
    min-height: 320px;
    margin-bottom: 70px;
    margin-top: -40px;

    .bg-img {
        position: absolute;
        bottom: -51px;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        margin: 0 auto;
    }

    .badge {
        position: absolute;
        bottom: -65px;
        left: 50%;
        margin-left: -150px;
        display:none;

    }
}
@media (max-width: @break-max-value) {
    .content .guarantee-visual {
        background: none;
    }
}

@media (max-width: @break-lg-value) {
    .guarantee-visual {
        .bg-img {
            width: 70%;
        }
    }
}

@media (max-width: @break-md-value) {
    .guarantee-visual {
        display: none;
    }
    .badge {
        display:block;
    }
}
