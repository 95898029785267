/*=====================================
=            Button combos	          =
=====================================*/
.input-button {
    position: relative;
    display: inline-block;
    width: 100%;

    input {
        width: 100%;
        padding: 11px 10px;
        border: 0;
        height: 40px;

        &.f-big {
            width: 100%;
        }
    }

    .button-holder {
        position: absolute;
        right: 0;
        top: 0;

        button {
            height: 40px;
            padding: 10px 15px 9px;
            border-radius: 0;
            border-top-right-radius: @box-border-radius;
            border-bottom-right-radius: @box-border-radius;
        }
    }
}

/*-----  End of Buttons combo  ------*/

.info-button {
    position: relative;
    top: 1px;
}

// Align button right
.btn-right {
    float: right;
    margin-top: 30px;
}

/*===============================
=            Buttons            =
===============================*/
a[class^="btn-"],
[class^="btn-"] {
    position: relative;
    display: inline-block;
    min-height: 40px;
    padding: 10px 12px; /* 10px-FF vs 9px-Chrome */
    border: 0;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    // hover effect <
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;

    border-radius: @button-border-radius;
    word-wrap: break-word;

    &.btn-icon {
        padding-left: 35px;

        i {
            position: absolute;
            left: 10px;
            font-size: 20px;

            &.icon-cart {
                position: absolute;
                left: 7px;
                top: 0.24em;
                font-size: 26px;
            }
        }
    }

    &.btn-big-icon {
        padding-left: 55px;

        .icon-cart {
            position: absolute;
            left: 12px;
            top: 3px;
            font-size: 32px;
        }
    }

    &:hover,
    &:active,
    &:focus {
        -webkit-transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        -ms-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
    }
}

// checkout buttons
a.button-back:hover > .inside,
a.button-back .inside:hover::before,
a.button-back .inside:hover::after,
a.button-next:hover > .inside,
a.button-next > .inside::before:hover,
a.button-next .inside:hover::after {
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
}

.disabled,
a.disabled {
    background-color: @color-grey;

    &.before {
        display: none;
    }
}

.btn-green,
a.btn-green {
    color: white;
    background-color: @color-secondary;

    &:hover {
        background: darken(@color-secondary, 4%);
    }
}

.btn-blue,
a.btn-blue {
    color: white;
    background-color: @color-primary;

    &:hover {
        background: darken(@color-primary, 6%);
    }
}

.btn-dark-blue,
a.btn-dark-blue {
    color: white;
    background-color: @color-blue-dark;

    &:hover {
        background: darken(@color-blue-dark, 6%);
    }
}

.btn-alt-blue,
a.btn-alt-blue {
    color: white;
    background-color: @color-primary-alt;

    &:hover {
        background: darken(@color-primary-alt, 6%);
    }
}

.btn-blue-black,
a.btn-blue-black {
    color: white;
    background-color: @color-blue-black;

    &:hover {
        background: darken(@color-blue-black, 6%);
    }
}

.btn-black,
a.btn-black {
    color: white;
    background-color: @color-fake-black;

    &:hover {
        color: @color-secondary-alt;
    }
}

.btn-red,
a.btn-red {
    color: white;
    background-color: @color-red-package;

    &:hover {
        background: darken(@color-red-package, 6%);
    }
}

.btn-orange,
a.btn-orange {
    color: white;
    background-color: @color-secondary-alt;

    &:hover {
        background: darken(@color-secondary-alt, 6%);
    }
}

.btn-cpanel,
a.btn-cpanel {
    color: white;
    background-color: @color-brand-cpanel;

    &:hover {
        background: darken(@color-brand-cpanel, 6%);
    }
}

.btn-grey,
a.btn-grey {
    /*color: @color-fake-black;*/
    color: @color-dark-grey;
    background-color: @color-grey;

    &:hover {
        background: darken(@color-grey, 4%);
    }

    &.c-dgrey {
        color: @color-dark-grey;
    }
}

.btn-dgrey,
a.btn-dgrey {
    color: #fff;
    background-color: @color-darker-grey;

    &:hover {
        background: darken(@color-darker-grey, 4%);
    }
}

.btn-d1grey,
a.btn-d1grey {
    color: #fff;
    background-color: @color-dark-grey;

    &:hover {
        background: darken(@color-dark-grey, 4%);
    }
}

.btn-bgrey,
a.btn-bgrey {
    color: @color-fake-black;
    background-color: @color-bluish-grey;

    &:hover {
        background: darken(@color-bluish-grey, 4%);
    }
}


.btn-white,
a.btn-white {
    color: inherit;
    background-color: #fff;

    &:hover,
    &:hover {
        background: darken(#fff, 5%);
        background: @color-bluish-grey;
    }

    &.c-secondary-alt {
        &:hover {
            color: #fff;
            background-color: @color-fake-black;
        }
    }
}

.btn-primary-white,
a.btn-primary-white {
    color: @color-primary;
    background-color: #fff;

    &:hover {
        background: darken(#fff, 2%);
        background: rgba(255, 255, 255, 0.8);
    }
}

/* SIZE */
.btn-tiny,
a.btn-tiny {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 25px;
    font-weight: normal;
}
.btn-small,
a.btn-small {
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 30px;
}

.btn-big,
a.btn-big {
    padding: 11px 15px 9px;
    font-size: @font-size-bigger;
    text-transform: uppercase;
}

.btn-bigger,
a.btn-bigger {
    &:extend(.btn-big);
    &:extend(a.btn-big);

    padding: 15px 15px 13px;
}

.btn-narrow,
a.btn-narrow {
    padding: 10px 5px;
}

.btn-deli,
a.btn-deli {
    padding: 10px 15px;
    font-weight: normal;
}

.btn-no-deco,
a.btn-no-deco {
    text-decoration: none;
}

.btn-no-trans,
a.btn-no-trans {
    text-transform: none;
}

.btn-extra,
a.btn-extra {
    padding: 15px 20px;
}

.btn-wide,
a.btn-wide {
    padding-left: 25px;
    padding-right: 25px;

    &.btn-small {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.btn-extra-wide,
a.btn-extra-wide {
    padding-left: 35px;
    padding-right: 35px;

    &.btn-small {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.btn-fw {
    width: 100%;
    text-align: center;
}

.btn-rnd {
    border-radius: @button-border-radius;
}

.btn-desc,
a.btn-desc {
    padding-left: 70px;
    text-align: left;

    i {
        position: absolute;
        left: 10px;
        top: 3px;
        font-size: 3.7em;
    }

    strong,
    small {
        display: block;
    }

    span {
        font-weight: normal;
        color: @color-grey;
    }

    &:hover,
    &:hover {
        i {
            color: #fff;
        }
    }
}

/* STYLE */
.btn-brd,
a.btn-brd {
    .box-shadow(0 0 0 0.16em #fff inset);

    color: #fff;
    background-color: transparent;
}

a.btn-brd,
.btn-brd {
    .box-shadow(0 0 0 2px #fff inset);

    color: #fff;
    background-color: transparent;

    &:hover,
    &:focus {
        color: @color-fake-black;

        &::after {
            color: @color-fake-black;
        }
    }

    &:hover {
        background-color: #fff;
    }

    &.after {
        color: @color-fake-black;
    }

    &.btn-blue {
        color: @color-primary;
        border-color: @color-primary;
        background-color: #fff;
        .box-shadow(0 0 0 2px @color-primary inset);

        &:hover {
            color: #fff;
            background-color: @color-primary;
        }
    }

    &.btn-red {
        color: @color-red-package;
        border-color: @color-red-package;
        background-color: #fff;
        .box-shadow(0 0 0 2px @color-red-package inset);

        &:hover {
            color: #fff;
            background-color: @color-red-package;
        }
    }

    &.btn-green {
        color: @color-secondary;
        border-color: @color-secondary;
        background-color: #fff;
        .box-shadow(0 0 0 2px @color-secondary inset);

        &:hover {
            color: #fff;
            background-color: @color-secondary;
        }
    }

    &.btn-orange {
        color: @color-secondary-alt;
        border-color: @color-secondary-alt;
        background-color: #fff;
        .box-shadow(0 0 0 2px @color-secondary-alt inset);

        &:hover {
            background-color: @color-secondary-alt;
            color: #fff;
        }
    }

    &.btn-cpanel {
        color: @color-brand-cpanel;
        border-color: @color-brand-cpanel;
        background-color: #fff;
        .box-shadow(0 0 0 2px @color-brand-cpanel inset);

        &:hover {
            background-color: @color-brand-cpanel;
            color: #fff;
        }
    }

    &.btn-moneta {
        color: @color-moneta-red;
        border-color: @color-moneta-red;
        background-color: #fff;
        .box-shadow(0 0 0 2px @color-moneta-red inset);

        &:hover {
            background-color: @color-moneta-red;
            color: #fff;
        }
    }

    &.btn-white {
        color: #fff;
        border-color: #fff;
        background-color: transparent;
        .box-shadow(0 0 0 2px #fff inset);

        &::before {
            background-color: @color-primary;
        }

        &:hover {
            color: #fff;
            .box-shadow(0 0 0 2px #fff inset);

            background-color: @color-primary;
        }
    }

    &.btn-blck-o {
        color: @color-black;
        border-color: @color-black;
        background-color: transparent;
        .box-shadow(0 0 0 2px @color-black inset);

        &::before {
            background-color: @color-black;
        }

        &:hover {
            color: #fff;

            &::after {
                background-color: @color-black;
                color: #fff;
            }
        }
    }

    &.btn-black {
        color: @color-fake-black;
        border-color: @color-fake-black;
        .box-shadow(0 0 0 2px @color-fake-black inset);

        &::before {
            background-color: @color-fake-black;
        }

        &:hover {
            color: #fff;
            background: @color-fake-black;

            &::after {
                background: @color-fake-black;
                color: #fff;
            }
        }
    }

    &.btn-grey {
        color: @color-grey;
        border-color: @color-grey;
        .box-shadow(0 0 0 2px @color-grey inset);

        &::before {
            background-color: @color-grey;
        }

        &:hover {
            color: #fff;
            background: @color-grey;

            &::after {
                background: @color-grey;
                color: #fff;
            }
        }
    }
}

.btn-3d,
a.btn-3d {
    &:extend(.no-pseudo all);
    &:extend(.with-3d all);

    &.btn-loading {
        .box-shadow(none);

        position: relative;
        top: 2px !important;
        right: -1px !important;
    }
}

.no-pseudo {
    &::before {
        display: none;
    }

    &.after {
        display: none;
    }
}

.with-3d {
    .box-shadow(~'1px 2px #000, 0px 1px #000, 0px 1px rgba(255,255,255,0.3) inset');

    &.before {
        .box-shadow(~'1px 2px #000, 0px 1px #000, 0px 1px rgba(255,255,255,0.3) inset');
    }

    &:hover,
    &:focus {
        .box-shadow(none);

        position: relative;
        top: 2px !important;
        right: -1px !important;
    }
}

.button-next,
.button-back {
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;

    .inside {
        display: inline-block;
        border: 3px solid @color-primary;
        height: 40px;
        padding: 7px 15px;
        font-weight: bold;
        text-decoration: none;
    }

    &.before,
    &::after,
    .inside::before,
    .inside::after {
        //left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        -moz-transform: scale(0.9999);
        z-index: 1;
    }
}

a.button-back {
    margin-left: 20px;

    .inside {
        border-color: @color-primary;
        border-left: 0;
        background-color: #fff;

        &::before {
            left: -40px;
            background-color: transparent;
            border-color: fadeout(@color-primary, 100%);
            border-right-color: @color-primary;
            border-width: 20px;
            margin-top: -20px;
        }

        &::after {
            left: -33px;
            border-color: rgba(255, 255, 255, 0);
            border-right-color: #fff;
            border-width: 17px;
            margin-top: -17px;
        }

        &:hover {
            background-color: @color-primary;
            color: #fff;
        }

        &:hover::before,
        &:hover::after {
            border-right-color: @color-primary;
        }
    }
}

a.button-next,
.button-next {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;

    .inside {
        border-right: 0;
        background-color: @color-primary;
        color: #fff;

        &::before {
            right: -40px;
            background-color: transparent;
            border-color: fadeout(@color-primary, 100%);
            border-left-color: @color-primary;
            border-width: 20px;
            margin-top: -20px;
        }

        &::after {
            right: -33px;
            border-color: rgba(255, 255, 255, 0);
            border-left-color: @color-primary;
            border-width: 17px;
            margin-top: -17px;
        }

        &:hover {
            background-color: darken(@color-primary, 3%);
            border-color: darken(@color-primary, 3%);

            &::before,
            &::after {
                border-left-color: darken(@color-primary, 3%);
            }
        }
    }

    /*
    3D gumb - senca
     &::after {
        content: "";
    }

    &.after {
        right: -40px;
        top: 22px;
        border-color: rgba(255,255,255, 0);
        border-left-color: @color-fake-black;
        border-width: 20px;
        margin-top: -20px;
        z-index: 0;
        .box-shadow(none);
    }

    &:hover:after {
        display: none;
    }*/
    //&:extend(.with-3d all);
}

/*----------  Buttons on various backgrounds  ----------*/

.bg-primary {
    .btn-brd {
        color: #fff;

        &:hover {
            &::before {
                background-color: #fff;
            }

            color: @color-primary;
            background: #fff;
        }
    }
    //background-color: #432b2b;
    //  color: #fff;
    .btn-primary-white {
        //color: @color-primary;
        &:hover {
            color: @color-secondary-alt;
        }
    }

    /*.container{
        .gradient-radial(@color-primary, @color-primary-light, 0%, 75%);
    }*/
}

.bg-secondary {
    .btn-brd {
        color: #fff;

        &:hover,
        &:focus {
            color: @color-secondary;
            background: #fff;
        }
    }

    .btn-white {
        color: @color-secondary;
    }
}

.bg-secondary-alt {
    .btn-brd {
        color: #fff;

        &:hover,
        &:focus {
            color: @color-secondary-alt;
            background: #fff;
        }
    }

    .btn-white {
        color: @color-secondary-alt;

        &:hover,
        &:focus {
            color: #fff;

            &::before {
                background: @color-fake-black;
            }
        }
    }
}

/*.bg-lgrey:not(.hosting-packages) {
    a.btn-brd {
        //background-color: @color-light-grey;
    }
}*/

.bg-dark {
    .btn-big {
        color: @color-fake-black;
    }

    .btn-brd {
        color: #fff;

        &:hover {
            color: @color-fake-black;
        }
    }

    .btn-white {
        &::before {
            background: @color-secondary-alt;
            color: #fff;
        }
    }
}

.btn-loading,
a.btn-loading {
    color: transparent;
    cursor: progress;

    &::after {
        &:extend(.spin all);
        .iconFamily();

        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        content: '\e64b';
        margin: -0.5em 0 0 -0.5em;
        width: 0.5em;
        height: 0.5em;
        -webkit-transform-origin: 100% 100%;
        -moz-transform-origin: 100% 100%;
        -o-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        color: rgba(255, 255, 255, 0.9);
        background: none;
        font-size: 2em;
    }

    &.f-bigger {
        &::after {
            font-size: 3em;
            width: 1em;
            height: 1em;
            margin: -0.5em 0 0 -0.5em;
            -webkit-transform-origin: 50% 50%;
            -moz-transform-origin: 50% 50%;
            -o-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
        }
    }

    /*:not(.checkout-cart &), */
    .input-btn &,
    &.btn-loading-hide,
    &.btn-3d {
        color: transparent !important;
    }

    .checkout-cart button& {
        padding-left: 17px;
        min-height: auto;

        &::after {
            position: relative;
            display: inline-block;
            padding: 0;
            right: -15px;
            left: auto;
            top: 1px;
            font-size: 1.5em;
            width: auto;
            height: auto;
            margin: -0.5em 0 0 -0.5em;
            -webkit-transform-origin: 50% 50%;
            -moz-transform-origin: 50% 50%;
            -o-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
        }
    }

    &.loading-tocart {
        background: none;

        &::after {
            color: @color-darker-grey;
        }
    }
}


/*-----  End of Buttons  ------*/

