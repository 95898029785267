.domain-pricelist,
.item-pricelist {
    margin-bottom: 50px;

    .tb .trow {
        & > .td {
            border-width: 0 0 1px;
        }
    }

    .table-content {
        position: relative;

        & > .td {
            &:last-of-type {
                border-bottom: 2px solid @color-grey;
            }
        }
    }

    .item-img {
        margin: 10px 5px;
    }

    .name {
        text-decoration: none;

        strong {
            text-transform: uppercase;
        }

        &.td {
            flex-grow: 0.9;
            min-width: 220px;
        }
    }

    .listing {
        .prices {
            text-align: right;
            justify-content: flex-end;

            .new-price
             {
                font-size: @font-size-bigger;
             }

            .btn-wide {
                margin: auto 0 5px auto;
            }
        }

        .old-price {
            margin-left: auto;
            color: #666;
            margin-top:1px;
        }

        .new-price {
            display: inline-block;
        }

        &:hover .spr-holder {
            .filter(grayscale(0) opacity(1));
        }
    }

    .spr-holder {
        width: 30px;
        text-align: right;

        div {
            margin-bottom: -3px;
            margin-right: 5px;
        }
        .filter(grayscale(100%) opacity(0.8));
    }

    .table-header {
        background-color: @color-light-grey;
        padding: 20px;

        div& {
            border: 0;
        }

        h1,
        h2,
        h3,
        h4 {
            margin-top: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    .table-footer {
        padding: 15px;
        background-color: #ebebeb;
        text-align: center;

        .pagination {
            a {
                border: 0;
            }
        }
    }

    .table-controls {
        background-color: @color-grey;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 7px 15px;

        .selectric-wrapper:not(.selectric-open) .selectric {
            border-color: @color-grey;
        }

        .align-right {
            margin-left: auto;
        }
    }

    .row-title {
        color: #fff;
        display: inline-block;
        flex-grow: 1.2;
        margin-top: 10px;
        min-height: 30px;

        &.box {
            float: left;
            margin-right: 8px;

            & + .input-box {
                float: right;
            }
        }
    }

    .trow {
        border-left: 2px solid #ccc;
        border-right: 2px solid #ccc;

        &:nth-of-type(2n - 1) {
            background-color: @color-lightest-grey;
        }
    }

    .sorting-select,
    .duration-select,
    .category-select {
        float: right;
        display: inline-flex;
        justify-content: flex-end;
        padding-left: 20px;
    }
}

.domain-pricelist .table-header img.align-right {
    height: 70px;
    padding: 0;
    margin-top: -10px;
}

.item-pricelist {
    .td.img {
        flex-grow: 1;
        flex-shrink: 1.5;
        min-width: 80px;

        img {
            width: 100%;
            max-width: 110px;
        }
    }

    .td.info {
        flex-grow: 3;
        min-width: 260px;
        flex-direction: column;

        h5 {
            display: inline-block;
            margin-bottom: 0;
            margin-right: auto;
            text-decoration: underline;
        }
    }

    .td.prices {
        padding-top: 20px;
        padding-bottom: 15px;
        flex-grow: 1.6;
        flex-direction: column;
        min-width: 180px;
    }

    .price-box {
        margin: 0 0 20px auto;
    }
}

.domain-pricelist {
    .table-controls {
        background-color: @color-secondary-alt;

        .selectric-wrapper:not(.selectric-open) .selectric {
            border-color: @color-secondary-alt;
        }
    }
}

.table-controls,
.domain-results {
    .selectric-open,
    .selectric-hover {
        .selectric {
            border-color: @color-fake-black;
        }
    }
}
