@media @break-sm {
    .pkg:not(.popular) {
        .pkg-spec {
            border-right: 1px solid @color-lighter-grey;
        }
    }

    .package-list {

        .pkg  {
            .package-title {
                border-top-left-radius: @box-border-radius;
                border-top-right-radius: @box-border-radius;
            }
            .feat-list {
                border-bottom-left-radius: @box-border-radius;
                border-bottom-right-radius: @box-border-radius;
            }

            .pkg-spec:last-of-type {
                border-bottom-left-radius: @box-border-radius;
                border-bottom-right-radius: @box-border-radius;
            }
        }

        &.comparison {
            .popular {
                margin: 0 0 -4px;
            }
        }

        .popular {
            margin: 0 0 -4px;

            &::before,
            &::after {
                display: none;
            }

            .ribbon-diag {
                top: 0;
            }

        }
    }
}

@media @break-lg {
    .package-list {
        .popular:before {
            display:none;
        }
    }
}
