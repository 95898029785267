/* ==============================
=            KEYVISUALS            =
============================== */

/* ==========  top-keyvisual  ========== */
.mobile-keyvisual {
    display:none;
}
.top-keyvisual {
    overflow: hidden;
    min-height: 410px;
    padding: 0;

    .kv-item {
        min-height: 350px;
        padding-bottom: 0;
    }

    h2 {
        font-size: 32px;
        margin: 0;
        padding: 35px 0 5px;
    }

    p {
        margin-top: 0;
    }

    .side-img {
        left: 10%;
        position: absolute;
        z-index: 1;
    }

    .center-img {
        display: block;
        margin: 4% auto 25px;
        // max-width: 400px;
    }

    .icon-cart {
        font-size: 1.5em;
        position: relative;
        top: 1px;
        margin-right: 3px;
    }

    .buttons {
        max-width: 430px;
        margin: 0 auto;
        text-align: center;

        a {
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .slides {
        background: @color-primary;
        max-height: 350px;
        overflow: hidden;

        &.slick-initialized {
            overflow: visible;
        }
    }
}

.bg-gostovanje {
    background: url(../img/cms/kv/gostovanje/webhosting-server-lights-off.png) no-repeat bottom center;
    min-height: 380px;
    &:after{
        content: "";
        width:100%;
        height: 80px;
        background: url(../img/cms/kv/gostovanje-2-lights.gif) no-repeat bottom center;
        z-index: 1;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .benefits {
        margin: 50px 0 0 0;
        padding-left:0;
        li {
            display:inline-block;
            color:#fff;
            width: 150px;
            padding:70px 0 0 0;
            margin: 0 20px;
            font-weight:bold;
            font-size:14px;
            &:hover {
                text-shadow:0px 0px 20px #fff;
                -moz-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                -webkit-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
            }
            &.guarantee {
                background: url(../img/cms/kv/gostovanje/icon-webhosting-guarantee.png) no-repeat top center;
            }
            &.ssd {
                background: url(../img/cms/kv/gostovanje/icon-webhosting-ssdnvme.png) no-repeat top center;
            }
            &.backup {
                background: url(../img/cms/kv/gostovanje/icon-webhosting-backups.png) no-repeat top center;
            }
            &.cpanel {
                background: url(../img/cms/kv/gostovanje/icon-webhosting-cpanel.png) no-repeat top center;
            }
        }
    }

}

.bg-turbo {
    background: url(../img/cms/kv/promo-turbo-kv-bg-old2.png) no-repeat 16px 0;
    min-height: 350px;
}

.bg-dedicated {
//    background: url(../img/cms/kv/dedicirano-gostovanje-kv-bg.png) no-repeat 16px 34px;
    min-height: 460px;
}


.bg-domene-gostovanje {
    background: url(../img/cms/kv/promo-new-domene-gostovanje-kv-bg.png) no-repeat 16px 0;
    min-height: 350px;
    .input-typing{
        transform: translate(110px, 155px);
        color:yellow;
        span{
            color:#333;
        }
    }
    .btn-promo-green{
        line-height: 36px;
        border-radius: 10px;
        transform: translate(130px, -58px);
    }
}

.bg-e-postno {
    background: url(../img/cms/kv/promo-e-postno-kv-bg.png) no-repeat 16px 0;
    min-height: 350px;

}

.bg-e-postno-2 {
    background: url(../img/cms/kv/e-postno.png) no-repeat 50% 90px;
    min-height: 480px;
}

.bg-email-streznik {
    background: url(../img/cms/kv/zasebni-e-postni-streznik.png) no-repeat 50% 120px;
    min-height: 500px;
}

/* .bg-vps {
    background: url(../img/cms/kv/vps-gostovanje.png) no-repeat 0px 80px;
    min-height: 480px;
    width: 1160px;

} */

.bg-spring {
    background: url(../img/cms/kv/promo-spring-kv-bg.png) no-repeat 16px 0;
    min-height: 350px;
}
.bg-black{
    background-color: #000;
}
/*.top-keyvisual .slick-slide:first-child{
    background-color: #000;
}*/

.bg-winter {
    background: url(../img/cms/kv/promo-winter-kv-bg.png) no-repeat 16px 0;
    min-height: 350px;
}

.bg-christmas {
    background: url(../img/cms/kv/promo-christmas-kv-bg.png) no-repeat 16px 0;
    min-height: 350px;
}

.bg-si-domain {
    background: url(../img/cms/kv/si-domena-registracija.png);
    min-height: 350px;
}

.bg-si-domain-c {
    background: url(../img/cms/kv/si-domena-registracija-clean.png);
    min-height: 350px;
}

.bg-ssl {
    background: url(../img/cms/kv/promo-ssl-kv-bg.png);
    min-height: 350px;
}

.bg-spletne-strani {
    background: no-repeat 100% 0 url(../img/cms/kv/izdelava-strani-bg.png);
    min-height: 400px;
    .page-title {
        text-align: left;
    }
    .buttons {
        a {
            margin-right: 20px;
        }
    }
}

.bg-predloge{
    background: 100% 0 no-repeat url(../img/cms/kv/predloge-bg.png);
    min-height: 400px;
    .page-title {
        text-align: left;
    }
}

.bg-izdelava-strani{
    background: url(../img/cms/kv/izdelava-strani.png);
    min-height: 400px;
}
@media(max-width:991px){
    .bg-izdelava-strani{
        background: url(../img/cms/spletne-strani/keyvisual-mobilegif.gif);
        min-height: 400px;
        background-position: center 30px;
        background-repeat: no-repeat;
    }
}
@media(max-width:576px){
    h1.c-wht{
        font-size: 26px;
    }
}

.subtitle-center {
    max-width: 330px;
    margin: 0 auto;
    overflow: auto;
}

.blck-shadow {
    text-shadow: 1px 1px 1px rgba(33, 33, 33, 0.8);
}

.c-yellow {
    color: #ff0;
}

.promo-sign {
    margin-top: 78px;
}

.bg-winter,
.bg-spring {
    .promo-sign {
        margin-top: 82px;
        margin-left: 35px;
        -webkit-transform: rotate(4deg);
        -moz-transform: rotate(4deg);
        -o-transform: rotate(4deg);
        transform: rotate(4deg);
        writing-mode: lr-tb;
    }

    .terms {
        p {
            font-size: 11px;
            color: #9ed4ee;
        }

        position: absolute;
        bottom: 0;
        left: 20px;
    }
}

.bg-lightning {
    background: url(../img/cms/lightning-large.png) no-repeat 0 0;
    padding-left: 30px;
    padding-bottom: 7px;
    padding-top: 7px;
}

.big-price {
    font-size: 36px;
    font-weight: bold;
}

.price-guarantee {
    margin-top: 17px;
    color: #c5232e;
    font-weight: bold;
    font-size: 13px;
}

.btn-promo-white,
a.btn-promo-white {
    color: #000;
    background-color: #fff;

    &:hover {
        background: #42aa0c;
        color: #fff;
    }
}

.btn-promo-orange,
a.btn-promo-orange {
    color: #fff;
    background-color: #f3831f;
    border: 2px solid white;

    &:hover {
        background: #fff;
        color: #000;
        border: 2px solid white;
    }
}

.btn-promo-green,
a.btn-promo-green {
    color: #fff;
    background-color: #42aa0c;
    border: 2px solid white;

    &:hover {
        background: #fff;
        color: #000;
        border: 2px solid white;
    }
}

.btn-promo-green-hov,
a.btn-promo-green-hov {
    color: #fff;
    background-color: #42aa0c;
    border: 2px solid white;

    &:hover {
        background: #fff;
        color: #42aa0c;
        border: 2px solid green;
    }
}

/* END Akcija si-domena */

/* Front keyvisuals */

.kv-ssd {
    .grd {
        max-width: 80%;
        margin: 0 auto;
    }

    .labeled {
        flex-grow: 0.3;
        min-width: 165px;
        padding-top: 40px;
        text-align: right;

        .label {
            padding-right: 10px;
            margin-top: 28px;
            display: inline-block;
            vertical-align: top;
        }

        .rubik {
            font-size: 82px;
            display: inline-block;
            line-height: 1em;
        }
    }

    .loading-compare {
        text-align: left;
    }

    .icon-check-black-long {
        display: inline-block;
        vertical-align: top;
        margin-top: 0;
        font-size: 32px;
        color: #42aa0c;
    }

    .green {
        .right-side {
            .loading-bar {
                max-width: 560px;
                display: inline-block;
            }
        }
    }
}

.bg-dark .loading-compare {
    .label {
        color: #fff;
    }

    .left-side {
        border-right-color: #fff;
    }
}

/* Qutoes KV */
.kv-item .quote {
    &:extend(.d-ib);

    margin: 10px;

    .speech-cloud {
        position: relative;
        padding: 10px 15px;
        max-width: 180px;
        font-weight: bold;
        font-size:14px;
        background-color: yellow;
        color: @color-fake-black;

        &::before {
            content: " ";
            position: absolute;
            top: 0;
            width: 39px;
            height: 37px;
            background: url('../img/cms/chatbox_arrow.svg');
        }

        &.left {
            border-radius: 5px 0;

            &::before {
                left: -39px;
            }
        }

        &.right {
            border-radius: 0 5px;

            &::before {
                right: -39px;
                background-position: 100%;
            }
        }
    }
}

.kv-quotes {
    div.buttons {
        margin: 50px 10px 10px;
    }

    .text-content {
        display: inline-block;
        max-width: 600px;
        vertical-align: top;
    }

    .align-center {
        width: 100%;
        max-width: 900px;

        > div {
            vertical-align: top;
        }

        & > img {
            margin-top: 44px;
            display: inline-block;
        }
    }

    .align-left,
    .align-right {
        margin-bottom: 25px;
        margin-top: 10px;
    }

    .align-left {
        & > .d-ib {
            margin-left: -10px;
        }
    }

    .align-right {
        & > .d-ib {
            margin-right: -10px;
        }
    }
}

/* ==========  KV - Front  ========== */


.kv-options {
    margin: 30px auto 25px;
    max-width: 860px;
    max-height: 115px;

    .option {
        display: block;
        opacity: 0;
        max-width: 200px;
        float: left;
        padding: 0 15px;
        text-align: center;
        min-width: 140px;

        .spr-holder {
            height: 70px;
            vertical-align: middle;
        }

        p {
            line-height:1.3em;
        }
    }
}

.kv-fast {
    .srv {
        height: 100px;
        max-width: 370px;
        position: relative;

        img {
            display: none;
            left: 0;
            right: 0;
            position: absolute;
        }
    }
}

.spr-kv-cloud,
.spr-kv-bolt {
    margin-top: 10px;
}

.domain-pros-kv {
    .option {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


.kv-casestudies {
    text-align:center;
    .beforeafter {
        max-width: 540px;
        margin: 0 auto;
        h4 {
            margin: 10px 0 0 0;
        }
    }
    #case-studies-animation {
        max-width: 800px;
        margin:-30px auto 0 auto;

    }
}

.kv-email-streznik {
    #zasebni-email-streznik-animation {
        max-width: 720px;
        margin:10px auto 0 auto;

    }
}

.kv-ssl {
    text-align:center;
    #ssl-animation {
        .grdcell {
            min-width: auto;
        }
        &:extend(.font-mono);
        #andy-typing {
            flex-direction: column;
            justify-content: flex-end;
            display: flex;
        }
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(29,154,214);
            background: linear-gradient(90deg, rgba(29,154,214,1) 0%, rgba(9,9,121,0) 50%, rgba(29,154,214,1) 100%);
        }
        #data-flow-encrypted {
            position: relative;
        }
        #data-flow-unencrypted {
            position: relative;
        }
        #secure-lock {
            width: 100%;
            height: 60px;
            margin-top: -10px;
            background: url(../img/cms/svg/lock.svg) 50% 100% no-repeat;
            background-size: 18%;
        }
        #andy-secured {
            flex-direction: column;
            justify-content: flex-end;
            display: flex;
        }
    }
    @media @break-max-lg  {
        #data-flow-encrypted,  #data-flow-unencrypted {
            display: none;
        }
        #secure-server-lock {
            padding: 0 20%;
        }
        #secure-lock {
            margin:0;
        }
    }

    @media @break-max-md  {
        #ssl-animation {
            flex-direction: column;
            justify-content: flex-end;
            #secure-server-lock {
                #secure-lock {
                    display:none;
                }
                padding: 0 20%;
            }
            #data-flow-encrypted,  #data-flow-unencrypted {
                display: block;
                width: 100%;
                .bg {
                    background: linear-gradient(to bottom, rgba(29,154,214,1) 0%, rgba(9,9,121,0) 50%, rgba(29,154,214,1) 100%);
                }
                canvas {
                    width: 60%;
                    min-height: 120px;
                }
            }
            #andy-typing {
                padding: 0 20%;
            }
            #andy-secured {
                padding: 0 20%;
            }
        }
    }
}
/* END of front keyvisuals */
