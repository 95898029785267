.user-reviews-side {
    position: relative;
    margin-top: 75px;

    .reviews-slider {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;
        min-height: 300px;

        .slick-track {
            height:auto;
        }

        .btn-holder {
            margin-top: auto;
        }
    }

    .on-top {
        font-size: 56px;
        font-weight: bold;
        line-height: 1.35em;
        color: @color-bluish-grey;
    }

    h5 {
        margin-top: 0;
    }

    .quote-cont {
        border: 0;
        background-color: #fff;
        color: @color-dark;

        &::before {
            content: none;
        }

        &::after {
            bottom: -30px;
            left: 48%;
            margin-left: -15px;
            content: " ";
            border-width: 30px 30px 0 0;
            border-color: #fff transparent transparent;
        }

        .icon-quote-alt {
            color: @color-primary;
        }
    }

    .author,
    .review {
        overflow: auto;
        margin-top: 10px;
    }

    .icon-star {
        font-size: 1.5em;
        color: @color-primary;
    }

    .reviews-slider + .testimonials {
        .item {
            background: #fff url('../img/icons/triangle.svg') repeat-x;
        }
    }
}

.testimonials {
    .clearfix();

    position: relative;
    padding: 5px;

    .item {
        padding: 25px 20px 20px;
        float: left;
        width: 100%;
        background-color: #fff;

        h5 {
            margin: 0;
            text-align: center;
        }

        small {
            display: block;
            padding: 5px 0;
            text-align: center;
        }
    }

    .slick-next,
    .slick-prev {
        margin-top: 15px;
    }
}

.yt-play {
    position: relative;
    width: 100%;
    padding: 10px 15px;
    display: inline-block;
    background-color: @color-bluish-grey;

    .icon-yt_play {
        float: right;
        font-size: 38px;
        margin-top: -9px;
        margin-right: -5px;
        margin-bottom: -10px;
    }
}
