.kv-tabs {
    padding: 0;

    .tabs-holder {
        margin: 0;
    }

    .kv-content {
        padding: 5px 40px 10px;

        h3 {
            margin-bottom: 0.4em;
        }

        &.condensed {
            letter-spacing: -0.5px;
        }
    }

    .kv-additional {
        position: relative;
        padding: 10px 40px 0;

        .align-right {
            position: absolute;
            bottom: 0;
            right: 3%;
            padding: 0;
        }
    }
}

.img-bt-shadow {
    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15px;
        .box-shadow(~'0 -13px 8px -7px rgba(0,0,0,0.5) inset')
    }
}

.desk {
    padding-top: 0 !important;
}

.cpanel-filemanager {
    align-items: flex-end;
}

.cpanel-text {
    align-self: baseline;
    min-width: 265px;
    padding-bottom: 1em;
}

.cpanel-monitor {
    margin-bottom: -15px;
    min-width: 160px;
    z-index: 2;
}

@media @break-sm {
    .cpanel-monitor {
        text-align: center;
    }
}
