/****** NAVIGATION ******/
.mobile-menu-holder,
.menu-toggle {
    display: none;
    font-size: 28px;
    cursor: pointer;
    color: @color-secondary;
    position: relative;
    z-index: 1;

    i {
        padding: 10px 10px 8px;
        display: block;
    }
}

body:not(.responsive) .navigation {
    &.closed {
        height: 4px;
        border-bottom: 0 none;

        .main-menu {
            display: none;
        }
        .container {
            ul {
                display: none;
            }
        }

        .menu-toggle {
            display: block;
            float: right;
            color: #fff;
            background-color: @color-secondary;
        }
    }
}

.navigation {
    border-bottom: 2px solid #fff;
    background-color: @color-secondary;
}

/*==========  Main-menu  ==========*/
.main-menu {
    height: 54px;

    .home .main-menu-link {
        padding: 1px 12px 12px;
    }
    a {
        font-family: 'Rubik', 'Tahoma', Helvetica, sans-serif; //dodano da se fonti naložijo takoj in stran ne skače
    }

    .icon-home {
        font-size: 36px;
        position: relative;
        top: 5px;
        vertical-align: bottom;
    }
}

.home-text {
    display: none;
}

.navigation nav {
    display: flex;
    justify-content: center;
    position: relative;
}

.main-menu {

    display: flex;
    width: @site-width;

    .post-icon {
        a {
            padding-right: 40px;
        }
    }

    .icon-ns-logo-alt {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 24px;
    }

    .main-menu-item {
        float: left;

        .main-menu-link {
            position: relative;
            font-size: @font-size-bigger;
            display: inline-block;
            padding: 17px 17px 12px;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;

            .mobile-submenu-toggle {
                display:none;
            }
        }
        &.home {
            .main-menu-link {
                padding-top:5px;
            }
        }

        &.active {
            &:not(.home) {
                margin-bottom: -2px;
                border-right: 2px solid #fff;
                border-left: 2px solid #fff;
                background-color: @color-primary;

                &.no-subheader {
                    .box-shadow(none);
                }
            }
            &.darker {
                background-color: #272122;
                .box-shadow(0 5px 0 -2px #272122);
            }
            .main-submenu {
                left: -2px;
            }
        }

        &.has-children:not(.fixed-menu .has-children) {
            &:hover {
                &:not(.home) {
                    background-color:#efefef;

                    > a {
                        color:#000;
                        background-color: #efefef;
                        margin-bottom:-2px;
                        z-index:1100;
                    }
                    a.active {
                        background-color: #efefef;
                    }
                    .large-submenu {
                        display: flex;
                    }
                }
            }
        }

        .main-submenu {
            display:none;
        }

        .large-submenu {
            display:none;
            position: absolute;
            width: 100%;
            justify-content: center;
            top: 52px;
            left: 0;
            background-color: #efefef;
            z-index:1000;
            -webkit-box-shadow: 0 3px 10px -3px rgba(0,0,0,0.77);
            -moz-box-shadow: 0 3px 10px -3px rgba(0,0,0,0.77);
            box-shadow: 0 3px 10px -3px rgba(0,0,0,0.77);

            &.bright-end {
                background: linear-gradient(to right, #efefef 50%, white 50%);
            }

            &.e-posta {
                .bright {
                    .image {
                        img {
                            margin-top:-20px;
                        }
                    }
                }
            }

            &.zakaj-neoserv {
                .affiliate {
                    .image {
                        img {
                            margin-top: -10px;
                        }
                    }
                }
            }
            ul {
                color:#000;
                margin-top:0;
                margin-bottom:0;
                width: @site-width;

                li {
                    padding:30px 20px;
                    background-color: #efefef;

                    &.bright {
                        background-color: @color-white;

                        .image {
                            border-bottom: none;
                        }
                        .title {

                        }
                    }

                    .title {
                        margin: 0 0 20px 0;

                        strong {
                            text-transform: uppercase;
                            color:@color-primary;
                            font-size: 2rem;
                            margin:0 0 5px 0;
                            display: block;
                            width: 100%;
                            height: 24px;
                            margin-top:8px;
                            &.turbo {
                                margin-top:4px;
                                margin-bottom:7px;
                            }
                            .icon-bolt {
                                margin-left: -10px;
                            }
                        }
                        span {
                            display:block;
                            width:100%;
                            margin-bottom:5px;
                            color: @color-black;
                            text-transform: uppercase;
                            font-weight: bold;
                            font-size:0.9rem;
                        }
                    }
                    .image {
                        width:100%;
                        text-align:center;
                        height: 140px;
                        padding:20px 0 10px 0;
                        position: relative;
                        border-bottom:1px solid #ddd;

                    }
                    p {
                        color: @color-dark-grey;
                        display:block;
                        width: 100%;
                        height: 75px;
                        font-size: @font-size-smaller;

                        strong {
                            color: @color-black;
                        }
                    }
                    a {
                        .arial;
                        margin-top: 5px;
                        display:block;

                        &:hover {

                        }
                    }

                }
            }
        }
    }
}


.mobile-cart-zone {
    display: none;
}

/*==========  active/scrolled navbar  ==========*/
.top-header {
    .main-menu {
        display: none;
    }
}

.mobile-menu-close {
    display: none;
}

.scrolled {
    .top-header {
        .box-shadow(0 2px 7px rgba(0,0,0,0.7));

        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        background-color: #fff;
        min-height: auto;

        .container {
            max-width: @site-width;
        }

        .logo,
        .support,
        .phone,
        .email {
            display: none;
        }
    }

    .top-header,
    .navigation {
        transform: translateY(-130%);
        will-change: transform;
    }

    &.show {
        .top-header,
        .navigation {
            transform: translateY(0%);
        }
    }

    &.animate {
        .top-header,
        .navigation {
            transition: transform 300ms, padding 300ms;
        }
    }

    .right-info {
        top: 0;
        padding: 0;
        width: 13%;
        min-width: auto;

        .top-links {
            display: none;
        }
    }

    .support-person {
        display: none;
    }

    /* .main-menu */
    .mobile-menu-holder {
        display: none;
        float: left;
        margin: 7px;
    }

    .navigation {
        background: transparent;
        border: 0;
        position: fixed;
        top: 0;
        width: 100%;

        /* main-header ima z-index 999 */
        z-index: 1000;
    }

    .mobile-cart-zone {
        display: block;
        float: right;
        min-height: 60px;
        width:140px;
    }

    .main-menu {
        margin-bottom: -5px;
        width: 1220px;
        height: 60px;
        color: @color-secondary;
        float: left;

        .icon-home {
            top: 0;
        }

        .large-submenu {
            top: 60px;
        }

        .main-menu-item {

            background-color: #fff;
            border-top:none;
            padding-top:0px;
            padding-bottom:0px;
            &.can-hide {
                display: none;
            }

            &.active {
                background-color: transparent;
                border:none;
                .box-shadow(none);

                .main-menu-link {
                    color: @color-fake-black;
                }
            }
        }

        .main-menu-link {
            color: @color-secondary;
            padding: 21px 13px 19px 14px;
        }

        .home {
            background-color: transparent;
            border-color: transparent;

            .main-menu-link {
                padding: 11px 4px;
            }

            &.active a {
                pointer-events: auto;
            }

            & + li {
                margin-left: 5px;
            }
        }
    }
}

.hidden-anim {
    overflow: hidden;
    max-width: 0;
    border: 0;
    max-height: 0;
}

.visible-anim {
    max-height: 99999px;
    max-width: 99999px;
}
