/* ==========  Domain check box ========== */
.bg-world {
    background: url('../img/cms/svg/domene-bg.svg') no-repeat 50%;
    background-size: 500px;
}

.www-prefix {
    float: left;
    height: 60px;
    width: 80px;
    position: absolute;
    top: 0;
    left: 0;
    background: @color-light-grey;
    border-top-left-radius:@box-border-radius;
    border-bottom-left-radius:@box-border-radius;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 80px;
        width: 0;
        height: 0;
        border: 30px solid transparent;
        border-left: 15px solid @color-light-grey;
        border-right-width: 0;
    }

    .prefix {
        font-size: @font-size-big;
        float: left;
        margin: 11px 8px 0 15px;
        color: @color-fake-black;
    }
}

.domain-check {
    position: relative;

    .input-button {
        input {
            padding-left: 105px;
            padding-right: 120px;
            margin: 0;
            outline:none;
            border-radius:@box-border-radius;
            &:focus {
                box-shadow: 0 0 14px #666;
            }
        }
    }

    &.small {
        .www-prefix {
            z-index: 1;
            height: 40px;
            width: 70px;

            &::after {
                left: 70px;
                border: 20px solid transparent;
                border-left: 15px solid @color-light-grey;
            }
        }

        .prefix {
            font-size: 18px;
            margin: 10px 8px 0 15px;
        }
    }

    .icon-search {
        font-size: 30px;
    }
}

.inline-domain-check:not(.checkout-content .inline-domain-check):not(.front .inline-domain-check){
    border-radius: @box-border-radius;
    &.top-stick {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.search-active {
    .inline-domain-check {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}



.inline-domain-check {
    position: relative;
    display: inline-block;

    form {
        padding: 0;
    }

    &.full {
        display: block;
        padding: 30px;

        & .sub-text {
            display: inline-block;
            margin: 0 0 10px;
            padding-left: 15px;
            color: #fff;
        }

        .domain-check {
            max-width: 760px;
            //width: 100%;

            small {
                padding-bottom: 3px;
                color: rgba(255, 255, 255, 0.7);

                strong {
                    color: #fff;
                }
            }

            .abs-left {
                position: absolute;
                left: -50px;
                bottom: 10px;
            }
        }
    }

    &.high {
        .domain-check {
            & .prefix {
                margin-top: 20px;
            }
        }

        .btn-3d {
            height: 60px;
            max-width: 130px;
            margin-top: -2px;
            padding: 8px 15px 12px 9px;
        }

        .input-button {
            input {
                height: 60px;
            }
        }
    }

    &.large {
        .domain-check {

            max-width: 900px;

            .www-prefix {
                height: 80px;
                width: 90px;

                .prefix {
                    margin-top: 30px;
                    font-size:30px;
                }

                &::after {
                    border-top-width: 40px;
                    border-bottom-width: 40px;
                    left: 90px;
                }
            }

            .btn-3d {
                height: 80px;
                max-width: 140px;
                margin-top: -2px;
            }

        }

        .btn-3d {

        }

        .input-button {
            input {
                height: 80px;
                font-size:30px;
                padding-left:115px;
            }
        }
    }

    &.simple {
        padding: 20px 0;
    }

    .domain-check {
        margin: 0 auto;

        & > strong {
            display: inline-block;
            margin: 10px 0 0 -3px;
            color: @color-grey;
            font-size: 17px;
        }

        &.small {
            margin: 0 0 0 auto;
        }
    }

    .tabs {
        display: block;
        margin: 20px 0 0 20px;
    }

    .simple-tabs {
        display: flex;

        .active {
            a {
                background-color: #fff;
                color: @color-primary;
            }
        }

        a {
            background-color: @color-blue-dark2;
            display: inline-block;
            margin-right: 2px;
        }
    }
}

.page-title {
    & + #domain-check-wrapper {
        .inline-domain-check {
            padding-top: 5px;
        }
    }
}

/* ==========  Domain check results ========== */
.domain-results {
    padding-top: 20px;
    padding-bottom: 15px;
    border-bottom-left-radius: @box-border-radius;
    border-bottom-right-radius: @box-border-radius;

    .clearfix();

    &::before {
        width: 10px;
        height: 10px;
        margin: -20px auto 10px auto;
        content: ' ';
        -moz-transform: scale(0.9999);
        border: solid;
        border-width: 15px 65px 0;
        border-color: @color-primary transparent;
        border-color: @color-primary rgba(255, 255, 255, 0);
    }

    &.bg-lgrey {
        .domain-query {
            li {
                padding: 0 20px;
            }
        }
    }

    .simple ~ & {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .selectric {
        background-color: @color-light-grey;
        border-color: transparent;
    }

    .container {
        width: auto;
        max-width: 1260px;
    }
}

.bg-world {
    .domain-results {
        border-top-left-radius: @box-border-radius;
        border-top-right-radius: @box-border-radius;
    }
}

.domain-price,
.domain-status {
    //margin-top: 9px;
    //margin-bottom: 9px;
}

.domain-actions {
    margin: 10px 0;
}

.advanced {
    .inline-domain-check {
        &.full {
            padding-bottom: 0;
        }
    }

    .domain-results {
        &::before {
            display: none;
        }

        .check-other {
            display: none;
        }
    }
}

.domain-check-footer {
    .check-other {
        display: inline-block;
        margin: 0 0 0 20px;
    }

    .to-order {
        float: right;
        margin-right: 20px;
        margin-top: 5px;
    }
}

.checkout-content {
    .domain-query {
        align-items: center;
        margin-top: -10px;

        li {
            border-bottom: 2px solid #ccc;
            padding: 0 10px;

            &.stand-out {
                background-color: @color-cart-bg;
                border-top: none;
                border-left: none;
                border-right: none;
            }

            &.domain-fail {
                background-color: #fff;

                /* border-bottom: 0;
                margin-bottom: 0px; */
            }
        }

        .domain-ok,
        .domain-info {
            .domain-status {
                //width: 49%;
            }
        }

        .domain-status {
            & > i {
                left: 0;
                margin-left: -5px;

            }
        }
    }

    .domain-loading .domain-status > i {
        left: 8px;
    }

    .domain-price {
        //margin: 0 3% 0 1%;
        text-align: right;
    }

    .inline-domain-check {
        padding-top: 20px;
    }

    .domain-check {
        margin: 0 auto;
    }

    .check-other {
        margin: 5px 0 25px;
    }

    .advanced {
        .domain-results {
            padding-top: 0;
        }

        .domain-query {
            margin-top: 5px;
        }

        .domain-filters {
            border-bottom: 2px solid #ccc;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 15px;
            width: 100%;
        }
    }
}

.colored-bg {
    a.check-other {
        border-bottom: 1px solid @color-primary;
        color: @color-primary;
    }
}

.domain-filters {
    .sorting-select,
    .duration-select,
    .category-select,
    .alt-category-select {
        align-items: center;
        display: inline-flex;
        float: right;
        margin-bottom: 15px;

        .row-title {
            margin-right: 5px;
        }
    }
}

.domain-query {
    margin: 30px 0 20px;

    &:last-of-type {
        margin: 10px 0;
    }

    li {
        align-items: center;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 3px;
        min-height: 78px;
        padding: 0;
        position: relative;
        width: 100%;

        & > div {
            &:last-of-type {
                padding-right: 0;
            }
        }

        &.stand-out {
            border: 1px solid @color-grey;
            margin-bottom: 10px;
        }
    }

    .domain-status {
        align-items: center;
        display: flex;
        flex-grow: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 0;
        text-align:left;

        small {
            line-height: 15px;
            display: inline-block;
            margin-top: 4px;
            margin-left: auto;
            margin-right: auto;
            color: @color-darker-grey;
        }

        .placeholder-domain {
            font-size: @font-size-big;
            line-height:22px;
            display: block;
        }

        .icon-check-circle,
        .icon-cancel-circle,
        .icon-info-circle {
            font-size: 64px;
            left: -10px;
            position: relative;
        }

        .idn-warning {
            margin-left: 4px;
            margin-top: 2px;
            font-size: 15px;
        }
    }

    .domain-price {
        display: none;
        position: relative;
        min-width: 67px;
        //max-width: 15%;
        margin-right: 2%;
        margin-left: auto;

        & .new-price,
        & .old-price,
        .icon-discount-right {
            text-align: right;
        }

        .old-price {
            color: @color-fake-black-light;
            float: right;
            margin-right:1px;
            /* Skrijmo prečrtane cene */
            display:none;

            &::after {
                .box-shadow(1px 0 0 1px @color-fake-black inset);
            }

            & + .new-price {
                float: right;
            }
        }

        .new-price {
            font-size: 1.8rem;
            margin-top: 4px;
            float: right;
            &:extend(.rubik);
        }

        .domain-promo {
            display: inline-flex;
            vertical-align: bottom;

            .icon-discount-right {
                float: right;
                font-size: 1.8rem;
                margin-top:4px;
                color: @color-secondary-alt;
            }
        }

        .domain-low {
            .icon-discount-right {
                float: right;
                font-size: 20px;
                color: @color-primary;
            }
        }

        .popover-holder {
            float: right;
        }

        & > strong,
        & > span,
        & .icon-discount-right {
            font-size: @font-size-bigger;
        }

        & > span {
            font-size: 1.2rem;
        }
    }

    .domain-actions {
        display: none;
        margin-left: auto;
        max-width: 200px;
        padding: 10px 11px;
        width: 100%;
        text-align: center;

        & .align-center {
            display: block;
            text-align: center;
        }

        .remove-item {
            display: block;
            margin: -10px 0 5px;
            text-align: center;
        }

        .icon-close {
            font-weight: bold;
            line-height: 1em;
            display: inline-block;
            width: 25px;
            height: 25px;
            padding: 5px;
            text-align: center;
            text-decoration: none;
            color: #000;
            border-radius: 20px;
            background-color: #ccc;
        }

        .icon-check-black {
            font-size: 2em;
            position: relative;
            bottom: -2px;
            color: @color-secondary;
        }

        .from-cart {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ajax-error {
            text-align: justify;
            color: @color-red;
        }

        .cart-status {
            display: inline-block;
            color: @color-secondary;
            position: relative;
            top: 2px;
            margin-left: 4px;
        }
    }

    .domain-ok {
        .domain-status {
            color: @color-secondary;

            small {
                display: none;
            }
        }
    }

    .domain-promoted {
        overflow: hidden;
        .domain-status {
            position: relative;
            .corner-ribbon {
                position: absolute;
                top: -10px;
                right: -5px;
                width: 124px;
                height: 84px;
                overflow: hidden;
                z-index: 1;
                line-height: 15px;

                .text {
                    position: relative;
                    top: 27px;
                    right: 14px;
                    display: block;
                    width: 150px;
                    height: 29px;
                    padding: 9px 55px;
                    line-height: 12px;
                    font-size: @font-size-smaller;
                    color: #999999;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    background-color: #eff537;
                }
            }
        }
    }

    .domain-terms {
        .domain-status {
            small {
                display: inline;
            }
        }
    }

    .domain-info {
        .domain-status {
            color: @color-primary;
        }
    }

    .domain-fail {
        background-color: @color-light-yellow;

        .domain-status {
            width: 100%;
            color: @color-red;
        }

        .domain-actions {
            display: none;
        }
    }

    .domain-loading {
        .domain-status {
            color: @color-grey;
        }

        [class*='icon-check'] {
            &:extend(.css-loader);
            left: 22px;
            top: -5px;
            margin: 9px auto !important;

            &::before {
                content: ' ';
            }
        }

        .domain-price,
        .domain-actions,
        .popover-holder {
            display: none;
        }
    }

    .domain-promoted {
        background-color: #fdffbe;
    }
}
