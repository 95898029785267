// Loader
.css-loader {
    width: 47px;
    height: 47px;
    clear: both;
    margin: 20px auto;
    border: 5px rgba(0, 0, 0, 0.25) solid;
    border-top: 5px black solid;
    border-radius: 50%;
    -webkit-animation: css-rotation 0.6s infinite linear;
    animation: css-rotation 0.6s infinite linear;
    display: inline-block;
}

@-webkit-keyframes css-rotation {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(359deg); }
}

@keyframes css-rotation {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
}

@keyframes shake {
    0% { transform: translate(0, 0); }
    10% { transform: translate(-3px, -3px); }
    20% { transform: translate(3px, 3px); }
    30% { transform: translate(-3px, 3px); }
    40% { transform: translate(3px, -3px); }
    50% { transform: translate(-3px, 0); }
    60% { transform: translate(3px, 3px); }
    70% { transform: translate(-3px, -3px); }
    80% { transform: translate(3px, 3px); }
    90% { transform: translate(-3px, -3px); }
    100% { transform: translate(0, 0); }
}

.overlay {
    position: relative;

    &::after {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.68);
        z-index: 100;
        content: " ";
    }

    .css-loader {
        display: none;
    }

    &.loading {
        &::after {
            background-color: rgba(255, 255, 255, 0.68);
        }

        .css-loader {
            content: ' ';
            position: absolute;
            display: block;
            z-index: 101;
            border-color: rgba(0, 0, 0, 0.25);
            border-top-color: rgba(0, 0, 0, 0.6);
            border-width: 12px;
            left: 50%;
            top: 50%;
            width: 100px;
            height: 100px;
            margin-left: -50px;
            margin-top: -50px;
        }
    }
}

.mdl-content .overlay {
    margin-top: -30px;
    margin-left: -20px;
}

/* Bounce */
.bounce {
    .animation(bounce, 2, 2s);
}

@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        .transform(translateY(0));
    }

    40% {
        .transform(translateY(-40px));
    }

    60% {
        .transform(translateY(-25px));
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        .transform(translateY(0));
    }

    40% {
        .transform(translateY(-40px));
    }

    60% {
        .transform(translateY(-25px));
    }
}
