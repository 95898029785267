/*==========  Customer praise - widget  ==========*/

/* Pohvale */
.quotes {
    margin-top: 20px;
    margin-bottom: 5px;

    .item {
        position: relative;
    }

    .author {
        display: inline-block;

        small {
            display: block;
            margin-top: 10px;
            font-size: @font-size-smaller;
        }
    }

    .review {
        display: inline-block;
        float: right;
        text-align: right;

        small {
            display: block;
            margin-top: 10px;
            font-size: @font-size-smaller;
            color: #fff;
        }
    }

    .icon-stars::before {
        color: @color-visible;
    }
}

/* Pohvale sidebar */
.quote-cont {
    position: relative;
    min-height: 115px;
    padding: 10px 15px;
    border: 3px solid #fff;
    border-radius: @box-border-radius;
    color: #fff;

    &::after {
        position: absolute;
        bottom: -18px;
        left: 10%;
        margin-left: 3px;
        content: " ";
        border: solid;
        border-width: 20px 20px 0 0;
        border-color: @color-primary transparent transparent;
    }

    &::before {
        position: absolute;
        bottom: -26px;
        left: 10%;
        content: " ";
        border: solid;
        border-width: 26px 26px 0 0;
        border-color: #fff transparent transparent;
    }

    .icon-quote-alt {
        margin-right: 5px;
        font-size: 22px;
        color: @color-visible;
    }
}
