.no-break {
    white-space: nowrap;
}

.text-upper {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

/* Font size */
.f-small {
    font-size: @font-size-small;
}

.f-smaller {
    font-size: @font-size-smaller;
}

.f-bigger {
    font-size: @font-size-bigger;
}

.f-evenbigger {
    font-size: @font-size-evenbigger;
    line-height:2.4rem;
}

.f-big {
    font-size: @font-size-big;
    line-height: 1.2em;
}

.f-biggest {
    font-size: @font-size-biggest;
}

.f-huge {
    font-size: @font-size-huge;
}

.f-massive {
    font-size: @font-size-massive;
}

.f-size-15 {
    font-size: @font-size-15;
}

.f-size-16 {
    font-size: @font-size-16;
}

.f-size-17 {
    font-size: @font-size-17;
}

.fs12 {
    font-size: 12px;
}

.fs14 {
    font-size: 14px !important;
}

.fs17 {
    font-size: 17px;
}

.fs25 {
    font-size: 25px;
}

/* Line height */
.lh-15 {
    line-height: 1.5em;
}

.lh-2 {
    line-height: 2em;
}

.lh-3 {
    line-height: 3em;
}

/* Text weight */
.weight-light {
    font-weight: 300;
}

.weight-normal,
a.weight-normal {
    font-weight: normal;

    &.h3,
    &.h2,
    &.h1 {
        font-size: 1.02em;
    }
}

.weight-bold {
    font-weight: bold;
}

/* Links */
.link {
    color: @color-primary;
    cursor: pointer;
    text-decoration: underline;
}

.link-icon {
    text-decoration: none;

    .text-back {
        text-decoration: underline;
    }

    .icon-carret-left + .text-back,
    .text-back + .icon-carret-left {
        padding-left: 8px;
    }
}

a.inv-link {
    text-decoration: none;
    color: @color-darker-grey;

    &:hover {
        text-decoration: underline;
    }
}

a.no-under {
    text-decoration: none;
}

/* Underline */
.xtra-line {
    position: relative;

    &::after {
        position: absolute;
        bottom: -6px;
        left: -5%;
        width: 110%;
        height: 4px;
        content: ' ';
        background: url('../img/cms/underline_yellow.png');
    }

    &.yellow {
        color: @color-visible;
    }

    &.orange {
        &::after {
            background: url('../img/cms/underline_orange.png');
        }
    }

    &.cpanel {
        &::after {
            background: url('../img/cms/underline_cpanel.png');
        }
    }

    &.red {
        &::after {
            background: url('../img/cms/underline_red.png');
        }
    }

    &.green {
        &::after {
            background: url('../img/cms/underline_green.png');
        }
    }


    &.red,
    &.yellow,
    &.orange,
    &.green,
    & {
        &::after {
            background-size: cover;
        }
    }
}

.xtra-line-short {
    position: relative;

    &::after {
        position: absolute;
        bottom: -6px;
        left: -3%;
        width: 105%;
        height: 4px;
        content: ' ';
        background: url('../img/cms/underline_yellow.png');
    }

    &.yellow {
        color: @color-visible;
    }
}

.i-infin {
    font-size: 1.5em;
    line-height: 0;
    position: relative;
    top: 2px;
}
