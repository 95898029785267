.promo-boxes {
    //margin-bottom: 40px;

    /* Flexbox semi-support fix */
    & > .grdcell {
        min-width: 210px;
    }

    .promotion {
        .clearfix();

        display: block;
        min-width: 150px;
        margin: 0 auto;
        padding: 5px;
        position: relative;
        text-decoration: none;

        border-radius: @button-border-radius;

        &.bg-primary,
        &.bg-secondary {
            .old-price::after {
                .box-shadow(1px 0 0 1px #fff inset);
            }
        }
        &.frontP{
            background: rgb(134,185,200); background: linear-gradient(135deg, rgba(134,185,200,1) 0%, rgba(95,168,189,1) 100%);
            img{
                display:block; margin-left:auto; margin-right:auto;
            }
            .old-price{
                color:@color-darkest-grey;
            }
            .new-price{
                color: @color-lighter-yellow;
            }
            .ribbon-diag.sm{

                .text{
                    color:@color-darkest-grey;

                    background-color: #fff;
                }
                @media(min-width:@break-lg-value){
                    top:-6px;
                    right: -8px;
                    .text{
                        font-size: .85rem;
                    }
                }
            }
        }
    }

    &.high-boxes {
        .promotion {
            min-height: 137px;
        }

        .ribbon-diag {
            .text {
                font-size: 14px;
                padding: 10px 0;
                text-align: center;
                top: 13px;
                right: -38px;
                height: 32px;
                opacity: 65%;
            }
        }
    }

    a.bg-primary,
    a.bg-secondary,
    a:visited.bg-primary,
    a:visited.bg-secondary {
        color: #fff;
    }

    a.bg-grey,
    a:visited.bg-grey,
    a.bg-secondary-alt,
    a:visited.bg-secondary-alt {
        color: @color-fake-black;
    }

    .item {
        margin: 20px 20px 10px;

        .rubik {
            font-size: 0.95em;
        }

        &.small {
            margin: 15px 20px 10px;

            & + img.align-right {
                padding-left: 10px;
                padding-bottom: 0;
            }
        }
    }

    .domain {
        .item {
            &:extend(.rubik);

            margin: 25px 0 10px -5px;
            font-size: 32px;
            text-align: center;
            line-height: 1em;
        }
    }

    .prices {
        margin: 0 0 15px;
        text-align: center;

        & > div {
            display: inline-block;
        }
    }

    .old-price {
        margin-right: 10px;
        font-size: 17px;
        /* Skrijmo prečrtane cene */
        display: none !important;
    }

    .new-price {
        font-size: 20px;

    }

    &.front-promotion {
        .promotion {
            max-width: inherit;
            padding: 0px 0;
            min-height: 114px;
        }

        .ribbon-diag {
            .text {
                height: 30px;
                padding: 10px 30px;
                font-size: 13px;
                top: 16px;
                right: -36px;
            }
        }
    }

    &.v2 {

        margin-top: -10px;
        margin-bottom: 30px;

        .grdcell {
            min-width:170px;
        }
        .promotion {
            border:2px solid #fff;
            background-color:rgba(255,255,255, 0.15);
            min-height: 160px;
            box-shadow: 0 -5px 10px rgba(0,0,0,2%), 0 12px 24px -16px rgba(0,0,0,10%), 0 16px 32px -8px rgba(0,0,0,10%);
            padding:0;

            &:hover {
                background-color:rgba(255,255,255, 0.25);

                .flag {
                    opacity: 0.2;
                }
            }

            .ribbon-diag .text {
                font-size: .87rem;
            }

            .flag {
                position: absolute;
                left:0;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                background-size: 113%;
                z-index: 1;

            }

            .item {
                margin: 0px 0 5px 0;
                &.si-tld {
                    img {
                        /*max-height: 90px;*/
                    }
                }
            }
            .prices {
                position:absolute;
                width: 100%;
                left: 50%;
                bottom:10px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                margin: 0 0 3px 0;
                z-index:500;
            }
        }
    }
    &.dark {
        .promotion {
            border:2px solid @color-light-grey;
        }
    }
}

.dotted-line {
    position: relative;
    top: 50%;
    left: 0;
    margin: 0 -20px;

    .dotted {
        position: absolute;
        left: 0;
        top: 40%;
    }
}

.spr-why-lock {
    position: relative;
    z-index: 1;
}

#domain-check-wrapper + .v2 {
    margin-top: 10px;
}

.tld-font {
    &:extend(.rubik800);
    width:100%;
    color:#000;
    font-size:65px;
    line-height: 2.3;
    position:relative;

    span {
        color: @color-secondary-alt;
    }

}

/**
 *
 * Domain promotions slider
 *
 */
.domain-promo-slider {
    position: relative;
    width: 100%;
    max-width: 860px;
    max-height: 90px;  /* Prevent reflow/redraw */
    margin: 10px auto 40px;
    padding: 0 55px;
    // Za lepši loading
    overflow: hidden;

    [class^='spr-tld-'] {
        display: inline-block;
    }

    .d-name {
        padding-bottom: 15px;

        &:visited {
            text-decoration: none;
        }
    }

    .carrousel-controls {
        margin-top: -10px;
    }

    .slick-list {
        max-height: 90px;
    }

    .slick-prev,
    .slick-next {
        &::before {
            font-size: 30px;
        }
    }

    .slick-next {
        right: -45px;
    }

    .slick-prev {
        left: -45px;
    }

    .slides {
        opacity: 0;
        transition: opacity 500ms;

        &.slick-initialized {
            opacity: 1;
        }
    }
}

.price-reminder {
    color: @color-darker-grey;
    font-size: 13px;
    text-align: center;
    margin: 10px 0 35px;

    .text {
        margin: 0;
    }

    hr {
        margin-bottom: 10px;
    }

    &.light {
        margin: -15px 0 25px;
        color: rgba(255, 255, 255, 0.8);

        a {
            color: rgba(255, 255, 255, 0.8);
            border-color: rgba(255, 255, 255, 0.7);
        }
    }
}

.tld-title {
    color: @color-fake-black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.domain-promo-box {
    display: block;
    height: 90px;
    position: relative;
    margin: 0;
    min-width: 134px;
    padding: 0 18px; // ribbon + spacing
    overflow: hidden;

    /* V tem primeru bi bilo mogoče bolje posamezne logotipe spraviti na iste velikosti (62x32?) */
    [class^='spr-tld-'] {
        margin-left: 0;
        margin-top: 0px;
    }

    .spr-tld-si {
        margin-top: -10px;
    }

    .spr-tld-org {
        margin-top: -3px;
        margin-left: 4%;
    }

    .prices {
        text-align: left;
        margin: 8px 10px 10px;
    }

    .old-price {
        color: #000;
        font-size: 12px;
        margin-top: 3px;
    }

    .new-price {
        color: @color-secondary-alt;
    }

    .one-price {
        [class^='spr-tld-'] {
            margin-top: 5px;
        }

        .spr-tld-com {
            margin-top: 8px;
        }

        .spr-tld-eu {
            margin-top: 5px;
        }

        .spr-tld-net {
            margin-top: 5px;
        }

        .spr-tld-blog {
            margin-top: 3px;
        }
    }
}

.mini-promo-box {
    position: relative;
    display: block;
    //float: left;
    width: 100%;
    min-width: 115px;
    //margin: 0 5px;
    min-height: 90px;
    background-color: white;
    text-decoration: none;

    a&:visited {
        text-decoration: none;
    }
}

.domain-promo-ribbon {
    background-color: @color-secondary-alt;
    box-sizing: border-box;
    color: #fff;
    position: absolute;
    top: 5px;
    right: -6px;
    padding: 5px 9px 5px 8px;
    font-size: 12px;
    line-height: 11px;
}

.domain-promo-ribbon::after {
    border-color: #6d3b0f transparent transparent #6d3b0f;
    border-style: solid;
    border-width: 3px;
    bottom: -5px;
    content: "";
    position: absolute;
    right: 0;
    height: 0;
}

.promo-slider-subpage {
    .mini-promo-box {
        border: 2px solid #ccc;
    }

    .d-name {
        padding-bottom: 0;
    }
}
