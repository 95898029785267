
/* ==========  hosting-features  ========== */
.hosting-features {
    border-top: 1px solid @color-grey;
    min-height: 400px;
    overflow: hidden; // DELL monitor bugfix
    padding-top: 30px;
    position: relative;
    text-align: center;

    &::before {
        &:extend(.arrow-up-100:before);

        left: 34%;
        margin-bottom: -2px;
        border-color: transparent;
        background: url('../img/cms/wide_arrow_up.svg') no-repeat 50% 0%;
    }

    &::after {
        &:extend(.arrow-up-100:before);

        background: url('../img/cms/wide_arrow_up.svg') no-repeat 50% 0%;
        border-color: transparent;
        left: 64%;
        margin-bottom: -2px;
    }

    .left-img {
        left: -210px;
        margin-top: 40px;
        position: relative;
    }

    .right-img {
        position: absolute;
        right: -210px;
        top: 160px;
        z-index: -1;
    }

    .written {
        bottom: 80px;
        font-size: 17px;
        padding: 35px 0 0 40px;
        position: absolute;
        right: -10px;
        z-index: 1;

        img {
            left: 0;
            position: absolute;
            top: 68px;
        }

        span {
            left: -50px;
            position: relative;
            top: 44px;
        }
    }

    .main-text {
        .center-block();

        max-width: 450px;
        text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
    }

    .options {
        margin-top: 40px;

        .option-btn {
            max-width: 135px;
        }
    }

    .fast-servers,
    .optimized-software {
        position: relative;
    }
}

/* ==========  Latest blog posts - widget  ========== */
.latest-widgets {
    margin-bottom: 40px;

    .grdcell {
        flex: 1;  /* Ensures each .grdcell takes equal space horizontally */
        display: flex;
        flex-direction: column;  /* Arrange children vertically */
        justify-content: space-between;
    }

}

.widget-desc {
    display: block;
    text-align: center;
}

.widget-holder {
    min-width: 280px;
    flex-grow: 1;
    border-bottom: 1px solid #ccc;
    h3 {
        margin-bottom: 5px;
        text-align: center;
    }

    .cnt-hld {
        border-top: 1px solid #ccc;
        padding: 20px 0 15px;
        margin: 15px 0;

        li:last-of-type {
            margin-bottom: 0;
        }
    }

    & + a.btn-blue {
        display: block;
        margin: 0 auto;
        width: 150px;
        text-align: center;

        align-self: flex-start;  /* Keep the button aligned consistently if it's smaller than the container */
        margin-top: 20px;

        &.btn-wide {
            display: inline-block;
            width: auto;
        }
    }

    &.text-left {
        & + a.btn-blue,
        .widget-desc,
        h3 {
            text-align: left;
            margin-top: 2px;
        }
    }
}

.latest-guides,
.blog-list {
    .item {
        margin-bottom: 15px;
        min-height: 50px;
    }

    .guides-list li {
        margin: 0 0 15px;
    }
}

.blog-list {
    a {
        display: block;
        text-decoration: none;
    }

    .item {
        position: relative;
        padding-left: 35px;
    }

    .published {
        color: @color-darker-grey;
    }

    .icon-comment {
        position: absolute;
        left: 0;
        font-size: 26px;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        color: @color-grey;
    }
}

/* ==========  Latest info - widget  ========== */
.info-list {
    &:extend(.grd);

    align-items: center;

    .item {
        &:extend(.grd);
        &:extend(.grdcell);

        align-items: center;
        flex: 0 0 auto;
        justify-content: center;
        margin: 0 auto 12px;
        min-height: 40px;
    }

    .main-num,
    .icon,
    .description {
        &:extend(.grdcell);
    }

    .main-num {
        flex-grow: 1.1;
        font-size: 25px;
        display: inline-block;
        min-width: 90px;
        max-width: 170px;
        text-align: right;
    }

    .icon {
        flex-grow: 0.5;
        font-size: 38px;
        color: #ccc;
        min-width: 60px;
        max-width: 80px;
        text-align: center;
        display: inline-block;
    }

    .description {
        flex-grow: 2;
        max-width: 180px;
        min-width: 170px;
        display: inline-block;
        font-size: 12px;
    }
}

/* ----------  Domain upsell  ---------- */
.front-domains-upsell {

    /* Flexbox semi-support fix */
    & > .grdcell {
        min-width: 140px;
        margin-top: 0.86rem;
        padding: 0.5rem 0 0 2.86rem;
        &:first-of-type{
            margin-top: 2.86rem;
            padding: 2.86rem 0 0 2.86rem;
        }

        @media(min-width:@break-md-value){
            margin-top: 2.86rem;
            padding: 2.86rem 0 0 2.86rem;
        }
    }

    .sprite-holder {
        margin-top: 5px;
        height: 50px;
        margin-bottom: 5px;
    }

    .upsell-box {
        padding: 15px 10px 20px;
        border-color: transparent;
        margin-bottom: 0;
        min-width: 105px;
        text-align: center;

        &:hover {
            border: 1px solid @color-lighter-grey;
        }
    }
}

.front-h1 {
    margin: 30px 0 20px;

    h1 {
        margin-bottom: 10px;
    }
}

/* ------------ Front - reviews ------------ */
.review-stats > .stats-holder {
    margin: 12px 20px 10px;
    min-width: 200px;
    text-align: center;
}

.review-count {
    font-size: @font-size-massive;
}

.customer-praise {
    .grd {
        align-items: center;
    }

    .container {
        padding: 10px 20px;
    }

    .video-quotes,
    .quotes {
        flex-grow: 1.8;
    }

    .quote-cont {
        min-width: 160px;
    }

    .quotes {
        .grdcell {
            min-width: 200px;
        }
    }
}

.video-quotes {
    &:extend(.quotes);

    margin-bottom: 15px;


    .grdcell {
        min-width: 215px;
    }

    .item {
        color: #fff;
        display: block;
        min-width: 110px;
        outline: none;
        text-align: center;
        text-decoration: none;

        &:focus {
            outline: none;
        }
    }
}

.review-image {
    display: inline-block;
    margin-bottom: 5px;
    max-width: 135px;
    min-width: 120px;
    padding: 5px;
    position: relative;

    .author-img {
        .border-radius(200px);
    }

    .icon-carret-right {
        position: absolute;
        bottom: 5px;
        left: 5px;
        display: inline-block;
        padding: 11px;
        width: 38px;
        height: 38px;
        border-radius: 50px;
        font-size: 18px;
        background-color: @color-primary;
    }
}

.bg-primary-black {
    background: @color-fake-black;
    background: -moz-linear-gradient(-45deg, @color-primary 50%, @color-fake-black 50%);
    background: -webkit-linear-gradient(-45deg, @color-primary 50%, @color-fake-black 50%);
    background: linear-gradient(135deg, @color-primary 50%, @color-fake-black 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@color-primary', endColorstr='@color-fake-black', GradientType = 1);
}
