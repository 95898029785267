
.sm-grd-show {
    display: none !important;
    visibility: hidden;
}

.md-grd-show {
    display: none !important;
    visibility: hidden;
}

@media @break-sm {
    .sm-negative-m {
        margin-left: -20px;
        margin-right: -20px;
    }

    .sm-txt-cntr {
        text-align: center;
    }

    .sm-grd-hide {
        display: none !important;
        visibility: hidden;
    }

    .sm-grd-show {
        display: inherit !important;
        display: initial !important;
        visibility: visible;
    }

    .sm-grd-nowrap {
        flex-wrap: nowrap;
    }
}

@media @break-md {
    .md-negative-m {
        margin-left: -20px;
        margin-right: -20px;
    }

    .md-grd-hide {
        display: none !important;
        visibility: hidden;
    }

    .md-grd-show {
        display: inherit !important;
        display: initial !important;
        visibility: visible;
    }

    .md-grd-nowrap {
        flex-wrap: nowrap;
    }
}

@media @break-lg {
    .md-grd-hide {
        display: inherit !important;
        display: initial !important;
        visibility: visible;
    }

    .lg-grd-hide {
        display: none !important;
        visibility: hidden;
    }

    .lg-grd-nowrap {
        flex-wrap: nowrap;
    }
}

@media @break-xlg {
    .md-grd-hide {
        display: inherit !important;
        display: initial !important;
        visibility: visible;
    }

    .lg-grd-hide {
        display: inherit !important;
        display: initial !important;
        visibility: visible;
    }

    .xlg-grd-hide {
        display: none !important;
        visibility: hidden;
    }

    .xlg-grd-nowrap {
        flex-wrap: nowrap;
    }
}

@media @break-max {
    .md-grd-hide-mobile {
        display: none !important;
    }
}

@media @break-min-xlg {
    .md-grd-hide {
        display: inherit !important;
        display: initial !important;
        visibility: visible;
    }

    .xlg-grd-hide {
        display: none !important;
        visibility: hidden;
    }

    .xxlg-grd-nowrap {
        flex-wrap: nowrap;
    }
}

.grd--row {
    flex-direction: row;
}

.grd--col {
    flex-direction: column;
}

.grdcell--autosize {
    flex: none !important;
}

.grdcell--full {
    flex: 0 0 100% !important;
    flex-basis: 100%;
}

.grd-grdcell--min100 {
    .grdcell {
        min-width: 100px;
    }
}

.grd-grdcell--min160 {
    .grdcell {
        min-width: 160px;
    }
}








