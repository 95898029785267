.hr-domain-promo {
    margin-top: 40px;

    .banner-title {
        font-weight: normal;
    }

    .extra-visible {
        font-size: 1.7em;
        white-space: nowrap;
    }

    .bg-holder {
        background: url(../img/cms/domains/promo/brezplacna-hr-domena.jpg) no-repeat;
        background-size: cover;
        display: flex;
        padding: 10px;
    }

    .banner-title {
        align-self: center;
        margin: 0 20px 0 30px;
    }

    .banner-img {
        align-self: flex-end;
        margin-bottom: -10px;
    }

    .promo-explained {
        margin: 0;
        border: 1px solid @color-grey;
        border-top: 0;
        font-size: @font-size-smaller;
        padding: 10px 15px;
    }
}

@media @break-lg {
    .hr-domain-promo {
        .extra-visible {
            white-space: wrap;
        }
    }
}

@media @break-md {
    .hr-domain-promo {
        .banner-title {
            margin: 0 10px 0 15px;
        }
    }
}

@media @break-sm {
    .hr-domain-promo {
        text-align: center;

        .extra-visible {
            white-space: wrap;
        }

        .banner-img {
            margin: 10px auto -10px;
        }

        .bg-holder {
            flex-wrap: wrap;
        }

        .banner-title {
            margin: 0 10px 0 15px;
        }
    }
}
