@media (max-width: @break-xlg-value) {
    .domain-categories {
        .extra-title {
            display: none;
        }

        .flat-tabs {
            padding-bottom: 10px;

            li {
            }

            a {
                width: 100%;
            }
        }
    }

    .inline-domain-check {
        .tabs {
            margin: 20px 0 0;
        }
    }
}

@media @break-lg {
    .inline-domain-check {
        .domain-check {
            min-width: auto;
            max-width: 440px;
        }
    }
}

@media (max-width: @break-md-value) {
    .inline-domain-check {

        .domain-check {
            min-width: inherit;
            margin: 0 auto;

        }

        .input-button {

            input {
                padding-right:10px;
            }

            .button-holder {
                position: relative;

                .btn-green {
                    margin-top: 10px;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    border-top-left-radius: @box-border-radius;
                    border-bottom-left-radius: @box-border-radius;
                }
            }
        }
    }
    .domain-promoted {
        .domain-status {
            .corner-ribbon {
               visibility:hidden;
            }
        }
    }
    .checkout-content {
        .domain-query {
            margin-top: 0;

            li {
                padding: 0;

                &.stand-out {
                    background: transparent;

                    .placeholder-domain {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media @break-sm {


    .page-title {
        & + #domain-check-wrapper {
            .inline-domain-check {
                margin-bottom: 25px;
            }
        }
    }
    #domain-check-wrapper {
        &.front {
            .container {
                padding: 0;
            }
        }
    }

    .domain-check {
        .button-holder {
            .btn-green {
                width: 100%;
                max-width: none;
            }
        }
        .input-button {
            input {
                min-width: 260px;
                padding-left: 90px;
            }
        }
    }

    .inline-domain-check {
        &.standalone {
            .input-button {
                input {
                    min-width: 230px;
                }
            }
        }
    }

    .domain-results {
        margin-top:20px;
        padding-top: 0;
    }

    .www-prefix {
        width: 65px;

        .prefix {
        }

        &::after {
            left: 65px;
        }
    }

    .checkout-content {
        .domain-query {
            li {
                padding: 0;
            }
        }
    }

    .inline-domain-check {
        .simple-tabs {
            flex-wrap: wrap;

            li {
                flex: 1 0 100%;
            }
        }
    }

    .domain-categories {
        .extra-title {
            display: inline;
        }
    }

    .domain-query {
        // Na mobitelu naj bo cena domene vedno v spodnji vrstici
        .domain-status {
            width: 100%;
        }

        .domain-promoted {
            .domain-status {
                .corner-ribbon {
                    visibility:visible;
                }
            }
        }

        // Leva poravnava cene
        .domain-price {
            margin: 0 0 10px;
        }


        .domain-promoted {
            .domain-status {
                position: relative;
                .corner-ribbon {
                    right: -60px;

                    .text {
                        padding: 9px 41px;
                    }
                }
            }
        }
    }

    .domain-results {

        .to-order {
            margin-right: 0;
            float:none;
        }
        .domain-check-footer {
            padding: 0 20px;
            text-align: center;
            .check-other {
                border: none;
                margin: 0 0 10px 0;
                font-size: 1.1rem;
            }
        }
    }

    .bottom-menu {
        .domain-results {
            margin: 10px -20px 20px;
        }

        .inline-domain-check.full {
            margin: 0 -20px;
        }
    }
}

// 415px
@media @break-xs {

    .domain-results:not(.checkout-content .domain-results) {
        background-color: #fff !important;
        .container {
            padding: 0;
        }
    }

    // Manjšanje elementov - problem predvsem na front strani
    .domain-check {
        .input-button {
            input {
                padding-left: 95px;
                min-width: 220px;
                font-size: 20px;
            }
        }
    }

    #domain-results {

        .domain-query {
            li {
                border-bottom:2px solid @color-light-grey;
                margin-bottom:0;
                padding: 0 10px;
            }
            .domain-actions {
                max-width:180px;
                font-size:.85rem;
            }

            .domain-check-footer .check-other {
                margin: 0 20px;
                font-size:13px;
            }

            .domain-price {
                .new-price {
                    font-size: 1.5rem;
                }
                br {
                    display:none;
                }
                .old-price {
                    display:none;
                }
            }

            .domain-promoted {
                .domain-status {
                    position: relative;
                    .corner-ribbon {
                        position: absolute;
                        top: -10px;
                        right: -15px;
                        width: 80px;
                        height: 25px;
                        z-index: 1;

                        .text {
                            position: static;
                            top: 0px;
                            right: 0px;
                            display: block;
                            width: 80px;
                            height: 23px;
                            padding: 6px 16px;
                            line-height: 12px;
                            font-size: @font-size-small;
                            color: #999999;
                            background-color: #eff537;
                            -webkit-transform: rotate(0deg);
                            -moz-transform: rotate(0deg);
                            -ms-transform: rotate(0deg);
                            -o-transform: rotate(0deg);
                        }
                    }
                }
            }

        }
    }

    .inline-domain-check {
        &.full {
            padding: 10px;
        }
        &.large {
            .input-button input {
                font-size:25px;
            }
        }
    }

    .www-prefix {
        width: 60px;

        &::after {
            left: 60px;
        }

        .prefix {
            font-size: 1.24rem;
        }
    }

    // Underline se striže s spodnjo vrstico
    .page-title .title-description {
        line-height: 1.6em;
    }
}

@media (max-width: 650px) {
    .domain-query {
        .domain-actions {
            margin-top: -10px;
        }
    }
}

@media (max-width: 340px) {

    .domain-query {
        // Na mobitelu naj se gumb "Dodaj v košarico" razširi na 100%
        .domain-actions {
            max-width: none;
            padding-left: 0;
        }
    }
}
