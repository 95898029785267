.contact-page {
    h3.rubik {
        margin-bottom: 8px;

        & + span {
            display: inline-block;
            padding-bottom: 5px;
        }
    }

    .div-table {
        line-height: 1.6em;
    }

    .mt20 {
        margin-top: 20px;
    }
}

.indent-icon,
.indent-text {
    display: inline-block;
    vertical-align: top;
}

.indent-icon {
    text-align: center;
    max-width: 52px;

    i {
        display: inline-block;
        font-size: 26px;
        padding-top: 3px;
    }
}

.contact-page,
.whyus-affiliate,
#review-form {
    .email {
        margin-top: 10px;
    }

    .phone {
        margin-top: 25px;
    }

    .email,
    .phone {
        display: flex;
        min-width: 265px;

        a {
            padding: 11px 0 8px;
            font-size: 17px;
            font-weight: bold;
        }

        span {
            color: @color-darker-grey;
        }
    }

    .ok,
    .generalerror,
    .invalid-email-error {
        width: 100%;
        margin: 30px 0 0;

        i {
            font-size: 60px;
            color: @color-secondary;
            float: left;
            margin-right: 20px;
        }
    }

    .generalerror,
    .invalid-email-error {
        i {
            color: @color-red;
            margin-bottom: 5px;
        }
    }
}

.contact-info {
    .indent-icon {
        margin-right: 10px;

        & + .text {
            display: inline-block;
        }

        .icon-email,
        .icon-phone {
            display: inline-block;
            margin-top: 1px;
            padding: 4px;
            color: @color-primary;
            border: 2px solid;
            border-radius: 80px;
            font-size: 28px;
        }
    }

    .indent-text {
        align-self: flex-start;
        color: @color-darker-grey;
        margin-right: 5px;
        max-width: 120px;
    }

    .text {
        min-width: 150px;
    }
}

.contact-info-wrapper {
    flex-grow: 2;
}

.social-col {
    border: 1px solid @color-grey;
    padding: 15px;

    .indent-icon {
        width: auto;
        margin-left: -3px;
        margin-right: 5px;
    }

    .text {
        display: inline-block;
        padding-left: 2px;
        max-width: 294px;
        width: 100%;
        font-size: 12px;
    }
}

