/* Zakaj izbrati nas */
/* Free transfer */

.why-us-transfer-visual {
    padding: 0 20px;
    position: relative;
    margin-bottom: 40px;

    h2 {
        margin-top: 30px;
        margin-bottom: 5px;
    }

    .pros-list {
        bottom: 60px;
        margin-left: 27px;

        .ok {
            margin-right: 5px;
        }
    }

    .promo-diag {
        background-color: #f3831f;
        color: white;
        display: inline-block;
        font-size: 1.215rem;
        transform: rotate(-15deg);
        right: -55%;
        padding: 11px 15px 9px;
        position: relative;
        top: -22px;
    }

    .ok {
        font-size: 32px;
        margin-left: -5px;
    }
}

.transfer-count {
    min-width: 265px;
    max-width: 290px;
    padding: 20px;
}

.counter {
    padding-top: 10px;
    margin-left: auto;

    .clock {
        float: left;
    }

    strong {
        float: left;
        padding: 17px 0 0 12px;
    }
}

.office {
    position: relative;
    margin-bottom: 5px;
    min-width: 265px;

    .thumbs-up {
        z-index: 2;
        position: relative;
        margin-left: -120px;
        left: 50%;
        margin-top: -18%;
    }

    .desk {
        background: url(../img/cms/why-us/wood-tile.png);
        height: 41px;
        margin-top: -35px;
        width: 100%;
    }
}

.transfer-domains,
.transfer-hosting {
    min-width: 210px;

    .buttons-holder {
        margin-bottom: 0;
    }

    .bigger-grdcell {
        flex-grow: 2;
    }
}

@media @break-sm {
    .why-us-transfer-visual {
        .promo-diag {
            transform: rotate(-5deg);
            right: -5%;
            top: -12px;
            margin-bottom: 20px;
        }
    }

    .counter strong {
        padding-left: 0;
    }

    .transfer-domains {
        .btn-big {
            width: 100%;
        }

    }
}
