/* colors */
.c-primary,
a.c-primary {
    color: @color-primary;
}

.c-secondary,
a.c-secondary {
    color: @color-secondary;
}

.c-secondary-alt,
a.c-secondary-alt {
    color: @color-secondary-alt;
}

.c-darken-grey, a.c-darken-grey{
    color:@color-darken-grey;
}

.c-drk-grey,
a.c-drk-grey {
    color: @color-dark-grey;
}

.c-dgry,
a.c-dgry,
.c-dgrey {
    color: @color-darker-grey;
}

.c-grey {
    color: @color-grey;
}

.c-ltgrey {
    color: @color-lightest-grey;
}

.c-lgrey {
    color: @color-lighter-grey;
}

.c-lghtgrey {
    color: @color-light-grey;
}

.c-blck {
    color: @color-fake-black;
}

.c-ltblck {
    color: @color-fake-black-light;
}

.c-blck-o {
    color: @color-black;
}

.c-wht {
    color: #fff !important;
}

.c-yellow {
    color: @color-yellow;

    &::after {
        &:extend(.c-yellow);
    }

    &:visited {
        color: @color-yellow;
    }
}

.c-cpanel,
a.c-cpanel {
    color: @color-brand-cpanel;
}

.c-blue,
a.c-blue {
    color: @color-primary;
}

.c-red,
a.c-red {
    color: @color-red;
}

/* background colors */
.bg-primary {
    background-color: @color-primary;
    color: #fff;
    > a {
        color: #fff;
    }
    p {
        a {
            color: #fff;
        }
    }
}

.bg-secondary {
    background-color: @color-secondary;
    color: #fff;
    a {
        color: #fff;
    }
}

.bg-secondary-alt {
    background-color: @color-secondary-alt;
    a {
        color: #fff;
    }
}

.bg-ltgrey {
    background-color: @color-lightest-grey;
    color: @color-black;
}

.bg-lgrey {
    background-color: @color-light-grey;
    color: @color-black;
}

.bg-lghtgrey {
    background-color: @color-lighter-grey;
    color: @color-black;
}

.bg-bgrey {
    background-color: @color-bluish-grey;
    color: @color-black;
}

.bg-grey {
    background-color: @color-grey;
    color: @color-black;
}

.bg-dgrey {
    background-color: @color-darker-grey;
    color: #fff;
    > a {
        color: #fff;
    }
}

.bg-white {
    background-color: #fff;
    color: @color-fake-black;
}

.bg-dark {
    background-color: @color-fake-black;
    color: #fff;
    > a {
        color: #fff;
    }
}

.bg-cpanel {
    background-color: @color-brand-cpanel;
}

.bg-light-yellow {
    background-color: @color-light-yellow;
}

.bg-lighter-yellow {
    background-color: @color-lighter-yellow;
}

.bg-yellow {
    background-color: @color-yellow;
}

.bg-red {
    background-color: @color-red;
}

.bg-black {
    background-color: @color-black;
}

.bg-gradient {
    &.bg-primary {
        .gradient-radial(@color-primary, @color-primary-light, 0%, 60%);
    }

    &.bg-secondary {
        .gradient-radial(@color-secondary, @color-secondary-light, 0%, 60%);
    }

    &.bg-secondary-alt {
        .gradient-radial(@color-secondary-alt, @color-secondary-alt-light, 0%, 60%);
    }

    &.bg-dark {
        .gradient-radial(@color-fake-black, @color-fake-black-light, 0%, 60%);
    }
}

/* Additional */
.colored-bg {
    color: #fff;

    a {
        color: #fff;
        border-bottom: 1px solid #fff;
        text-decoration: none;

        &.no-under {
            border: 0;
        }
    }

    .checkbox label::before,
    .radio label::before {
        border-color: #fff;
    }
}
