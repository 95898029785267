@media @break-sm {
    .loading-compare {
        margin: 0;

        &.small {
            .left-side,
            .right-side {
                width: 100%;
            }
        }

        .left-side {
            height: auto;
            text-align: left;
        }
    }
}
