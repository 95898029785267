@media only screen and (max-width: 1220px) {
   .package-list2 {
       .carousel-wrapper {
           width: 490px;

           .tns-controls {

               .prev {
                   left:-60px;
               }
               .next {
                   right:-60px;
               }
           }
       }
   }
}

@media only screen and (max-width: 900px) {
    .package-list2 {
        .carousel-wrapper {
            width: 300px;
            .tns-controls {
                .prev {
                    left:-60px;
                }
                .next {
                    right:-60px;
                }
            }
        }
    }
}

/* hosting type specific classes */
@media only screen and (max-width: 560px) {
    .mail-packages {
        .tabs {
            li {
                a {
                    padding: 4px 16px;
                }
            }
        }
    }
}
@media only screen and (max-width: 520px) {
    .mail-packages {
        .tabs {
            li {
                a {
                    padding: 4px 14px;
                }
            }
        }
    }
}
@media only screen and (max-width: 410px) {
    .mail-packages {
        .tabs {
            li {
                a {
                    padding: 4px 12px;
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .mail-packages {
        .tabs {
            li {
                a {
                    padding: 4px 8px;
                }
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    .package-list2 {
        .carousel-wrapper {
            .tns-controls {
                display:none;
            }
        }
    }
}

/* vps packages */
@media only screen and (max-width: 560px) {
    .vps-packages {
        .tabs {
            li {
                a {
                    padding: 4px 12px;
                }
            }
        }
    }
}
@media only screen and (max-width: 520px) {
    .vps-packages {
        .tabs {
            li {
                a {
                    padding: 4px 10px;
                }
            }
        }
    }
}
@media only screen and (max-width: 460px) {
    .vps-packages {
        .tabs {
            li {
                a {
                    font-size:15px;
                    padding: 4px 10px;
                }
            }
        }
    }
}
@media only screen and (max-width: 410px) {
    .vps-packages {
        .tabs {
            li {
                a {
                    font-size:14px;
                    padding: 4px 8px;
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .vps-packages {
        .tabs {
            li {
                a {
                    font-size:13px;
                    padding: 4px 6px;
                }
            }
        }
    }
    .package-list2 {
        .carousel-wrapper {
            .tns-controls {
                display:none;
            }
        }
    }
}


/* shared packages */
@media only screen and (max-width: 560px) {
    .shared-packages {
        .tabs {
            li {
                a {
                    padding: 4px 12px;
                }
            }
        }
    }
}
@media only screen and (max-width: 520px) {
    .shared-packages {
        .tabs {
            li {
                a {
                    padding: 4px 10px;
                }
            }
        }
    }
}
@media only screen and (max-width: 460px) {
    .shared-packages {
        .tabs {
            li {
                a {
                    font-size:15px;
                    padding: 4px 10px;
                }
            }
        }
    }
}
@media only screen and (max-width: 410px) {
    .shared-packages {
        .tabs {
            li {
                a {
                    font-size:14px;
                    padding: 4px 8px;
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .shared-packages {
        .tabs {
            li {
                a {
                    font-size:13px;
                    padding: 4px 6px;
                }
            }
        }
    }
}
@media only screen and (max-width: 500px) {
    .reseller-packages {
        .tabs {
            li {
                a {
                    font-size:18px;
                    padding: 4px 16px;
                }
            }
        }
    }
}
@media only screen and (max-width: 410px) {
    .reseller-packages {
        .tabs {
            li {
                a {
                    font-size:16px;
                    padding: 4px 10px;
                }
            }
        }
    }
}
@media only screen and (max-width: 550px) {
    .studio-packages {
        margin-top:0;
        .tabs {
            li {
                a {
                    font-size:18px;
                    padding: 4px 16px;
                }
            }
        }
    }
}
@media only screen and (max-width: 470px) {
    .studio-packages {
        .tabs {
            li {
                a {
                    font-size:16px;
                    padding: 4px 5px;
                }
            }
        }
    }
}
