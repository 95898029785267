
.table {

    td {
        padding: 5px 20px;

        &:first-of-type {
            border: solid @color-grey;
            border-width: 0 1px;
        }

        &:last-of-type {
            border: solid @color-grey;
            border-width: 0 1px;
        }
    }

    tr {
        &:first-of-type {
            td {
                padding-top: 15px;
                border-top-width: 1px;
            }
        }

        &:last-of-type {
            td {
                border-bottom-width: 1px;
                padding-bottom: 12px;
            }
        }
    }


    .brd-tp {
        td {
            padding-top: 10px;
        }
    }

    .new-row {
        td {
            border-top: 1px solid @color-grey;
            padding-top: 12px;
        }

        &.dbl-hght {
            td {
                padding-top: 5px;
            }
        }
    }

    .last-row {
        td {
            padding-bottom: 12px;
        }

        &.dbl-hght {
            td {
                padding-bottom: 5px;
            }
        }
    }
}

.div-table {
    width: 100%;
    color: @color-fake-black;

    .th {
        .td {
            padding: 10px 15px;
        }
    }

    .tb {
        .td {
            padding: 11px 15px 9px;
            min-height: 40px;

            &.img {
                padding: 9px 15px;
            }
        }

        .trow {
            border-bottom: 1px solid @color-grey;

            &:last-of-type {
                border-bottom: 2px solid @color-grey;

                & > .td {
                    border-bottom: 0;
                }
            }
        }
    }

    .td {
        align-items: center;
        display: flex;
    }

    .trow {
        &:extend(.grd all);

        & > .td {
            &:extend(.grdcell all);

            &.single {
                width: 100%;
            }
        }
    }

    .listing {
        & > .td {
            flex-shrink: 0.8;
            flex-grow: 1.2;

            &:last-of-type {
                flex-shrink: 1.2;
            }
        }
    }

    .promo {
        // color: @color-secondary;
        font-weight: bold;

        span {
            padding-left: 4px;
            padding-right: 10px;
            font-size: @font-size-smaller;
        }
    }
}

.div-table,
.table {
    .cctld {
        text-transform: uppercase;

        small {
            text-transform: capitalize;
            float: right;
            margin-top: 2px;
            color: @color-darker-grey;
            font-style: italic;
            font-weight: normal;

            span {
                margin-left: 7px;
                margin-bottom: -3px;
            }
        }
    }
}

.table {
    margin-bottom: 20px;
    width: 100%;
    display: table;
    color: @color-fake-black;

    .th {
        display: table-header-group;

        .td {
            padding: 10px 15px;
        }
    }

    .tb {
        display: table-row-group;

        .td {
            padding: 10px 15px 9px;

            &:first-of-type {
                border-left: 1px solid @color-grey;
            }

            &:last-of-type {
                border-right: 1px solid @color-grey;
            }
        }
    }

    .trow {
        display: table-row;
        border-bottom: 1px solid @color-lighter-grey;

        & > .td {
            display: table-cell;
        }

        &:last-of-type {
            border-bottom: 1px solid @color-grey;
        }
    }

    .promo {
        color: @color-secondary;
        font-weight: bold;

        span {
            padding-left: 4px;
            padding-right: 10px;
            font-size: @font-size-smaller;
        }
    }
}

.ns-table {
    color: @color-fake-black;
    width: 100%;

    thead {
        background-color: @color-light-grey;
        border-right: 0;

        th {
            border-bottom: 2px solid @color-grey;
            padding: 10px 8px 8px;
            text-align: left;
            text-transform: uppercase;
        }
    }

    td,
    th {
        flex: 1 1 150px;
        //flex: auto;
    }

    &.small-grid {
        td,
        th {
            flex: 1 1 80px;
            //flex: auto;
        }
    }

    td {
        border-bottom: 1px solid @color-grey;
        padding: 8px 10px;
    }

    tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &:first-of-type {
            td {
                border-top: 1px solid @color-grey;
            }
        }

        &:last-of-type {
            td {
                border-bottom: 1px solid @color-grey;
            }
        }
    }

    &:not(.simple-table) {
        color: @color-fake-black;

        thead {
            border-bottom: 0;

            th {
                &:extend(.rubik all);

                background-color: @color-primary;
                border-bottom: 1px;
                padding: 12px 10px 10px;
                color: #fff;
                border-right: 1px solid rgba(255, 255, 255, 0.1);

                &:last-of-type {
                    border-right: 1px solid @color-primary;
                }
            }
        }

        td {
            border-right: 1px solid @color-light-grey;

            &:first-of-type {
                border-left: 1px solid @color-light-grey;
            }
        }
    }

    &.ns-table-colored {
        tr {
            background-color: #fafafa;
            &:nth-of-type(2n) {
                background-color: lighten(@color-primary, 95%, relative);

                td {
                    border-left-color: rgba(235, 235, 235, 0.8);
                }
            }
        }
    }
}

table.ns-table-responsive-col {
    table-layout: fixed;
    width: 100%;

    tr {
        display: table-row;
    }

    th:first-child, td:first-child {
        width: 35%;
    }

    th:nth-child(2), td:nth-child(2) {
        width: 14%;
    }

    th:nth-child(3):nth-child(4), td:nth-child(3):nth-child(4) {
        width: 18%;
    }

    th:nth-child(5), td:nth-child(5) {
        width: 15%;
    }
}

table.ns-table-responsive-col-4 {
    table-layout: fixed;
    width: 100%;

    tr {
        display: table-row;
    }

    th, td {
        width: 23%;
    }

    th:first-child, td:first-child {
        width: 31%;
    }
}
