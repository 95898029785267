@media @break-xs {
    h1 {
        font-size: 32px;
    }
}

@media @break-sm {
    h1 {
        font-size: 34px;
    }
    h3 {
        font-size: 24px;
    }
}
@media @break-md {
    h1 {
        font-size: 38px;
    }
}
@media @break-lg {
    h1 {
        font-size: 45px;
    }
}


@media @break-xlg {

}
