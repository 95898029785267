.domain-benefits {
    .upsell-header {
        padding-right: 50px;
    }

    .upsell-box {
        min-height: 230px;
    }
    .upsell-title {
        font-size:1.2rem;
    }

    & > .grdcell {
        min-width: 330px;
    }
}
