@media @break-sm {
    .side-navigation {
        max-width: none;
    }
}

@media @break-max-xlg-menu-value {
    .faq {
        margin:40px 0px 40px 0px;
    }
}

/*----------  Logos list  ----------*/
@media only screen and (max-width: 460px) {

    .inline-list {
        &.vps-logos {
            .list-item {
                max-width: 130px;
                margin: 10px 0px;
                opacity: 1;
            }
        }
    }
}

@media @break-sm {
    .faq-list > li > a {
        font-size: .95rem;
    }
}
