.loading-compare {
    margin: 30px 0;

    .label {
        color: @color-dark-grey;
    }

    & > div {
        display: block;
    }

    .left-side,
    .right-side {
        height: 48px;
    }

    .green,
    .red {
        h4 {
            margin: 0;
        }
    }

    .green {
        .left-side {
            padding: 12px 2% 0 0;
        }

        .right-side {
            padding: 12px 0 8px;
        }
    }

    .red {
        .left-side {
            padding: 7px 2% 0 0;
        }

        .right-side {
            padding: 8px 0 15px;
        }
    }

    .left-side {
        display: inline-block;
        vertical-align: middle;
        width: 24%;
        text-align: right;
        margin-right: -4px;
        min-width: 160px;
    }

    .right-side {
        border-left: 2px solid #fff;
        color: #fff;
        display: inline-block;
        width: 65%;
        vertical-align: middle;
    }

    .losser {
        .label {
            font-weight: normal;
        }
    }

    .outside-bar {
        .loading-bar {
            display: inline-block;
        }

        strong {
            display: inline-block;
            padding: 0 10px 20px;
            vertical-align: middle;
            color: @color-red;
        }
    }

    &.small {
        .loading-bar {
            padding: 5px 5px 4px;
        }

        .label {
            font-size: 14px;
        }

        .left-side {
            width: 35%;
            border-right-width: 2px;
        }

        .right-side {
            font-size: 12px;
        }

        .red .left-side {
            padding: 13px 2% 0 0;
        }

        .green .left-side {
            padding: 18px 2% 0 0;
        }
    }
}

.loading-bar {
    position: relative;
    height: 28px;
    width: 0;
    overflow: hidden;
    background-color: #888;
    white-space: nowrap;

    &.top {
        margin-bottom: 25px;
    }

    .green & {
        background-color: #42aa0c;
        width: 0;
        .box-shadow(~'-3px 7px 0 -3px #2b6e09, -2px 6px 0 -2px #2b6e09, -1px 5px 0 -2px #2b6e09, 0px 4px 0 -2px #2b6e09, 1px 3px 0 -2px #2b6e09');
    }

    .red & {
        background-color: #d71e22;
        width: 0;
        .box-shadow(~'-3px 7px 0 -3px #9e1114, -2px 6px 0 -2px #9e1114, -1px 5px 0 -2px #9e1114, 0px 4px 0 -2px #9e1114, 1px 3px 0 -2px #9e1114');
    }

    .value {
        position: absolute;
        top: 5px;
        right: 10px;
        text-align: right;
    }
}
