.scrolled {
    .right-info {
        max-width: 151px;
        min-width: 150px;
    }

    .mini-cart {
        background-color: @color-secondary;
        float: left;
        margin-top: 0;
        top: 0;
        width: 100%;

        .spr-cart {
            background-position: -106px -164px;
        }navigationless

        .cart-content {
            .box-shadow(~'0 7px 7px rgba(0,0,0,0.7)');

            min-width: 370px;
            top: -600px;
            border-top: 1px solid @color-grey;
            z-index: 0;

            &::before {
                background-image: none;
            }

            &.empty {
                min-width: 213px;
            }
        }

        &:hover,
        &.active,
        &.hovered {
            .box-shadow(none);

            .cart-holder {
                & > a {
                    .box-shadow(~"-2px -5px 0 9px #FFFFFF inset, 9px -5px 0 5px #FFFFFF");
                }
            }

            .cart-content {
                top: 60px;
            }
        }

        .cart-text {
            margin-top: 4px;
            color: #fff;
            font-size:@font-size-big;
            &:extend(.rubik);

            // Napis "Košarica"
            .text-cart {
                display: none;
            }
            .cart-icon {
                font-size:@font-size-evenbigger;
            }
        }

        .cart-top {
            background-color: @color-secondary;
            min-height: 60px;
            padding: 8px 20px 0 20px;
            position: relative;
            text-align:center;

            /* z-index menija je 1000 */
            z-index: 1001;

            &::after {
                display: none;
            }
        }
    }

    .cart-container {
        border-top: 1px solid #ccc;
    }
}

.responsive {
    .mini-cart {
        .cart-content {
            /* Animation */
            transform: translateX(300%);
            will-change: transform;
            transition: transform 300ms, opacity 400ms;
        }
    }
}

.mini-cart {
    background-color: #fff;
    float: right;
    margin-top: 31px;
    padding: 0;
    position: relative;
    z-index: 100;

    .cart-content {
        .box-shadow(0px 2px 7px 0px rgba(0,0,0,0.7));
        border-top:1px solid @color-grey;
        background-color: #fff;
        min-height: 140px;
        position: absolute;
        right: 0;
        top: -500px;//73px;
        min-width: 265px;
        opacity: 0;
        z-index: 1000;
        transition: transform 300ms, opacity 400ms;

        &.empty {
            min-height: 50px;

            .icon-smiley {
                bottom: -7px;
                width: 39px;
                height: 39px;
                font-size: 22px;
            }

            &::before {
                top: -5px;
            }

            .mini-cart-list {
                & > li {
                    border-bottom: 0;
                    height: auto;
                }
            }
        }
    }

    .empty-cart {
        align-items: center;
        display: inline-flex;
        overflow: hidden;
        padding: 15px 18px 10px;

        .name {
            float: right;
            display: inline-block;
            width: 70%;
            padding: 6px 6px 0;
        }
    }

    //&:hover,
    &.hovered,
    &.active {
        .box-shadow(0 0 11px 0 rgba(0, 0, 0, .7));

        .cart-content {
            top: 58px;
            transform: translateX(0%);
            opacity: 1;
        }

        .cart-top {

        }

        .down-icon {
            transform: rotate(180deg) translate(0px, 3px);
        }
    }

    /* .mini-cart-list -> lists.less */
    .cart-text {
        font-size: @font-size-bigger;
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        color: @color-secondary;
        &:extend(.rubik400);
        .cart-icon {
            font-size:@font-size-biggest;
        }
        .down-icon {
            transition: transform 0.3s ease;
            display:inline-block;
        }

        span {
            margin-top:-2px;
        }

        .icon-cross-black {
            font-size: 30px;
            margin-top: -10px;
            display: inline-block;
            position: relative;
            top: 3px;
            color: @color-primary;
        }
    }

    .cart-labels {
        display: flex;
        padding: 5px 50px 10px;

        .price-text {
            display: inline-block;
        }
    }

    .price-text {
        margin-left:auto;
    }

    .final-price {
        font-size: @font-size-big;
        color: @color-secondary;
        margin-left: 20px;
        margin-right: 5px;
        text-align: right;
    }

    .cart-actions {
        &:extend(.clearfix);

        padding: 5px 20px 20px 80px;

        .btn-green {
            float: right;
            min-width: 120px;
        }
    }

    .cart-top {
        width: 100%;
        position: relative;
        z-index: 1100;
        cursor: pointer;
        padding: 14px 20px;
        display: inline-block;
        background-color: #fff;
    }
}

.close-cart,
.mobile-menu-close {
    .iconFamily();

    background-color: rgba(55, 55, 55, 0.1);
    border: 0;
    border-radius: 0;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-size: 36px;
    padding: 12px;
    position: absolute;
    left: 0;

    /* Nč na kredit, je reku šef */
    display: none;

    &::before {
        content: "\e61a";
    }

    &:hover {
        background: rgba(55, 55, 55, 0.2);
    }
}

.mobile-menu-close {
    left: auto;
    right: 0;
}

.mini-cart-list {
    margin: 0 0 10px;
    padding: 0;
    color: @color-fake-black;

    & > .item {
        align-items: center;
        border-bottom: 1px solid @color-grey;
        display: flex;
        list-style: none;
        min-height: 42px;
        padding: 8px 18px 7px;
        position: relative;

        & > .icon {
            font-size: 1.8em;
        }
    }

    .price {
        text-align: right;
        margin-left: auto;
        padding-left: 10px;
    }

    .name {
        min-width: 169px;
        margin-left: 5px;
    }

    .remove-item {
        min-width: 23px;
        min-height: 23px;
        max-width: 23px;
        max-height: 23px;
        margin: 0 0 0 15px;
        text-align: center;
        text-decoration: none;
        background-color: @color-lighter-grey;

        .border-radius(25px);

        .icon-close {
            font-size: 16px;
            left: 0.55%;
            color: black;

            &:hover {
                font-weight: bold;
            }
        }
    }

    /*.icon-domain {
        color: @color-primary;
    }
    .icon-hosting {
        color: @color-secondary;
    }*/
    .icon-fabo {
        color: @color-fake-black;
    }

    .collapsed {
        background-color: @color-lighter-yellow;

        a {
            color: @color-fake-black;
        }
    }
}

/* Non-responsive cart is wider */
body:not(.responsive) {
    .mini-cart-list {
        .name {
            min-width: 250px;
        }
    }
}

/* Mini cart animation on hover, cart-holder/icon is moved down */
#main-header:not(.scrolled):not(.fixed-menu) .mini-cart {
    //.transition(margin-top 100ms ease-out);

    &:hover,
    &.active {
        //margin-top: 37px;
    }
}

.main-menu:not(.hidden) ~ .mini-cart {
    border-color: @color-secondary;
}

@media @break-xs {
    .mini-cart-list {
        .item {
            flex-wrap: wrap;
        }

        .name {
            min-width: 125px;
        }

        .remove-item {
            margin-left: auto;
        }
    }
}
