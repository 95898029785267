/* Popup, ki prikaže postopek prenosa domene oziroma menjave ponudnika */

.domain-showbox,
.hosting-showbox {
    z-index: 1;
    color: @color-black;
    .box-shadow(0 0 14px 2px rgba(0,0,0,0.7));

    .mdl& {
        position: absolute;
        top: 95px;
        left: 50px;
        z-index: 10;
    }

    .mdl-header .mdl-title {
        background-color: @color-fake-black;
    }

    &:before {
        content: ' ';
        position: absolute;
        top: -50px;
        left: 50%;
        margin-left: -30px;
        border: solid @color-fake-black;
        border-width: 30px;
        border-color: transparent transparent @color-fake-black transparent;
    }

    .num-list {
        position: relative;
        margin-left: 4%;
        margin-bottom: -10px;

        & > div {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 23%;
            text-align: center;
            z-index: 1;
        }

        &:before {
            background-color: @color-primary;
            content: ' ';
            position: absolute;
            top: 16%;
            left: 10%;
            display: block;
            height: 3px;
            width: 70%;
            z-index: 0;
        }

        .num {
            display: inline-block;
            padding: 10px;
            width: 40px;
            height: 40px;
            background-color: @color-primary;
            border-radius: 20px;
            color: #FFF;
            font-size: 18px;
        }

        .smiley {
            position: relative;
            font-size: 25px;
            padding: 2px;
            border-radius: 30px;

            .icon-smiley {
                position: absolute;
                left: 3px;
                top: 3px;
                padding: 3px;
                background-color: #FFE800;
                border-radius: 20px;
                font-size: 28px;
                color: #000;

                &:before {
                    position: relative;
                    top: 2px;
                }
            }
        }

    }
}

.hosting-showbox {
    .mdl& {
        top: auto;
        bottom: 109px;
        left: 29.5%;
        .mdl-content{
            min-height: auto;
        }
    }

    .num-list {
        &:before {
            top: 16.5%;
            left: 14%;
            width: 66%;
        }

        & > div {
            width: 32%;
        }
    }

    .mdl-content {
        border-bottom: 4px solid @color-fake-black;
    }

    &:before {
        top: auto;
        bottom: -60px;
        border-color: @color-fake-black transparent transparent transparent;
    }
}
