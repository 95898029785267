/*==========  Prenos domen upsell bar  ==========*/
.domain-trans-upsell {
    .brd-bx {
        background: @color-light-grey;
        flex-direction: row;
    }

    .intro,
    .arrow-icons,
    .solution {
        display: inline-block;
        margin-top: 15px;
        min-width: 180px;
        padding-top: 10px;
        vertical-align: middle;
    }

    .intro {
        flex-grow: 1;
        text-align: right;
    }

    .arrow-icons {
        flex-grow: 0.9;
        padding-left: 1%;
        margin: 15px 5px 0;
    }

    .solution {
        flex-grow: 1;
        margin-bottom: 20px;
        padding-right: 10px;
    }
}

@media (max-width: @break-md-value) {
    .domain-trans-upsell {
        text-align: center;

        .brd-bx {
            flex-direction: column;
        }

        .intro {
            text-align: center;
        }

        .btn-blue {
            display: inline-block;
            float: none;
        }
    }
}
