/* ================================
=            CHECKOUT            =
================================ */
.checkout-stage {
    &:extend(.clearfix all);

    background: @color-light-grey;

    & > .content {
        margin-top: 42px;
    }

    #order-top {
        //margin-bottom: -48px;
    }
}

.checkout-header {
    &:extend(.clearfix all);

    margin-top: 40px;

    .checkout-nav-button {
        position: relative;
        margin-top: -17px;
        min-width: auto;
    }

    .button-next,
    .button-back {
        position: relative;
        //margin-top: 50px;
    }
}

.checkout-button-holder {
    //
}

.button-next {
    float: right;
}

.button-back {
    float: left;
}

.button-next,
.button-back {
    &.btn-loading {
        .inside {
            padding-left: 32px;
        }

        &::after {
            position: relative;
            left: 16px;
            top: 18px;
            float: left;
            .transform-origin(120%, 120%);
        }
    }
}

.checkout-timeline {
    flex-grow: 3;
    margin: -70px auto 10px;
    max-width: 520px;
    min-width: 520px;
    position: relative;
    width: 100%;

    .timeline {
        background: url(../img/icons/stageline.svg);
        height: 40px;
        left: 2px;
        max-width: 520px;
        padding-top: 21px;
        position: relative;
        top: 15px;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            top: 19px;
            left: 15px;
            width: 570px;
            height: 3px;
            // test 1
            background: #42aa0c;
            background: -moz-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 20%, #1d9ad6 100%);
            background: -webkit-gradient(linear, left top, right top, color-stop(0%, #42aa0c), color-stop(5%, #42aa0c), color-stop(20%, #1d9ad6), color-stop(100%, #1d9ad6));
            background: -webkit-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 20%, #1d9ad6 100%);
            background: -o-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 20%, #1d9ad6 100%);
            background: -ms-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 20%, #1d9ad6 100%);
            background: linear-gradient(to right, #42aa0c 0%, #42aa0c 5%, #1d9ad6 20%, #1d9ad6 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#42aa0c', endColorstr='#1d9ad6', GradientType=1);
        }
    }

    .stage {
        position: absolute;
        top: 22px;
        left: 9px;
        width: 28px;
        height: 28px;
        border-radius: 40px;
        background-color: @color-secondary;

        &::before {
            .family-icon();

            content: "\e653";
            position: relative;
            top: 5px;
            left: 5px;
            font-size: 18px;
            color: white;
        }
    }

    .stage-active {
        background-color: @color-primary;
        .box-shadow(0 0 0 5px #fff inset);

        border: 3px solid @color-primary;
    }

    .n-2 {
        left: 129px;
    }

    .n-3 {
        left: 249px;
    }

    .n-4 {
        left: 367px;
    }

    .n-5 {
        left: 487px;
    }

    .progress-list {
        color: @color-darker-grey;
    }

    .finished-label {
        color: @color-secondary;
    }

    .active-label {
        color: @color-primary;
        font-weight: bold;
    }

    /* ----------  Stages  ---------- */

    // Stage 1
    &.stage-1 {
        .timeline {
            &::before {
                width: 70px;
                background: #1d9ad6;
            }
        }

        .n-1 {
            &:extend(.checkout-timeline .stage-active);
        }

        .n-1::before,
        .n-2,
        .n-2::before,
        .n-3,
        .n-3::before,
        .n-4,
        .n-4::before,
        .n-5,
        .n-5::before {
            display: none;
        }

        .label-1 {
            &:extend(.checkout-timeline .active-label);
        }
    }
    // EOF Stage 1

    // Stage 2
    &.stage-2 {
        .timeline {
            &::before {
                width: 185px;
                background: #42aa0c;
                background: -moz-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 35%, #1d9ad6 100%);
                background: -webkit-gradient(linear, left top, right top, color-stop(0%, #42aa0c), color-stop(5%, #42aa0c), color-stop(35%, #1d9ad6), color-stop(100%, #1d9ad6));
                background: -webkit-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 35%, #1d9ad6 100%);
                background: -o-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 35%, #1d9ad6 100%);
                background: -ms-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 35%, #1d9ad6 100%);
                background: linear-gradient(to right, #42aa0c 0%, #42aa0c 5%, #1d9ad6 35%, #1d9ad6 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#42aa0c', endColorstr='#1d9ad6', GradientType=1);
            }
        }

        .n-2 {
            &:extend(.checkout-timeline .stage-active);
        }

        .n-2::before,
        .n-3,
        .n-3::before,
        .n-4,
        .n-4::before,
        .n-5,
        .n-5::before {
            display: none;
        }

        .label-1 {
            &:extend(.checkout-timeline .finished-label);
        }

        .label-2 {
            &:extend(.checkout-timeline .active-label);
        }
    }
    // EOF Stage 2

    // Stage 3
    &.stage-3 {
        .timeline {
            &::before {
                width: 305px;
                background: #42aa0c;
                background: -moz-linear-gradient(left, #42aa0c 0%, #42aa0c 40%, #1d9ad6 70%, #1d9ad6 100%);
                background: -webkit-gradient(linear, left top, right top, color-stop(0%, #42aa0c), color-stop(40%, #42aa0c), color-stop(70%, #1d9ad6), color-stop(100%, #1d9ad6));
                background: -webkit-linear-gradient(left, #42aa0c 0%, #42aa0c 40%, #1d9ad6 70%, #1d9ad6 100%);
                background: -o-linear-gradient(left, #42aa0c 0%, #42aa0c 40%, #1d9ad6 70%, #1d9ad6 100%);
                background: -ms-linear-gradient(left, #42aa0c 0%, #42aa0c 40%, #1d9ad6 70%, #1d9ad6 100%);
                background: linear-gradient(to right, #42aa0c 0%, #42aa0c 40%, #1d9ad6 70%, #1d9ad6 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#42aa0c', endColorstr='#1d9ad6', GradientType=1);
            }
        }

        .n-3 {
            &:extend(.checkout-timeline .stage-active);
        }

        .n-3::before,
        .n-4,
        .n-4::before,
        .n-5,
        .n-5::before {
            display: none;
        }

        .label-1,
        .label-2 {
            &:extend(.checkout-timeline .finished-label);
        }

        .label-3 {
            &:extend(.checkout-timeline .active-label);
        }
    }
    // EOF Stage 3

    // Stage 4
    &.stage-4 {
        .timeline {
            &::before {
                width: 425px;
                background: #42aa0c;
                background: -moz-linear-gradient(left, #42aa0c 0%, #42aa0c 60%, #1d9ad6 80%, #1d9ad6 100%);
                background: -webkit-gradient(linear, left top, right top, color-stop(0%, #42aa0c), color-stop(80%, #42aa0c), color-stop(80%, #1d9ad6), color-stop(100%, #1d9ad6));
                background: -webkit-linear-gradient(left, #42aa0c 0%, #42aa0c 60%, #1d9ad6 80%, #1d9ad6 100%);
                background: -o-linear-gradient(left, #42aa0c 0%, #42aa0c 60%, #1d9ad6 80%, #1d9ad6 100%);
                background: -ms-linear-gradient(left, #42aa0c 0%, #42aa0c 60%, #1d9ad6 80%, #1d9ad6 100%);
                background: linear-gradient(to right, #42aa0c 0%, #42aa0c 60%, #1d9ad6 80%, #1d9ad6 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#42aa0c', endColorstr='#1d9ad6', GradientType=1);
            }
        }

        .n-4 {
            &:extend(.checkout-timeline .stage-active);
        }

        .n-4::before,
        .n-5,
        .n-5::before {
            display: none;
        }

        .label-1,
        .label-2,
        .label-3 {
            &:extend(.checkout-timeline .finished-label);
        }

        .label-4 {
            &:extend(.checkout-timeline .active-label);
        }
    }
    // EOF Stage 4

    // Stage 5
    &.stage-5 {
        .timeline {
            &::before {
                width: 485px;
                background: #42aa0c;
                background: -moz-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 95%, #1d9ad6 100%);
                background: -webkit-gradient(linear, left top, right top, color-stop(0%, #42aa0c), color-stop(5%, #42aa0c), color-stop(95%, #1d9ad6), color-stop(100%, #1d9ad6));
                background: -webkit-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 95%, #1d9ad6 100%);
                background: -o-linear-gradient(left, #42aa0c 0%, #42aa0c 5%, #1d9ad6 95%, #1d9ad6 100%);
                background: -ms-linear-gradient(left, #42aa0c 0%, #42aa0c 80%, #1d9ad6 95%, #1d9ad6 100%);
                background: linear-gradient(to right, #42aa0c 0%, #42aa0c 80%, #1d9ad6 95%, #1d9ad6 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#42aa0c', endColorstr='#1d9ad6', GradientType=1);
            }
        }

        .n-5 {
            &:extend(.checkout-timeline .stage-active);
        }

        .n-5::before {
            display: none;
        }

        .label-1,
        .label-2,
        .label-3,
        .label-4 {
            &:extend(.checkout-timeline .finished-label);
        }

        .label-5 {
            &:extend(.checkout-timeline .active-label);
        }
    }
    // EOF Stage 5
}

.checkout {
    margin: 20px 0 30px;
    border: 1px solid #ccc;
}

.checkout-content {
    float: left;
    max-width: 940px;
    // overflow: hidden; no-go -> http://neoserv.lan/narocilo?koraki

    background-color: #fff;

    // domain-check
    .inline-domain-check {
        .sub-text {
            margin: 0;
        }
    }

    .full {
        padding-bottom: 20px;
    }
}

.appended-icon {
    align-items: center;
    display: inline-flex;

    i {
        font-size: 26px;
        margin-right: 6px;
    }
}

.checkout-side {
    background: #fff url(../img/cms/bg_shdw.png) repeat-y 0 0;
    float: right;
    min-width: 300px;
}

.checkout-cart {
    border-left: 0;
    //max-width: 382px;
    position: relative;
    right: 0;

    .cart-title {
        padding: 9px 20px 14px 12px;
        margin: 0;
        color: @color-primary;

        i {
            font-size: 32px;
            position: relative;
            bottom: -3px;
        }
    }

    /* &::before {
        position: absolute;
        left: 0;
        content: " ";
        height: 100%;
        width: 14px;
        box-shadow: 4px 0 4px -3px rgba(66,66,66,0.5) inset;
    } */
    .empty-cart {
        margin: 0 0 11px;
        padding: 22px 0 20px;
        text-align: center;
        // & +
    }

    .cart-content {
        border: 1px solid #ccc;
        border-width: 1px 0;

        .input-group {
            margin-bottom: 0;
        }
    }
    // cart item
    .item {
        .box-shadow(0 -11px 8px -13px rgba(66,66,66,0.7) inset);

        position: relative;
        display: block;
        padding: 15px 20px 10px;
        border-bottom: 1px solid rgba(155, 155, 155, 0.5);
        background: @color-cart-bg url(../img/cms/bg_shdw.png) repeat-y 0 0;

        &:last-of-type {
            border-bottom: 0;
        }
    }

    .selection {
        display: block;
    }

    .addons {
        display: block;
        font-size:12px;
        margin-bottom:10px;
        padding-left: 32px;
        font-weight: bold;
    }

    .price,
    .actions {
        display: inline-block;
    }

    .title {
        &:not(.cart-title) {
            max-width: 250px;
        }

        &.appended-icon {
            i {
                color: @color-primary;
                font-size: 26px;
            }

            /*
            .icon-hosting {
                color: @color-primary;
            }

            .icon-domain {
                color: @color-primary;
            }

            .icon-shield-ssl {
                color: @color-primary;
            } */

            .icon-wordpress,
            .icon-joomla {
                font-size: 24px;
                margin-top: 2px;
            }

            i.icon-fabo {
                color: @color-secondary;
            }
        }
    }

    .title + .selection {
        .selectric-wrapper {
            padding-top: 8px;
            margin-right: 20px;
        }

        label {
            padding-top: 10px;
        }
    }

    .price + .selection {
        margin-top: 10px;
    }

    .selection {
        font-size: 13px;
        width: 220px;


        label {
            padding-top: 6px;
            width: 120px;
        }

        .selectric-wrapper {
            padding-top: 3px;
        }

        &.main-domain {
            width: 100%;

            .selectric-wrapper {
                float: left;
                margin-left: 7px;
                margin-right: 55px;
                max-width: 185px;
                width: 100%;
            }
        }

        .selectric-low {
            height: 20px;
        }

        &.wide {
            width: 100%;

            .selectric-wrapper {
                float: left;
            }
        }

        &.whois-fields, &.addon-fields {
            .selectric-wrapper {
                width: 160px;

                .selectric {
                    width: 160px;
                }
            }
        }
    }

    .year {
        .selectric-wrapper {
            width: 100px;
        }
    }

    .inline {
        position: relative;

        .selectric-wrapper {
            display: inline-block;
            vertical-align: bottom;
        }
    }

    .whois-btn {
        position: absolute;
        top: 4px;
        right: -2px;
        font-size: 20px;
        color: fade(@color-fake-black, 60%);

        &.visible {
            color: @color-fake-black;
        }
    }

    .price {
        /* position: absolute;
        top: 16px;
        right: 70px; */
        float: right;
        padding: 0 35px 0 15px;
        text-align: right;
    }

    .new-price {
        display: block;
        font-size: @font-size;
    }

    .old-price {
        font-size: 14px;
        margin-top: 4px;
    }

    .actions {
        position: absolute;
        top: 15px;
        right: 15px;

        a {
            font-size: 25px;
            text-decoration: none;
            color: @color-fake-black;
        }
    }

    .domain-input {
        margin-top: 15px;

        &.main-domain-input {
            margin-top: 0;
        }

        label {
            font-size: 12px;
        }

        button {
            background-color: @color-secondary;
            color: #fff;
            padding: 5px 15px;
        }

        input {
            margin-bottom: 0;
            height: 30px;
        }

        .btn-loading {
            & ~ .popover-holder {
                left: 210px;
            }
        }

        /* Ni v uporabi
        .input-inline {
            .main-info {
                position: absolute;
                top: 9px;
                right: 108px;
                line-height: 1em;
            }
        }*/
    }

    .item-error {
        background-color: @color-cart-error-bg;
    }

    .single-error {
        font-size: @font-size-smaller;
        color: @color-red;
        margin-top: 5px;
    }
}

main #domain-check-wrapper:first-child {
    margin-top: 20px;
}

#domain-check-wrapper {
    &.loading {
        &::before {
            top: 350px;
        }
    }
}

.cart-item {
    .clearfix()
}

.checkout-cart {
    .cart-summary {
        padding: 20px;
        margin: 0;

        .savings {
            .value {
                color: @color-secondary;
            }
        }

        .total {
            padding-top: 10px;

            .value {
                font-size: @font-size-big;
            }
        }

        .title {
            width: 57%;
            margin-right: 18px;
            display: inline-block;
            text-align: right;
        }

        .value {
            display: inline-block;
            text-align: right;
            max-width: 100px;
        }
    }
}

.cart-discount {
    padding: 0 20px 18px;

    & > .input-group {
        border-top: 2px solid @color-grey;
        padding-top: 18px;
    }

    label {
        color: @color-darker-grey;
    }

    // info-icon
    .icon-info-circle-alt2, .icon-info-circle-warning-alt2 {
        position: absolute;
        top: 6px;
        right: 155px;
        height: 20px;
        width: 20px;
        line-height: 1em;
    }

    .btn-loading ~ .popover-holder {
        left: 160px;
    }
}

.discount-field {
    padding: 0;
    margin-top: 5px;

    .popover {
        color: @color-darker-grey;
    }

    input,
    button {
        height: 30px;
    }

    button {
        padding: 5px 25px;
        background-color: @color-grey;
        color: @color-darker-grey;
    }

    input {
        padding: 5px 175px 5px 15px;
    }

    .checked-code {
        position: absolute;
        top: 2px;
        right: 148px;
        font-size: 26px;
        color: @color-secondary;
        .iconFamily();
    }
}
// Discount code validity sign
.valid-code {
    .checked-code {
        &::before {
            content: "\e653";
        }
    }
}

.invalid-code {
    .checked-code {
        color: @color-red;

        &::before {
            content: "\e64a";
        }
    }
}

.mini-desc {
    display: block;
    // margin-top: 3px;
    font-weight: normal;
    font-size: 12px;
}

.inline-cart {
    .favorite {
        margin: 30px 0 20px 64%;

        &.reseller {
            margin-left: 54%;
        }
    }
}

.maindomain-response .popover-box.tooltip {
    max-width: 347px;
    width: 100%;
}

/* ----------  Checkout footer  ---------- */
.checkout-footer {
    &:extend(.clearfix all);

    position: relative;
}

.brand-features {
    margin-top: 75px;
    text-align: center;

    .grdcell {
        min-width: 150px;
    }

    .description {
        font-size: @font-size-smaller;
        color: @color-darker-grey;
    }

    .numbers {
        padding: 33px 0 7px;
        display: inline-block;
    }
}

/* Checkout progress bar */
.checkout-progress {

    //https://codepen.io/whqet/pen/EJgwb
    display: flex;
    margin: 0;
    padding: 0;

    .label {
        flex: 1 0 0%;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .step-link {
        padding: 10px 20px;
    }
}

/* =======================================
=            Checkout step 1            =
======================================= */
.domaincheck-presell {
    margin-top: 10%;
    text-align: center;

    img {
        display: inline-block;
        padding-bottom: 20px;
    }

    .written {
        color: @color-blue-pen;
    }
}

/* =====  End of Checkout step 1  ====== */

/* =======================================
=            Checkout step 3            =
======================================= */

/* ----------  Collapsable list - cart  ---------- */
.checkout-content .collapsable {
    margin: 20px;
    padding: 0;
    list-style: none;

    .classic-list {
        display: inline-block;
        max-width: 400px;
    }

    .actions {
        display: inline-block;
        margin-right: 10px;
        margin-top: -10px;
        float: right;

        .options {
            margin: 10px auto 0;
            text-align: center;

            span {
                padding-right: 5px;
            }
        }
    }

    .title {
        cursor: pointer;
        margin: 0;
        padding: 12px 10px 12px 15px;
        position: relative;
        width: 100%;

        strong {
            margin-right: 5px;
        }

        i {
            top: 10px;
            font-size: 25px;
            margin-right: 10px;
        }

        &::after {
            .iconFamily();

            content: "\e60c";
            font-size: 18px;
            margin-left: auto;
            padding-right: 10px;
            right: 10px;
            top: 17px;
        }
    }

    .desc {
        padding: 5px 20px 0;
    }

    .collapsable-content {
        float: left;
        width: 100%;
        padding: 20px 20px 0;
        border: 1px solid @color-grey;
        border-top: 0;

        .selectric-wrapper {
            margin-bottom: 15px;
        }

        h6 {
            margin-top: 0;
        }
    }

    .selectric-wrapper {
        display: inline-block;
        vertical-align: bottom;
    }

    .collapsable-control {
        background: @color-lighter-grey;
        color: @color-darker-grey;
    }

    .collapsable-item {
        &.active {
            &:extend(.clearfix all);

            .title {
                color: #fff;
            }
        }
    }
}

.collapsable-item {
    margin-top: 10px;
    margin-bottom: 15px;

    .collapsable-content {
        display: none;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &.active {
        .collapsable-control {
            background: @color-primary;
            color: #fff;
        }

        .collapsable-content {
            display: block;
        }

        .title {
            &::after {
                content: '\e60f';
            }
        }
    }
}

/* =====  End of Checkout step 3  ====== */

/* =======================================
=            Checkout step 4            =
======================================= */
.checkout-choice {
    .left-right-choice {
        padding: 6%;
        min-width: 230px;
    }

    h4 {
        margin-top: 20px;
    }

    .left-side,
    .right-side {
        /* display: inline-block; */
        float: left;
        //width: 50%;
        //margin: 30px -4px 30px 0; // inline-block fix
        text-align: center;

        h4 {
            margin-bottom: 10px;
        }

        .desc {
            margin: 0 0 25px;
        }
    }
}

.checkout-content .mfp-content {
    /* height: 100%; */
}

.lightbox.checkout-choice {
    margin-top: 8%;
    width: 70%;
    background-color: #fff;

    .left-side,
    .right-side {
        padding: 25px 45px 45px;
    }

    .button-next {
        float: none;
    }
}

.checkout-content {
    flex-grow: 2.1;

    .header {
        min-height: 55px;
        padding: 17px 25px;
        background: @color-primary;
        color: #fff;

        & > a {
            text-decoration: none;
            color: #fff;
        }

        &.dropdown-holder {
            padding: 0;
        }
    }

    .tabs-holder {
        padding-bottom: 0;

        .mini-desc {
            line-height: 14px;
            padding-top: 3px;
        }
    }

    .content {
        position: relative;
        padding: 0 40px 5px;
        padding-bottom: 20px;
    }

    .bottom-actions {
        position: absolute;
        width: 100%;
        padding-right: 80px;
        bottom: 40px;
    }

    .inlined-form {
        textarea {
            height: 112px;
            padding: 5px 5px 5px 10px;
        }
    }

    & > div:not(.mfp-wrap) .notice {
        //margin-left: 154px;

        .alert {
            margin-bottom: 15px;
        }
    }

    a[class^='btn-'] {
        vertical-align: top;
    }
}

.tabbed-radio {
    margin-bottom: 20px;
    border-bottom: 1px solid @color-grey;
    align-items: center;

    h6 {
        width: 150px;
    }

    a,
    h6 {
        display: inline-block;
    }

    .simple-tabs {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    a {
        display: inline-block;
        padding: 16px 20px 10px;
        border: 1px solid @color-grey;
        border-bottom: 0;
        background-color: @color-light-grey;
        color: @color-fake-black;

        &.active {
            font-weight: bold;
            background-color: #fff;
            .box-shadow(0 2px #fff);
        }
    }
}

/* =====  End of Checkout step 4  ====== */

/* ==============================================
=            Checkout step FB-promo            =
============================================== */
.fb-promo {
    position: relative;
    padding: 12% 20px 20px;
    text-align: center;
    min-height: 550px;

    .f-bigger {
        margin: 35px 0 10px;
    }

    .info {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 20px;
        text-align: left;
        background-color: @color-cart-bg;
        border-top: 1px solid @color-lighter-grey;
    }

    .info-holder {
        float: left;
        padding: 5px 0 25px 20px;
    }
}

.fb-sample {
    margin-top: 10px;
    background: url(../img/cms/checkout/facebook_post_option.gif) no-repeat;
    width: 101px;
    height: 115px;

    &.slo {
        float: left;
    }

    &.eng {
        float: right;
        background-position: -101px;
    }
}

/* =====  End of Checkout step FB-promo  ====== */

/* ==========================================
=            Checkout last step            =
========================================== */
.final-step {
    position: relative;

    h3 {
        padding: 6px 0 6px 130px;
        margin-top: 0;
        color: #fff;

        .rubik {
            display: inline-block;
            padding-left: 15px;
        }
    }

    .status-title {
        background-color: @color-secondary;
    }

    .payment-title {
        background-color: @color-primary;
        padding-left: 20px;
    }

    .indented {
        padding: 14px 0 12px 130px;
        margin: 0 0 0 15px;
    }

    .check-mark {
        position: absolute;
        left: 40px;
        top: 15px;
        width: 85px;
    }

    .cart-list {
        label,
        .input-value {
            padding-top: 5px;
            display: inline-block;
        }

        label {
            width: 120px;
        }

        .input-value {
            font-weight: bold;
        }
        .addons {
            label {
                width: 270px;
            }
        }
    }

    .checkout-cart {
        .title + .selection label,
        .title + .selection .input-value {
            padding-top: 10px;
            padding-left: 31px;
            width: 100%;
        }

        .price {
            padding: 2px 0 0 15px;
        }
    }

    .checkout-cart .price + .selection {
        margin-top: -6px;
    }

    .related {
        margin: 0 0 0 32px;
    }

    .main-domain,
    .year,
    .addons {
        padding-left: 32px;
    }
}

.what-now {
    padding: 0 39px;
    word-wrap: break-word;

    h4 {
        color: @color-primary;
    }

    ol {
        position: relative;
        padding: 0 0 5px 60px;
        list-style: none;
    }

    li {
        min-height: 40px;
        margin-bottom: 18px;
    }

    .num {
        padding: 10px 13px;
        position: absolute;
        left: 0;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: 1px solid @color-primary;
        color: @color-primary;
        font-size: 20px;
        &:extend(.rubik);
    }
}

.stay-informed {
    padding: 5px 0 0;
    color: @color-dark-grey;
}

.payment-methods {
    padding: 0 0 20px;

    .title {
        flex-grow: 2;
        margin-right: 4px;
        max-width: 130px;
        min-width: 130px;
        vertical-align: top;
    }

    .img-data {
        img {
            margin-right: 5px;
        }
    }

    .title,
    .img-data {
        margin-right: 5px;
    }

    .data {
        min-width: 80px;
    }

    .additional-data {
        flex-grow: 2.4;
        margin-right: 5px;
    }

    .payment-holder {
        align-items: center;
        border-bottom: 1px solid @color-grey;
        flex-wrap: wrap;
        padding: 25px 0 10px;
        margin: 0 40px;

        .grdcell {
            min-width: auto;
        }
    }

    .title,
    .img-data,
    .additional-data,
    .data {
        margin-bottom: 15px;
    }

    .payment-method {
        &:last-of-type {
            .payment-holder {
                border-bottom: 0;
            }
        }
    }

    h3 + div {
        padding: 10px 30px 20px;
    }

    // Posamezne metode
    .cc {
        .img-data {
            min-width: 200px;
        }
    }
}

.checkout-stage .payment-options {
    display: none;
}

/* ---------- QR koda ------------ */

#qr-icon {
    margin-top: -20px;
    max-width: 80px;
}

#qr-img {
    margin-bottom: 0;
}

/* ----------  UPN nalog  ---------- */
.upn {
    .title {
        padding: 0 20px 0 0;
    }

    .img-data {
        max-width: 60px;
    }
}

#sepa-img {
    margin-top: 5px;
    max-width: 48px;
}

.paysafecard-img {
    margin-top: 5px;
    max-width: 120px;
}

#upn {
    position: relative;
    float: left;
    width: 100%;
    min-width: 360px;
    overflow-x: scroll;

    .client,
    .company {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        width: 100%;
        padding: 10px 20px 10px 40px;
        margin: 0;
    }

    h6 {
        transform: rotate(-90deg);
        display: inline-block;
        position: absolute;
        color: @color-upn-orange;
    }

    .client-title {
        left: -10px;
        top: 35px;
    }

    .client {
        background: @color-upn-top;
    }

    .company-title {
        left: -20px;
        top: 200px;
    }

    .company {
        background: @color-upn-bottom;
    }

    .input,
    .textarea {
        font: bold 12px/1'Courier New', mono;
        float: left;
        padding: 3px 0 1px 1px;
        letter-spacing: 3px;
        border: 1px solid @color-upn-orange;
        background-color: #fff;
    }

    .input {
        padding-left: 2px;
        height: 22px;
        background-image: url(../img/cms/checkout/checkout-upn-lines.png);
        background-repeat: repeat-x;
        background-position: 12px 50%;
        line-height: 16px;
        letter-spacing: 5px;
        width: 100%;
    }

    .textarea {
        width: 100%;
        min-height: 22px;
        padding-left: 4px;
    }

    label {
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
        display: block;
        float: left;
        clear: both;
        width: 100%;
        margin: 0 0 3px;
        color: @color-upn-orange;
    }

    ul li {
        display: block;
        float: left;
        clear: both;
        margin: 0 0 7px;
    }

    .client {
        .name {
            width: 100%;
        }

        .transaction-title .input {
            width: 100%;
        }

        .transaction-title {
            float: right;
            clear: none;
            width: 80%;
            margin-bottom: 0;
        }

        .code {
            clear: none;
            width: 18%;
            margin-bottom: 0;

            .input {
                width: 49px;
            }
        }
    }

    .company {
        .value {
            clear: none;
            width: 50%;

            .currency {
                font-weight: bold;
                display: block;
                float: left;
                margin-right: 10px;
                padding: 15px 0 0;
            }

            label {
                clear: none;
            }

            .input {
                width: 100%;
                text-align: right;
            }

            .input-group {
                width: 55.4%;
                float: left;

                span {
                    position: relative;
                    right: -3px;
                }
            }
        }

        .bic {
            float: right;
            clear: none;
            width: 30%;

            .input {
                width: 121px;
            }
        }

        .iban {
            min-width: 280px;
            max-width: 287px;
        }

        .reference {
            width: 100%;

            .p1 {
                margin-right: 11px;
                width: 49px;
            }

            .p2 {
                width: 228px;
            }
        }
    }

    .upn-title {
        margin-left: 12px;
    }

    .recipient {
        .textarea {
            line-height: 20px;
        }
    }
}

/* ----------  ENDOF UPN nalog  ---------- */
#moneta {
    .img-data {
        max-width: 60px;
    }

    .additional-data {
        max-width: 260px;
    }

    img {
        vertical-align: top;
        margin: -6px 30px -6px 0;
    }

    .c-dgry {
        display: inline-block;
        max-width: 240px;
    }
}

#paypal {
    img {
        margin-top: 9px;
    }
}

#cc {
    img {
        margin: 5px 5px 0 0;
    }
}

#bitcoin {
    img {
        margin-left: 5px;
    }
}

/* =====  End of Checkout last step  ====== */

/* =====  End of CHECKOUT  ====== */

/* ----------  Lightbox  ---------- */
.lightbox {
    position: absolute;
    width: 70%;
    left: 50%;
    margin-left: -35%;
    z-index: 101;

    .flat-tabs {
        flex-wrap: wrap;

        li {
            margin-right: 2px;
        }
    }
}

.lightbox-header {
    min-height: 60px;

    .title {
        padding: 12px 70px 0 20px;
    }

    .icon-close,
    .mfp-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 10px;
        width: 60px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 42px;
        line-height: 60px;
        cursor: pointer;
    }
}

.lightbox-content {
    background-color: #fff;
    padding: 30px 20px;

    & > p {
        &:first-of-type {
            margin-top: 0;
        }
    }

    .footer-hint {
        position: relative;
        margin: 30px -20px -41px;
        padding: 1px 20px;
        background-color: @color-light-yellow;

        i {
            color: @color-secondary-alt;
        }
    }
}

.tag {
    position: relative;
    display: inline-block;
    padding: 3px 8px;
    background-color: @color-grey;
    border-radius: 3px;

    &.blue {
        background-color: @color-primary;
        color: #fff;
    }
}

/* Facebook promo - message boxx */
.error-msg {
    max-width: 362px;
    width: 100%;
    padding: 20px;
    min-height: 100px;
    background-color: #fff;
    display: inline-block;

    .btn-green,
    .btn-blue {
        margin: 0 5px 5px;
    }
}

/* =========================================
=            Namestitev strani            =
========================================= */

.install-option {
    border: 1px solid @color-grey;
    color: inherit;
    display: block;
    margin: 20px;
    padding: 15px 18px 0;
    text-decoration: none;
    .transition(background 200ms ease-out);

    .sprite {
        max-width: 52px;
        min-width: 52px;
    }

    .option-title,
    .pricing {
        min-width: auto;
    }

    .option-title {
        flex-grow: 4;
        margin: 10px 10px 10px 15px;
    }

    .pricing {
        margin-left: auto;
        margin-bottom: 0;
        text-align: right;

        .old-price {
            font-size: 0.8em;
            line-height: 1.3em;
            margin-right: 10px;
        }
    }

    .order-btn {
        align-self: flex-end;

        .btn-green {
            text-align: center;
            width: 100%;
        }
    }

    .content-holder {
        align-items: center;
        flex-wrap: wrap;
        position: relative;
    }

    .description {
        flex: 3 0 0%;
        margin-bottom: 0;
        min-width: 210px;
    }

    &:hover {
        background: @color-lightest-grey;
    }

    &:visited {
        color: inherit;
    }
}


/* =====  End of Namestitev strani  ====== */
