.icon-stars {
    color: @color-secondary-alt;

    &::before {
        content: '\e641 \e641 \e641 \e641 \e641';
        font-size: 1.5em;
    }

    &-4::before {
        &:extend(.icon-stars:before);

        content: '\e641 \e641 \e641 \e641';
    }

    &-3::before {
        &:extend(.icon-stars:before);

        content: '\e641 \e641 \e641';
    }
}

.pre-icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 0 12px 9px;
    height: 100%;
    border-right: 1px solid #ccc;
    width: 70px;

    & + h5 {
        padding-left: 60px;
    }

    .icon-holder {
        position: absolute;
    }
}

.icon-person {
    font-size: 48px;

    & + .icon-plus-circle-white,
    & + .icon-check-black-wbg {
        position: absolute;
        bottom: -1px;
        right: -7px;
        font-size: 32px;
    }
}

.icon-info-circle-alt,
.icon-info-circle-alt2 {
    .icon-mixin(@color-primary);
}

.icon-info-circle-warning-alt,
.icon-info-circle-warning-alt2 {
    .icon-mixin(@color-secondary-alt);
}

.plus-bold {
    position: relative;
    bottom: -3px;
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 20px;
    background-color: @color-secondary;
    color: white;

    &::after {
        position: absolute;
        left: 4px;
        top: -2px;
        content: '+';
        font-family: Arial, sans-serif;
    }
}

// For using icons in text
.in-text {
    &.icon-bolt {
        font-size: 1.3em;
        color: @color-secondary-alt;
    }
}

// Sprites - [class*='spr-']
.sprite {
    display: inline-block;
    background-repeat: no-repeat;
}

// popover trigger box
.round-arrow {
    display: inline-block;
    padding: 10px 11px;
    height: 60px;
    width: 60px;
    border: 4px solid;
    font-size: 32px;
    border-radius: 30px 0 30px 30px;
    color: #fff;
    cursor: pointer;

    &.left {
        border-radius: 0 30px 30px;
    }
}

.server-pros .litespeed-icon{
    background-image: url(../img/cms/gostovanje/litespeed.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 80px;
    height: 80px;
}