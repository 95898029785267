
/* ==========  Hosting packages  ========== */

.hosting-packages {
    .clearfix();

    border-top: 1px solid @color-lighter-grey;
}

.reseller-upsell {
    margin: 50px 0;

    .f-bigger {
        text-align: center;
    }
}

.package-list2 {
    align-items: center;
    position: relative;
    margin: 30px 0 70px 0;
    width:100%;
    float:left;

    .carousel-wrapper {

        position:relative;
        width:980px;
        margin: 0 auto;

        .tns-controls {
            span {
                color:#ccc;
                font-size:80px;
                top:45%;
                position:absolute;
                cursor:pointer;
                &:hover {
                    color:@color-darker-grey;
                }
            }
            .prev {
                left:-40px;
            }
            .next {
                right:-40px;
            }
        }

    }

    .icon-check-black {
        vertical-align: sub;
        line-height: 0;
        font-weight: bold;
        font-size: 26px;
        color: @color-secondary;
    }

    .hdd-space {

        &:not(:nth-of-type(2n)) {
            margin-bottom:15px;
            padding-top:0;
        }

        .space {
            &:extend(.rubik700);
            font-size: @font-size-large;
            line-height:40px;
            color: @color-black;
            padding-bottom:15px;
        }
    }

    .pkg {
        float:left;
        width: 300px;
        margin: 0;
        transition: all 200ms ease-in-out;

        .package-border {
            border: 4px solid #ddd;
            border-radius: 10px;
        }

        .package-title {
            color: #fff;
            text-align: center;
            min-height: 125px;
            padding: 22px 0 0 0;
            position: relative;

            .ribbon-diag {
                right:-4px;
                top:-5px;
                .text {
                    top:23px;
                    right:-33px;
                    font-size:.7rem;
                }
            }

            &::after {
                content: ' ';
                width: 0;
                height: 0;
                border-left: 146px solid transparent;
                border-right: 146px solid transparent;
                border-bottom: 25px solid #fff;
                display:block;
                position:absolute;
                bottom:0;
            }

            h3 {
                &:extend(.rubik700);
                font-size: 3.6em;
                text-transform:uppercase;
                line-height: 24px;
                margin: 0;
                padding: 8px 5px 11px 5px;
                text-shadow: 1px 1px 2px #000;
            }

            span {
                font-size: @font-size-smaller;

            }
        }

        &.hidden {
            opacity: 0;
            display: flex !important;
            height: 0;
            width: 0;
        }

        .price-box {
            padding: 10px 0;
            margin: 0 10px;
            height: auto;
            border-top: 1px solid @color-lighter-grey;
            border-bottom: 1px solid @color-lighter-grey;

            .dropdown {
                width: 90%;
                margin: 0 auto;
            }

            small {
                display: block;
            }

            .new-price {
                margin-top: 10px;
                display: inline-block;
                &:extend(.rubik700);
                font-size: @font-size-large;
                color: @color-black;

                .currency {
                    font-weight: 300;
                }
            }

            .old-price {
                margin-right: 5px;
                font-size: 18px;
            }

        }

        .order-box {
            margin: 0 0 5px 0;
            padding: 16px 8px 10px 8px;
            form {
                padding:0;
            }
            a, button {
                &:extend(.rubik600);
                font-size:@font-size-bigger;
                border:none;
                &:hover {
                    filter: brightness(105%);
                }
            }

        }

        .order-box-holder {
            padding-bottom: 20px;

            .selectric-wrapper {
                margin: 4px auto 0;
            }

            &:last-of-type {
                border-bottom-width: 1px;
            }

            .price-box,
            .order-box {
                border-left: 0;
            }

            form {
                padding: 0;
            }
        }

        .order-pkg {
            display: block;
        }
    }

    .col-1 {
        .package-border {
            border-color: #ddd;
        }
        .package-title {
            color: @color-dark-grey;
            background-color: #ddd;
        }
        .price-box {
            border-color:#ddd;
        }
    }

    .col-2 {
        .package-border {
            border-color: @color-secondary-alt;
        }
        .package-title {
            background-color: @color-secondary-alt;
        }
        .price-box {
            border-color:@color-secondary-alt;
        }
    }

    .col-3 {
        .package-border {
            border-color: @color-secondary;
        }
        .package-title {
            background-color: @color-secondary;
        }
        .price-box{
            border-color: @color-secondary;
        }
    }

    .col-4 {
        .package-border {
            border-color: @color-primary;
        }
        .package-title {
            background-color: @color-primary;
        }
        .price-box {
            border-color: @color-primary;
        }
    }

    .col-5 {
        .package-border {
            border-color: @color-red-package;
        }
        .package-title {
            background-color: @color-red-package;
        }
        .price-box {
            border-color: @color-red-package;
        }
    }

    .col-6 {
        .package-border {
            border-color: @color-fake-black;
        }
        .package-title {
            background-color: @color-fake-black;
        }
        .price-box {
            border-color: @color-fake-black;
        }
    }

    select {
        margin-top: 8px;
        width: 90%;
    }

    .before-price {
        padding-bottom: 12px;
    }
}

.old-price {
    display: inline-block;
    position: relative;
    font-weight: normal;

    &::after {
        .box-shadow(1px 0 0 1px @color-fake-black inset);

        content: ' ';
        position: absolute;
        right: -1%;
        top: 1px;
        width: 110%;
        height: 1px;
        transform: translateY(9px) translateX(4%) rotate(-15deg);
    }

    &.empty {
        &::after {
            display: none;
        }
    }
}

.feat-list {
    background: #fff;
    text-align: center;

    .icon-bolt {
        font-size: 1.3em;
        color: @color-secondary-alt;
    }

    .price-duration {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid @color-lighter-grey;

        & > .pkg-subspec {
            padding-bottom: 15px;

            &:nth-of-type(1n+2) {
                //min-height: 43px;
            }
        }

        .old-price {
            font-size: 14px;
            color: @color-darker-grey;

            &::after {
                .box-shadow(1px 0 0 1px @color-darker-grey inset);
            }
        }

        .new-price {
            display: inline-block;
            font-size: 17px;
            margin: 0 5px;
        }

        .special {
            .new-price {
                font-size: 1.43em;
            }
        }

        .order-box {
            padding: 25px 5px;
        }
    }

    .discount-info {
        padding-top: 3px;

        & > strong {
            padding: 3px 5px;
            color: @color-dark-grey;
            background-color: @color-lighter-grey;
            border-radius: 5px;
        }

        .icon-info-circle-alt {
            margin-left: 8px;
        }
    }
}

.pkg-spec {

    min-height: 32px;
    padding: 8px 0;
    position: relative;

    &.brd-bt {
        min-height: 41px;
    }

    &.brd-tp {
        padding-top: 10px;
    }

    &.dbl-hght {
        min-height: 61px;

        .icon-check-black,
        .algnfix {
            position: relative;
            top: 9px;
        }
    }

    &.trpl-hght {
        min-height: 101px;
    }

    &:nth-of-type(2n) {
        background: #f5f5f5;
    }

    &.pkg-subspec {
        padding: 0;
    }
}

.pkg-subspec {
    background: #fff;
    min-height: 32px;
    min-width: 155px;
    padding: 8px 12px;

    &:nth-of-type(2n) {
        background: #f5f5f5;
    }
}

.hosting-price {
    margin: 5px 0;
}


.stevec {
    display: none;
    .wrapper {
        position: relative;
        margin: auto;
      }
      .meter {
        width: 100%; height: 100%;
        transform: rotateX(180deg);
      }
      .circle {
        fill: none;
      }
      .outline, .mask {
        stroke: #e6e6e6;
        stroke-width: 30;
      }
      .range {
        stroke-width: 29;
      }
      .outline_ends{
          stroke: transparent;
      }
      .slider, #lbl {
        position: absolute;
      }
      .slider {
        cursor: pointer;
        left: 0;
        margin: auto;
        right: 0;
        top: 58%;
        width: 94%;
      }

      .meter_needle {
        height: 40%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 10%;
        transform-origin: bottom center;
        /*orientation fix*/
        transform: rotate(270deg);
      }
      .meter_needle-pin {
        height: 40%;
        width: 100%;
        left: 0px;
        margin: auto;
        position: absolute;
        right: 0;
        top: 15%;
        /*orientation fix*/

      }
}

/* TABS */

.package-list2 {
    .tabs {
        margin:20px 0;
        height:32px;
        width:100%;
        padding:0;
        display:flex;
        justify-content: center;

        li {
            list-style-type: none;
            display:block;
            border-bottom:1px solid #aaa;

            a {
                font-weight:bold;
                text-transform: uppercase;
                color: #aaa;
                display:block;
                padding:4px 24px;
                font-size: @font-size-bigger;
                white-space:nowrap;

                &.active {
                    color: @color-primary;
                    border-bottom: 3px solid @color-primary;
                }
                &:hover {
                    color: @color-darker-grey;
                    border-bottom: 3px solid @color-darker-grey;
                }
            }
        }
    }
}

/* SPECIFIC PACKAGE STYLES */

.package-list2 {
    &.studio-packages {
        .package-title {
            h3 {
                font-size:2.5rem;
            }
        }
    }
}

.package-list2 {
    #pkg-2205 {
        .package-title {
            h3 {
                font-size:2.3em;
            }
        }
    }
    #pkg-2632 {
        .package-title {
            h3 {
                font-size:2.3em;
            }
        }
    }

}
