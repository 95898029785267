.bx-maintitle {
    align-items: center;
    display: flex;
    margin-top: 0;
    margin-bottom: 5px;
    min-height: 40px;
}
.height-full {
    flex-grow:1;
}
.brd-bx {
    border: 1px solid @color-grey;
    border-radius: @box-border-radius;
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;

    &.brd-primary {
        border-color: @color-primary;
    }

    &.brd-secondary {
        border-color: @color-secondary;
    }

    &.brd-secondary-alt {
        border-color: @color-secondary-alt;
    }

    &.brd-red {
        border-color: @color-red-package;
    }

    &.brd-thick {
        border-width: 4px;
    }

    &.no-autoheight {
        height: auto;
    }

    &.small {
        .bx-ttl {
            padding: 15px 15px 14px;
        }

        .bx-cntnt {
            padding: 5px 15px 14px;
        }
    }

    &.inside-border {
        border: 0;

        .bx-ttl {
            border-style: solid;
            border-color: @color-grey;
            border-width: 1px 1px 0;
        }
    }

    &.grouped-header {
        .bx-ttl {
            display: inline-block;
            width: 38%;
            border-bottom: 1px solid #ccc;
            min-height: 98px;

            & + div {
                display: inline-block;
                width: 62%;
                vertical-align: top;
                border: 0;
                border-bottom: 1px solid #ccc;
                min-height: 98px;
            }

            .rubik {
                position: relative;
                top: 12px;
            }
        }
    }

    .bx-ttl {
        position: relative;
        padding: 15px 20px 14px;
        height: auto;
        border-radius: @box-border-radius @box-border-radius 0 0;
        flex-shrink: 0;
        text-align:center;
        text-transform: uppercase;


        &.appended {
            .appn {
                width: 27%;
                display: inline-block;
                text-align: right;

                &.f-huge {
                    color: @color-secondary-alt;
                    float: right;
                }
            }

            h5 {
                display: inline-block;
                text-align:left;
                width: 70%;
                font-size:@font-size-big;
            }
        }

        h3,
        h4,
        h5,
        h6 {
            margin: 0;
            &:extend(.rubik);
        }

        &.padding-left {
            padding: 15px 20px 15px 115px;

            .bx-crcle {
                right: initial;
                left: 5%;
                top: initial;
                bottom: -40px;

                & + .bx-ttl {
                    text-align: left;
                    padding-left: 115px;
                }
            }
        }

        &.sup-titled {
            padding: 20px 20px 18px;

            strong {
                color: @color-secondary-alt;
            }

            h3 {
                padding-top: 3px;
            }
        }
    }

    .double {
        min-height: 40px;
    }

    .bx-img {
        vertical-align: middle;
        text-align: center;
        height: auto;
        flex-shrink: 0;
        position: relative;

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
        .price-on-img{
            position: absolute;
            bottom:35px;
            left: 35px;
            background-color: transparent;
            color: #fff;
            text-align: left;
            span{
                font-weight: bold;
                font-size: 30px;
                line-height: 30px;

            }
        }
    }
    .bx-img-centered:extend(.bx-img) {
        width: 260px;
        height: 120px;
    }

    .padding-left {
        background-color: #fff;

        .bx-crcle {
            background-color: #fff;
        }
    }

    &.round-icons {
        .bx-ttl {
            padding-left: 95px;
            min-width: 280px;
        }

        .bx-img {
            position: relative;

            & .holder {
                position: absolute;
                top: -30px;
                left: 20px;
            }
        }

        &.double-icons {
            .bx-ttl {
                padding-left: 161px;
            }

            & .holder {
                left: 17px;

                &.second-icon {
                    left: 86px;
                }
            }
        }
    }

    .numbering {
        position: absolute;
        top: -22px;
        padding: 0;
        display: inline-block;
        height: 46px;
        width: 46px;
        margin-left: -22px;
        border: 6px solid #fff;
        border-radius: 18px;
        font-size: 1.43em;
        line-height: 32px;
        text-align: center;
        letter-spacing: 1px;
        background-color: @color-dark;
        color: #fff;

        & + h5 {
            padding: 10px 0 0px;
        }
    }
}

.bx-cntnt {
    .clearfix();

    -ms-display: flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 190px; // 190px - server-pros
    position: relative;
    padding: 4px 20px 20px;

    &.height-full {
        /*height: 100%;*/
    }

    &.bottom-cntnt {
        margin-top: auto;
    }

    .btn-fw {
        flex-grow: 0;
    }

    .holder-btn {
        margin-top: auto;
        text-align: center;
        width: 100%;

        .btn-brd,
        .btn-big,
        .btn-small {
            margin-top: 10px;
        }
    }

    p {
        margin-bottom: 0;
    }

    &.big {
        padding: 10px 20px 20px;

        p {
            margin-bottom: 1.2em;
        }
    }

    &.padding-left {
        padding: 5px 20px 20px 115px;

        &.extra {
            padding: 5px 20px 20px 115px;
        }

        .bx-crcle {
            right: initial;
            left: 5%;
            top: -40px;

            & + .bx-ttl {
                text-align: left;
                padding-left: 115px;
            }
        }
    }

    &.tuts {
        padding-bottom: 15px;
    }

    & .simple-tabs {
        overflow: auto;
        margin: -4px -20px 0 -20px;
        padding: 0;

        &.bg-primary {
            &,
            & > a {
                color: #fff;
            }
        }

        & > a.tab {
            background-color: @color-primary;
            float: right;
            padding: 10px;
            text-decoration: none;

            &.active {
                background: #fff;
                font-weight: bold;
                color: @color-fake-black;
            }
        }
    }

    img.align-right {
        padding-left: 25px;
        padding-bottom: 10px;
    }

    img.align-left {
        padding-right: 20px;
        padding-bottom: 10px;
    }
}

.bx-crcle {
    position: absolute;
    top: -40px;
    right: 50%;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    margin-right: -40px;
    border: 1px solid @color-grey;
    background-color: @color-lightest-grey;

    & + .bx-ttl {
        margin-top: 30px;
        text-align: center;
    }
}

@media @break-sm {
    .brd-bx {
        &.round-icons,
        &.round-icons.double-icons {
            .bx-ttl {
                padding-top: 0;
                padding-left: 0;
                min-width: 265px;
            }
        }
    }
}
