/* Write a review */

.ceo-letter {
    .signature {
        font-style: italic;
    }

    .andy-ceo {
        float: right;
        margin-right: 30px;
    }

    .u-1of4 {
        margin-left: 20%;
    }
}

#review-form {
    &.material {
        margin: 0 20% 15px 20%;
        textarea {
            min-height: 100px;
        }
    }

    .text-rating {
        float: left;
    }

    .ratingicon {
        color: @color-secondary-alt;

        &::before {
            content: "\e641";
            font-family: neoserv;
            font-size: 47px;
        }

        &:hover {
            color: gold !important;
            cursor: pointer !important;
        }
    }

    .ok,
    .invalid-email-error,
    .generalerror {
        margin-top: -20px;

        p {
            float: right;
            text-align: right;
            padding-right: 70px;
        }

        i {
            top: 0;
        }
    }

    .input-group {
        min-width: 220px;
    }

    .btn-blue {
        min-width: 170px;
    }

    .rating-select,
    .rating-submit {
        align-items: center;
    }
}
