/* black friday */

/* main menu links */
.black-friday-body #main-header:not(.scrolled, .fixed-menu) .top-menu nav .main-menu .main-menu-item.active.gostovanje:not(.home),
.black-friday-body #main-header:not(.scrolled, .fixed-menu) .top-menu nav .main-menu .main-menu-item.turbo.active.gostovanje:not(.home) {
    background-color: #272122;
    box-shadow: 0 5px 0 -2px #272122;
}

/* bottom menu */
.black-friday-body #main-header .bottom-menu.gostovanje,
.black-friday-body #main-header .bottom-menu.hosting-landing,
.black-friday-body #main-header .bottom-menu.spletno-gostovanje,
.black-friday-body #main-header .bottom-menu.reseller-gostovanje,
.black-friday-body #main-header .bottom-menu.turbo,
.black-friday-body #main-header .bottom-menu.namenski-strezniki,
.black-friday-body #main-header .bottom-menu.e-postno-gostovanje,
.black-friday-body #main-header .bottom-menu.optimizirano-gostovanje {
    background: #272122;
}

.black-friday-body {

    .bg-gostovanje {

        .benefits {
            width:50%;
            li {
                width: 110px;
                margin: 0;
                font-size:12px;
            }
        }

        .andy {
            background: url(../img/cms/kv/blackfriday-andy-shoppingcart.png) no-repeat top left;
            position:absolute;
            right: 299px;
            bottom: 79px;
            height: 160px;
            width: 300px;

            .bubble {
                position:absolute;
                right: -22px;
                bottom: 86px;
            }
        }

        .balloon {

            position:absolute;
            right:50px;
            top: 80px;

            -webkit-animation: Floatingy 3s infinite ease-in-out;

            @-webkit-keyframes Floatingy {
                from {
                    -webkit-transform:translateY(0px);
                }
                65% {
                    -webkit-transform:translateY(15px);
                }
                to {
                    -webkit-transform: translateY(-0px);
                }
            }
        }
    }
}

/* hosting landing */
@media (max-width: 1080px) {
    .black-friday-body {
        .bg-gostovanje {
            .andy {
                right:182px;
            }
        }
    }
}

@media (max-width: 960px) {
    .black-friday-body {
        .bg-gostovanje {
            .andy {
                display:none;
            }
            .benefits {
                width:75%;
            }
        }
    }
}

@media (max-width: 700px) {
    .black-friday-body {
        .bg-gostovanje {
            .balloon {
                display:none;
            }
            .benefits {
                width:100%;
                li {
                    margin: 30px 0 0 0;
                }
            }
        }
    }
}

.black-friday-body .kv-blackfriday {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(63,53,54,1) 50%, rgba(0,0,0,1) 100%);
    color: #fff;

    .left-side {
        float:left;
        width:50%;
        position:relative;
        padding-left:20px;
        height: 350px;
    }
    .deals {
        float:right;
        width:50%;
        position:relative;
        height: 350px;
    }
    .yellow {
        color: #fdff5f;
    }

    .title-section {
        margin: 15px 0 30px 0;
        h2 {
            font-size:52px;
        }
        h4 {
            font-size:18px;
            text-transform: uppercase;
            margin:-8px 0 0 5px;

            .yellow {
                font-weight: 900;
            }
        }
    }

    .countdowntitle {
        font-size: 13px;
        padding: 8px 0 0 2px;
    }

    #countdownblackfriday {
        margin: 10px 0 0 0;
        div {
            text-align:center;
            font-weight:bold;
            font-size: 34px;
            margin:0 3px 0 0;
            span{
                font-size: 13px;
                font-weight: normal;
                color: #999;
            }
        }
        div:nth-child(odd) {
            width: 45px;
        }
    }
    .cta {
        margin: 14px 0 0 5px;
        a {
            border: 2px solid #ffcd05;
            color: #fdff5f;
            text-transform: uppercase;
            padding: 8px 15px 8px 12px;
            font-weight:bold;
            text-decoration: none;
            font-size: 17px;

            &:hover {
                text-decoration:none;
                color: #fff;
                border-color:#fff;
                text-shadow:0px 0px 20px #fff;
                -moz-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                -webkit-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
            }
        }
    }

    .andy {
        position:absolute;
        right:18px;
        bottom: 18px;
    }

    .deals {

        .balloon {
            position:absolute;
            left:21%;
            top: 42px;

            -webkit-animation: Floatingy 3s infinite ease-in-out;

            @-webkit-keyframes Floatingy {
                from {
                    -webkit-transform:translateY(0px);
                }
                65% {
                    -webkit-transform:translateY(15px);
                }
                to {
                    -webkit-transform: translateY(-0px);
                }
            }
        }

        .rack {
            position:absolute;
            right:30%;
            bottom: -20px;
        }

        .deal {
            text-decoration:none;
            position:absolute;
            visibility:hidden;

            h5 {
                color: #fff;
                font-size: 24px;
                padding: 0 0 3px 0;
                margin: 0 0 4px 0;
                line-height: 24px;
                position:relative;
                -webkit-transition: text-shadow 0.2s linear;
                -moz-transition: text-shadow 0.2s linear;
                -ms-transition: text-shadow 0.2s linear;
                -o-transition: text-shadow 0.2s linear;
                transition: text-shadow 0.2s linear;
                span {
                    font-size:20px;
                }
            }
            .old-price {
                color: #999;
                font-size:17px;
                padding:2px 0 3px 0;
                &::after {
                    box-shadow: 1px 0 0 1px #fff inset;
                }
            }
            .new-price {
                color: #fff;
                margin:0 0 0 0px;
                font-weight:bold;
                font-size: 20px;
            }
            .bullet {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                position:absolute;
            }
            &.orange {
                text-align:right;
                right:35px;
                bottom: 70px;
                h5 {
                    color:@color-secondary-alt;
                    border-bottom: 2px solid @color-secondary-alt;
                    padding-left:50px;
                    .bullet {
                        background-color:@color-secondary-alt;
                        left:0;
                        bottom:-7px;
                    }
                }
            }
            &.green {
                text-align:right;
                right: 80px;
                top: 45px;
                h5 {
                    color:@color-secondary;
                    border-bottom: 2px solid @color-secondary;
                    padding-left:30px;
                    .line {
                        height: 50px;
                        width: 200px;
                        border-left: 3px solid @color-secondary;
                        -moz-transform: skew(-45deg);
                        -webkit-transform: skew(-45deg);
                        transform: skew(-45deg);
                        position:absolute;
                        left:-25px;
                        bottom:-50px;
                    }
                    .bullet {
                        left:-54px;
                        bottom:-57px;
                        background-color:@color-secondary;

                    }
                }
            }
            &.blue {
                text-align:left;
                bottom: 25px;
                left: 35px;
                h5 {
                    color:@color-primary;
                    border-bottom: 2px solid @color-primary;
                    padding-right: 40px;
                    .bullet {
                        background-color:@color-primary;
                        right:0;
                        bottom:-7px;
                    }
                }
            }
            &:hover {
                h5 {
                    color:#fff;
                    text-shadow:0 0 1px #fff, 0 0 1px #fff, 0 0 30px #fff;
                }
            }
        }
    }
}


@media (max-width: 1150px) {
    .black-friday-body .kv-blackfriday {
        .deals {
            .balloon {
                left: 15%;
            }
            .deal.blue {
                left: 15px;
            }
        }
    }
}
@media (max-width: 1100px) {
    .black-friday-body .kv-blackfriday {
        .deals {
            .balloon {
                left: 10%;
            }
            .rack {
                width:180px;
                right:34%;
                bottom: 15px;
            }
            .deal.blue {
                left: 15px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .black-friday-body .kv-blackfriday {
        .title-section {
            h2 {
                font-size: 45px;
            }
        }
        .left-side {
            .andy {
                width:150px;
            }
        }
        .deals {
            .balloon {
                left: 0%;
            }
            .rack {
                width:150px;
                right:38%;
                bottom: 20px;
            }
            .deal.blue {
                left: 5px;
            }
            .deal.orange {
                right: 0px;
            }
        }
    }
}

@media (max-width: 900px) {
    .black-friday-body .kv-blackfriday {
        .left-side {
            .andy {
                display:none;
            }
        }
        .deals {
            .balloon {
                left: 0%;
            }
            .rack {
                width:140px;
                right:41%;
                bottom: 90px;
            }
            .deal.blue {
                left: -15px;
            }
            .deal.orange {
                right: -10px;
            }
        }
    }
}

@media (max-width: 850px) {
    .black-friday-body .kv-blackfriday {
        .deals {
            .balloon {
                display:none;
            }
            .rack {
                width:120px;
                right:41%;
                bottom: 90px;
            }
            .deal.blue {
                left: -15px;
            }
            .deal.orange {
                right: -5px;
            }
        }
    }
}

@media (max-width: 760px) {
    .black-friday-body .kv-blackfriday {
        .title-section {
            h2 {
                font-size: 35px;
            }
            h4 {
                font-size: 14px;
            }
        }
        .deals {

            .rack {
                width:100px;
                right:41%;
                bottom: 100px;
            }
            .deal.blue {
                left: -15px;
            }
            .deal.orange {
                right: -5px;
            }
        }
    }
}

@media (max-width: 700px) {
    .black-friday-body .kv-blackfriday {
        .title-section {
            h2 {
                font-size: 30px;
            }
        }
        .deals {
            .balloon {
                display:block;
            }
            .deal {
                .bullet {
                    display:none;
                }
                .line {
                    display:none;
                }
                h5 {
                    padding: 0 0 3px 0 !important;
                }
            }
            .deal.blue {
                left: 10px;
            }
            .deal.orange {
                right: 5px;
            }
            .deal.green {
                right: 5px;
            }
            .rack {
                display:none;
            }
        }
    }
}

@media (max-width: 575px) {
    .black-friday-body .kv-blackfriday {
        .left-side {
            width: 70%;
        }
        .title-section {
            h2 {
                font-size: 38px;
                margin-bottom:10px;
            }
            h5 {
                font-size: 15px;
            }
        }
        .deals {
            width: 30%;
            .balloon {
                display:block;
            }
            .deal {
                .bullet {
                    display:none;
                }
                .line {
                    display:none;
                }
                display:none !important;
            }

            .rack {
                display:none;
            }
        }
    }
}

@media (max-width: 480px) {
    .black-friday-body .kv-blackfriday {
        .left-side {
            width: 70%;
        }
        .title-section {
            h2 {
                font-size: 30px;
                margin-bottom:10px;
            }
            h5 {
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 400px) {
    .black-friday-body .kv-blackfriday {
        .left-side {
            width: 100%;
            padding:0;
            text-align:center;
            h2 {
                font-size:37px;
            }
            #countdownblackfriday {
                width: 240px;
                margin:5px auto 0 auto;
            }
            .cta {
                text-align:center;
                margin:36px 0 0 0;
            }
        }
        .deals {
            display:none;
        }
    }
}
@media (max-width: 330px) {
    .black-friday-body .kv-blackfriday {
        .left-side {
            h2 {
                font-size: 30px;
            }
        }
    }
}
