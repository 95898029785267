/* Zakaj izbrati nas */

/* Plačilne metode */
.payment-visual {
    margin-bottom: 40px;
    position: relative;

    .secured-ssl {
        text-align: right;
        margin-right: 25px;
    }

    .payment-img {
        width: 100%;
    }
}

.visual-header {
    background: #fff;
    flex-grow: 1.2;
    padding: 15px 20px;

    h2 {
        margin: 0;
    }
}

.visual-addon {
    align-items: center;
    bottom: 25px;
    position: absolute;
    width: 100%;

    .grdcell {
        min-width: 265px;
    }

    p {
        display: inline-block;
        margin-right: 11px;
    }

    img {
        display: inline-block;
    }
}

@media (max-width: @break-md-value) {
    .visual-header {
        padding: 0;
    }

    .visual-addon {
        bottom: 0;
        position: relative;
    }

    .payment-visual {
        .secured-ssl {
            text-align: left;
        }

        .ssl-lock {
            display: none;
        }
    }
}
