@media @break-sm {
    .appended-content {
        .main-side {
            display: none;
        }
    }

    .margin-tab {
    	margin-right: 0;
	}
}

@media (max-width: @break-md-value) {
    .container.content > .grd:not(.package-list) > .grdcell:first-child {
        max-width: inherit;
        flex: 0 0 100%;
    }
    .content {
        .page-header {
            h1 {
                font-size:@font-size-evenbigger;
            }
            p {
                font-size: @font-size-bigger;
                margin-top:5px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .inverse {
        display: flex;
        flex-direction: column-reverse;

        .text-right {
            text-align: unset !important;
        }
    }
}

@media only screen and (max-width: 991px) {
    .inverse {
        display: flex;
        flex-direction: column-reverse;
    }
}
