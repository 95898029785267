
html {
    color: @color-fake-black;
    font-size: @font-size;
    line-height: 1.43em;
    font-family: Arial, Helvetica, "Liberation Sans", FreeSans, Sans-serif;
}

body, html {
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    overflow-x: hidden;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

::-webkit-input-placeholder {
    color: @color-grey;
}

:-moz-placeholder { /* Firefox 18- */
    color: @color-grey;
}

::-moz-placeholder { /* Firefox 19+ */
    color: @color-grey;
}

:-ms-input-placeholder {
    color: @color-grey;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 25px 0 25px;
    padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%; // responsive
    height: auto;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

select {
    padding: 8px 25px 8px 10px;
    color: @color-fake-black;
    border: 1px solid #ccc;
    border-radius: 1px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fdfdfd url('../img/icons/icon_carret-down.svg') no-repeat 95%;
    background-size: 17px;
}

ul {
    padding-left: 25px;
    margin: 10px 0;
    li {
        padding-bottom: 3px;
    }
}

ol {
    padding-left: 25px;
    margin: 10px 0;
    li {
        padding-bottom: 3px;
    }
}

ul.f-bigger {
    li {
        padding-bottom: 10px;
    }
}

// removes empty space after body
footer {
    //overflow: hidden;
}

figcaption {
    margin: 7px 0 5px;
    color: @color-dark-grey;
    font-size: @font-size-smaller;
}

p {
    margin: 11px 0;
    line-height: 1.5em;
    font-size: 1.03rem;
}

blockquote {
    padding: 20px 55px 20px 45px;
    background-color: #fafafa;
    margin: 20px 0;
    font-style: italic;
    position: relative;
    color: @color-primary;

    &::before,
    &::after {
        content: "\"";
        font-size: 50px;
        font-family: Georgia, serif;
        position: absolute;
    }

    &::before {
        top: 30px;
        left: 15px;
    }

    &::after {
        bottom: 10px;
        right: 25px;
    }
}

[role="button"] {
    cursor: pointer;
}

.auto-height {
    .transition(opacity 200ms ease-in-out);
}

/* Diagonal package ribbon */
.ribbon-diag {
    position: absolute;
    top: -5px; /*-10px;*/
    right: -6px;
    width: 145px;
    height: 145px;
    overflow: hidden;
    z-index: 1;
    line-height: 15px;

    .text {
        position: relative;
        top: 12px;
        right: -43px;
        display: block;
        width: 100%;
        height: 35px;
        padding: 5px 30px;
        line-height: 12px;
        font-size: @font-size-small;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        background-color: @color-fake-black;
        font-weight: bold;
    }

    &.sm {
        right: -2px;
        top: -2px;
        width: 135px;
        height: 135px;

        .text {
            height: 25px;
            text-align: center;
            padding: 6px 30px;
        }
    }

    &.big {
        top: 0;
        right: 0;
        width: 245px;
        height: 245px;

        .text {
            top: 2.5em;
            right: -3.5em;
            height: 45px;
            line-height: 2.2em;
            font-size: 1.2rem;
        }
    }

    .bg-primary {
        color: #fff;
        background-color: @color-primary;
    }

    .bg-secondary {
        color: #fff;
        background-color: @color-secondary
    }

    .bg-secondary-alt {
        color: #fff;
        background-color: @color-secondary-alt
    }

    .bg-red {
        color: #fff;
        background-color: @color-red;
    }
}

.svg-img {
    max-height: 100%;
}

// Content ima vedno prednost pred sidebarom
.grdcell.main-content {
    flex-grow: 4;
}

// Klas za body
.responsive {
    padding-top: 90px;
    .mfp-content {
        margin-top: 70px;
    }
}

// Responsive video holder
.res-videoholder {
    position: relative;
    padding-bottom: 53.5%;
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
