/* Domain page */

.kv-collapse {
    position: relative;

    .top-content {
        position: relative;

        .res-img {
            border-top-left-radius: @box-border-radius;
            border-top-right-radius: @box-border-radius;
        }

        .tld-icon {
            position: absolute;
            top: 0;
            left: 5%;
            background: @color-white;
            border-bottom-right-radius: @box-border-radius;
            border-bottom-left-radius: @box-border-radius;
            -webkit-box-shadow: 5px 8px 5px -3px rgba(0,0,0,0.52);
            -moz-box-shadow: 5px 8px 5px -3px rgba(0,0,0,0.52);
            box-shadow: 5px 8px 5px -3px rgba(0,0,0,0.52);

            .tld-font {
                width: 130px;
                text-align: center;
            }
            .flag {
                display:none;
            }

            &.si-icon {
                padding: 20px;
                img {
                    width: 100px;
                }
            }

            img {
                width: 150px;
            }
        }

    }

    .logo {
        position: absolute;
        top: 50%;
        left: 35px;
        max-height: 100%;
        max-width: 100%;
        margin-top: -60px;
    }

    .img-desc {
        position: absolute;
        top: 15px;
        right: 20px;

    }

    .collapse-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #fff;
        padding: 8px 40px 8px 20px;
        color: @color-darker-grey;
        text-decoration: none;

        &::after {
            .iconFamily();
            content: '\e635';
            position: absolute;
            right: 15px;
            font-size: 20px;
        }

        &.active {
            color: @color-primary;
            background-color: @color-lightest-grey;

            &::after {
                content: '\e62b';
            }
        }
    }
}

.collapsed-content {
    width: 100%;
    z-index: 2;
    display: none;
    padding: 20px;
    background-color: @color-lightest-grey;

    .grd {
        margin-bottom: 0;
    }

    .grdcell {
        min-width: 130px;
    }

    &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        // .rainbow-border();
    }

    &.active {
        display: block;
    }

    .description {
        .bigger {
            margin-bottom: 0;
        }
    }

    .location {
        img {
            border: 1px solid @color-grey;
            background-color: @color-lightest-grey;
        }
    }

    .details {
        // min-height: 340px;
    }

    .specific-list {
        font-size: @font-size-smaller;
    }

    .no-padd {
        & > .grdcell {
            padding-left: 10px;
            padding-right: 10px;

            &:last-of-type {
                padding-right: 0;
            }

            &:first-of-type {
                padding-left: 0;
            }
        }
    }
}

.domain-description {
    margin-top: 0;
}

.brd-bx .flag {
    vertical-align: top;

    img {
        max-height: 34px;
        margin-top: 3px;
    }
}

@media @break-md {
    .kv-collapse {
        .top-content {
            .tld-icon {
               img {
                   width: 50px;
               }
               .tld-font {
                   width: auto;
                   font-size: 40px;
                   padding: 0 20px;
               }
            }
            .tld-icon.si-icon {
                img {
                    width: 50px;
                }
            }

        }
    }
}
@media @break-sm {
    .domain-description {
        & > .grdcell {
            flex: 0 0 100%;
        }
    }

    .kv-collapse {
        .top-content {
            .tld-icon {
                display:none;
            }
        }
    }
}

