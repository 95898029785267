/*----------  Form + input  ----------*/
form {
    padding: 20px 0 10px;

    .right-side {
        float: right;
        max-width: 200px;
        width: 100%;

        input[type='text'] {
            display: block;
            width: 100%;
        }

        .std-list {
            max-width: 260px;
        }
    }

    .std-list {
        padding-bottom: 15px;
    }
}

.input-group {
    display: flex;
    flex-wrap: wrap;

    .input-holder {
        flex: 1 0 0%;
    }

    //&:extend(.clearfix all);

    &.inline {
        & > label,
        & > .label {
            padding-top: 10px;
            display: inline-block;
            float: left;
        }

        .radio {
            label,
            .label {
                padding-top: 0;
            }
        }
    }

    .notice {
        margin-bottom: 15px;
        margin-top: -10px;
    }

    .input-btn {
        position: relative;

        input[type='text'] {
            width: 100%;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px 25px;
            border: 0;
        }

        &.appended {
            input {
                padding: 5px 25px 5px 10px;
                width: 78%;
            }
        }

        .popover-holder {
            &:extend(.clearfix all);

            position: absolute;
        }
    }

    & div:not(.radio):not(.checkbox) > label {
        display: inline-block;
        padding-bottom: 5px;
    }

    input[type=email],
    input[type=text],
    input[type=password],
    textarea,
    select,
    .selectric-wrapper {
        margin-bottom: 15px;
    }
}

.input {
    &-xs {
        max-width: 60px;
    }

    &-sm {
        max-width: 140px;
    }

    &-md {
        max-width: 220px;
    }

    &-lg {
        max-width: 300px;
    }

    &-full {
        max-width: 100%;
    }
}

textarea,
input[type=email],
input[type=text],
input[type=password] {
    // overwritanje iOS izgleda
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: 6px 15px;
    border: 1px solid @color-grey;
    box-shadow: 1px 1px 1px @color-light-grey inset;
    margin: 0;
}

input[type=email],
input[type=text],
input[type=password] {
    height: 40px;
}

.lower {
    input[type=email],
    input[type=text],
    input[type=password] {
        height: 30px;
        padding: 5px 5px 5px 10px;
    }

    .selectric-wrapper {
        display: inline-block;
        height: 30px;
        //margin-bottom: 10px;
    }

    .selectric {
        height: 30px;

        .label,
        .button {
            height: 30px;
            line-height: 30px;
        }
    }
}

.textarea-label {
    vertical-align: top;
    padding-top: 5px;
}

/* Radio buttons */
label {
    cursor: pointer;
    position: relative;
}

.checkbox,
.radio {
    label {
        margin-bottom: 5px;
        display: inline-block;
        padding-left: 20px;
        margin-right: 0;
        color: @color-fake-black;

        &::before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 0;
            position: absolute;
            left: 0;
            top: 4px;
            //	box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .1), 0px 1px 0px 0px rgba(255, 255, 255, .2);
            border: 1px solid @color-fake-black;
        }
    }
}

/* Disabled radio & checkbox - use .checkbox wrapper */
input[type=radio],
input[type=checkbox] {
    display: none;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    box-shadow: 0 0 4px 1px #777;
}

.radio {
    margin-bottom: 5px;

    label::before {
        border-radius: 8px;
    }

    input[type=radio]:checked + label::after {
        content: "";
        position: absolute;
        left: 4px;
        bottom: 9px;
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: @color-fake-black;
        border-radius: 20px;
    }
}

.checkbox {
    input[type=checkbox]:checked + label::before,
    label.checked::before {
        .iconFamily();

        width: 14px;
        height: 14px;
        content: "\e649";
        font-size: 13px;
        text-align: left;
        line-height: 14px;
    }
}

.input-box {
    &.ib {
        min-width: 120px;
        margin-right: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.input-inline {
    width: 100%;

    .icon-check-black {
        font-size: 19px;
        position: relative;
        top: 1px;
    }
}

.label-white {
    label {
        color: #fff;
    }
}

.material {
    margin-bottom: 15px;

    .input-group {
        position: relative;
        margin-bottom: 20px;
        min-width: 240px;

        &.mb0 {
            margin-bottom: 0;
        }
    }

    input,
    textarea {
        display: block;
        width: 100%;
        padding: 40px 20px 12px;
        min-height: 70px;
        border: 1px solid @color-grey;
    }

    textarea {
        padding-top: 36px;
        min-height: 250px;
    }

    label {
        position: absolute;
        top: 15px;
        left: 22px;
        text-transform: uppercase;
        color: @color-darker-grey;
        font-size: 12px;
    }

    i[class^='icon-'] {
        position: absolute;
        right: 20px;
        top: 15px;
        font-size: 20px;
        color: @color-grey;
    }

    .input-group {
        .notice {
            margin-bottom: 10px;
        }
    }

    /*input[type^='file'] {}*/
}

/* Inlined form */
.inlined-form {
    padding: 5px 0 10px;

    .input-group {
        & > label,
        & > .label {
            display: inline-block;
            margin-bottom: 5px;
            max-width: 148px;
            width: 100%;
            flex-grow: 1;
        }

        input,
        .radio,
        .checkbox,
        .notice {
            flex-grow: 1.2;
        }

        &.notice {
            .notice-text {
                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .notice-inlined {
        padding-left: 150px;
    }

    /* small text hint */
    input + .hint {
        display: inline;
        padding-left: 10px;
        margin-bottom: 8px;
    }

    .hint {
        color: @color-dark-grey;
        display: inline-flex;
        font-weight: normal;
        margin-left: 5px;

        & + .notice {
            display: inline-flex;
        }
    }

    .huge {
        max-width: 500px;
    }

    /* input sizes */
    input.huge,
    textarea.huge {
        min-width: 220px;
        max-width: 400px;
        width: 100%;
    }

    input.large,
    textarea.large {
        min-width: 160px;
        max-width: 300px;
        width: 100%;
    }

    input.medium,
    textarea.medium {
        min-width: 140px;
        max-width: 230px;
        width: 100%;
    }

    input.small,
    textarea.small {
        min-width: 90px;
        max-width: 150px;
        width: 100%;
    }

    input.smaller,
    textarea.smaller {
        min-width: 90px;
        max-width: 130px;
        width: 100%;
    }

    input.tiny2,
    textarea.tiny2 {
        min-width: 40px;
        max-width: 70px;
        width: 100%;
    }

    input.tiny,
    textarea.tiny {
        max-width: 64px;
        width: 100%;
    }

    /* Poštna številka + pošta */
    .input-group .input-holder .tiny {
        margin-right: 6px;
    }

    /* SELECT country */
    label.before-select {
        vertical-align: top;
        padding-top: 5px;
    }

    .selectric,
    select {
        display: inline-block;
    }

    .selectric-large {
        max-width: 300px;
        width: 100%;

        .selectric {
            width: 100%;
        }
    }

    .floated {
        & > label,
        .selectric-wrapper,
        & > input,
        & > div {
            float: left;
        }

        & > label,
        & > small {
            padding-top: 6px;
            display: inline-block;
        }

        .selectric-wrapper {
            min-width: auto;
            margin-left: 0;
            margin-right: 10px;
        }
    }

    /* Checkbox pod inputom */
    .input-group {
        input + .checkbox {
            margin-top: -5px;
            margin-bottom: 10px;
        }

        .checkbox {
            margin-bottom: 10px;
            color: @color-darker-grey;

            label {
                font-size: 13px;
            }
        }
    }

    .input-content {
        align-items: center;
        display: inline-flex;
        flex-wrap: wrap;
        flex: 1 0 0%;
    }

    /* Vidno, a ne preveč */
    .agreement,
    .additional-info {
        margin-top: 3px;
        font-size: 12px;
    }

    .before-additional-info {
        margin-right: 10px;

        & + .additional-info {
            margin-top: 0;
        }
    }

    .agreement {
        color: @color-darker-grey;

        a {
            color: @color-darker-grey;
        }
    }
}

/* Razdelilec posameznih sekcij (kontat, payment, ...)*/
.form-section {
    margin-top: 15px;
    margin-bottom: 15px;

    & > h6 {
        margin-bottom: 20px;
    }

    &.no-input {
        .input-group {
            margin-bottom: 5px;
        }

        .mb10 {
            margin-bottom: 10px;
        }
    }
}

/* Notice polja, tekst za error-je*/
.notice {
    margin: 4px 0 5px;

    &-info:not(.notice-andy),
    &-warning:not(.notice-andy),
    &-error:not(.notice-andy) {
        font-weight: bold;
    }

    &-info {
        input,
        textarea {
            border-color: @color-primary;
        }

        .notice-text {
            color: @color-primary;
        }
    }

    &-warning {
        input,
        textarea {
            border-color: @color-secondary-alt;
        }

        .notice-text {
            color: @color-secondary-alt;
        }
    }

    &-error {
        input,
        textarea {
            border-color: @color-red;
        }

        .notice-text {
            color: @color-red;
        }
    }

    &-success {
        input,
        textarea {
            border-color: @color-secondary;
        }

        .notice-text {
            color: @color-secondary;
        }
    }

    [class^='icon-'] {
        font-size: 20px;
        position: relative;
        top: 1px;
        padding-right: 5px;
    }

    .notice-text {
        display: inline-block;
        margin: 0;
        font-size: 12px;

        .popover-holder {
            font-size: 14px;
        }

        .error,
        .warning,
        .info {
            position: relative;
            display: block;
            padding-left: 20px;

            &::before {
                position: absolute;
                left: 0;
                top: 1px;
                font-size: 18px;
                .iconFamily();
            }
        }

        & .error {
            &::before {
                content: "\e60a";
            }
        }

        & .warning {
            color: @color-secondary-alt;

            &::before {
                content: "\e61b";
            }
        }

        & .info {
            color: @color-primary;

            &::before {
                content: "\e628";
            }
        }
    }

    &.full-width {
        .notice-text {
            display: block;
        }
    }
}

@media (max-width: @break-sm-value) {
    .inlined-form {
        .floated {
            & > small,
            & > label {
                display: block;
                width: 100%;
            }

            .selectric-wrapper {
                margin-left: 0;
            }
        }

        .input-group {
            label {
                display: block;
                width: 100%;
            }

            .notice,
            .checkbox,
            .radio {
                margin-left: 0;
            }
        }

        label,
        .input-holder {
            flex: 1 0 100%;
        }
    }

    /**
     * If font-size on inputs is smaller than 16px, iOS will auto-zoom the input field
    */
    input[type="email"]:hover,
    input[type="number"]:hover,
    input[type="search"]:hover,
    input[type="text"]:hover,
    input[type="tel"]:hover,
    input[type="url"]:hover,
    input[type="password"]:hover,
    textarea:hover,
    select:hover,
    .selectric select {
        font-size: 16px;
    }
}

@media @break-xs {
    .inlined-form {
        /* input sizes */
        input.huge,
        textarea.huge,
        input.large,
        textarea.large,
        input.medium,
        textarea.medium,
        .selectric-large {
            max-width: none;
        }
    }


}

@media @break-sm {
    .input-group {
        .empty {
            display: none !important;
        }
    }
}
