.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    max-width: 1400px;

    .clearfix();
}

.container-noclear {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    max-width: 1400px;
}

