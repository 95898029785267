.row-banner {
    display: flex;
    flex-wrap: wrap;
    min-height: 80px;
    padding: 20px 40px 0;

    .btn-big {
        margin-bottom: 20px;
    }

    .align-right {
        margin-left: auto;
    }
}

@media @break-sm {
    .row-banner {
        .btn-big {
            width: 100%;
        }

        .row-banner {
            padding: 20px 20px 0;

            .written.rotated {
                display: none;
            }
        }
    }

    .written.rotated {
        display: none;
    }
}

@media @break-md {
    .written.rotated {
        display: none;
    }
}
