/* borders */

.rounded {
    border-radius: @box-border-radius;
}

.brd {
    border: 1px solid @color-grey;
}

.brd-v {
    border: solid @color-grey;
    border-width: 1px 0;
    text-align: center;
}

.brd-h {
    border: solid @color-grey;
    border-width: 0 1px;
}

.brd-h-bt {
    border: solid @color-grey;
    border-width: 0 1px 1px;
}

.brd-tp {
    border-top: 1px solid @color-grey;
}

.brd-bt {
    border-bottom: 1px solid @color-grey;
}

.brd-lft {
    border-left: 1px solid @color-grey;
}

.brd-rght {
    border-right: 1px solid @color-grey;
}

.brd-blck {
    border-color: @color-fake-black;
}

.brd-bt-dt {
    border-bottom: 1px dotted @color-primary;

    &.popover {
        cursor: pointer;
    }
}

.brd-strng {
    border-width: 2px;
}

.brd-extra {
    border-width: 5px;
}

.brd-c {
    &-blue {
        border-color: @color-primary;
    }

    &-green {
        border-color: @color-secondary;
    }

    &-orange {
        border-color: @color-secondary-alt;
    }

    &-dark {
        border-color: @color-fake-black;
    }
}

.default-cursor {
    cursor: default;
}

.no-brd {
    border: 0 !important;

    td {
        border: 0 !important;
    }
}

/* border colors */
.brd-c-green {
    border-color: @color-secondary;
}

.brd-c-orange {
    border-color: @color-secondary-alt;
}

.brd-c-blue {
    border-color: @color-primary;
}

.brd-c-red {
    border-color: @color-red;
}
