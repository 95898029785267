/* Zakaj izbrati nas */

/* Glavni keyvisual */
.whyus-kv {
    .clearfix {
        max-width: 920px;
        margin: 0 auto;
    }

    .align-left,
    .align-right {
        max-width: 460px;
    }

    .align-left {
        .user {
            float: left;
            margin-top: 2px;
        }

        .review,
        .additional {
            clear: right;
            float: left;
        }
    }

    .align-right {
        .user,
        .review,
        .additional {
            float: right;
        }

        .review,
        .additional {
            clear: left;
        }
    }

    .review {
        margin-top: 30px;
    }

    .additional {
        margin: 10px 20px;
        color: @color-white;
    }

    .review-aggregate {
        padding: 10px;
        position:absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        color:@color-white;
    }
}

/* Video izjave */

// Poudarjen video
.featured-video {
    padding-top: 15px;
    padding-bottom: 15px;

    .review {
        text-align: right;
    }
}

.review.summary {
    flex-direction: row;

    .quote {
        margin: 20px 30px 0 0;
    }
}

.video-review {
    a {
        &:extend(a.no-under);
    }
}

.video-review {
    height: 100%;
    min-width: 265px;

    .quote-info {
        padding-top: 20px;
    }

    .author {
        max-width: 65%;
        width: 100%;
        margin: 0 0 10px;
        float: left;

        a {
            font-size: @font-size-bigger;
            display: block;
        }
    }

    .quote {
        float: left;
        margin: 15px 0 5px;

        .title {
            color: @color-darker-grey;
        }
    }

    & > .rating {
        .icon-stars {
            margin: -3px -3px 0 0;
        }

        small {
            //	color: @color-secondary-alt;
        }
    }

    .inline-rating {
        border-bottom: 1px solid @color-grey;
    }

    small {
        color: @color-darker-grey;
    }

    &.summary {
        .quote {
            max-width: 65%;
        }

        .rating {
            float: right;
            max-width: 35%;
            text-align: right;
        }
    }

    .recommendation-extra {
        .icon-quote-alt {
            font-size: 16px;
        }

        .text {

            &:extend(.f-big);

            p:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.user-review-stars {
    align-items: baseline;
    flex-grow: 0;
    flex-shrink: 2;
    justify-content: space-around;
    min-height: 55px;

    &:extend(.grd all);

    & .align-left {
        &:extend(.grdcell);
    }

    &.inline-rating {
        border-bottom: 1px solid @color-grey;
        flex-direction: row;
        min-width: 265px;

        .rating {
            margin-left: auto;
            text-align: right;
        }
    }
}

// Uvod v spisku video izjav - brd-bx

.user-summary {
    align-items: center;
    border-top: 1px solid @color-grey;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0px 20px 0 0px;
    justify-content: center;
    flex-direction: row;

    .author-img {
        align-self: flex-end;
        display: block;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin: 0 auto;
        max-width: 130px;
        min-width: 80px;
        padding: 0 15px 0 0;
    }

    .author {
        vertical-align: top;
        margin-right: 10px;
        max-width: 430px;
        min-width: 100px;
        width: 100%;

        a {
            margin-top: 10px;
            display: block;
            padding: 0 0 5px;
            font-weight: bold;
        }
    }

    .btn-orange {
        float: right;
        margin: 15px 0;
    }
}

// Seznam izjav strank - brd-bx
.single-quote {
    flex-direction: row;
    padding: 15px 20px;
    border-bottom: 1px solid @color-grey;

    &:last-of-type {
        border-bottom: 0;
    }

    .left-spec {
        color: @color-darker-grey;
        margin-right: 5%;
        text-align: right;

        .icon-quote-alt {
            color: @color-lighter-grey;
            display: inline-block;
            font-size: 26px;
            padding-top: 15px;
        }
    }

    .right-data {
        .rating {
            min-width: 160px;
        }
    }

    small {
        vertical-align: middle;
    }

    .quote,
    .quote-icon {
        margin: 5px 0 0;
    }

    .name {
        height: 22px;
    }

    .date {
        height: 22px;
    }

    .rating {
        height: 27px;

        small {
            vertical-align: top;
        }

        i[class^="icon-stars"] {
            display: inline-block;
            margin-left: 5px;
            margin-top: -5px;
        }
    }
}

// Seznam video pohval
.whyus-users {
    .grdcell {
        min-width: 305px;
    }
}

.whyus-video-list {
    min-width: 265px;
}

.whyus-emails {
    .grdcell {
        min-width: 360px;
    }
}

@media @break-sm {
    .user-summary {
        text-align: center;
    }
}
