.our-clients{
    padding: 27px 0px 20px 0;
    max-height: 100px;
    overflow: hidden;
    .slides-clients{
        .slide{
            img{
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                transition: filter .1s ease-in-out, opacity .1s ease-in-out;
                opacity: .5;
                &:hover{
                    -webkit-filter: none;
                    filter: none;
                    opacity: .8;
                }
            }
            .logos{
                background-image: url('/dist/img/cms/clients/clients-spritesheet.png');
                background-repeat: no-repeat;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                transition: filter .1s ease-in-out, opacity .1s ease-in-out;
                opacity: .5;
                width: 159px;
                height: 42px;
                &:hover{
                    -webkit-filter: none;
                    filter: none;
                    opacity: .8;
                }

                &.delavska-hranilnica{
                    background-position:-5px 0;
                }
                &.t2{
                    background-position:-159px 0;
                }
                &.vigoshop{
                    background-position:-315px 0;
                }
                &.bigbang{
                    background-position:-472px 0;
                }
                &.madwise{
                    background-position:-630px 0;
                }
                &.src{
                    background-position:-789px 0;
                }
                &.vecer{
                    background-position:-947px 0;
                }
                &.kager{
                    background-position:-1103px 0;
                }
                &.eracun{
                    background-position:-1262px 0;
                }
                &.tvambienti{
                    background-position:-1420px 0;
                }
                &.tus{
                    background-position:-1575px 0;
                }
                &.bitcoin{
                    background-position:-1728px 0;
                }
                &.ainternet{
                    background-position:-1887px 0;
                }
                &.unilj{
                    background-position:-2045px 0;
                }
                &.bolha{
                    background-position:-2204px 0;
                }
                &.bagsmore{
                    background-position:-2363px 0;
                }
                &.aninakuhinja{
                    background-position:-2527px 0;
                }
                &.audiobm{
                    background-position:-2663px 0;
                }
                &.csc{
                    background-position:-2838px 0;
                }
                &.dobertekslo{
                    background-position:-2990px 0;
                }
                &.extremevital{
                    background-position:-3150px 0;
                }
                &.gorenjskabanka{
                    background-position:-3306px 0;
                }
                &.fotoklik{
                    background-position:-3465px 0;
                }
                &.gorenc{
                    background-position:-3622px 0;
                }
                &.klepetobkavi{
                    background-position:-3779px 0;
                }
                &.kovinc{
                    background-position:-3939px 0;
                }
                &.kupihitro{
                    background-position:-4097px 0;
                }
                &.lzs{
                    background-position:-4258px 0;
                }
                &.malalan{
                    background-position:-4414px 0;
                }
                &.mapri{
                    background-position:-4567px 0;
                }
                &.nepremicnine{
                    background-position:-4725px 0;
                }
                &.optiweb{
                    background-position:-4885px 0;
                }
                &.patricijapangersic{
                    background-position:-5042px 0;
                }
                &.pharmahemp{
                    background-position:-5196px 0;
                }
                &.primozrogljic{
                    background-position:-5351px 0;
                }
                &.renemlekuz{
                    background-position:-5518px 0;
                }
                &.robotina{
                    background-position:-5673px 0;
                }
                &.salomon{
                    background-position:-5827px 0;
                }
                &.surs{
                    background-position:-5985px 0;
                }
                &.telekom{
                    background-position:-6142px 0;
                }
                &.ukclj{
                    background-position:-6303px 0;
                }
                &.vitalgo{
                    background-position:-6461px 0;
                }
                &.wescale{
                    background-position:-6617px 0;
                }
                &.svetkrizarjenj{
                    background-position:-6773px 0;
                }
                &.degriz{
                    background-position:-6933px 0;
                }
                &.elektrogorenjska{
                    background-position:-7083px 0;
                }
                &.pipistrel{
                    background-position:-7714px 0;
                }
                &.szs{
                    background-position:-7245px 0;
                }
                &.rks{
                    background-position:-7406px 0;
                }
                &.rtv{
                    background-position:-7562px 0;
                }
                &.diginet{
                    background-position:-7873px 0;
                }
                &.imprim{
                    background-position:-8017px 0;
                }

                @media(min-width:1024px) and (max-width: 1700px){
                    //background-size: auto 32px;
                }
            }
        }
    }
}
