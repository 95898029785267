
/*==========  Widget - upsell boxes  ==========*/
.upsell-box {
    border: 1px solid #ccc;
    border-radius: @box-border-radius;
    width: 100%;

    &:nth-of-type(1n+3) {
        margin-bottom: 0;
    }

    &.popover {
        margin-bottom: 35px;
        padding: 20px 10px;
    }

    .upsell-header {
        position: relative;
        padding: 10px 20px;
        min-height: 62px;
        background-color: #ebebeb;
        font-size: @font-size-normal;

        &::before,
        &::after {
            position: absolute;
            bottom: 16px;
            right: 20px;
            font-size: 32px;
            .iconFamily();
        }

        &::after {
            content: "\e655";
            color: @color-secondary;
        }

        &::before {
            content: "\e654";
            color: #fff;
        }
    }

    .upsell-title {
        min-width: 180px;
    }

    .upsell-content {
        padding: 20px 19px;

        p {
            margin: 0;
        }
    }

    .spr-monitor,
    .spr-security_lock,
    .spr-cloud,
    .spr-speed_bolt,
    .spr-promo_label,
    .spr-dashboard,
    .spr-free-email-big,
    .spr-instant-registration,
    .spr-thumb_up,
    .spr-price-tag {
        position: absolute;
        bottom: 10px;

        & + .upsell-title {
            display: inline-block;
            padding-left: 75px;
        }
    }

    .spr-security_lock {
        bottom: -10px;
        left: 23px;
    }

    .spr-monitor {
        bottom: 0;
    }

    .spr-speed_bolt {
        left: 35px;
    }

    .spr-promo_label {
        bottom: 2px;
    }

    .spr-thumb_up {
        bottom: 13px;
    }

    .spr-price-tag {
        bottom: 0px;
    }

    .spr-free-email-big {
        bottom: 11px;
    }

    .spr-dashboard {
        bottom: 0;
    }
}
