/* Gumb za mobile menu */
.mobile-menu {
    width: 36px;
    height: 36px;
    min-width: 36px;
    position: relative;
    margin: 18px 20px 5px 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    #mm1,
    #mm2,
    #mm3 {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: @color-primary;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    #mm1 {
        top: 0;
    }

    #mm2 {
        top: 11px;
    }

    #mm3 {
        top: 22px;
    }

    &.open {
        margin-left:-3px;
        #mm1 {
            top: 13px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
        }

        #mm2 {
            opacity: 0;
            left: -60px;
        }

        #mm3 {
            top: 13px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
    }
}
@media (min-width: 1300px) and (max-width: 1400px) {
    .scrolled {
        .main-menu {
            .main-menu-item {
                .main-menu-link {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media (min-width: 1220px) and (max-width: 1300px) {
    .main-menu {
        .main-menu-item {
            .main-menu-link {
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }
    .scrolled {
        .top-header {
            .container {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .right-info {
            min-width: 120px;
            max-width: 120px;
        }
        .mini-cart .cart-top {
            padding-left: 10px;
            padding-right: 10px;

            .cart-text {
                font-size: 1.1rem;
            }
        }
        .main-menu {
            .home {
                .main-menu-link {
                    padding-left:15px;
                }
            }
            .main-menu-item {
                .main-menu-link {
                    font-size: 1.1rem;
                }
            }
        }
    }
}





@media (max-width: 1220px) {
    .main-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        height: auto;
    }

    .main-menu-item {
        white-space: nowrap;
        height: auto;

        &.active {
            &:not(.home) {
                background-color: rgba(255, 255, 255, 0.1);
                box-shadow: none;
                border: 0;
            }

            &.main-menu-link {
                padding: 14px 26px 11px;
                width: 100%;
            }
        }
    }

    &.home {
        flex-grow: 0;
    }

    .top-header {
        .logo {
            margin-right: 5%;
        }

        .support {
            .sprite {
                display: none;
            }

            .email {
                min-width: 210px;
            }

            .phone {
                min-width: 130px;
            }
        }
    }
}


@media @break-max-xlg-menu-value {
    .home-text {
        display: inline-block;
    }

    .scrolled {
        .main-menu {
            .main-menu-item {
                margin: 0.5%;
            }

            .main-menu-link {
                font-size: 1.15em;
            }
        }
    }

    .fixed-menu {

        .top-header {
            min-height: 60px;
        }

        .navigation {
            .box-shadow(0 2px 7px rgba(0,0,0,0.7));

            background: #fff;
            border: 0;
            left: 0;
            position: fixed;
            top: 90px;
            width: 100vw;
            z-index: 100;
            height: calc(~"100vh - 90px");
            overflow:auto;
            /* animation */
            transform: translateX(-300%);
            will-change: transform;
            transition: transform 300ms;

            &.active {
                transform: translateX(0%);
            }

            & > .container {
                padding: 0;
            }
        }

        .top-logo {
            width: auto;
            min-width: auto;
        }

        .top-menu {
            .box-shadow(0 2px 7px rgba(0,0,0,0.7));

            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 9999;
            background-color: #fff;
            min-height: auto;

            .container {
                max-width: 1200px;
            }

            .logo {
                display: inline-flex;
                max-height: 48px;
                align-self: center;
                margin: 0 auto;

                img {
                    margin: 0 auto;
                    padding: 0;
                }
            }

            .support,
            .phone,
            .email,
            .support-person {
                display: none;
            }
        }

        .right-info {
            top: 0;
            padding: 0;
            max-width: 70px;
            margin-left: 20px;
            min-width: 60px;
            //width: 13%;

            .top-links {
                display: none;
            }
        }

        .mobile-menu-holder {
            min-width: 70px;
            max-width: 70px;
        }

        /* Cart related */
        .cart-holder {
            min-width: 75px;
        }

        .cart-actions {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        .cart-summary {
            min-height: 110px;
        }

        .mini-cart {
            margin-top: 13px;

            &.hovered,
            &.active {

                .cart-text {
                    .cart-icon {
                        animation: shake 0.5s ease;
                        display:inline-block;
                    }
                }
            }

            .cart-text {
                margin-left: 0;
                font-size: 1.5rem;
                i {
                    font-size: @font-size-evenbigger;
                }
            }

            .text-cart,
            .down-icon {
                display: none;
            }

            .cart-top {
                display: flex;
                flex-direction: row-reverse;
                padding: 0;
            }

            .box-shadow(none);

            .cart-content {
                position: fixed;
                left: 0;
                top: 90px;
                width: 100vw;
            }

            .cart-top {
                &::after {
                    display: none;
                }
            }
        }

        /* .main-menu */
        .main-menu {
            color: @color-secondary;
            padding-bottom: 0;
            position: relative;
            margin-bottom: 0;
            width: 100%;
            z-index: 1000;
            display:block;

            .main-menu-item {
                border: 0;
                margin: 0;
                width: 100%;

                &.has-children {
                    &:hover {

                    }
                }

                &:first-child {
                    .main-menu-link {
                        border-top: none;
                    }
                }

                &.active {
                    background-color: transparent;
                    .box-shadow(none);
                    border-right:none;

                    .main-menu-link {
                        .mobile-submenu-toggle {
                            i {
                                transform: rotate(180deg) translate(0px, 3px);
                            }
                        }
                    }

                    .main-submenu {
                        display:block;
                        list-style-type: none;
                        padding:0;
                        margin:0;
                        background: tint(@color-primary, 90%);
                        li {
                            padding-bottom:0;
                            a {
                                border:none;
                                font-weight:normal;
                                font-size: @font-size-bigger;
                                padding-left:40px;
                                text-transform: none;
                            }
                            &.active {
                                a {
                                    font-weight:bold;
                                }
                            }
                        }
                    }
                }

                .large-submenu {
                    display:none !important;
                }

                .main-menu-link {
                    color: @color-primary;
                    border-top: 1px solid @color-light-grey;
                    padding: 14px 20px 11px;
                    width: 100%;
                    position:relative;
                    font-size:@font-size-big;



                    &:hover {
                        background: tint(@color-primary, 80%);
                    }

                    .mobile-submenu-toggle {
                        display:inline-block;
                        position:absolute;
                        right:0;
                        top:0;
                        padding: 15px 0 0 0;
                        height:100%;
                        width:50px;
                        font-size:@font-size-big;
                        text-align:center;
                        background-color:@color-lightest-grey;
                        i {
                            transition: transform 0.3s ease;
                            display:inline-block;
                        }
                    }
                }

            }


            .btn-black .main-menu-link{
                color: #fff;
                &:hover{
                    color: @color-dark;
                }
            }

            .home {
                width: 100%;
                background-color: transparent;
                border-color: transparent;

                &.active a {
                    pointer-events: auto;
                }

                .icon-home {
                    position: absolute;
                    left: 13px;
                    top:8px;
                    font-size: 32px;
                }

                .main-menu-link {
                    padding-left: 48px;
                }
            }
        }

        .top-header {
            .mobile-menu-holder,
            .mobile-menu {
                display: block;
                float: left;
                max-width: 36px;
            }

            .top-menu {
                position: fixed;
                z-index: 9999;
                top: 0;
            }
        }
    }
}

@media @break-xs {
    .js-minicart-strongqty {
        font-size:18px;
    }
    .fixed-menu  {
        .cart-holder {
            min-width:auto;
        }
        .right-info {
            min-width: 25px;
        }
    }
}
