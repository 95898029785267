/****** LISTS ******/

li > a {
    text-decoration: none;
}

.inline-list, .std-list, .domain-query, .faq-list, .flat-tabs, .square-btn-list, .sub-menu, .side-menu, .items, .check-list, .progress-list, .cart-list, .category-list {
    margin: 0;
    padding: 0;
}

.sub-menu, .side-menu {
    & li {
        padding-bottom: 0;
    }
}

.inline-list, .domain-results, .pagination, .flat-tabs, .square-btn-list, .cart-list {
    & > li {
        display: inline-block;
        margin: 0;
        padding-bottom: 0;
    }
}

.std-list, .domain-results, .faq-list, .pagination, .flat-tabs, .items, .check-list, .progress-list, .category-list {
    list-style: none;
}

.list-title {
    margin-bottom: 0;
    & + ul {
        margin: 5px 0 0;
    }
}

.std-list {
    &.styld .list-item {
        position: relative;
        padding-left: 1em;
        margin-top: 0;
        &:before {
            position: absolute;
            top: .6em;
            left: 1px;
            width: .36em;
            height: .36em;
            content: ' ';
            border-radius: 10px;
            background-color: @color-darker-grey;
        }
    }
}

.row-before {
    padding-top: 10px;
}

// Stranski meni - collapsable
.collapsable-trigger {
    display: block;
    padding: 0 21px;
    text-decoration: none;
}

.side-navigation,
.side-menu li {
    .transition(all 300ms);

    min-width: 230px;

    &.collapsable {
        .title,
        .collapsable-trigger {
            cursor: pointer;
            position: relative;

            &::after {
                .iconFamily();

                content: "\e635";
                font-size: 22px;
                position: absolute;
                right: 13px;
                top: 9px;
            }
        }

        li {
            height: 0;
            border-color: transparent;
        }

        .side-menu,
        & > ul {
            height: 0;
            overflow: hidden;
        }

        &.uncollapsed {
            .title,
            .collapsable-trigger {
                &::after {
                    content: "\e62b";
                }
            }

            .side-menu,
            & > ul {
                height: auto;

                li {
                    height: auto;
                    border-color: @color-grey;
                }
            }
        }
    }
}

.side-menu {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;

    .collapsable {
        .link-holder {
            display: flex;
        }

        .side-menu-item {
            &.active:not(.very-active) {

            }
        }
    }

    .side-item {
        .side-menu-link {
            display: block;
            flex: 1;
            padding: 11px 20px 9px;
            text-decoration: none;
        }
    }
    // 1. tier
    & > .side-item-1 {
        padding: 0;
        list-style: none;

        & > .link-holder .side-menu-link {
            padding-left: 10px;
            border-width: 1px 1px 0;
            border-style: solid;
            border-color: @color-secondary;
            background-color: @color-secondary;
        }

        &:last-of-type {
            border-width: 1px;
        }

        &.active {
            & > .link-holder .side-menu-link {
                border-color: @color-primary;
            }
        }
        // class za najvišji element
        &.primary {
            position: relative;
            border: solid @color-primary;
            border-width: 0 0 2px;

            & > .link-holder .side-menu-link {
                border: 0;
                background-color: #fff;
                font-size: @font-size-bigger;
                color: @color-primary;
            }

            &:first-of-type {
                border-top-width: 2px;
            }
        }

        // aktiven najvišji li in ne pod element
        &.very-active {
            & > .link-holder .side-menu-link {
                color: @color-fake-black;
                font-weight: bold;
                position: relative;
            }
        }
    }

    // Aktiven meni - all tier
    & .side-item {
        &.very-active {
            & > .link-holder .side-menu-link {
                color: @color-fake-black;
                font-weight: bold;
            }
        }

        &.active .sub-menu,
        &:focus .sub-menu {
            display: block;
        }
    }

    // Podmeni
    .sub-menu {
        //display: none;

        & .side-item-2 {
            font-weight: normal;
            list-style: none;
            border-color: @color-grey;
            border-top: 1px solid @color-grey;
            background-color: @color-lightest-grey;

            &:hover {
                background-color: #fafafa;
            }

            & > .link-holder .side-menu-link {
                min-height: 40px;
            }

            &:first-of-type {
                border-top-color: #fff;
            }
        }

        .side-item {
            position: relative;
        }
    }

    .sub-menu > .very-active,
    .sub-menu > .active {
        & > .link-holder .side-menu-link {
            position: relative;
            border: 0;
        }

        & .side-menu-link {
            background-color: transparent;
        }

        & > .sub-sub-menu {
            display: block;
        }
    }
    // 1. level - uber-active © emanuel
    .sub-menu .side-item-2.very-active {
        /*&:after {
            color: @color-primary;
        }*/
        & > .link-holder .side-menu-link {
            color: @color-fake-black;
        }
    }

    .sub-menu .side-item-2 > .sub-sub-menu {
        list-style: none;
    }

    // aktiven pod-pod-meni
    .sub-menu .sub-sub-menu {
        //display: none;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 36px;
        //border-right: 2px solid @color-primary;
        & .side-item-3 {
            a {
                &:hover {
                    color: darken(@color-primary, 10%);
                }
            }
        }

        & .side-item-3.very-active {
            & > .link-holder .side-menu-link {
                font-weight: bold;
                color: @color-fake-black;
            }

            .side-item {
                &.very-active {
                    .side-menu-link {
                        color: @color-fake-black;
                    }
                }
            }
        }
    }

    .sub-sub-menu {
        font-size: @font-size-smaller;
        //display: none;
        & > .side-item {
            & > .link-holder .side-menu-link {
                padding: 0 0 5px;

                &::after {
                    top: 5px;
                }
            }

            &:last-of-type {
                padding-bottom: 15px;
            }

            & > .sub-sub-menu {
                padding-left: 15px;
                list-style: none;
                display: none;
                border-right: 0;

                .side-item:last-of-type {
                    padding-bottom: 0;
                }
            }

            &.active > .sub-sub-menu {
                display: block;

                .side-menu-link {
                    font-weight: normal;
                }

                & > .side-item {
                    &.active {
                        .side-menu-link {
                            font-weight: bold;
                            color: @color-fake-black;
                        }
                    }
                }
            }
        }
    }

    .circle {
        margin-left: 5px;
    }

    .compare-icon {
        position: relative;
        padding-left: 55px;

        span {
            &::before {
                background-image: url(../img/sprites/trans.png);	// .spr-h-compoare
                background-position: -245px -132px;
                width: 27px;
                height: 24px;
                content: " ";
                position: absolute;
                left: 20px;
                top: 8px;
            }
        }
    }
}

.footer-list {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    color: white;
    li {
        list-style: none;
    }
    a {
        //font-size: @font-size-smaller;
        text-decoration: none;
        color: #fff;
    }
}


/*==========  Dropdown  ==========*/

ul.dropdown {
    position: relative;
    min-width: 130px;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid @color-lighter-grey;
    background-color: white;
    &::after {
        font-family: 'neoserv';
        font-weight: normal;
        position: absolute;
        top: 0;
        right: 0;
        width: 35px;
        padding: 7px 9px 7px;
        content: '\e60c';
        border-left: 1px solid @color-lighter-grey;
    }
    &:hover {
        li {
            display: block;
            &:first-of-type {
                border-bottom: 1px solid @color-lighter-grey;
            }
        }
    }
    li {
        display: none;
        width: 100%;
        padding: 7px 30px 6px 15px;
        &.selected {
            display: inline-block;
        }
    }
}



/*==========  Mini thumbnail list  ==========*/

.thumb-list li {
    margin: 3px 1px 0 0;
    &:last-of-type {
        margin-right: 0;
    }
}


/*==========  FAQ list  ==========*/
.faq {
    margin:100px 200px 80px 200px;
    border:1px solid @color-grey;
    border-radius: @box-border-radius;
    padding:20px;
    background-color: @color-white;

    h3 {
        margin-left:10px;
    }
}
.faq-list {
    & > li {
        position: relative;
        padding: 2px 50px 1px 10px;
        cursor: pointer;
        border-top: 1px solid @color-light-grey;
        &:last-of-type {
        }
        & > a {
            font-weight: bold;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
            text-decoration: none;
            color: inherit;
            font-size:1.1rem;
        }
        & > i {
            font-size: 28px;
            position: absolute;
            top: 10px;
            right: 5px;
            &:after {
                font-weight: bold;
                content: '\e637';
            }
        }
        &.active {
            cursor: default;
            .faq-answer {
                display: block;
            }
            & > i:after {
                font-weight: normal;
                content: '\e62e';
                color: @color-primary;
            }
        }
        .faq-answer {
            display: none;
            p {
                &:first-of-type {
                    margin-top: 0;
                }
                &:last-of-type {
                    margin-bottom: 1em;
                    &.list-title {
                        margin: 0;
                    }
                }
            }
            ul {
                margin-bottom: 1em;
            }
            a {
                text-decoration: underline;
            }
        }
    }
}



/*==========  Specifications  ==========*/

.specific-list {
    .clearfix();
    & dt, & dd {
        float: left;
        margin: 0;
    }
    dt {
        width: 60%;
        color: @color-darker-grey;
    }
    dd {
        width: 40%;

        &.trustee {
            span {
                font-style:italic;
            }
        }
    }
}


/*==========  Square buttons list  ==========*/

.square-btn-list {
    list-style: none;
    li {
        display: inline-block;
        margin-top: 3px;
        text-align: center;
        vertical-align: top;
        &.active {
            a {
                border-color: @color-secondary;
                background-color: @color-secondary;
                color: #fff;
                &:hover {
                    color: @color-fake-black;
                }
                &:before {
                    display: none;
                    color: inherit;
                }
            }
        }
        .discount {
            font-size: @font-size-smaller;
            color: @color-secondary-alt;
        }
    }
    a {
        font-weight: bold;
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        padding: 9px;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        // hover effect <
        vertical-align: middle;
        color: @color-fake-black;
        border: 1px solid @color-grey;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -moz-osx-font-smoothing: grayscale;
        /*-webkit-backface-visibility: hidden;
        backface-visibility: hidden;*/ /* ios-chrome problem */
        &:hover {
            color: #fff;
        }
        &:before {
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: '';
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
            -webkit-transition-duration: .3s;
            transition-duration: .3s;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            background-color: @color-grey;
        }
        &:hover:before, &:active:before, &:focus:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}


/*==========  Pagination  ==========*/

.pagination {
    a {
        font-size: 17px;
        display: block;
        width: 30px;
        padding: 5px 9px 4px;
        text-decoration: none;
        color: @color-fake-black;
        border: 1px solid @color-grey;
        background-color: #fff;
        &:hover {
            color: #fff;
            border-color: @color-primary;
            background-color: @color-primary;
        }
    }
    li {
        margin: 5px 0;
        pointer-events: auto;
        cursor: pointer;
        &.active a {
            font-weight: bold;
            color: #fff;
            border-color: @color-primary;
            background-color: @color-primary;
            pointer-events: none;
        }
    }
    &.clean {
        padding-left: 0;
        a {
            width: auto;
            padding: 10px 15px 9px;
            border: 0;
        }
        li {
            margin-right: 5px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    li.last {
        position: relative;
        margin-left: 40px;
        &:before {
            position: absolute;
            bottom: 20%;
            left: -31px;
            content: '...';
        }
    }
    li.first {
        position: relative;
        margin-right: 50px;
        &:before {
            position: absolute;
            right: -31px;
            bottom: 20%;
            content: '...';
        }
    }
    &.big {
        a {
            width: 40px;
            height: 40px;
            padding: 10px;
        }
    }
}


/*==========  Tabs  ==========*/

.flat-tabs {
    font-size: 0; // inline-block fix
    &:extend(.clearfix all);
    a {
        font-size: 17px;
        position: relative;
        display: block;
        padding: 16px 20px 12px;
        text-decoration: none;
        color: @color-lighter-grey;
        background-color: @color-darker-grey;
        .box-shadow(0 -6px 6px -4px rgba(66,66,66,0.6) inset);
        &.cart-flat-tab-link {
            font-size: 13px;
            padding: 16px 12px 12px;
        }
    }
    &.cart.responsive-tabs{
        padding: 0 20px; //dodano da je na mobilcu rob ob strani enak kot spodaj v containerju.
    }
    @media (min-width:@break-lg-value){
        &.cart.responsive-tabs{
            padding: 0;
        }
    }
    li {
        margin-right: 3px;

        @media (min-width:@break-lg-value){
        &.cart-flat-tab {
            max-width: 145px;

        }
    }
        &:last-of-type {
            margin: 0;
        }
        &.left-icon {
            a {
                padding-left: 55px;
            }
            i {
                font-size: 2em;
                position: absolute;
                top: 8px;
                left: 12px;
            }
        }
        &.right-icon {
            a {
                padding-right: 55px;
            }
            i {
                font-size: 2em;
                position: absolute;
                top: 8px;
                right: 12px;
            }
        }
        &.active {
            a {
                pointer-events: none;
                font-weight: bold;
                .box-shadow(none);
            }
        }
    }
    &.dark {
        li {
            &.active a {
                color: @color-fake-black;
                background-color: #fff;
            }
        }
    }
    &.orange {
        li {
            &.active a {
                color: @color-secondary-alt;
                background-color: #fff;
            }
        }
    }
    &.blue {
        li {
            &.active a {
                color: @color-primary;
                background-color: #fff;
            }
        }
    }
    &.cpanel {
        li {
            &.active a {
                color: @color-brand-cpanel;
                background-color: #fff;
            }
        }
    }
    &.cart {
        li {
            &.active a {
                color: @color-fake-black;
                background-color: #fff;
            }
            a {
                color: @color-fake-black;
                background-color: @color-grey;
                font-weight: bold;
            }
        }
        &.spammy {
            li {
                float: left;
            }
            a {
                padding: 15px 12px 10px;
            }
        }
    }
}

/*----------  Pros-list  ----------*/
.pros-list {
    display: inline-block;
    font-weight: bold;

    .circle {
        margin-right: 5px;
        background: @color-secondary-alt;

    }

    .icon-check-black-long {
        color: @color-secondary;
    }

}

/*----------  All checked list  ----------*/
.check-list {
    .list-item {
        padding-left: 28px;
        position: relative;
        margin-bottom: 5px;

        &::before {
            .iconFamily();

            position: absolute;
            top: -3px;
            left: -4px;
            content: "\e653";
            font-size: 28px;
            color: @color-secondary;
        }
    }

    &.plus-sign {
        .list-item {
            margin-bottom: 6px;
            padding-right: 8px;

            &::before {
                content: "\e638";
                color: @color-secondary;
                font-size: 24px;
            }
        }
    }

    &.key-sign {
        .list-item {
            &::before {
                content: "";
                background: url('../img/icons/key.png') no-repeat 0px 3px;
                height: 26px;
                width: 20px;
                left: -1px;
            }
        }
    }

    &.fail {
        .list-item::before {
            content: "\e64a";
            color: @color-secondary-alt;
        }
    }

    .list-item {
        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }
}

/*----------  Progress list  ----------*/
.progress-list {
    padding: 0;
    margin: 30px -50px 0;

    li {
        display: inline-block;
        width: 20%;
        margin-right: -4px;
        text-align: center;
    }
}

/*----------  Logos list  ----------*/
.inline-list {
    &.vps-logos {
        text-align: center;
        .list-item {
            max-width: 240px;
            margin: 15px 0px;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            opacity: 0.5;
            transition: opacity .6s ease-out;
            -moz-transition: opacity .6s ease-out;
            -webkit-transition: opacity .6s ease-out;
            -o-transition: opacity .6s ease-out;
        }
        .list-item:hover {
            opacity: 1;
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
        }
    }
}

/*----------  List columns  ----------*/
.list-col {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-fill: balance;
    -moz-column-fill: balance;
    -webkit-column-fill: balance;

    & li {
        display: inline-block;
    }
}

/*----------  Classic list - alineje  ----------*/
.classic-list {
    min-height: 70px;
    padding: 0;
    margin: 0;

    li {
        &::before {
            content: '-';
            padding-right: 5px;
        }
    }

}

/* Checkout - dropdown (identity pick) */
.dropdown-menu {
    position: relative;

    & > a {
        color: #fff;
        text-decoration: none;
    }

    .pre-title {
        padding: 18px 5px 17px;
        display: inline-block;
    }

    .dropdown-trigger {
        //animation
        .transition(all 0.2s ease-out);

        background-color: transparent;
        padding: 18px 10px 17px 20px;

        i {
            padding: 0 10px;
        }
    }

    ul {
        //animation
        .transition(all 0.2s ease-out);
        .box-shadow(-3px 0px 5px 0px rgba(66, 66, 66, 0.5));

        display: none;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 55px;
        padding: 0;
        max-width: 500px;
        width: 100%;
        margin-top: 0;
        z-index: 2;
        color: @color-fake-black;
        list-style: none;
        background-color: @color-lightest-grey;

        li {
            position: relative;
            padding: 18px 20px;
            border-bottom: 1px solid @color-grey;

            .tag {
                position: relative;
                font-size: 11px;
                padding: 1px 8px;
                margin-right: -15px;
                left: -20px;
                border-radius: 0 3px 3px 0;
            }
            .name {
                strong {
                    text-transform: uppercase;
                }
                & ~ p {
                    font-size: 12px;
                    margin-bottom: 0;
                    margin-top: 2px;
                }
            }
            &:last-of-type {
                border-bottom: 0;
            }
            &.selected {
                background-color: #fff;
                cursor: pointer;
                /*//redesigned
                 .tag {
                    background-color: @color-secondary;
                    &:before {
                        .iconFamily();
                        content: "\e653";
                        position: relative;
                        left: -5px;
                        top: 2px;
                        display: inline-block;
                        padding: 0 3px;
                        font-size: 18px;
                        color: #fff;
                    }
                    span {
                        background-color: @color-grey;
                        position: relative;
                        padding: 4px 8px 3px;;
                        right: 0;
                        top: 0;
                        display: inline-block;
                        margin: -3px -8px;
                        border-radius: 3px;
                    }
                    &.blue {
                        span {
                            background-color: @color-primary;
                        }
                    }
                }*/
                &:after {
                    position: absolute;
                    right:20px;
                    bottom: 10px;
                    .iconFamily();
                    content: "\e653";
                    font-size: 28px;
                    color: @color-secondary;
                }
            }

            &:hover {
                background-color: #fff;
                cursor: pointer;
            }
        }
        p {
            &:first-of-type {
                margin: 0;
            }
        }
        .btn-wide + .popover-holder {
            position: absolute;
            margin-top: 10px;
            right: 20px;
        }
    }

    &.active {
        .dropdown-trigger {
            position: relative;
            z-index: 3;
            background-color: #fff;
            color: @color-primary;
            .box-shadow(-5px 0 4px -4px rgba(66,66,66,0.5));
        }
        ul {
            display: block;
            //animation
            opacity: 1;
            .transition(all 0.4s ease-in-out 0.4s);
            height: auto;
        }
    }

}
/*----------  Discount list (cart popover)  ----------*/
.discount-list {
    list-style: none;
    padding-left: 0;
    li {
        padding-top: 3px;
        border-bottom: 1px dotted rgba(255,255,255,0.5);
        &:last-of-type {
            border-bottom: 0;
        }
    }
}


/*----------  Latest guides - widget  ----------*/
.guides-list {
    position: relative;
    .img-holder {
        position: absolute;
        left: 0;

        height: 50px;
        width: 50px;
        padding: 0;

        border: 1px solid @color-primary;
    }
    li {
        margin: 5px 0 10px;
        min-height: 50px;
    }
    a {
        display: inline-block;
        margin-left: 65px;
        /*margin-top: 5px;*/
        text-decoration: underline;
    }
}

/*----------  Wordpress - Category list  ----------*/
.category-list {
    margin-top: 10px;
    margin-bottom: 5px;
    li {
        border-bottom: 1px solid @color-grey;
    }
    a {
        display: inline-block;
        padding: 5px 0 7px;
        margin-top: 5px;
        &:before {
            .iconFamily();
            content: "\e904";
            position: relative;
            top: 1px;
            padding-right: 10px;
            font-size: 20px;
        }
    }
}

.fancy-tabs {
    &:extend(.std-list);

    .item {
        background-color: @color-light-grey;
        border-right: 2px solid #fff;
        min-width: 90px;
        padding: 0;
        position: relative;
        text-align: center;

        &:last-of-type {
            border-right: 0;
        }

        a {
            display: block;
            height: 100%;
            padding-top: 15px;
        }

        .icon {
            min-height: 40px;
            padding-top: 0;
        }

        .svg-holder {
            padding-top: 10px;
            padding-bottom: 10px;

            &.browsers {
                svg {
                    margin-top: 3px;
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .icons {
            font-size: 32px;
            padding-top: 8px;

            .icon-phone {
                margin-right: -3px;
            }

            .icon-email {
                margin-left: -3px;
            }
        }

        &.active {
            background-color: @color-primary;

            a {
                color: #fff;
            }

            i {
                color: #fff;
            }

            &:after {
                content: " ";
                position: absolute;
                bottom: -13px;
                left: 50%;
                margin-left: -13px;
                border: solid;
                border-width: 13px 13px 0px 13px;
                border-color: @color-primary transparent;
            }

            .active-white,  {
                fill: #fff;
            }

            #firefox-air {
                fill: transparent;
            }
        }
    }

    .icon-shield-ssl {
        font-size: 32px;
        margin-top: 7px;
        display: inline-block;
    }

    .icon-graph {
        display: inline-block;
        font-size: 52px;
        margin-top: 0px;
    }

    .browsers {
        padding-top: 13px;
    }

    .icon-download-arrow {
        font-size: 42px;
        padding-top: 0;
        display: inline-block;
        margin-top: 2px;
    }
}
.fancy-content {
    border: 2px solid #1D9AD6;
    padding: 20px;
}

.list-circles {
    margin: 25px 0 20px;
    .circle {
        font-weight: bold;
        margin-right: 5px;
    }
    dt {
        margin: 8px 0;
    }
    dd {
        padding: 0;
        margin: 0 0 20px 0;
    }
}

/*=====================================================
=            Technical specifications list            =
=====================================================*/
.spec-list {
}

.spec-toggle,
.spec-summary,
.spec-title {
    min-height: 40px;
    border: 1px solid @color-grey;
    padding: 10px 9px 9px;

    height: auto;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    width: 100%;
}

.spec-toggle {
    max-width: 40px;
    padding: 9px 10px 10px;
    font-size: 18px;
    border-width: 1px 1px 0;
    cursor: pointer;

    &::after {
        .iconFamily();
        content: "\e60c";
    }
}

.spec-title {
    border-width: 1px 0 0 0;
    flex-grow: 1.3;
}

.spec-summary {
    border-width: 1px 1px 0 0;
    color: @color-fake-black;
    flex-grow: 1.4;

    & > p,
    & > div {
        margin: 0;
        display: inline;
        vertical-align: top;

        .popover {
            display: inline-block;
            white-space: nowrap;
            vertical-align: inherit;
        }
    }

    .popover-holder {
        vertical-align: inherit;
    }

    .svg-holder {
        display: inline-block;
        position: relative;
        top: -9px;

        svg {
            margin: 10px 10px 0 0;
            width: 30px;
            height: 30px;
        }
    }

    .icon-check-black-long,
    .icon-cross-black {
        font-size: 28px;
        position: relative;
        top: -3px;
        color: @color-secondary;
        margin-right: 5px;
    }

    .icon-cross-black {
        color: @color-red;
        top: -5px;
    }
}

.spec-description {
    .transition(all 200ms);

    /* Visibility */
    width: 0;
    height: 0;
    padding: 0;
    border-width: 0 1px;
    visibility: hidden;
    overflow: hidden;

}

.spec-row {
    .transition(color 200ms);

    color: @color-darker-grey;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &:last-of-type {
        .spec-toggle,
        .spec-summary,
        .spec-title {
            border-bottom: 1px solid #ccc;
        }

        &.active {
            .spec-description {
                border-bottom: 1px solid @color-grey;
            }
        }
    }

    &.active {
        .transition(color 200ms);

        color: @color-fake-black;

        .spec-toggle {
            background: @color-lightest-grey;
            border-bottom: 0;

            &::after {
                content: "\e60f";
            }
        }

        .spec-summary,
        .spec-title {
            border-bottom: 1px solid @color-grey;
        }

        .spec-description {
            background: @color-lightest-grey;
            border: 1px solid #ccc;
            border-width: 0px 1px 0;
            height: auto;
            flex: 0 0 100%;
            padding: 10px 20px 20px;
            visibility: visible;

            p {
                height: auto;
            }
        }
    }
}

/*=====  End of Technical specifications list  ======*/

@media @break-lg {
    .flat-tabs {
        li {
            width: 50%;
            padding-left:1px;
            padding-right: 1px;
            margin: 0;
            max-width: unset;
        }
    }
}

@media @break-md {
    .flat-tabs {
        margin-top: 20px;
        li {
            width: 49%;
            max-width: unset;
        }
    }
}

@media @break-sm {
    .flat-tabs {
        margin-top: 20px;

        li {
            width: 100%;
            text-align: center;
            max-width: unset;
        }

        &.responsive-tabs {
            li {
                &.left-icon {
                    a {
                        padding: 10px;
                        text-align: center;
                    }



                    i {
                        position: relative;
                        top: 0;
                        left: 0;
                    }
                }
            }
            .tab-text {
                display: none;
            }
        }
    }

    .fancy-tabs {
        .item {
            a {
                padding: 5px 0;
            }
        }
    }
}
