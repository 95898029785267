/* ==========  Brands bar  ========== */
.hosting-brands {
    background-color: @color-lightest-grey;
    border-bottom: 1px solid @color-grey;
    margin-top: 20px;

    &:extend(.arrow-up-100 all);
}

.brands-bar {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;

    .item {
        height: 80px;
        min-width: 120px;
        position: relative;
        text-align: center;
        width: 100%;

        &:hover {
            background-color: #fff;

            .icon {
                .opacity(1);
                .filter(grayscale(0));
            }

            .popup {
                display: block;
            }
        }

        .icon {
            .filter(grayscale(1));
            .opacity(0.8);

            position: relative;
            top: 20px;
        }
    }

    /* Brands colors */
    .dell {
        .popup {
            background-color: @color-brand-dell;
            border-color: @color-brand-dell transparent;
        }
    }

    .intel {
        .popup {
            background-color: @color-brand-intel;
            border-color: @color-brand-intel transparent;
        }
    }

    .cloudlinux {
        .popup {
            background-color: @color-brand-cloudlinux;
            border-color: @color-brand-cloudlinux transparent;
        }
    }

    .cpanel {
        .popup {
            background-color: @color-brand-cpanel;
            border-color: @color-brand-cpanel transparent;
        }
    }

    .php {
        .popup {
            background-color: @color-brand-php;
            border-color: @color-brand-php transparent;
        }
    }

    .mariedb {
        .popup {
            background-color: @color-brand-mariedb;
            border-color: @color-brand-mariedb transparent;
        }
    }

    .litespeed {
        .popup {
            background-color: @color-brand-litespeed;
            border-color: @color-brand-litespeed transparent;
        }
    }

    .imunify {
        .popup {
            background-color: @color-brand-imunify;
            border-color: @color-brand-imunify transparent;
        }
    }

    .patchman {
        .popup {
            background-color: @color-brand-patchman;
            border-color: @color-brand-patchman transparent;
        }
    }

    .btn-brd {
        margin: 10px 0 20px;
    }

    .popup {
        border-width: 0;
        bottom: 100%;
        color: #fff;
        display: none;
        min-height: 100px;
        padding: 15px 10px;
        position: absolute;
        z-index: 2;

        .text {
            display: block;
            min-height: 110px;
            line-height: 20px;
        }

        &::after {
            bottom: -10px;
            border-color: inherit;
            border-width: 10px 10px 0;
            border-style: solid;
            content: ' ';
            left: 50%;
            margin-left: -10px;
            position: absolute;
        }
    }
}
