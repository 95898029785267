@media @break-max-xlg {
    p {
        font-size: 0.9rem;
    }
}
@media (max-width: @break-md-value) {
    .bottom-footer {
        text-align: center;

        .social-contacts {
            justify-content: center;
        }
        .footer-title {
            margin-top: 60px;
            &.text-right {
                text-align: center;
                margin-top: 0;
            }
        }
        .text-right {
            text-align: center !important;
        }
    }

}
@media @break-sm {
    .footer-praise {
        max-width: none;
    }

    .extra-footer {
        .hosting-promo {
            text-align: center;
            width: 100%;
        }
    }

    .payment-options {
        display: none;
    }

    .top-footer {
        padding-bottom: 115px;

        .empty {
            display: none;
        }

        .grdcell {
            .col-title {
                margin: 20px 0 10px 0;
            }
        }
    }
}
