/*==========  Mixins  ==========*/
.border-radius(@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

.wrap() {
	text-wrap: wrap;
	white-space: -moz-pre-wrap;
	white-space: pre-wrap;
	word-wrap: break-word;
}

.box-shadow(@arg) {
	-webkit-box-shadow: @arguments;
	-moz-box-shadow: @arguments;
	box-shadow: @arguments;
}

.clearfix() {
	&:after, &:before {
	    content: " ";
	    display: table;
	}
	&:after {
		clear: both;
	}
}

.rainbow-border () {
	background: @color-secondary;
	background: -moz-linear-gradient(left,  @color-secondary 0%, @color-secondary 33.33%, @color-primary 33.33%, @color-primary 66.66%, @color-secondary-alt 66.66%, @color-secondary-alt 100%);
	background: -webkit-gradient(linear, left top,  right top, color-stop(0%,@color-secondary), color-stop(33.33%,@color-secondary), color-stop(33.33%,@color-primary), color-stop(66.66%,@color-primary), color-stop(66.66%,@color-secondary-alt), color-stop(100%,@color-secondary-alt));
	background: -webkit-linear-gradient(left,  @color-secondary 0%,@color-secondary 33.33%,@color-primary 33.33%,@color-primary 66.66%,@color-secondary-alt 66.66%,@color-secondary-alt 100%);
	background: -o-linear-gradient(left,  @color-secondary 0%,@color-secondary 33.33%,@color-primary 33.33%,@color-primary 66.66%,@color-secondary-alt 66.66%,@color-secondary-alt 100%);
	background: -ms-linear-gradient(left,  @color-secondary 0%,@color-secondary 33.33%,@color-primary 33.33%,@color-primary 66.66%,@color-secondary-alt 66.66%,@color-secondary-alt 100%);
	background: linear-gradient(to right,  @color-secondary 0%,@color-secondary 33.33%,@color-primary 33.33%,@color-primary 66.66%,@color-secondary-alt 66.66%,@color-secondary-alt 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color-secondary', endColorstr='@color-secondary-alt',GradientType=1 );
}
.stage-border () {
	background: #42aa0c;
	background: -moz-linear-gradient(left,  #42aa0c 0%, #42aa0c 5%, #1d9ad6 20%, #1d9ad6 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#42aa0c), color-stop(5%,#42aa0c), color-stop(20%,#1d9ad6), color-stop(100%,#1d9ad6));
	background: -webkit-linear-gradient(left,  #42aa0c 0%,#42aa0c 5%,#1d9ad6 20%,#1d9ad6 100%);
	background: -o-linear-gradient(left,  #42aa0c 0%,#42aa0c 5%,#1d9ad6 20%,#1d9ad6 100%);
	background: -ms-linear-gradient(left,  #42aa0c 0%,#42aa0c 5%,#1d9ad6 20%,#1d9ad6 100%);
	background: linear-gradient(to right,  #42aa0c 0%,#42aa0c 5%,#1d9ad6 20%,#1d9ad6 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42aa0c', endColorstr='#1d9ad6',GradientType=1 );
}



@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";
.at2x(@path, @w: auto, @h: auto) {
  background-image: url(@path);
  @at2x_path: ~`@{path}.replace(/\.\w+$/, function(match) { return "@2x" + match; })`;

  @media @highdpi {
    background-image: url("@{at2x_path}");
    background-size: @w @h;
  }
}

.opacity(@opacity) {
	opacity: @opacity;
	@opacity-ie: @opacity * 100;
	filter: ~"alpha(opacity=@{opacity-ie})"; // IE8
}

.center-block() {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.fix-whitespace() {
	font-size: 0;
	& > * {
		font-size: 14px;
	}
}

.filter(@filters) {
	-webkit-filter: @arguments;
	-moz-filter: @arguments;
	-o-filter: @arguments;
	-ms-filter: @arguments;
	filter: @arguments;
}
.gradient-radial(@outer-color, @inside-color, @start: 0%, @end: 100%) {
	background: @inside-color; /* Old browsers */
	background: -moz-radial-gradient(center, circle cover, @inside-color @start, @outer-color @end); /* FF3.6+ */
	background: -webkit-gradient(radial, center center, 0px, center center, @end, color-stop(@start,@inside-color), color-stop(@end,@outer-color)); /* Chrome,Safari4+ */
	background: -webkit-radial-gradient(center, circle cover, @inside-color @start,@outer-color @end); /* Chrome10+,Safari5.1+ */
	background: -o-radial-gradient(center, circle cover, @inside-color @start,@outer-color @end); /* Opera 12+ */
	background: -ms-radial-gradient(center, circle cover, @inside-color @start,@outer-color @end); /* IE10+ */
	background: radial-gradient(circle at center, @inside-color @start,@outer-color @end); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@inside-color', endColorstr='@outer-color',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.triple-gradient (@startColor: #eee, @midColor: white, @endColor: white) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(left, @startColor, @midColor, @endColor);
	background: -moz-linear-gradient(left, @startColor, @midColor, @endColor);
	background: -ms-linear-gradient(left, @startColor, @midColor, @endColor);
	background: -o-linear-gradient(left, @startColor, @midColor, @endColor);
	background: linear-gradient(to-right, @startColor, @midColor, @endColor);
}


/*==========  Animation mixins  ==========*/
.animation (@name, @duration: 300ms, @delay: 0, @ease: ease) {
	-webkit-animation: @name @duration @delay @ease;
	-moz-animation:    @name @duration @delay @ease;
	-ms-animation:     @name @duration @delay @ease;
	animation:     @name @duration @delay @ease;
}

.transition (@transition) {
	-webkit-transition: @transition;
	-moz-transition:    @transition;
	-ms-transition:     @transition;
	-o-transition:      @transition;
}
.transform(@string){
	-webkit-transform: @string;
	-moz-transform: 	 @string;
	-ms-transform: 		 @string;
	-o-transform: 		 @string;
}
.scale (@factor) {
	-webkit-transform: scale(@factor);
	-moz-transform: 	 scale(@factor);
	-ms-transform: 		 scale(@factor);
	-o-transform: 		 scale(@factor);
}
.rotate (@deg) {
	-webkit-transform: rotate(@deg);
	-moz-transform: 	 rotate(@deg);
	-ms-transform: 		 rotate(@deg);
	-o-transform: 		 rotate(@deg);
}
.skew (@deg, @deg2) {
	-webkit-transform:       skew(@deg, @deg2);
	-moz-transform: 	 skew(@deg, @deg2);
	-ms-transform: 		 skew(@deg, @deg2);
	-o-transform: 		 skew(@deg, @deg2);
}
.translate (@x, @y:0) {
	-webkit-transform:       translate(@x, @y);
	-moz-transform: 	 translate(@x, @y);
	-ms-transform: 		 translate(@x, @y);
	-o-transform: 		 translate(@x, @y);
}
.translate3d (@x, @y: 0, @z: 0) {
	-webkit-transform:       translate3d(@x, @y, @z);
	-moz-transform: 	 translate3d(@x, @y, @z);
	-ms-transform: 		 translate3d(@x, @y, @z);
	-o-transform: 		 translate3d(@x, @y, @z);
}
.perspective (@value: 1000) {
	-webkit-perspective: 	@value;
	-moz-perspective: 	@value;
	-ms-perspective: 	@value;
	perspective: 		@value;
}
.transform-origin (@x:center, @y:center) {
	-webkit-transform-origin: @x @y;
	-moz-transform-origin:    @x @y;
	-ms-transform-origin:     @x @y;
	-o-transform-origin:      @x @y;
}

.icon-mixin (@color, @fontSize: 20px) {
    font-size: @fontSize;
    line-height: 0;
    color: @color;
}

.json-response-mixin (@font-color, @background-color) {
    background: @background-color;
    color: @font-color;
    font-weight: 700;
    border: 1px solid @font-color;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    margin: 20px;
}

.tag-mixin(@width, @height, @font-size, @color, @color-bg: white) {
    width: @width;
    height: @height;
    position: relative;
    background: @color;
    color: @color-bg;
    font-size: @font-size;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-right: @height/2 solid @color-bg;
        border-top: @height/2 solid transparent;
        border-bottom: @height/2 solid transparent;
    }

    &::before {
        content: "";
        position: absolute;
        left: -@height/2;
        bottom: 0;
        width: 0;
        height: 0;
        border-right: @height/2 solid @color;
        border-top: @height/2 solid transparent;
        border-bottom: @height/2 solid transparent;
    }
}
