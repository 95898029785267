/*============================================
=            Payment page/methods            =
============================================*/
@color-payment-danger: #f3431f;
@color-payment-info: #1d9ad6;
@color-payment-ok: #42aa0c;

@color-payment-grey-2: rgba(88, 88, 88, 0.2);
@color-payment-grey-1: rgba(88, 88, 88, 0.5);
@color-payment-grey-0: rgba(88, 88, 88, 0.8);

.payment-details {
    margin: 30px 40px;

    img {
        max-height: 20px;
        margin: -3px 3px 0;

        &.moneta {
            max-height: 30px;
            margin-top: -5px;
        }

        &#sepa-img {
            margin-top: -3px;
            max-height: 25px;
        }
    }
}

.pp-description {
    width: 380px;
    margin-bottom: 10px;
}

.fields-holder {
    float: left;
    position: relative;
    border: 0;
    padding: 0;
    margin-bottom: 15px;
    max-width: 565px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 0;

    .braintree-field {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 0 10px;
        height: 40px;
        margin: 5px 0 10px;
        border-radius: 0;
        background-color: rgb(244, 250, 253);
        font-size: 14px;

        &.braintree-hosted-fields-focused {
            box-shadow: 0 0 4px 1px #777;
        }

        &.braintree-hosted-fields-invalid {
            outline-color: @color-payment-danger;
            box-shadow: 0 0 4px -2px @color-payment-danger;
            box-shadow-color: fade(@color-payment-danger, 20%);
            border-color: @color-payment-danger;
        }

        &.braintree-hosted-fields-valid {
            outline-color: @color-payment-ok;
            box-shadow: 0 0 4px -2px @color-payment-ok;
            box-shadow-color: fade(@color-payment-ok, 20%);
            border-color: @color-payment-ok;
        }
    }

    input {
        border: 1px solid #ccc;
    }
}

// Notice on top, about advanced security
.security-notice {
    &::before {
        .iconFamily();

        position: relative;
        top: 0;
        left: -5px;
        content: "\e63e";
        font-size: 30px;
        margin-right: 0;
    }

    display: flex;
    align-items: baseline;
    //position: absolute;
    top: 0;
    min-height: 20px;
    left: 0;
    padding: 10px 25px;
    width: 100%;
    background-color: #1d9ad6;
    color: #fff;

    span {
        position: relative;
        top: -4px;
        display: inline-block;
    }
}

.input-fields {
    padding: 20px 25px 25px;
    box-shadow: 0 0 4px -2px rgba(123, 123, 123, 0.8);
    border: 1px solid #cecece;
    border-top: 0;

    label {
        display: block;
    }

    .fields-title {
        margin-top: 0;
        font-weight: bold;
        color: @color-payment-grey-0;
        border-bottom: 1px solid @color-payment-grey-1;
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    .input-box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
}

.hosted-form label {
    width: 100%;
    color: rgba(51, 51, 51, 0.78);
}

/* CC types */
#card-number-container {
    min-width: 230px;
    min-height: 80px;
}

#card-number,
#card-holder {
    max-width: 230px;
    display: block;
    width: 100%;
}

#card-number {
    background-size: 38px !important;

    &.visa {
        background: rgb(244, 250, 253) url('../img/cms/checkout/visa.svg') no-repeat 95% !important;
    }

    &.master-card {
        background: rgb(244, 250, 253) url('../img/cms/checkout/master-card.svg') no-repeat 95% !important;
    }

    &.american-express {
        background: rgb(244, 250, 253) url('../img/cms/checkout/american-express.svg') no-repeat 95% !important;
    }

    &.diners-club {
        background: rgb(244, 250, 253) url('../img/cms/checkout/diners.svg') no-repeat 95% !important;
        background-size: 38px !important;
    }

    &.discover {
        background: rgb(244, 250, 253) url('../img/cms/checkout/discover.svg') no-repeat 95% !important;
    }

    &.jcb {
        background: rgb(244, 250, 253) url('../img/cms/checkout/jcb.svg') no-repeat 95% !important;
    }

    &.maestro {
        background: rgb(244, 250, 253) url('../img/cms/checkout/maestro.svg') no-repeat 95% !important;
    }
}

.input-container {
    max-width: 230px;
    flex: 1;
}

#paypal-container {
    margin: -10px 0 5px;
}

#postal-code-container,
#security-code-container {
    clear: none;
    max-width: 50px;
}

#postal-code {
    max-width: 40px;
}

#security-code-field {
    max-width: 50px;
}

#security-code-container,
#expiration-data-container {
    display: inline-block;
    margin-left: auto;
}

#expiration-data-container {
    min-width: 110px;
    max-width: 110px;
}

#expiration-date {
    max-width: 70px;
}

#expiration-month {
    max-width: 40px;
}

#expiration-year {
    max-width: 55px;
    float: right;
}

#coinbase-container {
    margin: -5px 0 -20px;
}

.security-notification {
    position: absolute;
    left: 20px;
    top: 50px;
    max-width: 160px;
    margin-right: 20px;
    margin-top: 40px;
    font-size: 12px;
    color: #333;
    color: rgba(51, 51, 51, 0.93);
    text-align: right;
}

.cc-button {
    margin: 15px 0 0 auto;
}

/* Validation classese */
.valid-info,
input.valid-info {
    outline-color: @color-payment-ok;
    box-shadow: 0 0 4px -2px @color-payment-ok;
    box-shadow-color: fade(@color-payment-ok, 20%);
    border-color: @color-payment-ok;
}

.poss-valid-info,
input.poss-valid-info {
    outline-color: @color-payment-info;
    box-shadow: 0 0 4px -2px @color-payment-info;
    box-shadow-color: fade(@color-payment-info, 20%);
    border-color: @color-payment-info;
}

.invalid-info,
input.invalid-info {
    outline-color: @color-payment-danger;
    box-shadow: 0 0 4px -2px @color-payment-danger;
    box-shadow-color: fade(@color-payment-danger, 20%);
    border-color: @color-payment-danger;
}

.security-img {
    margin-top: 20px;
    float: right;
}

#braintree-checkout-form {
    padding: 15px 0 0;
}

.braintree-message-box,
#braintree-message-box {
    padding: 12px 20px;
    margin: 0 40px;
    line-height: 25px;
    color: #dc323a;
    background-color: #fff5f6;
    border-bottom: 1px solid #ccc;

    &.success {
        color: #42aa0c;
        background: #edfbe5;
        border-color: #4fb61a;
    }

    p:first-of-type {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}

.braintree-message-box.success,
#braintree-message-box.success {
    color: #42aa0c;
    background: #edfbe5;
    border-color: #4fb61a;
}

.braintree-message-box {
    clear: both;
    flex: 100%;
    margin: 0 0 25px 0;
    max-width: 100%;
}

/* Bitcoin */
#bitcoin iframe {
    float: right;
    margin-right: -15px;
}

.prepayment-modal {
    max-width: 520px !important;

    .prepayment-success {
        .json-response-mixin(@color-secondary, @color-secondary-very-light);
    }

    .prepayment-error {
        .json-response-mixin(@color-moneta-red, @color-red-light);
    }

    .prepayment-process {
        display: block;
        width: 100px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
    }
}

#card-image {
    position: absolute;
    top: 142px;
    left: 205px;
    width: 44px;
    height: 28px;
    background-image: url(../img/cms/checkout/card_sprite.png);
    background-size: 86px 458px;
    border-radius: 4px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    margin-bottom: 1em;
    &.visa {
        background-position: 0 -398px;
    }
    &.master-card {
        background-position: 0 -281px;
    }
    &.american-express {
        background-position: 0 -370px;
    }
    &.discover {
        background-position: 0 -163px;
    }
    &.maestro {
        background-position: 0 -251px;
    }
    &.jcb {
        background-position: 0 -221px;
    }
    &.diners-club {
        background-position: 0 -133px;
    }
}

#button-pay.disabled-button {
    color: @color-darker-grey;
}
