/*
MARGINS
*/
.m-auto {
    margin: 0 auto !important;
}

.no-mr {
    margin: 0 !important;
}

// All sides
.m0 {
    margin: 0 !important;
}

.m5 {
    margin: 5px !important;
}

.m10 {
    margin: 10px !important;
}

// Top side
.mt-10 {
    margin-top: -10px !important;
}

.mt0 {
    margin-top: 0 !important;
}

.mt5 {
    margin-top: 5px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt25 {
    margin-top: 25px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mt40 {
    margin-top: 40px !important;
}

.mt50 {
    margin-top: 50px !important;
}

.mt60 {
    margin-top: 60px !important;
}

.mt80 {
    margin-top: 80px !important;
}

.mt83 {
    margin-top: 83px !important;
}

.mt100 {
    margin-top: 100px !important;
}
.mt100-liquid {
    margin-top: 100px !important;
}
.mb100-liquid {
    margin-bottom: 100px !important;
}

.mt120 {
    margin-top: 120px !important;
}

.mt130 {
    margin-top: 130px !important;
}

// Bottom side
.mb0 {
    margin-bottom: 0 !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mb45 {
    margin-bottom: 45px !important;
}

.mb50 {
    margin-bottom: 50px !important;
}

.mb60 {
    margin-bottom: 60px !important;
}

.mb80 {
    margin-bottom: 80px !important;
}

.mb100 {
    margin-bottom: 100px !important;
}

.mb120 {
    margin-bottom: 120px !important;
}

// Bottom & Top side
.mbt5 {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}

.mbt20 {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.mbt40 {
    margin-bottom: 40px !important;
    margin-top: 40px !important;
}

.mbt45 {
    margin-bottom: 45px !important;
    margin-top: 45px !important;
}

.mbt60 {
    margin-bottom: 60px !important;
    margin-top: 60px !important;
}

.mbt80 {
    margin-bottom: 80px !important;
    margin-top: 80px !important;
}

// Left side
.ml10 {
    margin-left: 10px !important;
}

.ml15 {
    margin-left: 15px !important;
}

.ml18 {
    margin-left: 18px !important;
}

.ml120 {
    margin-left: 120px !important;
}

.ml20 {
    margin-left: 20px !important;
}

.ml30 {
    margin-left: 30px !important;
}

.ml40 {
    margin-left: 40px !important;
}

.ml50 {
    margin-left: 50px !important;
}

// Right side
.mr5p {
    margin-right: 5% !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr18 {
    margin-right: 18px !important;
}

.mr20 {
    margin-right: 20px !important;
}

/*
PADDINGS
*/
.no-pd {
    padding: 0 !important;
}

// All sides
.p0 {
    padding: 0 !important;
}

.p5 {
    padding: 5px !important;
}

.p10 {
    padding: 10px !important;
}

.p20 {
    padding: 20px !important;
}
.p30 {
    padding: 30px !important;
}
.p40 {
    padding: 40px !important;
}
.p50 {
    padding: 50px !important;
}
.p100 {
    padding: 100px !important;
}

// Right side
.pr15 {
    padding-right: 15px !important;
}

.pr20 {
    padding-right: 20px !important;
}

// Top side
.pt0 {
    padding-top: 0 !important;
}

.pt10 {
    padding-top: 10px !important;
}

.pt15 {
    padding-top: 15px !important;
}

.pt20 {
    padding-top: 20px !important;
}

.pt30 {
    padding-top: 30px !important;
}
.pt40 {
    padding-top: 40px !important;
}

.pt50 {
    padding-top: 50px !important;
}

// Bottom side
.pb0 {
    padding-bottom: 0 !important;
}

.pb10 {
    padding-bottom: 10px !important;
}

.pb15 {
    padding-bottom: 15px !important;
}

.pb20 {
    padding-bottom: 20px !important;
}

.pb30 {
    padding-bottom: 30px !important;
}

.pb40 {
    padding-bottom: 40px !important;
}

.pb50 {
    padding-bottom: 50px !important;
}


// Top & Bottom side
.ptb10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.ptb15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.ptb25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.ptb30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.ptb40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.ptb50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}
// Left & Right side
.plr10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.plr20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.margin-tab {
    margin-right: 40px !important;
}

@media @break-sm {
    .margin-tab {
        margin-right: 0 !important;
    }
    .mt100-liquid {
        margin-top: 50px !important;
    }
    .mb100-liquid {
        margin-bottom: 50px !important;
    }
}
