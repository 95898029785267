.additional-questions {
    border: 1px solid @color-grey;
    margin: 40px 0 20px;
    position: relative;
    text-align: center;
    border-radius: @box-border-radius;
    background-color: #fff;
    .supp-woman,
    .supp-man {
        position: absolute;
    }

    .supp-woman {
        left: 33%;
        top: 27px;
    }

    .supp-man {
        top: 29px;
        right: 34%;
    }

    .questions,
    .all-time,
    .work-time {
        min-width: 260px;
    }

    .questions {
        .logo {
            display: block;
            max-height: 22px;
            margin: 3px auto 7px;
        }

        .ftr {
            background-color: @color-secondary;
            color:#fff;
            border-bottom-left-radius: @box-border-radius - 4px;
        }
    }

    .all-time {
        .ftr {
            background-color: @color-primary;
            border-right: 2px solid @color-white;
            border-left: 2px solid @color-white;
            color:#fff;
        }

    }

    .work-time {
        .ftr {
            background-color: @color-secondary-alt;
            color:#fff;
            border-bottom-right-radius: @box-border-radius - 4px;
        }

    }

    .hdr {
        min-height: 100px;
        padding: 20px 10px 10px;

        small {
            display: block;
            margin-top: 9px;
            padding-bottom:4px;
            font-size: @font-size-normal;
        }

        a,
        p {
            font-size: @font-size-bigger;
        }
    }

    .ftr {
        padding: 10px 0 8px;
        text-align: center;
        color: @color-dark-grey;
        background-color: #DDD;

        .c-b {
            color: @color-fake-black;
        }
    }
}

@media(max-width: @break-md-value) {
    .additional-questions {
        .work-time .ftr {
            border-bottom-left-radius: @box-border-radius - 4px;

        }
        .all-time {
            .ftr {
                border-right: 0;
                border-left: 0;
            }

        }
        .questions {
            .ftr {
                border-bottom-left-radius: 0;
            }
        }
    }
}
