// Arrow between row content - mostly used on front
.arrow-up-100,
.arrow-down-100 {
    position: relative;

    &::before {
        position: absolute;
        left: 50%;
        width: 0;
        height: 0;
        content: "";
        border: solid transparent;
        border-color: transparent;
        border-width: 26px 75px ;
        margin-left: -75px;
        -moz-transform: scale(0.9999); // FF fix
        z-index: 2;
    }

    &.arrow-low {
        &::before {
            border-width: 18px 75px;
        }
    }
}

.arrow-up-100::before {
    bottom: 100%;
    border-bottom-color: @color-lightest-grey;
}

.arrow-down-100::before {
    top: 100%;
    border-top-color: @color-lightest-grey;
}
