/* ==========  Modal + gallery lightbox  ========== */
.mdl {
    &.moneta-modal {
        max-width: 740px;
    }

    &.qr-modal {
        max-width: 300px !important;
    }
}

.mdl-md {
    &.mdl,
    .mdl,
    .mfp-content {
        max-width: 900px;
    }
}

.mdl-auto {
    &.mdl,
    .mdl,
    .mfp-content {
        width: auto;
    }
}

.mdl-sm {
    &.mdl,
    .mdl,
    .mfp-content {
        max-width: 640px;
    }
}

.mdl-header {
    .mdl-title {
        position: relative;
        padding: 17px 70px 13px 20px;
        min-height: 60px;
        background-color: @color-primary;
        color: #fff;
        .clearfix();

        h4 {
            margin: 0;
            line-height: 1.48em;
        }

        & .icon-close {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 60px;
            padding: 11px 10px;
            font-size: 42px;
            cursor: pointer;
            background-color: @color-primary-alt;
            background-color: rgba(0, 0, 0, 0.3);

            &:active {
                top: 0;
            }
        }
    }
}

.ssd-compare .mdl-content {
    padding-bottom: 10px;
}

.mdl-content {
    min-height: 340px;
    padding: 30px 20px;
    z-index: 3;
    &.no-height {
        min-height: unset;
    }

    &.video-slides {
        padding: 20px 0 0;

        h4 {
            padding: 0 20px;
        }

        .youtube-video {
            min-height: 428px;
        }
    }

    .img-holder {
        overflow: auto;
    }

    &.mdl-image {
        padding: 0;
        margin: 0;
        // background-color: @color-fake-black;
        h4 {
            background-color: #fff;
            color: black;
            padding: 15px 20px;
            margin: 0;
        }

        img {
            margin: 0;
            padding: 20px;
        }
    }

    .notice-text {
        margin: 20px 20px 5px;
    }
}

.mfp-iframe-holder {
    .mdl-content {
        min-height: 500px;
    }
}

.mfp-iframe {
    min-height: 500px;
}

.mfp-gallery .mdl-image {
    .img-holder {
        position: relative;
        padding: 0 70px;
    }
}

.mdl {
    .center-block();

    position: relative;
    width: 100%;
    background-color: #fff;

    &.md {
        max-width: 900px;
    }

    .mdl-title {
        min-height: 30px;
    }

    .mdl-img {
        position: relative;
        background-color: #ebebeb;
        border-bottom: 1px solid #ccc;
        padding: 20px;

        &.exact-size {
            padding: 0;
            border-bottom: 0;
        }

        &.empty-img {
            height: 100px;
            background: @color-light-grey;
        }

        .center-icon {
            position: absolute;
            top: 10px;
            left: 50%;
            max-width: 100px;
            margin-left: -50px;
            width: 100%;

            &.medium {
                max-width: 120px;
                margin-left: -60px;
            }

            &.big {
                bottom: -17px;
                top: auto;
                max-width: 140px;
                margin-left: -70px;
            }
        }
    }

    .mdl-img-center {
        position: relative;
        background-color: #ebebeb;
        border-bottom: 1px solid #ccc;
        padding: 20px;
        text-align: center;
         }

    .mdl-footer {
        border-top: 1px solid #ccc;
        padding: 30px 20px;

        .content {
            border: 0;
        }
    }
}

.mdl-content {
    /* @TODO Prevei, če je potrebno */
    .grdcell {
        & > p {
            &:first-of-type:not([class^="mt"]) {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    h4 {
        &:first-of-type {
            margin-top: 0;
        }
    }
}

.pre-image {
    align-items: center;
    display: inline-flex;

    .text {
        padding-left: 10px;
    }

    .img {
        margin-top: 5px; // <p> - lineheight
    }

    &.small {
        .text {
        }
    }
}

@media @break-sm {
    .mfp-content {
        max-width: 100%;
    }

    .mdl-md {
        &.mdl,
        .mdl,
        .mfp-content {
            max-width: 100%;
        }
    }

    .mdl-auto {
        &.mdl,
        .mdl,
        .mfp-content {
            max-width: 100%;
            width: auto;
        }
    }
    .mdl-header {
        .mdl-title {
            h4 {
                font-size:@font-size;
            }
        }
    }

    .mdl-sm {
        &.mdl,
        .mdl,
        .mfp-content {
            max-width: 100%;
        }
    }
}
