/*==============================
=            HEADER            =
==============================*/

/**
 *
 * Breadcrumbs
 * Top-header - most of pages
 *
 */
.breadcrumbs {
    font-size: 15px;
    list-style: none;
    margin: 20px 0 10px;
    padding: 0;

    a {
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    }

    a,
    li {
        color: @color-white;
    }

    li {
        padding: 0 15px 7px 0;
        @media(max-width:@break-sm-value){
            padding: 0 7px 7px  0;
            font-size: 14px;
        }
        display: inline-block;
        list-style: none;

        &:first-of-type {
            padding-left: 0;
        }

        &::after {
            .iconFamily();

            content: "\e604";
            position: relative;
            top: -1px;
            left: 10px;
            font-size: 10px;
            @media(max-width:@break-sm-value){
                left: 5px;
            }
        }

        &:last-of-type::after {
            display: none;
        }
    }
}

/*==========  top-header  ==========*/

.top-links {
    background-color:@color-dark;
    text-align: left;
    font-size: 13px;
    color: @color-darken-grey;
    &:extend(.rubik);

    ul {
        list-style: none;
        display: flex;
        gap: 15px;
        width:100%;
        margin:0;
        padding: 0;
        position:relative;

        li {
            padding:3px 0 4px 10px;

            &:last-child {
                margin-left:auto;
            }
            &.mobile-links {
                display:none;
                cursor:pointer;

                i {
                    transition: transform 0.3s ease;
                    display:inline-block;
                }
                &:hover {
                    color:@color-white;
                }
                &.active {
                    background-color: @color-black;
                    padding-right:10px;
                    i {
                        transform: rotate(180deg);
                    }
                }
                ul {
                    position:absolute;
                    z-index:1000;
                    background-color:@color-dark;
                    -webkit-box-shadow: 0px -3px 5px -2px rgba(0,0,0,0.72);
                    -moz-box-shadow: 0px -3px 5px -2px rgba(0,0,0,0.72);
                    box-shadow: 0px -3px 5px -2px rgba(0,0,0,0.72);

                    width:100%;
                    top:30px;
                    left:0;
                    li {
                        width:100%;
                        padding:0 0 0 10px;
                        margin:0;
                        border-bottom:1px solid @color-darken-grey;
                        &:last-child {
                            border-bottom:none;
                        }
                        a {
                            &:hover {
                                font-weight:bold;
                            }
                            width:100%;
                            font-size:1.1rem;
                            padding:12px 12px 12px 0;
                            display:block;
                            color: @color-lighter-grey;

                            small {
                                float:right;
                                color: @color-darken-grey;
                                text-align:right;
                                font-style:italic;
                            }
                            i {
                                margin:0 10px 0 0;
                            }
                        }
                    }
                }
            }

            .contact-list {
                display:none;
                padding:0;
                &.active {
                    display:block;
                }
            }
        }
    }

    a {
        color: @color-light-grey;
        font-size: 13px;
        &:hover {
            color: @color-white;
            text-shadow: 1px 0 white;
        }
    }

}

.top-header {
    background-color: #fff;
    min-height: 110px;
}

.top-logo {
    height: auto;
    margin: 20px 10px 14px 0;
    min-width: 300px;
    width: 300px; // Firefox doesn't display SVG if parent node doesn't have width specified
    margin-right: 7%;

    .logo-img {
        &.mobile {
            max-height:none;
            max-width:none;
            width:auto;
            display:none;
        }
        height: auto;
        max-height: 80px;
        max-width: 265px;
        width: 100%;
    }
}

@media @break-max-xlg-menu-value {
    .top-logo {
        .logo-img {
            display:none;
            &.mobile {
                display:block;
            }
        }
    }
    .top-links {
        .desktop-only {
            display:none;
        }
        .container {
            padding:0;
        }
        ul {
            li {
                &.mobile-links {
                    display:block;
                }
                &:last-child {
                    padding-right:15px;
                }
            }
        }
    }
}

.empty-cart {
    .icon-smiley,
    .icon-smiley-down {
        display: inline-block;
        position: relative;
        bottom: -5px;
        padding: 8px 7px 6px;
        width: 41px;
        height: 41px;
        border: 2px solid;
        border-radius: 200px;
        font-size: 24px;
        font-weight: 800;
        color: @color-primary;
    }
}

.top-header {
    .support,
    .top-logo,
    .right-info {
        display: inline-block;
        vertical-align: top;
    }

    .support {
        flex-grow: 1.6;
        margin-top: 44px;
        &:extend(.rubik);
        & .email,
        & .phone {
            float: left;
        }

        & .email {
            min-width: 250px;
            margin-right: 50px;
        }

        & .phone {
            min-width: 175px;
        }

        & a {
            display: block;
            text-decoration: none;
            font-size: @font-size-bigger;
        }
    }

    .working-time {
        color: @color-darker-grey;
    }

    .spr-support_phone,
    .spr-support_mail {
        vertical-align: top;
    }

    .phone-data,
    .email-data {
        display: inline-block;
        margin-left: 5px;
        text-align: left;
        vertical-align: bottom;

        strong {
            border-bottom: 1px solid @color-primary;
        }
    }

    .right-info {
        margin-left: auto;
        max-width: 250px;
        min-width: 210px;
    }

}

/*==========  bottom-header  ==========*/
.bottom-menu,
.bottom-menu-extra {
    position: relative;
    background-color: @color-primary;
}

.bottom-menu {
    .upsell-btn {
        position: absolute;
        top: 30px;
        right: 20px;
    }
}

.bottom-menu .additional-content {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: right;
    width: 100%;
    max-width: 360px;
    height: 80px;
    overflow: hidden;
    margin: 20px 0 20px 40px;

    .box-shadow(0 0 4px -2px rgba(0,0,0,0.5));

    .single-item-carrousel {
        max-width: 360px;
        max-height: 80px;
    }

    &.winter-promo {
        background: url(../img/cms/banners/promo-winter-bg.png);

        .price {
            font-size: 28px;
            margin-top: 30px;
            margin-right: 5px;
        }

        .col {
            padding-right: 10px;
        }
    }
}

.browser-alert {
    background: #da2126;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99999;
    color: #fff;
    padding: 2px 8px;
}

#main-header:not(.scrolled.animate.show):not(.fixed-menu) .main-menu .main-menu-item.turbo.active:not(:hover){
    background-color: #0f3d53;
    border-top-color: #0f3d53;
    box-shadow: 0 5px 0 -2px #0f3d53;
}
#main-header.fixed-menu:not(.scrolled.animate.show) .main-menu-item.turbo.active{
    background-color: transparent;
    box-shadow: none;
}

/* TURBO HOSTING KEYVISUAL*/

.bottom-menu.turbo{
    background: rgb(15,61,83);
    background: linear-gradient(0deg, rgba(15,61,83,1) 0%, rgba(44,85,106,1) 50%, rgba(15,61,83,1) 100%);

    .price{
        position: absolute;
        top:-45px;
        left: auto; right: 20%;
        color:#422a2a;
        font-size: 16px;
        background-image: url("../img/cms/hosting/ribbon.png");
        background-size: cover;
        background-repeat: no-repeat;
        padding: 30px 0px;
        line-height: 1;
        width: 105px;
        height: 105px;
        span{
            margin-top: 5px;
            display: block;
            font-weight: 800;
            font-size: 19px;
        }
        @media(min-width:@break-sm-value){
            top:-45px;
            left: auto; right: 20%;
            padding: 30px 0px;
        }
        @media(min-width:@break-md-value){
            top:-20px;
            left: 30px;
            right: auto;
            font-size: 18px;
            padding: 40px 14px;
            width: 132px;
            height: 132px;
            span{
                font-size: 26px;
            }
        }
        @media(min-width:@break-lg-value){
            left: 0;
            top:-75px
        }
    }

    .turbo-banner-icon{
        margin-right: 2rem;
        @media(max-width:@break-sm-value){
            width: 50%;
            float: left;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-right: 0rem;
        }
        svg{
            width: 60px;
            height: 60px;
        }
        p{
            margin-bottom: 0;
        }
    }
    .server-wrap{
        position: relative;
        margin-top: 40px;
        @media(min-width:@break-lg-value){
            margin-top: -60px;
        }
        //margin-left: 115px;
    }
    .server{
        height: 240px;
    }

    .turbo-banner-padding{
        padding: 20px 140px 0;
    }

    /* Animations */

    /* TURBO HOSTING ANIMATION */
      @shooting-time: 3000ms;

      .night {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: rotateZ(180deg);
        overflow: hidden;
        // animation: sky 200000ms linear infinite;
      }


      .shooting_star {
        position: absolute;
        left: 50%;
        top: 50%;
        // width: 100px;
        height: 2px;
        background: linear-gradient(-45deg, rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
        border-radius: 999px;
        filter: drop-shadow(0 0 6px rgba(105, 155, 255, 1));

        animation:
          tail @shooting-time ease-in-out infinite,
          shooting @shooting-time ease-in-out infinite;

        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 1px);
          right: 0;
          // width: 30px;
          height: 2px;
          background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
          transform: translateX(50%) rotateZ(45deg);
          border-radius: 100%;
          animation: shining @shooting-time ease-in-out infinite;
        }

        &::after {

          content: '';
          position: absolute;
          top: calc(50% - 1px);
          right: 0;
          // width: 30px;
          height: 5px;
          background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
          transform: translateX(50%) rotateZ(45deg);
          border-radius: 100%;
          animation: shining @shooting-time ease-in-out infinite;
          transform: translateX(50%) rotateZ(-45deg);
        }

      }

      @keyframes tail {
        0% {
          width: 0;
        }

        30% {
          width: 100px;
        }

        100% {
          width: 0;
        }
      }

      @keyframes shining {
        0% {
          width: 0;
        }

        50% {
          width: 30px;
        }

        100% {
          width: 0;
        }
      }

      @keyframes shooting {
        0% {
          transform: translateX(0);
        }

        100% {
          transform: translateX(3840px);
        }
      }

      @keyframes sky {
        0% {
          transform: rotate(45deg);
        }

        100% {
          transform: rotate(45 + 360deg);
        }
      }

    .andy-rocketman{
        max-width: 300px;
        animation: flying 700ms ease-in-out infinite;
        margin-top: 0px;
        @media(min-width: @break-sm-value){
            margin-top: 70px
        }
        @media(min-width: @break-lg-value){
            margin-top: -20px
        }
    }
    @keyframes flying {
        0%{
            transform: translateY(0px);
        }
        10%{
            transform: translateY(3px);
        }
        20%{
            transform: translateY(0px);
        }
        30%{
            transform: translateY(-2px);
        }
        50%{
            transform: translateY(0px);
        }
        60%{
            transform: translateY(2px);
        }
        75%{
            transform: translateY(1px);
        }
        100%{
            transform: translateY(0px);
        }
    }

    .flame{
        transform-origin: 47% 50%;
        animation: flameEffect 150ms ease-in-out infinite;
    }
    @keyframes flameEffect{
        0%{
            transform:scaleX(1) scaleY(1);
        }

        33%{
            transform:scaleX(1.1) scaleY(.95);
        }
        66%{
            transform:scaleX(.9) scaleY(1.05);
        }
        99%{
            transform:scaleX(1);
        }
    }

    .server polygon{
        animation: ledBlinking 700ms ease-in-out infinite;
    }
}
@keyframes ledBlinking{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

/* SHARED HOSTING KEYVISUAL*/
.bg-deljeno {
    .shared-hosting-badge-road{
        position: relative;
        z-index: 1;
        .kv-badges{
            position: absolute;
            height: auto;
            left: 6%;
            top: 12%;
            z-index: -1;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            .green-badge-shared{
                background-color: @color-secondary;
                border-radius: 20px;
                padding: 20px 55px;
                border:3px solid #fff;
                font-size: 18px;
                line-height: 30px;
                align-self: flex-end;
                box-shadow: 3px 3px 1px 1px rgba(0,0,0,.2);
                span{
                    font-size:30px;
                }
            }
            .orange-badge-shared{
                background-color: @color-secondary-alt;
                border-radius: 25px;
                padding: 20px 35px;
                border:3px solid #fff;
                font-size: 18px;
                line-height: 30px;
                float: right;
                align-self: flex-end;
                box-shadow: 3px 3px 1px 1px rgba(0,0,0,.2);
                position: relative;
                top:-20px;
                left:100px;
                span{
                    font-size:30px;
                }
            }
        }
        .road{
            max-height: 350px;
            position: relative;
            left: 18%;
        }
    }
    .andy-racer{
        max-height: 350px;
        position: relative;
        top: 137px;
    }

    .balloon {
        display:block;
        position:absolute;
        right:0px;
        top: 80px;

        -webkit-animation: Floatingy 3s infinite ease-in-out;

        @-webkit-keyframes Floatingy {
            from {
                -webkit-transform:translateY(0px);
            }
            65% {
                -webkit-transform:translateY(15px);
            }
            to {
                -webkit-transform: translateY(-0px);
            }
        }
    }
    @media (max-width:1000px) {
        .shared-hosting-badge-road {
            .kv-badges {
                .orange-badge-shared {
                    left: 0;
                }
            }
        }
    }
    @media (max-width:900px) {
        .shared-hosting-badge-road {
            .kv-badges {
               display:none;
            }
            .road {
                left: 0%;
            }
        }
    }
    @media (min-width:@break-md-value) and (max-width:@break-xlg-value){
        .shared-hosting-badge-road {
            .road {
                top: 0px;
            }
        }
        .balloon {
            top:20px;
        }
        .andy-racer{
            max-height: 200px;
            top: 12%;
            margin-bottom: 0px;
        }
    }
    @media @break-md{

        .balloon {
            display:none;
        }
        .andy-racer{
            max-height: 250px;
            position: relative;
            top: -145px;
            margin-bottom: -170px;
        }
    }
    @media @break-sm{
        .road{
            margin-bottom: -40px;
        }
        .shared-hosting-badge-road {
            .road{
                top: -20px;
            }
        }
        .balloon {
            display:none;
        }
        .andy-racer{
            max-height: 185px;
            position: relative;
            top: -100px;
            margin-bottom: -120px;
        }
    }
    /* SHARED HOSTING ANIMATION*/
    .formula{
        animation: infinityCarRacing 2000ms linear infinite;
        transform: translate(0px ,0px );
        transform-origin: 60% 28%;
    }
    .bridge{
        animation: underBridge 2000ms linear infinite;

    }
    @keyframes underBridge{
        0%{
            opacity: 1;
        }
        30%{
            opacity: 1;
        }
        35%{
            opacity: 0;
        }
        99%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes infinityCarRacing{
        0%{
            transform: translate(0,0);
        }
        10%{
            transform: translate(-52px, 49px);
        }
        15%{
            transform: translate(-72px, 54px) rotate(34deg);
        }
        20%{
            transform: translate(-92px, 47px) rotate(66deg);
        }
        25%{
            transform: translate(-104px, 28px) rotate(110deg);
        }
        30%{
            transform: translate(-104px, 7px) rotate(138deg);
        }
        35%{
            transform: translate(-91px, -10px) rotate(174deg);
        }
        40%{
            transform: translate(-70px, -17px) rotate(211deg);
        }
        45%{
            transform: translate(-51px, -12px) rotate(243deg);
        }
        50%{
            transform: translate(-36px, 2px) rotate(265deg);
        }
        60%{
            transform: translate(6px, 44px) rotate(265deg);
        }
        65%{
            transform: translate(23px, 49px) rotate(237deg);
        }
        70%{
            transform: translate(41px, 44px) rotate(205deg);
        }
        75%{
            transform: translate(52px, 30px) rotate(169deg);
        }
        80%{
            transform: translate(52px, 11px) rotate(128deg);
        }
        85%{
            transform: translate(41px, -5px) rotate(89deg);
        }
        90%{
            transform: translate(24px, -11px) rotate(56deg);
        }
        95%{
            transform: translate(10px, -8px) rotate(23deg);
        }
        100%{
            transform: translate(0px, 0px) rotate(0deg);
        }

    }
}
/* End SHARED HOSTING */
/* RESELLER HOSTING */
.andyReseller{
    width: 120px;
    margin-top:0 !important;

    @media(min-width:769px){
        transform: translateX(-45px);
    }
    @media(min-width:992px){
        width: 150px;
        transform: translateX(-50px);
    }
}
.resellerFloor{
    z-index: 0;
    margin-top: -10px;
    @media(min-width:768px){
        margin-top: -35px;
    }
    @media(min-width:992px){
        margin-top: -50px;
    }
}
.resellerRight, .resellerLeft{
    z-index: 1;
}
.resellerLeft svg{
    margin-top: 15px;
    margin-bottom: -18px;
    @media(min-width:769px){
        margin-top: -60px;
        margin-bottom: 0;
    }
}
.resellerRight{
    margin-bottom: -170px;
    @media(min-width:@break-md-value){
        margin-bottom: 0;
    }
}
.resellerRight .align-self-flex-end{
    align-self: auto;
    @media(min-width:@break-md-value){
        align-self: flex-end;
    }
}
.kv-list-title{
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-transform: uppercase;
    animation:opacityShow 1s linear;
    animation-delay:.5s;
    opacity: 0;
    margin-top: -10px; ;
    margin-left: -30px;
    @media(min-width:769px){
        font-size: 20px;
        line-height: 30px;
    }
}
.kv-reseller-list{
    transform: translateX(40px);
}


.kv-reseller-list ul{
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    list-style-type: none;
    padding-left: 20px;
    margin-left: -30px;
    li{
        line-height: 30px;
        align-self: center;
        display: flex;
        animation:opacityShow 1s linear;
        opacity: 0;
        &:first-child{animation-delay: 1.6s;}
        &:nth-child(2){animation-delay: 2.1s;}
        &:nth-child(3){animation-delay: 2.6s;}
        &:last-child{animation-delay: 3.1s;}
    }
    li:before{
        content:'';
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cpolygon style='fill:%23FFFFFF;' points='168.4,452.2 3.8,294 93.9,204.5 177.1,287.7 420.8,44 508.6,131.8 '/%3E%3C/svg%3E");
        position: relative;
        left: -20px;
        width: 20px;
        height: 20px;
        top: 5px;
        background-repeat: no-repeat;
    }
    @media(min-width:992px){
        font-size: 18px;;
       li{
           line-height: 40px;
       }
       li:before{
        width: 30px;
        height: 30px;
        top: 3px;
       }
    }
}

@media(max-width:@break-sm-value) {
    .kv-reseller-list {
        transform: none;

        ul {
            margin-left: 0;
        }

        .kv-list-title {
            margin-left: -8px;
        }
    }
}

@media @break-max-md{
    .r-side-mb-55{
        min-height: 378px;
        svg{margin-top:-115px}
        @media(min-width:768px){
            margin-bottom: -55px;
            svg{margin-top:-60px}
        }
    }
}
#cpanel, #mariadb, #nvme, #safe, #litespeed{
    opacity: 0;
    animation: slideUp 2s linear;
}
#nvme{
    animation-delay: .5s;
}
#safe{
    animation-delay:.2s;
}
#cpanel{
    animation-delay:.6s;
}
#mariadb{
    animation-delay:.9s;
}
#litespeed{
    animation-delay:.4s;
}
#obroba, #obroba2, #obroba3, #obroba4, #obroba5{
    fill:@color-primary;
    stroke:#8ccae9;
    stroke-width: 1px;
    stroke-dasharray: 450;
    stroke-dashoffset: 0;
    animation: strokeanim 5s linear infinite;
}
#obroba2{
    animation-delay: 1.5s;
}
#obroba3{
    animation-delay: .75s;
}
#obroba4{
    animation-delay: .3s;
}
#obroba5{
    animation-delay: 1s;
}
#_x23_star1L, #_x23_star2L, #_x23_star3L{
    animation: blingshine 1.5s linear infinite;
    opacity: 0;
}
#_x23_star1L{
    animation-delay: .5s;
}
#_x23_star2L{
    animation-delay: .7s;
}
#_x23_star3L{
    animation-delay: .3s;
}
@keyframes opacityShow{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes slideUp {
    0%{
        transform: translate(0px, 15px);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: translate(0px, 0px);
        opacity: 1;
    }

}
@keyframes strokeanim {
    0%{
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dashoffset: -450;
    }
    100%{
        stroke-dashoffset: -900;
    }
}
@keyframes blingshine{
    0%{
        opacity: 0;
    }
    40%{
        opacity: 1;
    }
    80%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
/* End RESELLER HOSTING */
/* DEDICATED HOSTING */
.bg-dedicated{
    .left-side{
        max-width: 500px;
        overflow: visible;
        #andyStart, #andyEnd{
            transform: translateY(15px);
        }
    }
    #workingLights path{
        opacity: 0;
    }
}
.buttonPlay{
    max-width: 180px;
    margin-right: 50%;
    margin-top: 10%;

    @media (max-width: 950px) {
        margin-top: 0;
    }

    @media (@break-max-md) {
        margin-right: 0;
    }

    @media (@break-xs) {
        max-width: 100px;
    }

    &:focus, &:active, &:hover{
        cursor: pointer;
        .gumbTop{
            transform: translateY(3px);
            filter:brightness(.85);
        }
    }
}
#puscica{
    animation: arrowUpDown 1s linear infinite;

}

.bg-dedicated.play{
    #vetrnica-levo{
        animation: ventSpin .8s linear infinite;
        transform-origin: 60px 224px;
    }
    #vetrnica-desno{
        animation: ventSpin .8s linear infinite;
        transform-origin: 91px 221.5px;
    }
    #workingLights path{
        animation: ledBlinking linear infinite;
    }
}
@keyframes ventSpin{
    0%{
        transform: rotateZ(0deg)
    }
    100%{
        transform: rotateZ(360deg);
    }
}
@keyframes arrowUpDown{
    50%{
        transform: translateY(10px);
    }
}

.advantages-list {
    ul{
        list-style: none;
        margin-top: 45px;
        li{
            position: relative;
            text-align: left;
            padding-left: 40px;
            font-size: 20px;
            margin-bottom: 30px;
            display: none;
            text-transform: uppercase;

            &:after{
                background-image: url("data:image/svg+xml,%3Csvg id='f3cc7299-65f2-49d5-8044-0011ea4e3bc6' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 181.67 150.25'%3E%3Cdefs%3E%3Cstyle%3E.be0aee77-0a6e-4d91-9884-8a4b2570e3d8%7Bfill:%2376c04d;%7D%3C/style%3E%3C/defs%3E%3Cpolygon class='be0aee77-0a6e-4d91-9884-8a4b2570e3d8' points='58.33 150.25 0 93.51 30.03 65.37 61.33 90.85 148.67 0 181.67 28.18 58.33 150.25'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                content: '';
                position: absolute;
                left:0;
                top: 0;
                width: 27px;
                height: 23px;
            }

            &:nth-child(1){
                &:after{
                    filter: brightness(100%);
                }
            }
            &:nth-child(2){
                &:after{
                    filter: brightness(95%);
                }
            }
            &:nth-child(3){
                &:after{
                    filter: brightness(90%);
                }
            }
            &:nth-child(4){
                &:after{
                    filter: brightness(85%);
                }
            }
            &:nth-child(5){
                &:after{
                    filter: brightness(80%);
                }
            }

        }

        @media (max-width: 1050px){
            li {
                margin-bottom:22px;
            }
        }

        @media (max-width: 950px){
            margin-top: 5px;
            li {
                font-size:18px;
                margin-bottom:16px;
                padding-left: 30px;
                &:after {
                    width:22px;
                }
            }
        }

        @media (max-width: 840px){
            margin-top: 0px;
            li {
                font-size:16px;
                margin-bottom:14px;
                padding-left: 25px;
                &:after {
                    width:19px;
                }
            }
        }

        @media (max-width: 390px){
            li {
                font-size:15px;
                text-transform:none;
                margin-bottom:13px;
                padding-left: 25px;
                &:after {
                    width:19px;
                }
            }
        }

    }
}
/* VPS hosting */
.vps-advantages {
    margin-left:40px;
    @media (max-width: 1220px){
        margin-left:0;
    }
    @media (max-width: 800px){
        padding-top:20px !important;
    }
    .advantages-list ul {
        margin-top:30px;
        @media (max-width: 1000px){
            padding-left:35px;
        }
        @media (max-width: 800px){
            margin-top:0px;
        }
    }
}
.vps-jetpack {
    margin-top:-20px;
    margin-bottom:20px;
    margin-right:40px;
    @media (max-width: 1220px){
        margin-right:0;
    }
}
/*-----  End of HEADER  ------*/
