.buttons.sitebuilder{
    &.top-button{
        display: block;
        text-align: center;
        .btn-big:first-child{
            position:relative;
            background-color: @color-primary;
            color:#fff;
        }
    }
    &.bottom-button{
        display: block;
        text-align: center;
        .btn-big{
            position: relative;
            top:-30px;
            background-color: @color-primary;
            color:#fff;
        }
    }
    
}

.big-screen{
    display: none;
}
.mobile-screen{
    display: block;
}
@media(min-width:589px){
    .big-screen{
        display: block;
    }
    .mobile-screen{
        display: none;
    }
    .buttons.sitebuilder{
        position: relative;
        top: -50px;
        .btn-big:first-child{
            position: absolute;
            left: 10%;
        }
        .btn-big:last-child{
            position: absolute;
            right: 10%;
        }
    }
}