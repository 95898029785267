.content {
    margin-top: 20px;

    &.mt0 {
        margin-top: 0;
    }

    .page-header {
        margin-bottom: 40px; // 20px
        padding-bottom: 10px;
        text-align:center;

        &.brd-bt {
            position: relative;
            padding-bottom:20px;
            border-bottom: none;
            &:before, &:after {
                content: '';
                position: absolute;
                height: 1px;
                width: 50%;
                background: linear-gradient(to right, transparent, @color-fake-black);
                bottom: 0;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
                background: linear-gradient(to left, transparent, @color-fake-black);
            }
        }


        h1 {
            font-size: 2.55em;
            &.f-massive {
                font-size: @font-size-massive;
            }
            &:extend(.rubik);
            font-weight:700;
            line-height: 40px;
            margin: 0.6em 0 0.8em;
        }

        &:not(.no-upper) h1,
        &:not(.no-upper) h2 {
            text-transform: uppercase;
        }

        & h1,
        & h2 {
            margin-bottom: 0;
        }

        & p {
            margin-top: 15px;
            margin-bottom: 5px;
            font-size: @font-size-big;
            &:extend(.rubik);
        }

        .upsell.written {
            position: absolute;
            left: 20px;
            top: 110px;
        }

        &.modern {
            p {
                font-size: 25px;
            }

            &.brd-bt {
                border-bottom: none;
            }
        }
    }

    .page-pagination {
        padding: 10px;
        margin: 20px 0 80px;
        text-align: center;
        background-color: #efefef;

        &.mt0 {
            margin: 0 0 40px;
        }

        &.brd-tp {
            border-top: 2px solid @color-fake-black;
            padding-top: 15px;
        }
    }
}

.container.content > .grd > .grdcell:first-child {
}

.page-holder {
    position: relative;
}

.elem {
    & > div {
        margin-bottom: 40px;
    }
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}
.order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

@media @break-min-md{
    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media @break-max-md {

    .left-right-content {
        .image1 {
            margin:0 auto;
        }
    }

}
