.written {
    &.rotated {
        display: inline-block;
        height: 40px;
        line-height: 4em;
        padding: 2px 10px;
        position: absolute;
        right: 5%;
        border-radius:@box-border-radius;
        top: 10px;
        .rotate(-15deg);
    }

    &.centered {
        right: 50%;
        margin-right: -120px;
    }

    &.upsell {
        position: relative;
        display: inline-block;
        transform: rotate(-15deg);
        font-size: 18px;
        line-height: 20px;
        color: @color-primary;

        span {
            position: relative;
            font-size: 22px;
            left: 80px;
        }

        /* webfont bug fix */

        .text {
            position: relative;
            bottom: -20px;
            left: 20px;
            margin-bottom: -10px;
        }

        &.in-text {
            left: 20px;
            bottom: -5px;
        }
    }

    &.priceboxes {
        position: absolute;
        left: 306px;
        top: 157px;
    }
}

.promo-written {
    position: relative;
    margin-top: 75px;

    .written {
        img {
            position: absolute;
            left: 10px;
            top: -30px;
            transform: rotate(110deg);
            height: 30px;
        }

        span {
            position: absolute;
            left: 65px;
            top: -12px;
            font-size: 18px;
            transform: rotate(-11deg);
        }
    }
}

.written.favorite {
    font-size: @font-size-bigger;
    display: inline-block;
    position: relative;
    margin: 15px 0 25px 62%;

    &.v2 {
        margin-left: 52%;
    }

    &::before {
        position: absolute;
        left: -30px;
        top: -17px;
        width: 28px;
        display: block;
        height: 40px;
        content: ' ';
        background: url(../img/cms/hand_arrow_down.svg) no-repeat;
    }
}

.cta {
    .written {
        &.rotated {
            top: 44px;
        }
    }
}

@media (max-width: @break-md-value) {
    .written {
        &.priceboxes {
            left: auto;
            right: 85px;
            top: 145px;
        }
    }
}
