/* ==============================
=            FOOTER            =
============================== */

/* ==========  Extra footer - domaincheck+  ========== */
.extra-footer {
    padding: 20px 0 0;
    min-height: 75px;
    background-color: @color-fake-black;

    & > .grd {
        align-items: center;
    }

    .hosting-promo {
        float: left;
        margin-bottom: 20px;
        min-width: 280px;

        .btn-blue {
            padding: 11px 20px 9px;
            height: 40px;
            margin-right: 10px;
        }

        .grey {
            padding-left: 8px;
        }

        .info-button {
            white-space: nowrap;
        }
    }

    .inline-domain-check {
        float: right;
        margin-bottom: 20px;
        width: 100%;

        /* Flexbox semi-support fix */
        min-width: 280px;

        .input-button {
            float: right;
        }
    }
}

.on-dark {
    color: @color-lighter-grey;
}

/* ==========  Top footer  ========== */
.top-footer {
    padding-bottom: 30px;
    padding-top: 30px;
    background-color: @color-primary;
    color: #fff;

    .grdcell {
        padding-top: 0;
    }

    .col-title {
        margin: 25px 0 15px;
        min-width: 210px;
        height: 27px;
        text-transform: uppercase;
        &:extend(.rubik);

        & a {
            color: #fff;
            text-decoration: none;
        }

        &.logo img {
            max-width: 105px;
        }
    }

    .title {
        display: block;
        margin: 0;
        padding-top: 5px;
        font-size: @font-size-bigger;
    }

    .footer-list {
        a {
            &:extend(.rubik);
        }
    }
}

/* ==========  Bottom footer  ========== */
.bottom-footer {
    background-color: @color-primary-alt;
    font-size: 12px;
    color: @color-grey;

    .yrs {
        display: inline-block;
        position: absolute;
        top: -45px;
        left: 50%;
        margin-left: -62px;
        text-align: center;
    }

    .yrs-mascote {
        position: absolute;
        top: -77px;
        left: -65px;
    }

    .yrs-number,
    .yrs-text {
        position: absolute;
        display: block;
        width: 100%;
    }

    .yrs-number {
        font-size: 36px;
        top: 20px;
    }

    .yrs-text {
        top: 46px;
        font-size: 22px;
    }

    .written {
        color: #fff;
        font-size: 15px;
        line-height: 18px;
        margin-top: 10px;
        margin-left: 25px;
        position: relative;
        transform: rotate(-15deg);

        .written-row {
            padding-left: 40%;
        }
    }

    .payment-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 30px 0;

        .payment-icon {
            filter: invert(1);
            opacity: 0.5;
        }

        .payment-option {
            margin-right: 15px;
            margin-bottom: 15px;

            &:last-of-type {
                margin: 0;
            }

            & > div {
                margin-right: 10px;
            }
        }

        .upn {
            flex: 1 0 100%;
            margin-right: 5px;
            display: flex;
            align-items: center;

            .payment-text {
                margin-right: 10px;
            }

            .payment-icon {
                margin-left: auto;
                max-height: 35px;
            }
        }

        .moneta,
        .crypto {
            text-align: center;
        }

        .credit-cards {
            min-width: 130px;
        }

        .paysafe,
        .paypal {
            .payment-icon {
                margin-top: 8px;
            }
        }

        .moneta {
            .payment-icon {
                margin-top: 5px;
            }
        }
    }
    .footer-contact{
        .footer-title{
            margin-top: 30px;
            &.text-underline{
                a{
                    text-decoration: underline;
                }
            }
        }
        .sm-grd-1of2 .grdcell{
            min-width: 0;
        }
        a{
            color: @color-grey;
            text-decoration: none;
            display: inline-block;
            @media(min-width:@break-xs-value){
                margin-right: 20px;
            }
            @media(min-width:@break-sm-value){
                margin-right: 0px;
            }
            @media(min-width:@break-md-value){
                margin-right: 20px;
            }

        }
        span{
            text-decoration: underline;
            vertical-align: middle;
            display: inline-block;
            line-height: 30px;
            @media(min-width:@break-xs-value){
                line-height: 40px;
                font-size: 15px;
            }
            @media(min-width:@break-sm-value){
                line-height: 30px;
                font-size: 12px;
            }
            @media(min-width:@break-md-value){
                line-height: 40px;
                font-size: 15px;
            }
        }
        .icon{
            font-size: 30px;
            color:@color-grey;
            vertical-align: top;
            display: inline-block;
            @media(min-width:@break-xs-value){
                font-size: 40px;
            }
            @media(min-width:@break-sm-value){
                font-size: 30px;
            }
            @media(min-width:@break-md-value){
                font-size: 40px;
            }
        }
    }
}

.paypal {
    .payment-icon {
        margin-top: 5px;
    }
}

.crypto {
    .payment-icon {
        &:first-of-type {
            margin-right: 5px;
        }
    }
}




.simple-footer {
    margin-top: 70px;

    .written {
        font-size: 18px;
        left: 228px;
        top: 50px;
    }

    .years-praise {
        margin-top: 40px;
        padding: 0 0 35px;
    }
}

.mascote-years {
    position: absolute;
    top: -77px;
    left: -65px;
    height: 128px;
}

.footer-title {
    margin-top: 50px;
    //min-width: 280px;
    margin-bottom: 10px;
}

.footer-praise {
    margin: 0;
}

.social-contacts {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    position: relative;

    .icon {
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: @color-grey;
        font-size: 28px;
        margin: 0 0 20px;
        text-decoration: none;
        padding: 10px;
        border-right: 0;

        &:last-of-type {
            border-right: 1px solid rgba(255, 255, 255, 0.3);
        }
    }
}

.simple-footer {
    .social-contacts {
        float: right;
    }
}

/* ----------  Google analytics  ---------- */
footer ~ iframe {
    display: none;
}

iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}


/* -----  End of FOOTER  ------ */
