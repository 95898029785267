/*===================================
=            Popover-box            =
===================================*/

.qtip-content{
   position: relative;
   padding: 0;

   text-align: left;
   word-wrap: break-word;
}

.qtip {
   position: absolute;
   left: -28000px;
   top: -28000px;
   display: none;

   /*max-width: 280px;
   min-width: 50px;*/

   direction: ltr;

   box-shadow: none;
   padding: 0;


}
.qtip-pos- {
    /* bottom center */
    &bc {
        .popover-box:before {
            left: 50%;
            margin-left: -11px;
        }
        .popover-box:after {
            left: 50%;
            margin-left: -13px;
        }
    }
    /* left center */
    &lc {
        margin-left: 15px;
        .popover-box:before {
            bottom: -13px;
            left: 32px;
        }
        .popover-box:after {
            bottom: -12px;
            left: 30px;
        }
    }
    /* right center */
    &rc {
        margin-right: 15px;
        .popover-box:before {
            bottom: -13px;
            right: 28px;
            left: auto;
        }
        .popover-box:after {
            bottom: -12px;
            right: 30px;
            left: auto;
        }
    }
    /* top center */
    &tc {
        margin-top: 25px;
        .popover-box:before {
            display: none;

        }
        .popover-box:after {
            left: 50%;
            top: -11px;
            margin-left: -13px;
            bottom: auto;
            border: solid;
            border-width: 0 12px 12px;
            border-color: @color-fake-black transparent !important;
        }
    }
    /* top left */
    &tl {
        margin-top: 15px;
        .popover-box:before {
            top: -12px;
            bottom: auto;
            border: solid;
            border-width: 0 13px 13px;
            border-color: #000 transparent;

        }
        .popover-box:after {
            top: -11px;
            bottom: auto;
            border: solid;
            border-width: 0 12px 12px;
            border-color: #fff transparent;
        }
    }
    &bl {
        margin-left: -40px;
    }
}

.popover-box {
    max-width: 320px;
    width: 100%;

    &.small {
        max-width: 240px;
        .holder {
            padding: 15px 15px 15px 20px;
            &.img-small {
                padding: 15px 60px 10px 20px;
                .img-rght {
                    top: 20%;
                    left: 85%;
                }
            }
        }
    }
    &.wide {
        max-width: 360px;
        .holder {
            padding: 15px 15px 15px 20px;
            &.img-small {
                padding: 15px 60px 10px 20px;
                .img-rght {
                    top: 20%;
                    left: 85%;
                }
            }
        }
    }
    &.extra-wide {
        max-width: 400px;
        .holder {
            padding: 15px 15px 15px 20px;
            &.img-small {
                padding: 15px 60px 10px 20px;
                .img-rght {
                    top: 20%;
                    left: 85%;
                }
            }
        }
    }
    &:before {
        content: " ";
        position: absolute;
        bottom: -12px;
        left: 31px;
        border: solid;
        border-width: 12px 12px 0px 12px;
        border-color: #000 transparent;
    }
    &:after {
        content: " ";
        position: absolute;
        bottom: -11px;
        left: 30px;
        border: solid;
        border-width: 12px 12px 0px 12px;
        border-color: #fff transparent;
    }
    &.bg-primary {
        &:after {
            border-color: @color-primary transparent;
        }
    }
    &.bg-secondary {
        &:after {
            border-color: @color-secondary transparent;
        }
    }
    &.bg-secondary-alt {
        &:after {
            border-color: @color-secondary-alt transparent;
        }
        .icon-close {
            background-color: @color-fake-black;
        }
    }
    &.bg-lgrey {
        &:after {
            border-color: @color-light-grey transparent;
        }
        .icon-close {
            background-color: rgba(0,0,0,0.95);
            &:hover {
                color: #fff;
            }
        }
    }
    &.bg-grey {
        &:after {
            border-color: @color-grey transparent;
        }
        .icon-close {
            background-color: rgba(0,0,0,0.65);
        }
    }
    &.bg-dgrey {
        &:after {
            border-color: @color-darker-grey transparent;
        }
        .icon-close {
            background-color: rgba(0,0,0,0.65);
            &:hover {
                color: #fff;
            }
        }
    }
    &.bg-light-yellow {
        &:after {
            border-color: @color-light-yellow transparent;
        }
        .icon-close {
            background-color: rgba(0,0,0,0.65);
            &:hover {
                color: #fff;
            }
        }
    }
    &.arrow-off {
        &:after, &:before {
            display: none;
        }
    }
}

.popover {
    &:not(i) {
        border-bottom: 1px dotted @color-fake-black;
        color: inherit;
        //border-image:url('../img/cms/dot-border.png') 5 repeat;
    }
    cursor: pointer;
    text-decoration: none;
}
.popover-holder {
    position: relative;
    display: inline-block;
}
.close-window {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 9px;
    height: 30px;
    width: 30px;
    background-color: rgba(0,0,0,0.5);
    color: white;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        background-color: rgba(0,0,0,1);
    //  color: @color-fake-black;
    }
}
.qtip {
    position: absolute;
    //width: 360px;
    z-index: 10;
    background-color: #fff;
    text-align: left;
    .box-shadow(~"2px 2px 0 #000, 0 0 3px 0px rgba(0,0,0,0.3)");

    .holder {
        position: relative;
        padding: 15px 20px;
        overflow: hidden;
        display: block;
        &.img-hld {
            padding: 15px 35% 18px 20px;
        }
        p:last-of-type {
            margin-bottom: 0;
        }
    }
    h5, h6 {
        margin-top: 0;
    }
    .cnt {
        p {
            font-weight: normal;
        }
        p:first-of-type {
            margin-top: 0;
        }
    }
    .cnt + a {
        margin-top: 20px;
    }
    .img-rght {
        position: absolute;
        left: 65%;
        top: 25%;
    }
    .icon-close {
        &:extend(.close-window all);
        z-index: 1;
    }

    & > .icon-close {
        padding: 6px 7px;
    }
    // Poravnava desno zgoraj
    &.top-right {
        top: inherit;
        bottom: 100%;
        left: 100%;
    }
}
.popover-box, .qtip-content {

    .popover-header {
        padding: 10px 45px 10px 20px;
        min-height:  40px;
        background: @color-fake-black;
        color: #fff;
        h6 {
            margin: 0;
        }
        .icon-close {
            padding-left: 10px;
            width: 40px;
            height: 40px;
            font-size: 20px;
            line-height: 25px;
        }
    }
}
.popover-box.tooltip, .tooltip{
    width: 300px;
    .holder {
        padding: 20px 30px 22px 20px;
    }
}
.popover-box.small .qtip div.small {
    width: 250px;
    &:before {
        display: none;
    }
    &:after {
        display: none;
    }
    .holder {
        padding: 20px 20px 15px;
    }
}

.popover-box {
    .right-image {
        max-width: 110px;
        float: right;
        padding: 10px 5px 10px 15px;

        &.small {
            padding: 5px 15px 20px 25px;
        }
    }
}

.main-domain-input {
    .popover-box {
        z-index: 100;
        position: absolute;
        .holder {
            padding: 5px 15px 15px 20px;
        }
    }
    .qtip-pos-tc {
        margin-top: 15px;
    }
    form {
        margin-bottom: 5px;
    }
}

.proteus {
    .cnt {
        display: flex;
    }

    .logo {
        min-width: 60px;
        min-height: 60px;
        margin-left: 20px;
    }

    &:after {
        border-color: @color-lightest-grey transparent;
    }

}



/*=====  End of Popover-box  ======*/

