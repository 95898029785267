/*=================================
=            Wordpress            =
=================================*/

.kv-blog {
    background:url(../img/cms/andy/andy-scientist.png) no-repeat 94% 100%;
    background-size: 200px;
    .title-description {
        max-width: 700px;
        margin: 20px auto 50px;
    }
}

@media(max-width: 1100px) {
    .kv-blog {
        background: none;
    }
}

.kv-support-andy {
    background:url(../img/cms/andy/andy-support-headphones.png) no-repeat 94% 100%;
    background-size: 150px;
}

@media(max-width: 650px) {
    .kv-support-andy {
        background-size: 100px;
    }
}

.wordpress {
   .front-page > .grd > .grdcell {
        min-width: 230px;
    }
}

.wp-categories {
    .btn-white {
        &.active {
            background-color: @color-primary;
        }
    }
}

.category-box {
    min-width: 265px;

    .brd-bx {
        .bx-ttl {
            .clearfix();

            background-color: @color-light-grey;
            padding: 5px 5px 4px;

            h4 {
                padding: 12px 20px 6px;

                &.iconized {
                    position: relative;
                    padding-left: 60px;
                }

                .icon {
                    font-size: 42px;
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    color: @color-primary;
                }
            }
        }
    }

    .tabs-nav {
        .clearfix();

        text-align: center;

        li {
            width: 50%;
            float: left;
            border: 1px solid @color-grey;
            border-width: 0 0 1px;

            &.active {
                background-color: #fff;
                border-bottom-color: #fff;

                &:nth-of-type(2) {
                    border-width: 1px 0 1px 1px;
                }

                &:nth-of-type(1) {
                    border-width: 1px 1px 1px 0;
                }
            }
        }

        a {
            padding: 10px 0;
            display: inline-block;
            width: 100%;
            color: @color-fake-black;
        }
    }

    .guides-list {
        margin-top: 15px;
    }

    .tabs-content {
        min-height: 270px;

        & > div {
            display: none;
        }
    }
}

/* Seznam člankov - tabela */
.posts-list {
    .title,
    .date,
    .comments {
        position: relative;
    }

    .date,
    .comments {
        text-align: right;
        color: @color-darker-grey;
        font-size: @font-size-smaller;
    }

    .tb {
        .title {
            min-width: 265px;
            flex-grow: 6;
        }

        .date {
            justify-content: flex-end;
            min-width: 120px;
        }

        /*.comments {
            .icon-comment {
                font-size: 26px;
                position: relative;
                top: -3px;
                left: -4px;
            }

            span {
                display: inline-block;
                position: relative;
                top: -6px;
            }
        }*/

        .trow {
            .title {
                padding-left: 20px;
            }

            .date,
            .comments {
                padding-right: 20px;
            }
        }
    }

    .thead {
        min-height: 40px;
        background-color: @color-grey;
        font-size: @font-size-smaller;

        .date,
        .comments {
            color: @color-fake-black;
        }
    }

    .tbody {
        .trow {
            & > .td {
                padding: 12px 15px;
            }
        }

        .title {
            font-size: @font-size-bigger;
            padding: 13px 20px 12px;
        }
    }

    .img-holder {
        position: absolute;
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-right: 15px;
        border: 1px solid @color-primary;
    }
}

.posts-pagination {
    padding: 10px;
    background-color: @color-light-grey;
    border: 1px solid @color-grey;
    text-align: center;

    .pagination a {
        border: 0;
        margin: 0 2px;
    }
}

.social-actions {
    padding: 20px;
    margin-bottom: 40px;
    background-color: #ececec;
    border: 2px solid @color-fake-black;
    border-width: 2px 0;
    .triple-gradient(rgba(255, 255, 255, 1) 10%, rgb(235, 235, 235) 50%, rgba(255, 255, 255, 1) 90%);

    .title {
        display: block;
        position: relative;
        top: -40px;
        padding: 8px;
        width: 200px;
        margin: 0 auto -20px;
        background-color: @color-fake-black;
        text-align: center;
        color: #fff;
    }

    .share {
        margin-top: 10px;
    }

    #fb-root {
        margin-top: 5px;
    }
}

.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget span iframe[style],
.fb_iframe_widget_fluid span {
    width: 100% !important;
}

.soc-square {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;

    & i {
        color: #fff;
        font-size: 24px;
        display: inline-block;
        position: relative;
        top: 3px;
    }
}

.soc-fabo {
    background-color: #4e69a2;
}

.soc-twt {
    background-color: #2ca7e0;
}

.soc-gp {
    background-color: #e02f2f;
}

.soc-in {
    background-color: #0083be;
}

.action-promote {
    position: relative;
    top: -7px;
    text-align: right;
}

.posts-box {
    .bx-ttl {
        padding-bottom: 12px;

        h3 .rubik,
        h4 .rubik {
            font-size: 1em;
        }
    }

    .bx-cntnt {
        padding: 20px 20px 0;
    }

    li {
        position: relative;
        float: left;
        width: 50%;
        margin-bottom: 20px;
    }

    a {
        margin-top: 0;
    }

    .title {
        display: inline-block;
        padding-top: 5px;
        text-decoration: underline;
    }
}

.comments {
    .bx-cntnt {
        padding: 20px 20px 0;
    }

    .comment {
        .clearfix();

        position: relative;
        min-height: 60px;
        margin-bottom: 20px;
        padding: 0;
    }

    .comment-meta {
        font-size: @font-size-smaller;

        .c-grey {
            margin-top: 5px;
        }

        .author {
            margin-right: 5px;
        }

        .date {
            display: inline-block;
            margin: 0 5px;
        }

        .reply a {
            display: inline-block;
            margin-left: 5px;
        }
    }

    .comment-content {
        padding-left: 73px;
    }

    .comment-text {
        & > p:first-of-type {
            margin-top: 0;
        }
    }

    .author {
        color: @color-fake-black;
    }

    .bx-ttl {
        .icon {
            color: @color-primary;
            position: relative;
            top: 2px;
            margin-right: 10px;
            font-size: 28px;
        }
    }

    .img-holder {
        position: absolute;
    }

    .profile-pic {
        width: 60px;
        height: 60px;
    }

    .std-list {
        .std-list {
            margin: 30px 0 0 75px;

            .comment:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .inlined-form {
        padding-left: 45px;
        padding-bottom: 5px;

        .input-group > label {
            padding-top: 5px;
            max-width: 200px;
            width: 100%;
        }

        textarea {
            margin-bottom: 5px;
            max-width: 580px;
            min-height: 100px;
            width: 100%;
        }

        .btn-green {
            margin-top: 5px;
        }
    }

    .textarea {
        label {
            vertical-align: top;
        }
    }

    .replying-to {
        margin-left: 45px;

        .comment {
            padding: 10px;
            margin-bottom: 30px;
            margin-top: 5px;
            min-height: 80px;
            background-color: #efefef;
        }

        .reply {
            display: none;
        }

        .icon-close {
            position: absolute;
            right: 20px;
            top: 22px;
            font-size: 36px;
            font-weight: bold;
            cursor: pointer;
            color: @color-darker-grey;

            &:hover {
                color: @color-dark-grey;
            }
        }
    }

    .notice {
        flex: 100%;
    }
}

/*================================================
=            Wordpress Default styles            =
================================================*/
@color-wordpress-text: #38393a;

.post-content {
    .clearfix();

    color: @color-wordpress-text;
    margin: 30px 80px 40px 80px;

    .size-large,
    .size-full {
        .box-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
    }

    p {
        font-size: 1.15rem;
        line-height: 1.62em;
    }

    ul,
    ol {
        //font-size: @font-size-normal; 14px
        font-size: 1.15rem;
        line-height: 1.62em;
        //line-height: 1.45em; 20px
        text-align: justify;
    }

    img {
        max-width: 80%;
        max-height: 100%;
        width: auto;
        height: auto;
        border: 1px solid #dbe3e3;
        margin: 20px auto;
        padding: 5px;

        &.size-full:not(.aligncenter):not(.alignleft):not(.alignright) {
            max-width: 100%;
            margin: 20px 0;
            width: 100%;
        }
    }

    .image-holder {
        /*display: block;
        padding: 10px;
        background-color: @color-wordpress-text;*/

        img {
            image-rendering: auto;
        }
    }

    .alignnone {
        margin: 5px 20px 20px 0;
    }

    .aligncenter,
    div.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .alignright {
        float: right;
        margin: 20px 0 20px 20px;
    }

    .alignleft {
        float: left;
        margin: 20px 20px 20px 0;
    }

    a img.alignright {
        float: right;
        margin: 20px 0 20px 20px;
    }

    a img.alignnone {
        margin: 20px 20px 20px 0;
    }

    a img.alignleft {
        float: left;
        margin: 20px 20px 20px 0;
    }

    a img.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .wp-caption {
        background: #fff;
        border: 1px solid #f0f0f0;
        max-width: 96%;

        /* Image does not overflow the content area */
        padding: 5px 3px 10px;
        text-align: center;
    }

    .wp-caption.alignnone {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignleft {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignright {
        margin: 5px 0 20px 20px;
    }

    .wp-caption img {
        border: 0 none;
        height: auto;
        margin: 0;
        max-width: 98.5%;
        padding: 0;
        width: auto;
    }

    .wp-caption p.wp-caption-text {
        font-size: 11px;
        line-height: 17px;
        margin: 0;
        padding: 0 4px 5px;
    }

    blockquote {
        color: lighten(@color-wordpress-text, 5%);

        /*background: #f9f9f9;
        border-left: 8px solid #ddd;
        margin: 1.5em 10px;
        padding: 20px 15px 20px 20px;
        quotes: "\201C""\201D""\2018""\2019";
        color: lighten(@color-wordpress-text, 5%);

        &:before, &:after {
            display: none;
            /*color: #ccc;
            content: open-quote;
            font-size: 4em;
            line-height: 0.1em;
            margin-right: 0.25em;
            vertical-align: -0.4em;
        }*/
        &::before,
        &::after {
            color: @color-primary;
        }

        p {
            display: inline-block;
        }
    }

    /* Headings */
    p {
        margin-bottom: 1.3em;

        & + ul,
        & + ol,
        & + dl {
            margin-top: -0.6em;
        }
    }

    ol,
    ul,
    dl {
        margin-bottom: 1.4em;
    }

    h1,
    h2,
    h3,
    h4 {
        margin: 1.78em 0 0.72em;
        font-weight: bold;
        line-height: 1.2;
        text-transform: uppercase;
    }

    h1 {
        margin-top: 0;
        font-size: 2.14em;
    }

    h2 {
        font-size: 1.92em;
    }

    h3 {
        font-size: 1.58em;
    }

    h4 {
        font-size: 1.23em;
    }

    small,
    .font_small {
        font-size: 0.75em;
    }


}

/*=====  End of Wordpress Default styles  ======*/

/*===========================================
=            Wordpress - NS BLOG            =
===========================================*/

.wp-blog {
    .bx-ttl {
        text-transform: none !important;

        a {
            text-decoration: none;
            &:visited {
            }
        }
        h3 {
            font-size:@font-size-big;
            font-weight: 500;
        }
    }

    & > .grd {
        flex-direction: row;
    }

    .grd.main-holder {
        flex-direction: row-reverse;
    }

    .comments-count {
        padding: 6px 0 0 10px;
        margin-left: auto;

        .icon-comment {
            position: relative;
            top: 3px;
            font-size: 24px;
        }
    }


    .meta {
        display: inline-flex;
        width: 820px;

        a {
            text-decoration: none;
        }

        .author {
            display: inline-flex;
            margin-right:auto;
        }
        .date {
            margin-left: auto;
        }

        .author-img {
            margin-top: -30px;
            max-width: 80px;
            min-width: 80px;

            .res-img {
                border: 10px solid #fff;
                width: 80px;
                height: 80px;
                .border-radius(80px);
            }
        }
    }

    article .meta {
        width: auto;
        .date {
            margin-left: auto;
        }
    }

    .post-page .post-header {

        text-align: center;

        img.post-kv {
            max-width: 860px;
            margin: 20px auto 6px auto;
            border-radius: @box-border-radius;

        }
    }
}

/* Notice boxes */
.post-content .notice,
.notice-box.notice {
    position: relative;
    padding: 20px 30px 20px 55px;
    margin: 10px 0 20px;
    border: 1px solid transparent;
    border-radius: 1px;

    p {
        margin: 11px 0;

        &:last-of-type {
            margin: 0;
        }

        &:first-of-type {
            margin: 0;
        }
    }

    & > h4,
    & > h3,
    & > h5 {
        margin: 0 0 8px;
    }

    &,
    &-andy {
        ol,
        ul {
            color: @color-fake-black;
            margin: 0;
        }
    }

    &::before {
        .iconFamily();

        position: absolute;
        font-size: 32px;
        top: 16px;
        left: 15px;
    }

    & {
        &-info {
            color: @color-primary;
            background-color: lighten(@color-primary, 40%);

            &::before,
            &-grey::before {
                content: "\e628";
            }

            &-grey {
                color: @color-wordpress-text;
                background-color: lighten(@color-wordpress-text, 45%);
            }
        }

        &-success {
            color: @color-secondary;
            background-color: lighten(@color-secondary, 60%);

            &::before {
                content: "\e614";
            }
        }

        &-warning {
            color: @color-secondary-alt;
            background-color: lighten(@color-secondary-alt, 40%);

            &::before {
                content: "\e61b";
            }
        }

        &-error {
            color: @color-red;
            background-color: lighten(@color-red, 40%);

            &::before {
                content: "\e60a";
            }
        }

        /* Andy notices */
        &-andy {
            padding: 16px 30px 16px 115px;
            position: relative;
            margin: 10px 0 20px;
            border: 1px solid transparent;
            border-radius: 1px;
            min-height: 100px;

            p {
                margin: 11px 0;
                color: @color-fake-black;

                &:last-of-type {
                    margin: 0;
                }

                &:first-of-type {
                    margin: 0;
                }
            }

            &::before {
                position: absolute;
                top: 15px;
                left: 30px;
                height: 97px;
                width: 70px;
            }

            /*& > p:before {
                content: 'UPORABNE INFORMACIJE';
                position: relative;
                font-weight: bold;
                display: block;
                margin-bottom: 3px;
            }*/
            & > h4,
            & > h3,
            & > h5 {
                margin: 0 0 8px;
            }

            &.notice-info {
                color: @color-primary;
                background-color: lighten(@color-primary, 40%);
                border-color: lighten(@color-primary, 20%);
                min-height: 122px;

                &::before {
                    top: 12px;
                    left: 20px;
                    content: "";
                    background: url(../img/cms/wordpress/andy_notice.png) no-repeat 0 0;
                }
            }

            &.notice-success {
                color: @color-secondary;
                background-color: lighten(@color-secondary, 60%);
                border-color: lighten(@color-secondary, 20%);

                &::before {
                    left: 20px;
                    content: "";
                    background: url(../img/cms/wordpress/andy_notice.png) no-repeat 0 -96px;
                }
            }

            &.notice-warning {
                color: @color-secondary-alt;
                background-color: lighten(@color-secondary-alt, 40%);
                border-color: lighten(@color-secondary-alt, 20%);
                top: -11px;

                &::before {
                    content: "";
                    background: url(../img/cms/wordpress/andy_notice.png) no-repeat -68px -25px;
                    height: 73px;
                }
            }

            &.notice-error {
                color: @color-red;
                background-color: lighten(@color-red, 40%);
                border-color: lighten(@color-red, 20%);

                &::before {
                    content: "";
                    background: url(../img/cms/wordpress/andy_notice.png) no-repeat -71px -96px;
                }
            }

            &.alignright,
            &.align-right {
                width: 50%;
                margin-left: 30px;
                margin-top: 0;
            }

            &.alignleft,
            &.align-left {
                width: 50%;
                margin-right: 30px;
                margin-top: 0;
            }
        }
    }
}


.sidebar {
    .transition(all 300ms);

    padding-top: 25px;
    min-width: 280px;
}

.blog-box {
    & > .title {
        margin-bottom: 0;
        background-color: #999;
        padding: 11px 10px 10px 20px;
        color: #fff;
    }

    .side-menu {
        & > .side-item {
            &.primary {
                border-color: @color-grey;
                border-width: 1px 0 0;

                &:first-of-type {
                    border-width: 0;
                }

                a {
                    padding-left: 20px;
                    font-size: @font-size-normal;
                    font-weight: normal;
                    background-color: @color-light-grey;
                }
            }

            &.active {
                border-top-color: @color-primary;

                & > a {
                    color: #fff;
                    background-color: @color-primary;
                    font-weight: bold;
                }

                & + .primary {
                    border-color: @color-primary;
                }
            }

            &.very-active {
                & > a {
                    &::after {
                        top: 13px;
                    }
                }
            }
        }
    }
}

.articles-list {
    .grdcell {
        min-width: 320px;
    }
}

.tag-cloud {
    background-color: @color-lighter-grey;
    padding: 15px;

    .d-ib {
        line-height: 12px; //0.8em;
        word-break: break-word;
    }
}

/*=====  End of Wordpress - NS BLOG  ======*/

/*=============================================
=            Email guides - widget            =
=============================================*/
.email-settings {
    .clearfix();

    border: 2px solid @color-primary;
    background-color: @color-light-grey;

    & > .title,
    .inline-list {
        display: inline-block;
    }

    & > .title {
        float: left;
        margin: 18px 30px 15px 15px;
    }

    .inline-list {
        float: right;
        width: auto;

        .hardware-pick {
            background-color: #fff;
            margin-right: -2px; // display: inline-block;
            position: relative;
            color: @color-fake-black;
            display: inline-block;
            font-size: @font-size-bigger;
            height: 54px;
            padding: 18px 18px 16px 15px;
            text-decoration: none;

            &:last-of-type {
                margin: 0;
            }
        }

        i {
            font-size: 36px;
            left: 0;
            position: relative;
            top: -7px;
        }

        &.selected {
            & + .guides-selection {
                display: block;
            }
        }
    }

    .hardware-pick {
        &.active {
            background-color: @color-primary;
            color: #fff;
            font-weight: bold;
        }

        & > span {
            line-height: 2em;
            position: relative;
            top: -12px;
        }

        .icon-pc {
            margin-right: 7px;
        }

        .icon-tablet {
            margin-right: 5px;
        }

        .icon-phone {
            margin-right: -4px;
        }
    }
}

.guides-selection {
    background-color: @color-primary;
    display: none;
    float: left;
    padding: 10px;
    width: 100%;

    .std-list {
        float: left;
        vertical-align: middle;

        & > li {
            margin: 5px 15px 0 0;

            &.label {
                color: #fff;
                padding: 10px 0 0 10px;
                vertical-align: top;
            }
        }
    }

    .selectric {
        background-color: @color-light-grey;
    }

    .selectric,
    .selectric-hover .selectric {
        border-color: transparent;
    }

    .selectric-open .selectric {
        border-color: @color-fake-black;
    }
}

.guides-results {
    background-color: #fff;
    float: left;
    width: 100%;

    .item {
        float: left;
        padding-left: 0;
        position: relative;
        width: 50%;
        min-width: auto;
    }

    h6 {
        margin-top: 0;
    }

    .item-content,
    .item-number {
        display: inline-block;
    }

    .item-content {
        width: 85%;

        .title {
            display: inline-block;
            max-height: 50px;
            //overflow: hidden;
            padding-top: 5px;
            text-decoration: underline;
        }
    }

    .item-number {
        font-size: 26px;
        margin: 20px 5px 0 0;
        text-align: center;
        vertical-align: top;
        width: 8%;
    }

    &.with-results {
        padding: 15px;
    }
}

.image-gallery {
    .grdcell {
        min-width: 153px; // 203?
    }
}

.post-content .image-gallery {
    .grdcell {
        flex-grow: 0.1;
        min-width: 170px;
    }

    img {
        max-width: 100%;
        padding: 0;
    }
}
/*=====  End of Email guides - widget  ======*/

/*=====  End of Wordpress  ======*/

@media @break-sm {
    .action-promote {
        margin-bottom: 0;
        text-align: left;
    }

    .comments {
        .inlined-form {
            padding: 0;
        }
    }

    .post-content {
        .notice-andy {
            &.notice-info,
            &.notice-success,
            &.notice-warning,
            &.notice-error {
                padding-left: 20px;
                padding-top: 100px;

                &::before {
                    margin-left: 50%;
                    left: -36px;
                }
            }

            &.notice-info {
                padding-top: 130px;
            }
        }
    }
}

#tabsBlog {
    .tab {
        display: inline-block;
        font-size: 1.15rem;
        padding: 10px 16px;
        cursor: pointer;
        position: relative;
        z-index: 5;
        background-color: #cbcbcb;
        margin-right: 0px;
        left:-4px;
        color:#656565;
        border-bottom:1px solid #b8b8b8;
        &#frst{
            left:0px;
        }
    }
    .whiteborder {
        background-color: #f5f2f0;
        color:#38393a;
        border-bottom: none;
    }

    .tabContent {
        position: relative;
        top: -1px;
        z-index:1;
        .language-bash{
            margin: 0;
        }
    }

    .hide {
        display: none;
    }

    .show {
        display: block;
    }
}

@media(max-width: 900px) {
    .wp-blog {
        .post-page {
            .post-header {
                img.post-kv {
                    max-width:none;
                    width: 100%;
                }
            }
        }
        .meta {
            display:block;
            width: auto;
        }
    }

    .post-content {
        margin: 20px;
    }
}
