
.alert {
    padding: 20px 30px;
    display: flex;

    .text {
        margin-right: 15px;
    }

    &.small {
        padding: 15px 20px;
    }
}

@media @break-sm {
    .alert {
        flex-wrap: wrap;

        .text {
            margin-right: 0;
        }

        .action {
            margin-top: 10px;
        }
    }
}
