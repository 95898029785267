.content {

    .andy-teacher {
        background:@color-light-grey url(../img/cms/andy/andy-teacher.png) no-repeat 50% 100%;
        background-size: 200px;
        height: 140px;
        color:@color-white;
        font-size: 20px;
        position: relative;
        font-weight:900;
        &:extend(.written);

        &.reverse {
            background:@color-light-grey url(../img/cms/andy/andy-teacher-reverse.png) no-repeat 50% 100%;
            background-size: 200px;
            span {
                left: 37.5%;
                text-align:left;
            }
        }

        span {
            text-align: right;
            position:absolute;
            top: 34px;
            left: 47%;
            width: 96px;
        }
        &.domena {
            span {
                top: 40px;
            }
        }
        &.izbira-domene {
            span {
                font-size:@font-size-normal;
                line-height:1.2em;
            }
        }
        &.izbira-koncnice {
            span {
                font-size:0.95rem;
                line-height:1.2em;
            }
        }
        &.izbira-imena {
            span {
                font-size:1rem;
                line-height:1.2em;
            }
        }
    }

    .hosting-types {
        .shared, .reseller, .turbo {
            .bx-img {
                height: 200px;
            }
        }
        h4 a {
            text-decoration:none;
            color: @color-white;
        }
        .price {
            &:extend(.rubik400);
            position: absolute;
            bottom:35px;
            left: 35px;
            background-color: transparent;
            text-align: left;
            display:none;
            span{
                font-weight: bold;
                font-size: 30px;
                line-height: 30px;

            }

        }
        .shared {
            .bx-img {
                background: url(../img/cms/andy/andy-shared.svg) no-repeat 50% 100%;
                background-size: 190px;

            }
        }
        .reseller {
            .bx-img {
                background: url(../img/cms/andy/andy-reseller.svg) no-repeat 50% 100%;
                background-size: 260px;

            }
        }
        .turbo {
            .bx-img {
                background: url(../img/cms/andy/andy-turbo.svg) no-repeat 50% 100%;
                background-size: 230px;

            }
        }
    }

    .bg-turbo-advantage {
        background:@color-light-grey url(../img/cms/andy/prednosti-turbo-gostovanja.png) no-repeat 50% 100%;
        background-size: 320px;
        height: 160px;
    }

    .bg-turbo-vs-vps {
        background:@color-light-grey url(../img/cms/andy/turbo-vps-gostovanje.png) no-repeat 50% 100%;
        background-size: 320px;
        height: 160px;
    }

    .bg-domain-renew-forget {
        background:@color-light-grey url(../img/cms/andy/pozor-na-podaljsanje-domene.png) no-repeat 50% 100%;
        background-size: 220px;
        height: 160px;
    }

    .bg-domain-renew {
        background:@color-light-grey url(../img/cms/andy/podaljsanje-domene-pri-neoserv.png) no-repeat 50% 100%;
        background-size: 260px;
        height: 160px;
    }

    .bg-domain-why-transfer {
        background:@color-light-grey url(../img/cms/andy/prenos-domene-k-neoserv.png) no-repeat 50% 100%;
        background-size: 260px;
        height: 160px;
    }

    .bg-domain-how-transfer {
        background:@color-light-grey url(../img/cms/andy/potek-prenosa-domena.png) no-repeat 50% 100%;
        background-size: 260px;
        height: 160px;
    }

    .bg-domain-oportunities {
        background:@color-light-grey url(../img/cms/andy/priloznosti-prostih-domen.png) no-repeat 50% 100%;
        background-size: 250px;
        height: 160px;
    }

    .bg-domain-search {
        background:@color-light-grey url(../img/cms/andy/iskanje-proste-domene.png) no-repeat 50% 100%;
        background-size: 320px;
        height: 160px;
    }


    .bg-managed-vps {
        background:@color-light-grey url(../img/cms/andy/vzdrzevano-vps-gostovanje.png) no-repeat 50% 100%;
        background-size: 280px;
        height: 160px;
    }

    .bg-unmanaged-vps {
        background:@color-light-grey url(../img/cms/andy/nevzdrzevano-vps-gostovanje.png) no-repeat 50% 100%;
        background-size: 280px;
        height: 160px;
    }

}
