
/* ==========  Hosting packages  ========== */

.hosting-packages {
    .clearfix();

    border-top: 1px solid @color-lighter-grey;
}

.reseller-upsell {
    margin: 50px 0;

    .f-bigger {
        text-align: center;
    }
}

.package-list {
    &:not(.full) {
        .upsell.written {
            position: relative;
            top: 20px;
            left: -10px;
            font-size: 16px;

            span {
                top: 10px;
                left: 50px;
                font-size: 20px;
            }
        }
    }

    &.full {
        .upsell.written {
            top: 40px;
            left: -40px;
        }

        & > .grdcell {
            min-width: 180px;
        }
    }
}

.package-list {
    align-items: center;
    position: relative;
    margin-bottom: 30px;

    & > .grdcell {
        min-width: 170px;
    }

    .icon-check-black {
        vertical-align: sub;
        line-height: 0;
        font-weight: bold;
        font-size: 26px;
        color: @color-secondary;
    }

    .hdd-space {
        font-weight: bold;
        padding-top:18px;
        padding-bottom:18px;
    }

    & > .pkg {
        //width: 21%;
        //display: inline-block;

        &.col-desc {
            //width: 16%;
        }

        .package-title::after {
            position: absolute;
            top: 100%;
            left: 0;
            height: 60px;
            width: 100%;
            content: ' ';
            background-size: 100% auto;
        }
    }

    .col-1 {
        .package-title {
            color: @color-fake-black !important;
            background-color: #fff;

            .box-shadow(~'0 -1px 0 0  #fff inset, 0 0 0 1px #ebebeb inset');

            &::after {
                background: url(../img/cms/ribbon_white.svg) 0 0 no-repeat;
                background-size: 100%;
            }
        }

        .feat-list {
            // background-color: #ebebeb;
        }
    }

    .col-2 {
        .package-title {
            background-color: @color-secondary-alt;

            &::after {
                background: url(../img/cms/ribbon_orange.svg) 0 0 no-repeat;
                background-size: 100%;
            }
        }

        .price-box,
        .order-box {
            border-top: 1px solid @color-secondary-alt;
        }
    }

    .col-3 {
        .package-title {
            background-color: @color-secondary;

            &::after {
                background: url(../img/cms/ribbon_green.svg) 0 0 no-repeat;
                background-size: 100%;
            }
        }

        .price-box,
        .order-box {
            border-top: 1px solid @color-secondary;
        }
    }

    .col-4 {
        // float:right;

        .package-title {
            background-color: @color-primary;

            &::after {
                background: url(../img/cms/ribbon_blue.svg) 0 0 no-repeat;
                background-size: 100%;
            }
        }

        .price-box,
        .order-box {
            border-top: 1px solid @color-primary;
        }
    }

    .col-5 {
        float: right;

        .package-title {
            background-color: @color-red-package;

            &::after {
                background: url(../img/cms/ribbon_red.svg) 0 0 no-repeat;
                background-size: 100%;
            }
        }

        .price-box,
        .order-box {
            border-top: 1px solid @color-red-package;
        }
    }


    .col-6 {
        float: right;

        .package-title {
            background-color: @color-fake-black;

            &::after {
                background: url(../img/cms/ribbon_black.svg) 0 0 no-repeat;
                background-size: 100%;
            }
        }

        .price-box,
        .order-box {
            border-top: 1px solid @color-fake-black;
        }
    }

    .price-box {
        height: auto;
        border-top: 1px solid @color-lighter-grey;
    }

    select {
        margin-top: 8px;
        width: 90%;
    }

    // Hosting landing page

    &.cart {
        margin: 0 20px 40px;
        width: 733px;

        .feat-list {
            .icon-info-circle-alt,
            .icon-info-circle-alt2 {
                position: relative;
                top: 3px;
                color: @color-darker-grey;
            }
        }

        .col-1 {
            .feat-list {
                background-color: #fff;
            }
        }

        .price-box {
            padding-top: 7px;
            padding-bottom: 0;
            min-height: 50px;

            .old-price {
                font-size: @font-size-normal;
            }

            .new-price {
                margin-top: 5px;
                display: block;
                font-size: @font-size-big;
            }
        }

        .order-box {
            // padding: 15px 5px 15px;
            padding: 20px 8px;
            border-top: 0;

            a.btn-wide {
                max-width: 100%;
                padding: 10px;
            }
        }

        & > .pkg {
            width: 15.3%;

            &.col-desc {
                width: 23.5%;
            }

            .price-box,
            .order-box {
                border-top-color: @color-lighter-grey;
            }
        }

        .package-title {
            min-height: 60px;
            padding: 4px 20px 1px;

            h5 {
                margin: 0;
                padding: 8px 5px 0;
                line-height: 22px;
            }

            span {
                font-size: 1em;
            }
        }

        .hdd-space {
            span {
                font-weight: normal;
                font-size: 12px;
            }
        }

        .brd-bt {
            & ~ .gift {
                padding-bottom: 15px;
            }

            & + .gift {
                padding-top: 15px;
            }
        }

        .discount-info {
            .description {
                margin-top: 0;
                color: @color-darker-grey;
            }
        }

        .btn-narrow {
            max-width: 95px;
        }

        .popular {
            & + .pkg {
                margin-left: 15.3%;
            }
        }
    }

    &.cols-4 {
        & > .pkg {
            width: 19%;

            &.col-desc {
                width: 24%;
            }
        }

        .popular {
            & + .pkg {
                margin-left: 19%;
            }
        }

        .order-box {
            padding: 20px 15px;
        }
    }

    // Package comaprison
    &.full {
        &.comparison {
            & > .pkg {
                //width: 16%;

                &.col-desc {
                    //width: 20%;
                }
            }

            .popular + .pkg {
                //margin-left: 16%;
            }
        }

        & > .pkg {
            //width: 20%;

            &.col-desc {
                .order-box {
                    border-bottom: 0;
                }
            }
        }

        .col-desc {
            //width: 20%;
        }
    }

    // Single package
    &.single {
        & > .pkg {
            //width: 50%;
        }
    }

    .before-price {
        padding-bottom: 12px;
    }
}

.old-price {
    display: inline-block;
    position: relative;
    font-weight: normal;

    &::after {
        .box-shadow(1px 0 0 1px @color-fake-black inset);

        content: ' ';
        position: absolute;
        right: -1%;
        top: 1px;
        width: 110%;
        height: 1px;
        transform: translateY(9px) translateX(4%) rotate(-15deg);
    }

    &.empty {
        &::after {
            display: none;
        }
    }
}

.old-price-webdesign {
    font-size: 18px;
   /* margin-right: 10px;*/

    &::after {
        top: 5px;
    }
}

/* Rewrite - nove stvari (2017) */
.comparison-filters {
    margin-bottom: 20px;

    .checkbox {
        margin: 0 15px 10px 0;
    }
}

.package-list {
    .title {
        background-color: @color-light-grey;
        border: 0;
        cursor: pointer;
        padding: 8px 12px 7px;

        & strong::after {
            .iconFamily();

            content: '\e60c';
            margin-left: 5px;
        }

        // Odpade, ker en element vpliva na vse - .title.active je samo 1 izmed 5ih elementov

        /*&.active {
            strong::after {
                content: '\e60c';
            }
        }*/
    }

    .collapsable {
        padding: 0;
        border-top: 1px solid #bfbfbf;

        &.active {
            .title strong {
                &::after {
                    content: '\e60f';
                }
            }
        }

        & > .pkg-spec {
            position: relative;
            min-height: 28px;

            &:last-of-type {
                padding-bottom: 15px;
            }
        }
    }

    .order-box {
        padding: 20px 5px;
        border-bottom: 1px solid @color-lighter-grey;
        border-top: 1px solid @color-lighter-grey;
        min-height: 82px;

        &:first-of-type a {
            display: block;
            margin-top: 10px;
            padding-left: 0;
        }

        form {
            padding: 0;
        }
    }
}

.package-list {

    .pkg {
        max-width: 555px;
        transition: all 200ms ease-in-out;

        &:not(.popular):nth-of-type(2) {
            .pkg-spec {
                border-right: 1px solid @color-lighter-grey;
            }
        }

        .package-title {
            color: #fff;
            text-align: center;
            min-height: 73px;
            min-width: 230px;
            padding: 22px 0 5px;
            position: relative;

            h3 {
                font-size: 2.8em;
                line-height: 24px;
                margin: 0;
                padding: 8px 5px 0;
            }

            span {
                font-size: @font-size-small;
            }
        }

        &.hidden {
            opacity: 0;
            display: flex !important;
            height: 0;
            width: 0;
        }

        .price-box {
            padding-top: 5px;

            .dropdown {
                width: 90%;
                margin: 0 auto;
            }

            small {
                display: block;
            }

            .new-price,
            .old-price {
                font-size: 30px;
                margin-top: 10px;
                display: inline-block;
            }

            .old-price {
                margin-right: 5px;
                font-size: 22px;
            }

            &.monthly {
                .new-price {
                    font-weight: normal;
                    font-size: 34px;
                }
            }
        }

        .order-box-holder {
            padding-bottom: 20px;

            .selectric-wrapper {
                margin: 4px auto 0;
            }

            &:last-of-type {
                border-bottom-width: 1px;
            }

            .price-box,
            .order-box {
                border-left: 0;
            }

            form {
                padding: 0;
            }
        }

        .order-pkg {
            display: block;
        }
    }
}
.package-list {
    .feat-list {
        background: #fff;
        text-align: center;

        .icon-bolt {
            font-size: 1.3em;
            color: @color-secondary-alt;
        }

        .price-duration {
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid @color-lighter-grey;

            & > .pkg-subspec {
                padding-bottom: 15px;

                &:nth-of-type(1n+2) {
                    //min-height: 43px;
                }
            }

            .old-price {
                font-size: 14px;
                color: @color-darker-grey;

                &::after {
                    .box-shadow(1px 0 0 1px @color-darker-grey inset);
                }
            }

            .new-price {
                display: inline-block;
                font-size: 17px;
                margin: 0 5px;
            }

            .special {
                .new-price {
                    font-size: 1.43em;
                }
            }

            .order-box {
                padding: 25px 5px;
            }
        }

        .discount-info {
            padding-top: 3px;

            & > strong {
                padding: 3px 5px;
                color: @color-dark-grey;
                background-color: @color-lighter-grey;
                border-radius: 5px;
            }

            .icon-info-circle-alt {
                margin-left: 8px;
            }
        }

        & > .pkg-spec:first-of-type {
            padding-top: 45px;
            //max-height: 90px; // Primerjava paketov - popravek zamika
        }

        & > .pkg-spec {
            &:last-of-type {
                border-bottom: 1px solid @color-light-grey;
            }
        }
    }
}
.pkg-spec {
    border-left: 1px solid @color-lighter-grey;
    border-right: 1px solid @color-lighter-grey;
    min-height: 32px;
    min-width: 165px;
    padding: 8px 12px;
    position: relative;

    &.brd-bt {
        //padding-bottom: 15px;
        min-height: 41px;
    }

    &.brd-tp {
        padding-top: 10px;
    }

    &.dbl-hght {
        min-height: 61px;

        .icon-check-black,
        .algnfix {
            position: relative;
            top: 9px;
        }
    }

    &.trpl-hght {
        min-height: 101px;
    }

    &:nth-of-type(2n) {
        background: #fafafa;
    }

    &.pkg-subspec {
        padding: 0;
    }
}

.pkg-subspec {
    background: #fff;
    min-height: 32px;
    min-width: 155px;
    padding: 8px 12px;

    &:nth-of-type(2n) {
        background: #fafafa;
    }
}

.hosting-price {
    margin: 5px 0;
}

/* Izpostavljen paket */
@media(min-width: @break-lg-value) {

    .package-list .popular {
        margin: -4px -4px -6px;
        z-index: 2;

        &::before,
        &::after {
            display: block;
            height: 18px;
            content: ' ';
        }

        &::after {
            height: 4px;
        }

        .ribbon-diag {
            right: 0;
            top: -18px;
        }

        .pkg-spec {
            border-right: 5px solid @color-secondary;
            border-left: 5px solid @color-secondary;

            &.pkg-subspec {
                padding: 0;
            }
        }

        .feat-list {
            & > .pkg-spec:first-of-type {
                &:not(.pkg-subspec) {
                    padding-top: 45px;
                }
            }

            & > .pkg-spec:last-of-type {
                &:not(.pkg-subspec) {
                    padding-top: 20px;
                    padding-bottom: 35px;
                }
            }
        }

        .pkg-subspec {
            padding: 8px;
        }

        .order-box {
            padding: 25px 5px;
        }

        & .feat-list {
            .box-shadow(0 0 15px 1px rgba(0, 0, 0, 0.2));
        }

        .title {
            padding: 8px 8px 7px;
        }

        &.green {
            &::after {
                background-color: @color-secondary;
            }

            .pkg-spec {
                border-right-color: @color-secondary;
                border-left-color: @color-secondary;

                &.price-box {
                    border-width: 1px 5px;
                }
            }

            & .feat-list {
                & > .pkg-spec {
                    &:last-of-type {
                        //border-bottom: 4px solid @color-secondary;
                    }
                }
            }

            &::before {
                background-color: @color-secondary;
            }
        }

        &.orange {
            &::after {
                background-color: @color-secondary-alt;
            }

            .pkg-spec {
                border-right-color: @color-secondary-alt;
                border-left-color: @color-secondary-alt;

                &.price-box {
                    border-width: 1px 5px;
                }
            }

            & .feat-list {
                & > .pkg-spec {
                    &:last-of-type {
                        //border-bottom: 4px solid @color-secondary;
                    }
                }
            }

            &::before {
                background-color: @color-secondary-alt;
            }
        }
    }
}
.package-list.comparison {
    .popular {
        //margin: -18px -4px -4px;
        margin: -4px -4px -5px;

        & .feat-list > .pkg-spec:last-of-type:not(.pkg-subspec) {
            padding-top: 15px;
        }
    }

    .package-title {
        min-width: 180px;
    }

    .price-box {
        padding-top: 15px;

        .new-price {
            font-size: 2em;
        }

        .old-price {
            font-size: @font-size-bigger;
        }

        .btn-big {
            margin-top: 15px;
        }

        .new-price,
        .old-price {
            margin-top: 15px;
        }
    }

    &.small {
        .package-title {
            min-width: 165px;
        }

        .popular {
            margin: -10px -4px -12px;
        }
    }
}

// Hosting landing page
.package-list.small {
    .package-title {
        min-width: 210px;
    }

    .price-box {
        .new-price {
            font-size: 25px;
        }

        .old-price {
            font-size: 20px;
        }

        &.monthly {
            .new-price {
                font-size: 30px;
                font-weight: normal;
            }
        }
    }
}

// Cart hosting
.package-list.small {
    .package-title {
        min-width: 160px;
    }

    .price-box {
        .new-price {
            font-size: 25px;
        }

        .old-price {
            font-size: 20px;
        }

        &.monthly {
            .new-price {
                font-size: 30px;
                font-weight: normal;
            }
        }
    }
}



.stevec {
    display: none;
    .wrapper {
        position: relative;
        margin: auto;
      }
      .meter {
        width: 100%; height: 100%;
        transform: rotateX(180deg);
      }
      .circle {
        fill: none;
      }
      .outline, .mask {
        stroke: #e6e6e6;
        stroke-width: 30;
      }
      .range {
        stroke-width: 29;
      }
      .outline_ends{
          stroke: transparent;
      }
      .slider, #lbl {
        position: absolute;
      }
      .slider {
        cursor: pointer;
        left: 0;
        margin: auto;
        right: 0;
        top: 58%;
        width: 94%;
      }

      .meter_needle {
        height: 40%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 10%;
        transform-origin: bottom center;
        /*orientation fix*/
        transform: rotate(270deg);
      }
      .meter_needle-pin {
        height: 40%;
        width: 100%;
        left: 0px;
        margin: auto;
        position: absolute;
        right: 0;
        top: 15%;
        /*orientation fix*/

      }
}
