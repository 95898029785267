/* Youtube PLAY gumb */
.play-info {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140px;
    margin-top: -47px;
    margin-left: -65px;
    color: #fff;
    text-align: center;
    z-index: 1;

    .icon-play {
        .box-shadow(~'0 0 4px -1px rgba(0,0,0,0.5), 0 0 4px -1px rgba(0,0,0,0.5) inset');

        display: inline-block;
        padding: 19px 16px 19px 22px;
        margin-bottom: 10px;
        border: 3px solid;
        font-size: 30px;
        border-radius: 100px;
        height: 75px;
        width: 75px;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    }

    .duration {
        display: block;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    }
}

/* video player - placeholder image - play */
.video-holder {
    min-width: 265px;
    position: relative;

    .video-placeholder {
        .filter(brightness(0.5));

        width: 100%;
    }

    &:hover {
        .video-placeholder {
            .filter(brightness(0.6));
        }
    }
}

.youtube-video {
    height: 315px;
    margin: 30px auto;
    display: table;

    iframe {
        height: inherit;
    }
}
