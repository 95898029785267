
/*============================================
=          Napaka 403, 404, 500, 503         =
============================================*/
.error-page {
    width: 100%;

    .error-holder {
        margin: 100px 0;
        text-align: center;
    }

    .error-title {
        text-align: left;

        .description {
            margin-top: 1.5em;
        }
    }

    .error-title,
    .error-img {
        display: inline-block;
        vertical-align: bottom;
    }

    .error-img {
        margin-right: 25px;
        max-height: 160px;
    }

    h1 {
        font-size: 4.5em;
        margin: 8px 0 6px;
    }

    p {
        margin: 0.8em 0 1em 5px;
    }
}

.error-403,
.error-404,
.error-500,
.error-503 {
    .error-holder {
        margin-top: 60px;
    }

    .error-title {
        text-align: center;
        margin-top: 20px;
    }
}

.error-503 {
    h1 {
        font-size: 62px;
    }
}

.error-img-holder {
    margin-bottom: 20px;

    .main,
    .mascote-idea,
    .mascote-laptop {
        display: inline-block;
        max-width: 155px;
    }

    .mascote-idea,
    .mascote-laptop {
        vertical-align: top;
        max-width: 135px;
    }

    .mascote-idea {
        margin-top: -60px;
        max-height: 140px;
    }

    .mascote-laptop {
        margin-top: -15px;
        max-height: 102px;
    }
}

.crossed-out {
    display: inline-block;
    position: relative;
    font-weight: normal;

    &::after {
        .box-shadow(3px 0 0 3px @color-secondary-alt inset);

        content: " ";
        position: absolute;
        right: -2%;
        top: 44%;
        line-height: 0;
        box-shadow: 27px 0 2px 2px #f3831f inset;
        width: 110%;
        height: 3px;
        transform: translateY(0) translateX(4%) rotate(-19deg);
    }
}

/*=====  End of Napaka 403, 404, 500, 503  ======*/
