
.social-holder {
    height: 25px;
    margin-top: 10px;

    iframe {
        max-width: none;
    }
}

.social-center {
    text-align: center;
}

.social-share-row {
    display: flex;
    margin: 0 auto;

    &>div:nth-of-type(2) {
        margin-left: 15px;
    }
}

@media @break-max-md {
    .social-share-row {
        display: block;
        margin: 0 auto;

        &>div.action-promote-center {
            text-align: center !important;
        }

        &>div:nth-of-type(2) {
            width: 98px;
            margin: 0 auto;
        }
    }
}

@media @break-sm {
    .action-promote-center {
        text-align: center !important;
    }
}
