/*==================================
=           NS main icons          =
==================================*/

/*==========  Icons  ==========*/
@font-face {
    font-family: 'neoserv';
    src:  url('../fonts/neoserv.eot?3b1twg');
    src:  url('../fonts/neoserv.eot?3b1twg#iefix') format('embedded-opentype'),
        url('../fonts/neoserv.ttf?3b1twg') format('truetype'),
        url('../fonts/neoserv.woff?3b1twg') format('woff'),
        url('../fonts/neoserv.svg?3b1twg#neoserv') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*----------  Icon definitions  ----------*/
.iconFamily() {
	&:extend(.family-icon);
}
.family-icon {
	font-family: 'neoserv';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	text-decoration: none;
}
[class^="icon-"], [class*=" icon-"] {
	.iconFamily();
}

.icon-wordpress:before {
    content: "\e910";
}
.icon-joomla:before {
    content: "\e911";
}
.icon-play:before {
    content: "\e90f";
}
.icon-graph:before {
    content: "\e90e";
}
.icon-speed:before {
    content: "\e90d";
}
.icon-euro:before {
    content: "\e90a";
}
.icon-lock:before {
    content: "\e90b";
}
.icon-organization:before {
    content: "\e90c";
}
.icon-download-arrow:before {
    content: "\e909";
}
.icon-search:before {
	content: "\e907";
}
.icon-comment-full:before {
	content: "\e908";
}
.icon-comment:before {
	content: "\e906";
}
.icon-folder:before {
	content: "\e905";
}
.icon-mobile-phone:before {
	content: "\e902";
}
.icon-pc:before {
	content: "\e903";
}
.icon-tablet:before {
	content: "\e904";
}
.icon-burger-menu:before {
	content: "\e901";
}
.icon-minus-circle-white:before {
	content: "\e65d";
	color: rgb(255, 255, 255);
}
.icon-minus-circle-white:after {
	content: "\e65e";
	margin-left: -1em;
	color: @color-red;
}
.icon-plus-circle-white:before {
	content: "\e65f";
	color: rgb(255, 255, 255);
}
.icon-plus-circle-white:after {
	content: "\e660";
	margin-left: -1em;
	color: @color-secondary;
}
.icon-check-black-wbg:before {
	content: "\e654";
	color: rgb(255, 255, 255);
}
.icon-check-black-wbg:after {
	content: "\e655";
	margin-left: -1em;
	color: @color-secondary;
}
/*
.icon-ns-logo:before {
	content: "\e631";
	margin-left: -1em;
	color: rgb(29, 153, 213);
}
.icon-ns-logo:after {
	content: "\e632";
	margin-left: -1em;
	color: rgb(242, 130, 32);
}*/
.icon-yt_play {
	&:before {
		content: "\e647";
		color: rgb(211, 35, 39);
	}
	&:after {
		content: "\e648";
		margin-left: -1em;
		color: rgb(255, 255, 255);
		position: absolute;
	}
}


.icon-smiley-down:before {
	content: "\e661";
}
.icon-info-circle-alt2, .icon-info-circle-warning-alt2 {
    &:before {
        content: "\e662";
        color: rgb(204, 204, 204);
    }
}
.icon-info-circle-alt2, .icon-info-circle-warning-alt2 {
    &:after {
        content: "\e663";
        margin-left: -1em;
        color: rgb(102, 102, 102);
    }
}
.icon-ninja:before {
	content: "\e664";
}
.icon-shield-ssl:before {
	content: "\e665";
}
.icon-person:before {
	content: "\e65c";
}
.icon-qurantine:before {
	content: "\e65a";
}
.icon-reorder:before {
	content: "\e65b";
}
.icon-server-rack:before {
	content: "\e656";
}
.icon-cloud-bolt:before {
	content: "\e657";
}
.icon-discount-left:before {
	content: "\e640";
}
.icon-discount-right:before {
	content: "\e64c";
}
.icon-hw-cpu:before {
	content: "\e64d";
}
.icon-hw-ram:before {
	content: "\e64f";
}
.icon-hw-ssd:before {
	content: "\e650";
}
.icon-hw-psu:before {
	content: "\e651";
}
.icon-smiley:before {
	content: "\e652";
}
.icon-spinner-1:before {
	content: "\e64b";
}
.icon-check-black-long:before {
	content: "\e653";
}
.icon-domain-2:before {
	content: "\e658";
}
.icon-email-2:before {
	content: "\e659";
}
.icon-hw-pci:before {
	content: "\e64e";
}
.icon-cross-black:before {
	content: "\e64a";
}
.icon-arrow-down:before {
	content: "\e600";
}
.icon-arrow-down-thin:before {
	content: "\e601";
}
.icon-arrow-left:before {
	content: "\e602";
}
.icon-arrow-left-thin:before {
	content: "\e603";
}
.icon-arrow-right:before {
	content: "\e604";
}
.icon-arrow-right-thin:before {
	content: "\e605";
}
.icon-arrow-up:before {
	content: "\e606";
}
.icon-arrow-up-thin:before {
	content: "\e607";
}
.icon-bolt:before {
	content: "\e608";
}
.icon-cancel:before {
	content: "\e609";
}
.icon-cancel-circle:before {
	content: "\e60a";
}
.icon-cancel-circle-alt:before {
	content: "\e60b";
}
.icon-carret-down:before {
	content: "\e60c";
}
.icon-carret-left:before {
	content: "\e60d";
}
.icon-carret-right:before {
	content: "\e60e";
}
.icon-carret-up:before {
	content: "\e60f";
}
.icon-cart:before {
	content: "\e610";
}
.icon-cc:before {
	content: "\e611";
}
.icon-check:before {
	content: "\e612";
}
.icon-check-bold:before {
	content: "\e613";
}
.icon-check-black:before {
	content: "\e649";
}
.icon-check-circle:before {
	content: "\e614";
}
.icon-check-circle-alt:before {
	content: "\e615";
}
.icon-circle:before {
	content: "\e616";
}
.icon-circle-full:before {
	content: "\e617";
}
.icon-clock-out:before {
	content: "\e618";
}
.icon-close:before {
	content: "\e619";
}
.icon-cross-bold:before {
	content: "\e61a";
}
.icon-danger-circle:before {
	content: "\e61b";
}
.icon-danger-circle-alt:before {
	content: "\e61c";
}
.icon-domain:before {
	content: "\e61d";
}
.icon-email:before {
	content: "\e61e";
}
.icon-fabo:before {
	content: "\e61f";
}
.icon-filemanager:before {
	content: "\e620";
}
.icon-filemanager-out:before {
	content: "\e621";
}
.icon-gauge-out:before {
	content: "\e622";
}
.icon-gp:before {
	content: "\e623";
}
.icon-home:before {
	content: "\e624";
}
.icon-hosting:before {
	content: "\e625";
}
.icon-hosting-alt:before {
	content: "\e626";
}
.icon-in:before {
	content: "\e627";
}
.icon-info-circle, .icon-info-circle-warning {
    &:before {
        content: "\e628";
    }
}
.icon-info-circle-alt, .icon-info-circle-warning-alt {
    &:before {
        content: "\e629";
    }
}
.icon-mail-out:before {
	content: "\e62a";
}
.icon-minus:before {
	content: "\e62b";
}
.icon-minus-circle-20:before {
	content: "\e62c";
}
.icon-minus-circle-65:before {
	content: "\e62d";
}
.icon-minus-circle-alt-21:before {
	content: "\e62e";
}
.icon-minus-circle-alt-66:before {
	content: "\e62f";
}/*
.icon-ns-logo-alt:before {
	content: "\e633";
}*/
.icon-phone:before {
	content: "\e634";
}
.icon-plus:before {
	content: "\e635";
}
.icon-plus-circle-31:before {
	content: "\e636";
}
.icon-plus-circle-63:before {
	content: "\e637";
}
.icon-plus-circle-alt-32:before {
	content: "\e638";
}
.icon-plus-circle-alt-64:before {
	content: "\e639";
}
.icon-question-circle:before {
	content: "\e63a";
}
.icon-question-circle-alt:before {
	content: "\e63b";
}
.icon-quote:before {
	content: "\e63c";
}
.icon-quote-alt:before {
	content: "\e63d";
}
.icon-security-ok:before {
	content: "\e63e";
}
.icon-server-out:before {
	content: "\e63f";
}
.icon-star:before {
	content: "\e641";
}
.icon-star-out:before {
	content: "\e642";
}
.icon-statistika:before {
	content: "\e643";
}
.icon-thumb-up-out:before {
	content: "\e644";
}
.icon-twt:before {
	content: "\e645";
}
.icon-x:before {
    content: "\1d54f";
}
.icon-yt:before {
	content: "\e646";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-lsws{
	display: block;
	height: 20px !important;
	width: 20px !important;
	background-image: url('../img/cms/lsws-icon.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position:center;
}
.icon-imunify360:before {
    content: "\e900";
}




